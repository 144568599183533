import { useGlobal } from "reactn";
import SideModal from "../side-modal/side-modal.component";
import Button from "../../../components/button/button.component";
import HaveAccess from "../../../access-control.jsx";
import { Modal } from "react-bootstrap";
import { mentorUrl, designerUrl } from "../../../api/api";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location.svg";

const ReportedProfileModal = ({
  show,
  onHide,
  reportedProfile,
  onDeactivateUser,
  onReactivateUser,
  reactivateUserState,
}) => {
  const [currentUser] = useGlobal("currentUser");

  let reportedUser = {
    identity_id: reportedProfile.reported_user.id,
    identity_type: reportedProfile.reported_user.identity_type,
    account_type: reportedProfile.reported_user.account_type,
    email: reportedProfile.reported_user.email,
    is_active: reportedProfile.reported_user.is_active,
    last_deactivated_at: reportedProfile.reported_user.last_deactivated_at,
    last_deactivated_by: reportedProfile.reported_user.last_deactivated_by,
    last_deactivated_reason:
      reportedProfile.reported_user.last_deactivated_reason,
  };

  let reportedBy = {
    identity_id: reportedProfile.reported_by.id,
    identity_type: reportedProfile.reported_by.identity_type,
    account_type: reportedProfile.reported_by.account_type,
    email: reportedProfile.reported_by.email,
  };

  reportedUser =
    reportedProfile.reported_user.identity_type === "Mentor"
      ? {
          ...reportedUser,
          ...reportedProfile.reported_user.mentor,
          profile_url: mentorUrl(reportedProfile.reported_user.mentor.slug),
        }
      : {
          ...reportedUser,
          ...reportedProfile.reported_user.designer,
          profile_url: designerUrl(reportedProfile.reported_user.designer.slug),
        };

  reportedBy =
    reportedProfile.reported_by.identity_type === "Mentor"
      ? {
          ...reportedBy,
          ...reportedProfile.reported_by.mentor,
          profile_url: mentorUrl(reportedProfile.reported_by.mentor.slug),
        }
      : {
          ...reportedBy,
          ...reportedProfile.reported_by.designer,
          profile_url: designerUrl(reportedProfile.reported_by.designer.slug),
        };

  return (
    <>
      {reportedProfile && (
        <SideModal show={show} onHide={onHide} onBlurHide={true}>
          <Modal.Body className="scrollbar">
            <div className="modal-inner">
              <div className="content-medium">
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={reportedUser.profile_url}>
                    <div className="designer-pic mb-10">
                      <img
                        className="img-f-w-obj-cover"
                        src={reportedUser.profile_photo_url}
                        alt=""
                      />
                    </div>
                    <div className="text-grey text-20 font-bold">
                      {reportedUser.name}
                    </div>

                    <div className="text-grey text-16 font-500">
                      {reportedUser.title}

                      {reportedUser.employer && (
                        <span className="text-success pl-2">
                          {reportedUser.employer}
                        </span>
                      )}
                    </div>
                  </a>

                  {reportedUser.country.name && (
                    <div className="text-grey text-12 font-bold mt-10 d-flex">
                      <LocationIcon /> {reportedUser.country.name}
                    </div>
                  )}

                  <div className="text-grey-light text-16 font-400 mt-30">
                    Reported by
                  </div>
                  <a
                    href={reportedBy.profile_url}
                    target="_blank"
                    rel="noreferrer">
                    <div className="d-flex mt-10 pointer">
                      <div className="pic-small mr-3">
                        <img
                          className="img-f-w-obj-cover"
                          src={reportedBy.profile_photo_url}
                          alt=""
                        />
                      </div>
                      <span className="text-16 text-grey">
                        {reportedBy.name}
                      </span>
                    </div>
                  </a>

                  <div>
                    <div className="text-grey-light text-16 mt-25 font-400">
                      Reasons for reporting
                    </div>

                    {reportedProfile.report_reasons.length === 0 && (
                      <span className="text-16 text-dark font-bold">-----</span>
                    )}

                    {reportedProfile.report_reasons.length > 0 && (
                      <div>
                        {reportedProfile.report_reasons.map((reason) => (
                          <span className="text-16 text-dark font-bold">
                            {reason.reason}
                            <br />
                          </span>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="mt-25">
                    <div className="text-grey-light text-16 font-400">
                      Note for the team
                    </div>

                    <span className="text-16 text-dark font-bold">
                      {reportedProfile.note}
                    </span>
                  </div>

                  <div className="mt-25">
                    <div className="text-grey-light text-16 font-400">
                      Average attendance rate
                    </div>

                    <span className="text-16 text-dark font-bold">
                      calculating...
                      {/* TODO: fetch from calendar API */}
                    </span>
                  </div>

                  <div className="mt-25">
                    <div className="text-grey-light text-16 font-400">
                      Account type
                    </div>

                    <span className="text-16 text-dark font-bold">
                      {reportedUser.account_type}
                    </span>
                  </div>

                  <div className="mt-25">
                    <div className="text-grey-light text-16 font-400">
                      Created on
                    </div>

                    <span className="text-16 text-dark font-bold">
                      {reportedProfile.date_reported.split("T")[0]} UTC
                    </span>
                  </div>

                  <div className="mt-25">
                    <div className="text-grey-light text-16 font-400">
                      Email
                    </div>

                    <span className="text-16 text-success font-bold">
                      {reportedUser.email}
                    </span>
                  </div>

                  <div className="mt-25">
                    <div className="text-grey-light text-16 font-400">
                      LinkedIn
                    </div>

                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={reportedUser.linkedin_url}>
                      <span className="text-16 text-success font-bold">
                        {reportedUser.linkedin_url}
                      </span>
                    </a>
                  </div>

                  {!reportedUser.is_active && (
                    <div>
                      <div>
                        <div className="mt-25">
                          <div className="text-grey-light text-16 font-400">
                            Deactivated at
                          </div>

                          <span className="text-16 text-dark font-bold">
                            {reportedUser.last_deactivated_at
                              ? `${new Date(
                                  reportedUser.last_deactivated_at
                                ).toUTCString()}`
                              : "N/A"}
                          </span>
                        </div>
                      </div>

                      <div className="mt-25">
                        <div className="text-grey-light text-16 font-400">
                          Deactivated by
                        </div>

                        <span className="text-16 text-dark font-bold">
                          {reportedUser.last_deactivated_by
                            ? `${reportedUser.last_deactivated_by.name}`
                            : "N/A"}
                        </span>
                        <br />
                        <span className="text-16 text-success font-bold">
                          {reportedUser.last_deactivated_by &&
                            reportedUser.last_deactivated_by.email}
                        </span>
                      </div>

                      <div className="mt-25">
                        <div className="text-grey-light text-16 font-400">
                          Reason for deactivation
                        </div>

                        <span className="text-16 text-dark font-bold">
                          {reportedUser.last_deactivated_reason
                            ? reportedUser.last_deactivated_reason
                            : "No reason provided"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          {HaveAccess("user", "Write", currentUser) && (
            <Modal.Footer>
              {reportedUser.is_active ? (
                <div
                  className="modal-footer-inner"
                  style={{ paddingBottom: "40px" }}>
                  <Button classes="small danger" onClick={onDeactivateUser}>
                    Deactivate
                  </Button>
                </div>
              ) : (
                <div
                  className="modal-footer-inner"
                  style={{ paddingBottom: "40px" }}>
                  <Button
                    classes="small secondary"
                    loading={
                      reactivateUserState.processing &&
                      !reactivateUserState.error
                    }
                    disabled={reactivateUserState.processing}
                    onClick={onReactivateUser}>
                    Reactivate
                  </Button>

                  {reactivateUserState.error && (
                    <div className="text-danger text-16 mt-10">
                      {reactivateUserState.error}
                    </div>
                  )}
                </div>
              )}
            </Modal.Footer>
          )}
        </SideModal>
      )}
    </>
  );
};

export default ReportedProfileModal;
