import { useState, useGlobal, useEffect } from "reactn";
import SideModal from "../side-modal/side-modal.component";
import InputField from "../../form/input-field/input-field.component";
import Button from "../../../components/button/button.component";
import { Modal } from "react-bootstrap";
import { postData, putData } from "../../../api/api";

const LanguageModal = ({
  show,
  onHide,
  type = "Add",
  language,
  token,
  onUpdated = () => {},
}) => {
  const [languages, setLanguages] = useGlobal("languages");

  const [state, setState] = useState({
    description: "",
    processing: false,
    error: null,
  });

  return (
    <>
      {(language || type === "Add") && (
        <SideModal show={show} onHide={onHide} onBlurHide={false}>
          <Modal.Body className="scrollbar">
            <div className="modal-inner">
              <div className="content-medium">
                {/* add language */}
                <div>
                  <div className="text-dark text-20 font-bold mb-30">
                    {type === "Add" ? "Add new " : "Edit"} language
                  </div>
                  <InputField
                    label="Name"
                    placeholder={"eg. English"}
                    value={state.description}
                    onChange={(e) =>
                      setState({ ...state, description: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="modal-footer-inner"
              style={{ paddingBottom: `${window.bottomSpace}` }}
            >
              {state.error && !state.processing && (
                <div className="text-danger text-16 mb-10">{state.error}</div>
              )}

              <div className="d-flex">
                <Button
                  classes="small secondary mr-2"
                  loading={state.processing}
                  disabled={state.description.length < 1 || state.processing}
                  onClick={() => {
                    setState({ ...state, processing: true, error: null });
                    if (type === "Add") {
                      postData(
                        "language/",
                        { description: state.description },
                        token
                      ).then((resp) => {
                        if (resp.success) {
                          setState({
                            description: "",
                            processing: false,
                            error: null,
                          });
                          let newData = [resp.data, ...languages];
                          onUpdated(newData);
                          return;
                        }
                        setState({
                          ...state,
                          processing: false,
                          error: resp.message,
                        });
                      });
                    } else {
                      let id = language["id"];
                      putData(
                        "language/" + id + "/",
                        { description: state.description },
                        token
                      ).then((resp) => {
                        if (resp.success) {
                          setState({
                            description: "",
                            processing: false,
                            error: null,
                          });

                          let current = languages.filter(
                            (lang) => lang.id !== id
                          );
                          let newData = [resp.data, ...current];
                          onUpdated(newData);
                          return;
                        }
                        setState({
                          ...state,
                          processing: false,
                          error: resp.message,
                        });
                      });
                    }
                  }}
                >
                  {type === "Add" ? "Add" : "Save"}
                </Button>
                <Button
                  classes="small info"
                  disabled={state.processing}
                  onClick={() => {
                    setState({ ...state, description: "", error: null });
                    onHide();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </SideModal>
      )}
    </>
  );
};

export default LanguageModal;
