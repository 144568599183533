import { useState, useGlobal, useEffect } from "reactn";
import { Table, Row, Col, Spinner } from "react-bootstrap";

import { fetchData, putData } from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import InputField from "../../../components/form/input-field/input-field.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
const CountriesPage = (props) => {
  const [currentUser] = useGlobal("currentUser");
  const [countries, setCountries] = useGlobal("countries");

  const [state, setState] = useState({
    activeTab: 0,
    showCountryModal: false,
    countryModalType: null,
    currentcountry: null,
    showConfirmModal: false,
    confirmDescription: "",
    confirmProcessing: false,
    confirmTitle: "",
    confirmError: null,
    fetching: false,
    canFetch: true,
    error: null,
    query: "",
    queryDisabled: "",
    activeCountries: countries,
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [disabledPaginate, setDisabledPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (state.canFetch && !state.fetching && countries.length < 1) {
      setState({
        ...state,
        fetching: true,
        canFetch: false,
      });

      let authToken = currentUser.token.actual;
      fetchData("country/", authToken).then((response) => {
        if (response.success) {
          setCountries(response.data);
          setState({
            ...state,
            fetching: false,
            canFetch: false,
            error: null,
            activeCountries: response.data,
            //disabledCountries: response.data.filter(country => country.disabled === true),
          });

          return;
        }
        setState({
          ...state,
          fetching: false,
          canFetch: false,
          error: response.message,
        });
      });
    }
  }, [
    countries,
    state.activeCountries,
    state.disabledCountries,
    state.canFetch,
    state.limit,
    paginate,
  ]);

  //edit or add country --callback
  function onCountryUpdated(data) {
    setState({
      ...state,
      activeCountries: data,
      showCountryModal: false,
    });
    setCountries(data);
  }
  //confirm disable or enable country
  function onCountryDisableOrEnable() {
    setState({ ...state, confirmProcessing: true });
    let data = {
      description: state.currentCountry.description,
      disabled: state.currentCountry.disabled ? false : true,
    };

    putData(
      `country/${state.currentCountry.id}/`,
      data,
      currentUser.token.actual
    ).then((response) => {
      if (response.success) {
        let current = countries.filter(
          (country) => country.id !== state.currentCountry["id"]
        );
        let newData = [...current, response.data];
        setState({
          ...state,
          confirmProcessing: false,
          confirmError: null,
          showConfirmModal: false,
          activeCountries: newData,
          //disabledCountries: newData.filter(country => country.disabled === true),
        });
        setCountries(newData);

        return;
      }
      setState({
        ...state,
        confirmProcessing: false,
        confirmError: response.message,
      });
    });
  }

  //active filtering
  const activeCountries = () => {
    let active = state.activeCountries;
    let totalItems = active.length;

    if (state.query.length > 0) {
      active = active.filter((country) =>
        country.name.toLowerCase().includes(state.query.toLowerCase())
      );

      //list length
      totalItems = active.length;
    }

    // paginate
    const { page, pageSize } = paginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: active.slice(start, end), totalItems: totalItems };
  };

  //disabled mentors filtering
  const disabledCountries = () => {
    let disabled = state.disabledCountries;
    let totalItems = disabled.length;

    if (state.queryDisabled.length > 0) {
      disabled = disabled.filter((country) =>
        country.name.toLowerCase().includes(state.queryDisabled)
      );
      //list length
      totalItems = disabled.length;
    }

    // paginate
    const { page, pageSize } = disabledPaginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: disabled.slice(start, end), totalItems: totalItems };
  };

  return (
    <>
      {/*       <CountryModal
        country={state.currentCountry}
        token={currentUser.token.actual}
        type={state.countryModalType}
        show={state.showCountryModal}
        onHide={() => {
          setState({ ...state, showCountryModal: false });
        }}
        onUpdated={onCountryUpdated}
      />

      <ConfirmModal
        show={state.showConfirmModal}
        token={currentUser.token.actual}
        title={state.confirmTitle}
        description={state.confirmDescription}
        processing={state.confirmProcessing}
        error={state.confirmError}
        onHide={() => {
          setState({ ...state, confirmError: null, showConfirmModal: false });
        }}
        onConfirm={onCountryDisableOrEnable}
      /> */}

      <PageLayout activeLink="countries" parentMenu="demographics">
        <div>
          {/* <div className="text-grey text-20 font-bold mb-20">Countries</div> */}

          <Row>
            <Col md={7}>
              <div className="d-flex flex-wrap align-center mb-20">
                <div className="text-grey text-20 font-bold mb-20">
                  Countries({countries.length})
                </div>
                {/* <Button
                  classes={`pill success ${state.activeTab == 0} mr-2  mb-20`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 0 });
                  }}>
                  Active({state.activeCountries.length})
                </Button>

                <Button
                  classes={`pill success ${state.activeTab == 1}  mb-20 mr-3`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 1 });
                  }}>
                  Disabled({state.disabledCountries.length})
                </Button> */}

                {/* <Button
                  classes={`small success w-200  mb-20`}
                  onClick={() => {
                    setState({ ...state, countryModalType: "Add", showCountryModal: true });
                  }}>
                  <PlusIcon /> <span className="pl-2"> Add Country</span>
                </Button> */}
              </div>
            </Col>

            <Col md={5}>
              {state.activeTab == 0 && (
                <InputField
                  placeholder="Search by name"
                  classes="small mb-20"
                  isSearch
                  value={state.query}
                  onChange={(ev) => {
                    setState({ ...state, query: ev.target.value });
                    if (paginate.page > 0)
                      setPaginate({ ...paginate, page: 0 });
                  }}
                />
              )}
            </Col>
          </Row>

          {state.activeTab == 0 && state.activeCountries.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Country</th>
                    <th>ISO</th>
                    <th>Mentors</th>
                    <th>Members</th>
                    <th>Reported Users</th>
                  </tr>
                </thead>
                <tbody>
                  {activeCountries().items.map((country) => (
                    <tr key={country.id}>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {country.name}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-400">
                          {country.iso}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-400">
                          {country.total_mentors}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-400">
                          {country.total_members}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-400">
                          {country.total_reported_users}
                        </span>
                      </td>
                      {/* <td>
                        <span
                          className="text-16 text-success font-400 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              currentCountry: country,
                              countryModalType: "Edit",
                              showCountryModal: true,
                            });
                          }}>
                          Edit
                        </span>

                        <span
                          className="text-16 text-warning font-400 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              currentCountry: country,
                              confirmTitle: "Disable Country",
                              confirmDescription: `Do you want to disable this country [ ${country.name} ] `,
                              showConfirmModal: true,
                            });
                          }}>
                          Disable
                        </span>
                      </td> */}
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={5}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={activeCountries().totalItems}
                        onChangePage={(newPage) => {
                          setPaginate({ ...paginate, page: newPage });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({ ...paginate, pageSize: size, page: 0 });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {/*no active countries */}
          {state.activeTab == 0 &&
            state.activeCountries.length == 0 &&
            !state.fetching && (
              <div className="text-grey text-16 mb-20">
                {" "}
                No active Countries{" "}
              </div>
            )}

          {/*  {state.activeTab == 1 && state.disabledCountries.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Country</th>
                    <th>Number of Mentors</th>
                    <th>Number of Designers</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {disabledCountries().items.map(country => (
                    <tr key={country.id}>
                      <td>
                        <span className="text-16 text-grey font-400">{country.name} </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400"> </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-400"></span>
                      </td>
                      <td>
                        <span
                          className="text-16 text-success font-400 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              currentCountry: country,
                              countryModalType: "Edit",
                              showCountryModal: true,
                            });
                          }}>
                          Edit
                        </span>

                        <span
                          className="text-16 text-grey font-400 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              currentCountry: country,
                              confirmTitle: "Enable Country",
                              confirmDescription: `Do you want to enable this country [ ${country.description} ] `,
                              showConfirmModal: true,
                            });
                          }}>
                          Enable
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4}>
                      <PaginationBar
                        page={disabledPaginate.page}
                        pageSize={disabledPaginate.pageSize}
                        totalItems={disabledCountries().totalItems}
                        onChangePage={newPage => {
                          setDisabledPaginate({ ...disabledPaginate, page: newPage });
                        }}
                        onChangeRowsPerPage={size => {
                          setDisabledPaginate({ ...disabledPaginate, pageSize: size, page: 0 });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )} */}
          {/*no disabled country */}
          {state.activeTab == 1 &&
            state.disabledCountries.length == 0 &&
            !state.fetching && (
              <div className="text-grey text-16 mb-20">
                {" "}
                No disabled countries{" "}
              </div>
            )}

          <div className="mt-10 d-flex justify-center">
            <Spinner
              hidden={!state.fetching || state.query.length > 0}
              animation="border"
              variant="primary"
            />
          </div>

          <div className="mt-30" hidden={!state.error || state.fetching}>
            <span className="mt-10 text-danger">{state.error}.</span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => setState({ ...state, canFetch: true })}
            >
              TRY AGAIN
            </span>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default CountriesPage;
