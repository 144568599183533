import { Fragment } from "react";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import InputField from "components/form/input-field/input-field.component";
import FileInput from "components/form/file-input/file-input.component";
import Editor from "components/form/editor/editor.component";
import Button from "components/button/button.component";

const SlideUI = ({
  type = "new",
  index,
  state,
  setState,
  slideCount,
  isLoading,
  setIsLoading,
  featureRelease,
  handleUpdateSlide,
  handleCreateAnnouncementSlide,
}) => {
  /**
   * variables
   */
  let isLastSlide = index + 1 === slideCount;

  if (type === "edit") {
    // if type is edit and slidecount > 0 means a new slide has been added which makes those slides be the last slide
    slideCount > 0
      ? (isLastSlide =
          index + 1 ===
          featureRelease?.announcement_slides?.length + slideCount)
      : (isLastSlide =
          index + 1 === featureRelease?.announcement_slides?.length);
  }

  return (
    <Fragment>
      <div className="grey-hr my-30" />
      <div className="mb-20">
        <InputField
          label={`Title ${index + 1}`}
          placeholder="Feature title"
          value={state.title[index]}
          onChange={(e) => {
            let newTitle = [...state.title];
            newTitle[index] = e.target.value;

            return setState({
              ...state,
              title: [...newTitle],
            });
          }}
        />
      </div>
      <div className="mb-20">
        <Editor
          label={`Description ${index + 1}`}
          height={150}
          placeholder="Describe the feature"
          value={state?.description[index]}
          onEditorChange={(value, editor) => {
            let newDescription = [...state.description];
            newDescription[index] = value;

            return setState({ ...state, description: [...newDescription] });
          }}
        />
      </div>

      <div>
        <FileInput
          accept="image/*"
          type="file"
          id="cover-image"
          label={`Media file (image/gif/video) ${index + 1}`}
          placeholder="Upload file here"
          selectedFile={
            !state.media_file[index]?.name
              ? { name: state.media_file[index] }
              : state.media_file[index]
          }
          onChange={(e) => {
            let file = e.target.files[0];
            let selected = file;

            let newFile = [...state.media_file];
            newFile[state.index || 0] = selected;

            return setState({ ...state, media_file: [...newFile] });
          }}
        />
      </div>
      {/* show 'add new SLide' On Last Slide */}
      {(type?.toLowerCase() === "new" || slideCount > 0) && isLastSlide ? (
        <Button
          classes="small success my-30"
          disabled={
            state.processing ||
            !state.title[index] ||
            !state.description[index] ||
            !state.media_file[index]
          }
          onClick={() => handleCreateAnnouncementSlide()}
        >
          <PlusIcon /> <span className="pl-2">Save and add new slide</span>
        </Button>
      ) : type?.toLowerCase() === "edit" ? (
        <Button
          classes="small secondary my-30"
          loading={isLoading}
          disabled={
            state.processing ||
            !state.title[index] ||
            !state.description[index] ||
            !state.media_file[index]
          }
          onClick={() => setIsLoading(true) | handleUpdateSlide()}
        >
          Update
        </Button>
      ) : null}
    </Fragment>
  );
};

export default SlideUI;
