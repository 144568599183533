import { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import TurndownService from "turndown";

import { updateInProductReleaseSlideService, createInProductReleaseSlideService } from "services/InProductReleaseAnnouncementService"; // prettier-ignore
import Notify from "components/Notify";
import SlideUI from "./SlideUI";

const SlideLogic = ({
  id,
  type = "new",
  index,
  state,
  mutate,
  setState,
  slideCount,
  setSlideCount,
  featureRelease,
}) => {
  /**
   * state
   */
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isNaN(index)) {
      setState((prevState) => ({ ...prevState, index: index }));
    }
  }, [index]);

  /**
   * functions
   */
  const handleCreateAnnouncementSlide = () => {
    setState({ ...state, processing: true, error: null });

    const descriptionMarkdown =
      state?.description &&
      new TurndownService().turndown(state.description[index]);

    const params = new FormData();
    state?.title && params.append("title", state.title[index]);
    state?.description && params.append("description", descriptionMarkdown);
    state?.media_file?.[index] &&
      params.append("media_file", state.media_file[index]);
    (state?.in_product_release_announcement_id || featureRelease?.id) &&
      params.append(
        "in_product_release_announcement_id",
        state.in_product_release_announcement_id || featureRelease?.id
      );

    createInProductReleaseSlideService(params).then((res) => {
      if (res.success) {
        let newState = { ...state };
        newState["releaseId"][index] = res?.data?.id;
        newState["title"][index] = res?.data?.title;
        newState["description"][index] = res?.data?.description;
        newState["media_file"][index] = res?.data?.media_file;

        setState((state) => ({
          ...state,
          processing: false,
          error: null,
          ...newState,
        }));
        toast(<Notify type="success" body="Slide added" />);
        mutate();
        setSlideCount((n) => n + 1); // increment slide count
      } else {
        toast(
          <Notify
            type="error"
            body="Unable to add slide. Please ensure you have the appropriate permissions and try again."
          />
        );

        setState({
          ...state,
          processing: false,
          error:
            res.message ||
            "Failed to add Slide. Please contact support to update your permissions and try again.",
        });
      }
    });
  };

  const handleUpdateSlide = (e) => {
    setState({ ...state, processing: true, error: null });
    id = id || state.releaseId[index];

    const descriptionMarkdown =
      state?.description &&
      new TurndownService().turndown(state.description[index]);

    const params = new FormData();
    state?.title && params.append("title", state.title[index]);
    state?.description && params.append("description", descriptionMarkdown);
    state?.media_file &&
      typeof state.media_file[index] !== "string" &&
      params.append("media_file", state.media_file[index]);
    (state?.in_product_release_announcement_id || featureRelease?.id) &&
      params.append(
        "in_product_release_announcement_id",
        state.in_product_release_announcement_id || featureRelease?.id
      );

    /**
     * Update In DB
     */
    if (id && params) {
      updateInProductReleaseSlideService(id, params).then((res) => {
        if (res.success) {
          let newState = { ...state };
          newState["title"][index] = res?.data?.title;
          newState["description"][index] = res?.data?.description;
          newState["media_file"][index] = res?.data?.media_file;

          setState({
            ...state,
            error: null,
            processing: false,
            releaseId: res?.data?.id,
            ...newState,
          });
          setIsLoading(false);
          mutate();
          toast(<Notify type="success" body="Slide updated" />);
          if (e) {
            if (e === "UPDATE_ADD") {
              setSlideCount((prevState) => prevState + 1);
            }
          }
        } else {
          setState({
            ...state,
            processing: false,
            error:
              res.message ||
              "Failed to add Slide. Please contact support to update your permissions and try again.",
          });
          setIsLoading(false);
        }
      });
    } else {
      setState({
        ...state,
        error: null,
        processing: false,
      });
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <SlideUI
        {...{
          id,
          type,
          index,
          state,
          mutate,
          setState,
          slideCount,
          isLoading,
          setIsLoading,
          setSlideCount,
          featureRelease,
          handleUpdateSlide,
          handleCreateAnnouncementSlide,
        }}
      />
    </Fragment>
  );
};

export default SlideLogic;
