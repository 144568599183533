import React from "reactn";
import { Spinner } from "react-bootstrap";

const LoaderPane = ({
  loading,
  error = null,
  canReload = true,
  noRecords = false,
  onReload = () => {},
}) => {
  return (
    <div className="d-flex justify-center w-100">
      {loading && (
        <div className="">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {error && !loading && <span className="text-danger">{error}</span>}

      {noRecords && !loading && <div className="text-grey">No Records.</div>}

      {error && canReload && (
        <span>
          &nbsp;
          <span
            className="text-primary pointer font-bold"
            onClick={() => onReload()}
          >
            TRY AGAIN
          </span>
        </span>
      )}
    </div>
  );
};

export default LoaderPane;
