import { useState, useGlobal } from "reactn";
import { ReactComponent as Logo } from "../../assets/icons/adplist.svg";
import { ReactComponent as Chevron } from "../../assets/icons/chevron-down.svg";
import { Link, withRouter } from "react-router-dom";
import "./navbar.styles.scss";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";

const Navbar = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [, setAuthCredential] = useGlobal("authCredential");
  const [state, setState] = useState({
    showMenu: false,
  });

  const { onSideToggle } = props;

  return (
    <div className="main-navbar">
      <div className="nav-inner">
        <div className="side-toggle" onClick={onSideToggle}>
          <MenuIcon />
        </div>

        <div onClick={() => props.history.push(`/`)}>
          <Logo />
        </div>
        <div className="nav-inner-right">
          <div
            className="d-flex align-center"
            onClick={() => {
              setState({ ...state, showMenu: state.showMenu ? false : true });
            }}
          >
            {!currentUser ? (
              <div className="pic"></div>
            ) : (
              <div className="text-16 text-grey font-500 mr-2">
                {" "}
                {currentUser.username}
              </div>
            )}
            <div>
              <Chevron />
            </div>
          </div>
          {state.showMenu && (
            <div className="dropMenu">
              <div
                className="text-14 font-500 pointer text-danger"
                onClick={() => {
                  setAuthCredential(null);
                  setCurrentUser(null);
                  setState({ ...state, showMenu: false });
                  props.history.push(`/login`);
                }}
              >
                Logout
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Navbar);
