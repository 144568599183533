import React from "react";
import "./file-input.styles.scss";
import { ReactComponent as FileIcon } from "../../../assets/icons/file-input.svg";

const FileInput = ({
  id,
  label,
  placeholder,
  selectedFile,
  isSearch,
  rightIcon,
  visible,
  classes,
  ...otherProps
}) => (
  <label className={`file-input ${classes}`} htmlFor={id}>
    {label && <label>{label}</label>}
    <div className={`input-container ${visible}`}>
      <div className="file-source">
        {!selectedFile && <span className="placeholder">{placeholder}</span>}
        {selectedFile && (
          <div className="text-truncate">{selectedFile?.name}</div>
        )}
      </div>
      <div className="right-icon iconDiv">
        {" "}
        <FileIcon />{" "}
      </div>
    </div>
    <input id={id} type="file" hidden={true} {...otherProps} />
  </label>
);

export default FileInput;
