import Button from "components/button/button.component";
import PageLayout from "components/page-layout/page-layout.component";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { canWrite } from "access-control";
import { Col, Row, Table } from "react-bootstrap";
import { useGlobal, useState, useEffect } from "reactn";
import InputFIeld from "components/form/input-field/input-field.component";
import useSWR from "swr";
import { apiBaseUrl } from "api/api";
import ButtonDropdown from "components/button-dropdown/button-dropdown";

import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import { MenuItem } from "@material-ui/core";
import LoaderPane from "components/loader-pane/loader-pane.component";
import PaginationBar from "components/pagination-bar/pagination-bar.component";
import SkillModal from "./skill.modal";

const Search = ({ query, setQuery }) => {
  return (
    <InputFIeld
      placeholder="Search"
      classes="small mb-20"
      isSearch
      value={query}
      onChange={(ev) => {
        setQuery(ev.target.value);
      }}
    />
  );
};

const Filter = ({ filter, setFilter }) => {
  return (
    <div className="d-flex mr-10">
      <ButtonDropdown
        classes="mw-200px"
        title={
          <div className="d-flex align-center text-truncate">
            <FilterIcon /> &nbsp;&nbsp;
            <span>{filter.label || "Filter"}</span>
          </div>
        }>
        <MenuItem
          active={filter.value === ""}
          onClick={() =>
            setFilter({
              label: "Filter",
              value: "",
            })
          }>
          Show all
        </MenuItem>

        <MenuItem
          active={filter.value === "Soft"}
          onClick={() =>
            setFilter({
              label: "Soft",
              value: "Soft",
            })
          }>
          Soft
        </MenuItem>

        <MenuItem
          active={filter.value === "Applied"}
          onClick={() =>
            setFilter({
              label: "Applied",
              value: "Applied",
            })
          }>
          Applied
        </MenuItem>
      </ButtonDropdown>
    </div>
  );
};

const SkillsTable = ({
  active,
  select = "active",
  query,
  filter,
  editAction,
  enableAction,
  disableAction,
  setTotalCount,
  setMutateAction,
}) => {
  const [paginationState, setPaginationState] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    remoteNext: null,
    remotePrev: null,
  });

  const getUrl = () => {
    let path = `${apiBaseUrl}skill/?offset=${
      paginationState.page * paginationState.pageSize
    }&limit=${paginationState.pageSize}`;

    if (select) path += `&select=${select}`;

    if (active && query) path += `&search=${query}`;
    if (active && filter) path += `&skill_type=${filter}`;

    return path;
  };

  const { data, error, mutate } = useSWR(getUrl());

  useEffect(() => {
    if (data) {
      setTotalCount(data.count);
    }

    setMutateAction(() => () => mutate());
  }, [data, mutate, setTotalCount, setMutateAction]);

  return (
    <>
      <LoaderPane
        loading={!data && !error}
        error={error?.message}
        noRecords={data && data?.results.length === 0}
        onReload={() => {
          mutate();
        }}
      />
      {data?.results.length > 0 && (
        <Table bordered responsive className="table-white">
          <thead>
            <tr>
              <th>Name</th>
              <th>Motivation</th>
              <th># of mentors</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.results?.map((skill) => (
              <tr key={skill.id}>
                <td>
                  <span className="text-16 text-grey font-500">
                    {skill.name}
                  </span>
                </td>

                <td>
                  <span className="text-16 text-grey font-500">
                    {skill.motivation?.description ?? "N/A"}
                  </span>
                </td>

                <td>
                  <span className="text-16 text-grey font-500">
                    {skill.total_mentors}
                  </span>
                </td>

                <td>
                  <span className="text-16 text-grey font-500">
                    {skill.skill_type}
                  </span>
                </td>
                <td>
                  <span
                    className="text-16 text-success font-500 pointer"
                    onClick={() => editAction(skill)}>
                    Edit
                  </span>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {skill.disabled ? (
                    <span
                      className="text-16 text-grey font-500 pointer"
                      onClick={() => enableAction(skill)}>
                      Enable
                    </span>
                  ) : (
                    <span
                      className="text-16 text-warning font-500 pointer"
                      onClick={() => disableAction(skill)}>
                      Disable
                    </span>
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={4}>
                <PaginationBar
                  page={paginationState.page}
                  pageSize={paginationState.pageSize}
                  totalItems={data?.count}
                  onChangePage={(newPage) => {
                    setPaginationState({ ...paginationState, page: newPage });
                  }}
                  onChangeRowsPerPage={(size) => {
                    setPaginationState({
                      ...paginationState,
                      pageSize: size,
                      page: 0,
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};

const SkillsPage = () => {
  const [currentUser] = useGlobal("currentUser");
  const [activeTab, setActiveTab] = useState(0);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState({
    label: "",
    value: "",
  });

  const [activeTotalCount, setActiveTotalCount] = useState(0);
  const [disabledTotalCount, setDisabledTotalCount] = useState(0);

  const [modalState, setModalState] = useState({
    show: false,
    skill: null,
  });

  const [mutateActive, setMutateActive] = useState(() => {});
  const [mutateDisabled, setMutateDisabled] = useState(() => {});

  const canWriteSkill = canWrite("skill", currentUser);

  const { data: motivations } = useSWR(`${apiBaseUrl}motivation/`);

  return (
    <div>
      <SkillModal
        show={modalState.show}
        skill={modalState.skill}
        motivationList={motivations}
        onHide={(refresh = false) => {
          if (refresh) {
            mutateActive();
            mutateDisabled();
          }
          setModalState({
            ...modalState,
            show: false,
          });
        }}
      />

      <PageLayout activeLink="skills" parentMenu="demographics">
        <div className="text-grey text-20 font-450 mb-20">Skills</div>
        <Row>
          <Col>
            <div className="d-flex flex-wrap mb-20">
              {canWriteSkill && (
                <Button
                  classes={`small success w-150  mb-10 mr-3`}
                  onClick={() =>
                    setModalState({
                      skill: null,
                      show: true,
                    })
                  }>
                  <PlusIcon /> <span className="pl-2">Add new skill</span>
                </Button>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <div className="d-flex flex-wrap align-center mb-20">
              <Button
                classes={`pill success ${activeTab === 0} mr-2  mb-20`}
                onClick={() => {
                  setActiveTab(0);
                  setFilter({
                    label: "",
                    value: "",
                  });
                  setQuery("");
                }}>
                Active ({activeTotalCount})
              </Button>

              <Button
                classes={`pill success ${activeTab === 1}  mb-20 mr-3`}
                onClick={() => {
                  setActiveTab(1);
                  setFilter({
                    label: "",
                    value: "",
                  });
                  setQuery("");
                }}>
                Disabled ({disabledTotalCount})
              </Button>
            </div>
          </Col>

          <Col md={8}>
            <div className="d-flex flex-wrap flex-end">
              <Filter filter={filter} setFilter={setFilter} />
              <Search query={query} setQuery={setQuery} />
            </div>
          </Col>
        </Row>

        <div className={activeTab === 0 ? "" : "hidden"}>
          <SkillsTable
            active={activeTab === 0}
            query={query}
            filter={filter.value}
            editAction={(skill) =>
              setModalState({
                skill: skill,
                show: true,
              })
            }
            disableAction={(skill) =>
              setModalState({
                skill: {
                  ...skill,
                  disabled: true,
                },
                show: true,
              })
            }
            setTotalCount={setActiveTotalCount}
            setMutateAction={setMutateActive}
          />
        </div>

        <div className={activeTab === 1 ? "" : "hidden"}>
          <SkillsTable
            active={activeTab === 1}
            select="disabled"
            query={query}
            filter={filter.value}
            editAction={(skill) =>
              setModalState({
                skill: skill,
                show: true,
              })
            }
            enableAction={(skill) =>
              setModalState({
                skill: {
                  ...skill,
                  disabled: false,
                },
                show: true,
              })
            }
            setTotalCount={setDisabledTotalCount}
            setMutateAction={setMutateDisabled}
          />
        </div>
      </PageLayout>
    </div>
  );
};

export default SkillsPage;
