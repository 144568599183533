import axios from "axios";
import ERRORS from "./errors";

export const apiBaseUrl = process.env.REACT_APP_BASE_API;
export const siteUrl = process.env.REACT_APP_SITE_URL;
export const mentorUrl = (slug) => siteUrl + "mentors/" + slug;
export const designerUrl = (slug) => siteUrl + "members/" + slug;
export const groupSessionUrl = (slug) => siteUrl + "session/" + slug;

export const http = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// create axios config object
export const createConfig = (authToken, file = false) => {
  if (!authToken) return null;

  let headers = { Authorization: `Token ${authToken}` };
  if (file)
    headers = {
      ...headers,
      "Content-Type": "multipart/form-data",
      Accept: "application/*",
    };
  return {
    headers: headers,
  };
};

// create api response
export const createResponse = (success, data, message) => ({
  success,
  message,
  data,
});

//SWR fetcher
export const SWRFetcher =
  (currentUser) =>
  (
    path,
    config = { headers: { Authorization: `Token ${currentUser.token.actual}` } }
  ) =>
    http.get(path, config).then((resp) => resp.data);

// fetch data
export const fetchData = (url, authToken = null) =>
  axios
    .get(`${apiBaseUrl}${url}`, createConfig(authToken))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error));

// post data
export const postData = (url, data, authToken = null, file = false) =>
  axios
    .post(`${apiBaseUrl}${url}`, data, createConfig(authToken, file))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error, authToken ? false : true));

// update data
export const putData = (url, data, authToken = null, file = false) =>
  axios
    .put(`${apiBaseUrl}${url}`, data, createConfig(authToken, file))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error));

// delete data
export const deleteData = (url, authToken = null, file = false) =>
  axios
    .delete(`${apiBaseUrl}${url}`, createConfig(authToken, (file = false)))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error));

// handler error

//fetch file
export const fetchFile = async (url, authToken, config_) => {
  // set config

  const config = {
    baseURL: apiBaseUrl,
    headers: { Authorization: `Token ${authToken}` },
    responseType: "blob",
    ...config_,
  };

  try {
    let response = await axios.get(`${url}`, config);
    return {
      success: true,
      data: response,
    };
  } catch (error) {
    return handleError(error);
  }
};

export const handleError = (error, isLogin = false) => {
  if (error.response) {
    console.log("respo", error.response);
    switch (error.response.status) {
      case 401:
        const message = isLogin
          ? error.response.data.message
          : ERRORS.LOGIN_EXPIRED;
        return createResponse(false, null, message);
      case 404:
        return createResponse(false, null, ERRORS.NOT_FOUND);
      case 403:
        return {
          success: false,
          message:
            "You do not have access to this resource. Contact your system administrator to grant you access.",
        };
      case 400:
      case 409:
        return createResponse(
          false,
          error.response,
          error.response.data.message || error.response.data?.error
        );
      default:
        return createResponse(false, null, ERRORS.UNKNOWN_SERVER_ERROR);
    }
  }

  return createResponse(false, null, ERRORS.UNKNOWN_ERROR);
};
