import React, { useState, useGlobal } from "reactn";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import fileDownload from "js-file-download";
import { apiBaseUrl, fetchFile } from "../../api/api";
//import axios from "axios";

/* const fetchFile = async (url, authToken) => {
  // set config
  const config = { 
    headers: { Authorization: `Token ${authToken}` } ,
    responseType : "blob"
  };

  try {
    let response = await axios.get(`${url}`, config);
    return {
      success: true,
      data: response,
    };
    
  } catch (error) {
    return handleError(error);
  }
}; */

const ExportButton = ({
  url = null,
  baseUrl = apiBaseUrl,
  label = "Export file",
  fileName = "exported-file",
  extension = ".xls",
}) => {
  const [currentUser] = useGlobal("currentUser");
  const [exporting, setExporting] = useState({
    exporting: false,
    error: null,
  });
  const onExport = async () => {
    if (!url) return;

    setExporting({
      exporting: true,
      error: null,
    });

    let authToken = currentUser.token.actual;

    fetchFile(url, authToken, { baseURL: baseUrl }).then((response) => {
      if (response.success) {
        setExporting({
          exporting: false,
          error: null,
        });
        fileDownload(
          response.data.data,
          `${fileName}-${new Date()}${extension}`
        );
        return;
      }

      setExporting({
        exporting: false,
        error: response.message,
      });
    });
  };

  return (
    <div>
      <div
        className="d-flex align-center pointer"
        onClick={() => {
          if (url) onExport();
        }}
      >
        <div className="text-16 font-400 text-grey mr-1">
          {!exporting.exporting && <span>{label}</span>}
          {exporting.exporting && <span>Exporting...</span>}
        </div>
        <DownloadIcon />
      </div>
      {exporting.error && (
        <div className="text-danger text-14">{exporting.error}</div>
      )}
    </div>
  );
};

export default ExportButton;
