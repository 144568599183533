import { useState, useGlobal, useEffect } from "reactn";
import { Table, Row, Col } from "react-bootstrap";
import Button from "../../components/button/button.component";
import InputField from "../../components/form/input-field/input-field.component";
import DesignerSideModal from "../../components/modal/designer-side-modal/side-modal.component";
import { withRouter } from "react-router-dom";
import { apiBaseUrl, designerUrl } from "../../api/api";
import PaginationBar from "../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../components/page-layout/page-layout.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import ExportButton from "../../components/export-button/export-button.component";
import ButtonDropdown, {
  MenuItem,
} from "../../components/button-dropdown/button-dropdown";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import ConfirmDeactivateUserModal from "../../components/modal/confirm-deactivate-user-modal/confirm-deactivate-user-modal.component";
import {
  blockOrUnblockUser,
  resetUserPhoneNumber,
} from "api/services/account.service";

import SuspendedUsers, {
  SuspendedUsersSearch,
} from "../suspended-user/suspended-user.page";
import ConfirmModal from "components/modal/confirm-modal/confirm-modal.component";

const DesignerPage = () => {
  const [currentUser] = useGlobal("currentUser");

  const [designers] = useGlobal("designers");
  const [totalDesigners] = useGlobal("totalDesigners");

  const [query, setQuery] = useState("");
  const [queryBlocked, setQueryBlocked] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const [suspendedQuery, setSuspendedQuery] = useState("");
  const [totalSuspended, setTotalSuspended] = useState(0);

  const [state, setState] = useState({
    showDesignerModal: false,
    currentDesigner: null,
    fetching: false,
    designers: designers,
    totalDesigners: totalDesigners,
    canFetch: true,
    error: null,
    phoneNumberCleared: false,
    sort: {
      text: "",
      value: "",
    },
  });

  const [confirmModalState, setConfirmModalState] = useState({
    show: false,
    success: false,
    error: null,
    processing: false,
    title: "Clear phone number",
    description: "",
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    nextURL: null,
    prevURL: null,
  });

  const [blockedPaginate, setBlockedPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    nextURL: null,
    prevURL: null,
  });

  const [canFetchActive, setCanFetchActive] = useState(true);
  const [canFetchDisabled, setCanFetchDisabled] = useState(false);

  const [confirmDeactivateModalState, setConfirmDeactivateModalState] =
    useState({
      show: false,
      error: null,
      processing: false,
    });

  useEffect(() => {}, [
    query,
    designers,
    state.designers,
    totalDesigners,
    state.fetching,
    state.canFetch,
    state.totalDesigners,
  ]);

  //active,
  const getURL = (
    q = query,
    limit = paginate.pageSize,
    offset = paginate.pageSize * paginate.page,
    select = "active",
    sort = ""
  ) => {
    return `${apiBaseUrl}member/?q=${q}&limit=${limit}&offset=${offset}&select=${select}&sort=${sort}`;
  };
  const { data: totalActiveData, mutate: mutateTotalActive } = useSWR(
    getURL("", 1, 1, "active")
  );

  const { data: totalBlockedData, mutate: mutateTotalBlocked } = useSWR(
    getURL("", 1, 1, "blocked")
  );

  const {
    data: activeDesignersData,
    error: activeDesignersError,
    mutate: mutateActiveDesigners,
  } = useSWR(
    getURL(
      query,
      paginate.pageSize,
      paginate.pageSize * paginate.page,
      "active",
      activeTab === 0 ? state.sort?.value : ""
    )
  );

  const {
    data: blockedDesignersData,
    error: blockedDesignersError,
    mutate: mutateBlockedDesigners,
  } = useSWR(
    canFetchDisabled &&
      getURL(
        queryBlocked,
        blockedPaginate.pageSize,
        blockedPaginate.pageSize * blockedPaginate.page,
        "blocked",
        activeTab === 1 ? state.sort?.value : ""
      )
  );

  const onDesignerUpdated = (info) => {
    const member = info?.data;
    let newData = activeTab === 0 ? activeDesignersData : blockedDesignersData;

    // let totalCount =
    //   activeTab === 0 ? totalActiveData?.count : totalBlockedData;

    if (info.type === "updated") {
      let r = newData?.results?.filter((el) => el.id !== member.id);
      r.push(member);
      newData = { ...newData, results: r };
      activeTab === 0
        ? mutateActiveDesigners(newData, false)
        : mutateBlockedDesigners(newData, false);
    } else if (info.type === "blocked" || info.type === "unblocked") {
      let r = newData?.results?.filter((el) => el.id !== member.id);
      let count =
        info.type === "blocked" ? newData?.count - 1 : newData?.count + 1;

      newData = { ...newData, count: count, results: r };
      if (activeTab === 0) {
        mutateActiveDesigners(newData, false);
        mutateTotalActive(
          { ...totalActiveData, count: totalActiveData?.count - 1 },
          false
        );
        mutateTotalBlocked(
          { ...totalBlockedData, count: totalBlockedData?.count + 1 },
          false
        );
      } else {
        mutateBlockedDesigners(newData, false);
        mutateTotalBlocked(
          { ...totalBlockedData, count: totalBlockedData?.count - 1 },
          false
        );
        mutateTotalActive(
          { ...totalActiveData, count: totalActiveData?.count + 1 },
          false
        );
      }
    } else if (info.type === "deleted") {
      let r = newData?.results?.filter((el) => el.id !== member.id);
      let count = newData.count - 1;

      newData = { ...newData, count: count, results: r };
      if (activeTab === 0) {
        mutateActiveDesigners(newData, false);
        mutateTotalActive(
          { ...totalActiveData, count: totalActiveData?.count - 1 },
          false
        );
      } else {
        mutateBlockedDesigners(newData, false);
        mutateTotalBlocked(
          { ...totalBlockedData, count: totalBlockedData?.count - 1 },
          false
        );
      }
    }
  };

  const onConfirmDeactivateUser = (reason) => {
    setConfirmDeactivateModalState({
      ...confirmDeactivateModalState,
      processing: true,
      error: null,
    });

    blockOrUnblockUser(currentUser.token.actual, {
      pk: state.currentDesigner.identity_id,
      status: false,
      reason: reason ?? null,
    }).then((response) => {
      if (response.success) {
        setConfirmDeactivateModalState({
          ...confirmDeactivateModalState,
          processing: false,
          success: "Member deactivated successfully",
        });

        // update mentor data
        setState({
          ...state,
          currentDesigner: {
            ...response.data.designer,
            last_deactivated_info: {
              last_deactivated_at: response.data.last_deactivated_at,
              last_deactivated_by: response.data.last_deactivated_by,
              last_deactivated_reason: response.data.last_deactivated_reason,
            },
          },
        });

        onDesignerUpdated({
          type: "blocked",
          data: response.data.designer,
        });

        return;
      }

      setConfirmDeactivateModalState({
        ...confirmDeactivateModalState,
        processing: false,
        error: response.message,
      });
    });
  };

  const onClearPhoneNumber = () => {
    setConfirmModalState({
      ...confirmModalState,
      processing: true,
      error: null,
    });

    resetUserPhoneNumber(
      currentUser.token.actual,
      state.currentDesigner.identity_id
    ).then((response) => {
      if (response.success) {
        setConfirmModalState({
          ...confirmModalState,
          processing: false,
          success: "Phone number reset successful",
        });

        setState({
          ...state,
          currentDesigner: {
            ...state.currentDesigner,
            phone_number: null,
            phone_number_country_code: null,
          },
        });

        return;
      }

      setConfirmModalState({
        ...confirmModalState,
        processing: false,
        error: response.message,
      });
    });
  };

  return (
    <>
      <DesignerSideModal
        show={state.showDesignerModal}
        phoneNumberCleared={state.phoneNumberCleared}
        onHide={() => setState({ ...state, showDesignerModal: false })}
        token={currentUser.token.actual}
        designer={state.currentDesigner}
        onUpdated={onDesignerUpdated}
        onDeactivateUser={() => {
          setState({ ...state, showDesignerModal: false });
          setConfirmDeactivateModalState({
            ...confirmDeactivateModalState,
            show: true,
          });
        }}
        onClearPhoneNumber={(phoneNumberWithCountryCode, memberName) => {
          setState({ ...state, showDesignerModal: false });
          setConfirmModalState({
            ...confirmModalState,
            show: true,
            description: `Please confirm clearing of phone number ${phoneNumberWithCountryCode} for ${memberName}. User will no longer be able to book sessions until they add a new verified phone number.`,
          });
        }}
      />

      <ConfirmModal
        show={confirmModalState.show}
        processing={confirmModalState.processing}
        title={confirmModalState.title}
        description={confirmModalState.description}
        error={confirmModalState.error}
        success={confirmModalState.success}
        onHide={() => {
          setConfirmModalState({
            ...confirmModalState,
            show: false,
            success: false,
            error: null,
            processing: false,
          });
          setState({ ...state, showDesignerModal: true });
        }}
        onConfirm={() => onClearPhoneNumber()}
      />

      <ConfirmDeactivateUserModal
        show={confirmDeactivateModalState.show}
        error={confirmDeactivateModalState.error}
        processing={confirmDeactivateModalState.processing}
        userToDeactivateName={state.currentDesigner?.name}
        success={confirmDeactivateModalState.success}
        onHide={() => {
          setConfirmDeactivateModalState({
            ...confirmDeactivateModalState,
            show: false,
            success: false,
            error: null,
            processing: false,
          });
          setState({ ...state, showDesignerModal: true });
        }}
        onConfirm={onConfirmDeactivateUser}
      />

      <PageLayout activeLink="members">
        <div>
          <div className="mb-20 d-flex space-between">
            <div className="text-grey text-20 font-bold">Members</div>
            <div>
              <ExportButton
                label="Export All to Excel"
                url={`member/export`}
                fileName={`members`}
              />
            </div>
          </div>
          <Row>
            <Col md={6}>
              <div className="d-flex flex-wrap mb-20">
                <Button
                  classes={`pill success ${activeTab === 0} mr-2`}
                  onClick={() => {
                    setActiveTab(0);
                    if (!canFetchActive) setCanFetchActive(true);
                  }}>
                  Active ({totalActiveData?.count || 0})
                </Button>
                <Button
                  classes={`pill success ${activeTab === 2} mr-2`}
                  onClick={() => {
                    setActiveTab(2);
                    if (!canFetchActive) setCanFetchActive(true);
                  }}>
                  Suspended ({totalSuspended})
                </Button>
                <Button
                  classes={`pill success ${activeTab === 1} mr-2`}
                  onClick={() => {
                    setActiveTab(1);
                    if (!canFetchDisabled) setCanFetchDisabled(true);
                  }}>
                  Deactivated ({totalBlockedData?.count || 0})
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <div
                className={activeTab === 0 || activeTab === 1 ? "" : "hidden"}>
                <div className="d-flex flex-end">
                  <div className="mr-10">
                    <ButtonDropdown
                      title={
                        <div className="d-flex align-center">
                          <FilterIcon /> &nbsp;&nbsp;
                          <span>{state.sort.text || "Filter by all"}</span>
                        </div>
                      }>
                      <MenuItem
                        active={state?.sort.value === ""}
                        onClick={() => {
                          setState({
                            ...state,
                            sort: {
                              text: "Filter by all",
                              value: "",
                            },
                          });
                        }}>
                        Filter by all
                      </MenuItem>
                      <MenuItem
                        active={state?.sort.value === "name"}
                        onClick={() => {
                          setState({
                            ...state,
                            sort: {
                              text: "Alphabetical order",
                              value: "name",
                            },
                          });
                        }}>
                        Alphabetical order
                      </MenuItem>
                    </ButtonDropdown>
                  </div>

                  <div
                    className={
                      activeTab === 0 || activeTab === 1 ? "" : "hidden"
                    }>
                    <InputField
                      placeholder="Search"
                      classes="small mb-20"
                      isSearch
                      value={activeTab === 0 ? query : queryBlocked}
                      onChange={(ev) => {
                        if (activeTab === 0) {
                          setQuery(ev.target.value);
                        } else {
                          setQueryBlocked(ev.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={activeTab === 2 ? "" : "hidden"}>
                <div className="d-flex flex-wrap flex-end">
                  <SuspendedUsersSearch
                    query={suspendedQuery}
                    setQuery={setSuspendedQuery}
                  />
                </div>
              </div>
            </Col>
          </Row>

          {activeTab === 0 && (
            <LoaderPane
              loading={!activeDesignersData && !activeDesignersError}
              error={activeDesignersError?.message}
              noRecords={
                !activeDesignersError &&
                activeDesignersData &&
                activeDesignersData["count"] === 0
              }
              onReload={() => {
                mutateActiveDesigners();
              }}
            />
          )}
          {activeTab === 0 &&
            activeDesignersData &&
            !activeDesignersError &&
            activeDesignersData["count"] > 0 && (
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Expertise</th>
                    <th>Company</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {activeDesignersData?.results?.map((designer) => (
                    <tr key={designer.id}>
                      <td>
                        <a href={designerUrl(designer.slug)} target="_blank">
                          <div className="d-flex pointer">
                            <div className="pic-small mr-3">
                              <img
                                className="img-f-w-obj-cover"
                                src={designer.profile_photo_url}
                                alt=""
                              />
                            </div>
                            <span className="text-16 text-grey font-500">
                              {designer.name}{" "}
                            </span>
                          </div>
                        </a>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-500">
                          {designer.expertise &&
                            designer.expertise.map((exp) => (
                              <span key={exp.id}> {exp.description}, </span>
                            ))}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-500">
                          {designer.employer}
                        </span>
                      </td>
                      <td>
                        <span
                          className="text-16 text-success font-500 pointer"
                          onClick={() =>
                            setState({
                              ...state,
                              currentDesigner: designer,
                              showDesignerModal: true,
                            })
                          }>
                          View
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={activeDesignersData["count"]}
                        onChangePage={(newPage) => {
                          setPaginate({ ...paginate, page: newPage });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({ ...paginate, pageSize: size, page: 0 });
                          //fetchDesigners(query, null, paginate.page, size);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}

          {/* deactivated designers */}
          {activeTab === 1 && (
            <LoaderPane
              loading={!blockedDesignersData && !blockedDesignersError}
              error={blockedDesignersError?.message}
              noRecords={
                !blockedDesignersError &&
                blockedDesignersData &&
                blockedDesignersData["count"] === 0
              }
              onReload={() => {
                mutateBlockedDesigners();
              }}
            />
          )}
          {activeTab === 1 &&
            blockedDesignersData &&
            !blockedDesignersError &&
            blockedDesignersData["count"] > 0 && (
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Expertise</th>
                    <th>Company</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {blockedDesignersData?.results?.map((designer) => (
                    <tr key={designer.id}>
                      <td>
                        <a href={designerUrl(designer.slug)} target="_blank">
                          <div className="d-flex pointer">
                            <div className="pic-small mr-3">
                              <img
                                className="img-f-w-obj-cover"
                                src={designer.profile_photo_url}
                                alt=""
                              />
                            </div>
                            <span className="text-16 text-grey font-500">
                              {designer.name}{" "}
                            </span>
                          </div>
                        </a>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-500">
                          {designer.expertise &&
                            designer.expertise.map((exp) => (
                              <span key={exp.id}> {exp.description}, </span>
                            ))}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-500">
                          {designer.employer}
                        </span>
                      </td>
                      <td>
                        <span
                          className="text-16 text-success font-500 pointer"
                          onClick={() =>
                            setState({
                              ...state,
                              currentDesigner: designer,
                              showDesignerModal: true,
                            })
                          }>
                          View
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4}>
                      <PaginationBar
                        page={blockedPaginate.page}
                        pageSize={blockedPaginate.pageSize}
                        totalItems={blockedDesignersData["count"]}
                        onChangePage={(newPage) => {
                          setBlockedPaginate({
                            ...blockedPaginate,
                            page: newPage,
                          });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setBlockedPaginate({
                            ...blockedPaginate,
                            pageSize: size,
                            page: 0,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}

          <div className={activeTab === 2 ? "" : "hidden"}>
            {SuspendedUsers({
              identityType: "Designer",
              query: suspendedQuery,
              setTotalCount: setTotalSuspended,
            })}
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default withRouter(DesignerPage);
