import { useState, useEffect } from "reactn";
import SideModal from "../side-modal/side-modal.component";
import { Modal, Row, Col } from "react-bootstrap";
import InputField from "../../../components/form/input-field/input-field.component";
import Button from "../../../components/button/button.component";
import Select from "../../../components/form/select/select.component";
import { apiBaseUrl, SWRFetcher, postData } from "../../../api/api";

const UsersModal = ({
  show,
  onHide,
  user = null,
  roles,
  canWrite,
  token,
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    selecetdRole: null,
    processing: false,
    error: null,
  });

  const [allRoles, setAllRoles] = useState([]);

  useEffect(() => {
    let r = [];
    roles &&
      roles.map((role) => {
        if (role.name !== "Super Admin")
          r.push({ label: role.name, value: role });
      });

    setAllRoles(r);
  }, [roles]);

  const onAddUser = () => {
    setState({
      error: null,
      processing: true,
    });
    let body = {
      name: state.name,
      username: state.email,
      email: state.email,
      role_id: state.selecetdRole?.value.id,
    };

    const createUser = () => {
      postData("dashboard/user/", body, token).then((response) => {
        if (response.success) {
          setState({
            ...state,
            processing: false,
            error: null,
          });

          onHide();
          onUpdated();
          return;
        }

        setState({
          ...state,
          processing: false,
          error: response?.message || "Unable to add user",
        });
      });
    };

    createUser();

    /*  if (firebaseUser) {
      createUser();
    } else {
      googleSignInPopup({
        onSuccess: (userCredentials) => {
          setFirebaseUser(userCredentials);
          createUser();
        },
        onError: (error) => {
          setState({
            ...state,
            processing: false,
            error: error?.message,
          });
        },
      });
    } */
  };

  return (
    <>
      <SideModal show={show} onHide={onHide} onBlurHide={true}>
        <Modal.Body className="scrollbar">
          <div className="modal-inner">
            <div className="content-medium mb-50">
              <div className="text-dark text-20 font-bold mb-30">
                {true ? "Add new " : "Edit"} User
              </div>
              <Row>
                <Col md={12} className="mb-30">
                  <InputField
                    label="Name"
                    value={state.name}
                    disabled={!canWrite}
                    placeholder="Enter Name"
                    onChange={(ev) => {
                      setState({ ...state, name: ev.target.value });
                    }}
                  />
                </Col>
                <Col md={12} className="mb-30">
                  <InputField
                    label="Email"
                    value={state.email}
                    disabled={!canWrite}
                    placeholder="Enter email"
                    onChange={(ev) => {
                      setState({ ...state, email: ev.target.value });
                    }}
                  />
                </Col>
                {/* <Col md={12} className="mb-30">
                  <InputField
                    label="Password"
                    type="text"
                    placeholder="*******"
                    value={state.password}
                    disabled={!canWrite}
                    onChange={(ev) => {
                      setState({ ...state, password: ev.target.value });
                    }}
                  />
                </Col> */}
                <Col md={12} className="mb-50">
                  <Select
                    label="Role type"
                    //value={[state?.selecetdRole]}
                    disabled={!canWrite}
                    options={[
                      { label: "Select role", value: null },
                      ...allRoles,
                    ]}
                    onChange={(selected) => {
                      setState({ ...state, selecetdRole: selected });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        {canWrite && (
          <Modal.Footer>
            <div
              className="modal-footer-inner"
              style={{ paddingBottom: `${window.bottomSpace}` }}
            >
              {state.error && (
                <div className="ml-3 mb-2 text-danger">{state.error}</div>
              )}
              <Col xs={4}>
                <Button
                  secondary
                  loading={state.processing}
                  classes="small w-100 secondary"
                  disabled={
                    state.processing ||
                    state.name?.length === 0 ||
                    state.email?.length === 0 ||
                    !state.selecetdRole
                  }
                  onClick={onAddUser}
                >
                  Publish User
                </Button>
              </Col>
            </div>
          </Modal.Footer>
        )}
      </SideModal>
    </>
  );
};

export default UsersModal;
