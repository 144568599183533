import React, { useState, useGlobal } from "reactn";
import PageLayout from "../../../components/page-layout/page-layout.component";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import {
  LineChart,
  Line,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getAnalytics } from "../../../api/services/analytics.service";
import ExportButton from "../../../components/export-button/export-button.component";
import "./bookings-analytics.styles.scss";
import Select from "../../../components/form/select/select.component";
import DateRangeInput from "../../../components/form/date-range/date-range.component";
import {
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { apiBaseUrl, SWRFetcher } from "../../../api/api";

const BookingsAnalytics = () => {
  const [currentUser] = useGlobal("currentUser");
  const [BookingsGraphData, setBookingsGraphData] =
    useGlobal("BookingsGraphData");

  const [state, setState] = useState({
    processing: false,
    error: null,
    success: null,
  });

  const [graphData, setGraphData] = useState(BookingsGraphData);

  const [weekOrMonth, setWeekOrMonth] = useState("month");
  const [filter, setFilter] = useState("all");
  const [range, setRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);

  const [exportFile, setExportFIle] = useState({
    exporting: false,
    error: null,
    url: `${
      process.env.REACT_APP_CALENDAR_API
    }booking/analytics/export/?start_date=${range[0].startDate
      .toISOString()
      .slice(0, 10)}&end_date=${range[0].endDate
      .toISOString()
      .slice(0, 10)}&filter=${filter}`,
  });

  // const { data: activeGraphData, error: activeGraphError, mutate: mutateActiveGraph } = useSWR(
  //   `account/analytics/user/?start_date=${range[0].startDate.toISOString().slice(0, 10)}&end_date=${range[0].endDate.toISOString().slice(0, 10)}&user_type=${filter}`,
  //   SWRFetcher(currentUser)
  // );

  return (
    <div className="bookings-analytics-page">
      <PageLayout activeLink="bookings-analytics" parentMenu="analytics">
        <div className="text-grey text-20 font-bold mb-30">Bookings</div>
        <Row>
          <Col md={6} className="d-flex flex-end">
            <span
              className={`${
                weekOrMonth === "week"
                  ? "text-grey font-bold"
                  : "text-grey-light"
              } mt-20 pointer mr-2`}
              onClick={() => {
                setWeekOrMonth("week");
                setRange([
                  {
                    startDate: startOfWeek(new Date()),
                    endDate: endOfWeek(new Date()),
                    key: "selection",
                  },
                ]);
              }}
            >
              This week
            </span>
            <span
              className={`${
                weekOrMonth === "month"
                  ? "text-grey font-bold"
                  : "text-grey-light"
              } mt-20 pointer`}
              onClick={() => {
                setWeekOrMonth("month");
                setRange([
                  {
                    startDate: startOfMonth(new Date()),
                    endDate: endOfMonth(new Date()),
                    key: "selection",
                  },
                ]);
              }}
            >
              This Month
            </span>
          </Col>
          <Col md={4} className="px-0">
            <DateRangeInput
              placeholder="Custom range"
              ranges={range}
              onDateChange={(data) => {
                setRange(data);
              }}
            />
          </Col>
          <Col md={2}>
            <Button
              classes="secondary pl-2 pr-2 w-100"
              disabled={state.processing}
              loading={state.processing}
              onClick={() => {
                setState({ ...state, processing: true, error: null });
                let start_date = range[0].startDate.toISOString().slice(0, 10);
                let end_date = range[0].endDate.toISOString().slice(0, 10);
                //let user_type= filter
                getAnalytics(
                  currentUser.token.actual,
                  `${process.env.REACT_APP_CALENDAR_API}booking/analytics/?start_date=${start_date}&end_date=${end_date}&filter=${filter}`
                ).then((response) => {
                  if (response.success) {
                    setState({ ...state, processing: false, error: null });
                    setGraphData(response.data);
                    setBookingsGraphData(response.data);
                    return;
                  }
                  setState({
                    ...state,
                    processing: false,
                    error: response.message,
                  });
                });
              }}
            >
              Apply
            </Button>
          </Col>
          <Col className="d-flex flex-end">
            {state.error && (
              <div className="w-500 text-danger"> {state.error}</div>
            )}
          </Col>
        </Row>

        <div className="card pr-3 mt-20">
          <Row className="mt-20">
            <Col md={4}>
              <div className="pl-4">
                <Select
                  noLabel
                  placeholder="Select booking"
                  options={[
                    { label: "All bookings", value: "all" },
                    { label: "Confirmed", value: "Confirmed" },
                    { label: "Declined", value: "Declined" },
                    { label: "Cancelled", value: "Cancelled" },
                  ]}
                  onChange={(selected) => setFilter(selected.value)}
                />
              </div>
            </Col>

            <Col md={5} />

            <Col md={3}>
              <div className="d-flex flex-end mt-20 mr-3">
                {graphData?.data && (
                  <ExportButton
                    label="Export"
                    url={exportFile.url}
                    fileName={`bookings-analytics-${filter}`}
                  />
                )}
              </div>
            </Col>
          </Row>
          {/*  <LoaderPane
              loading={!activeGraphData && !activeGraphError}
              error={activeGraphError?.message}
              noRecords={!activeGraphError && activeGraphData && activeGraphData["count"] === 0}
              onReload={() => {
                mutateActiveGraph();
              }}
            /> */}
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={800}
              height={350}
              data={graphData?.data}
              margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="XAxis" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="card mt-20">
          <div className="card-body">
            <div className="bookings-progress-bar">
              <div
                className="progress"
                style={{
                  width: `${
                    graphData?.total_bookings_percentage_for_period || 0
                  }%`,
                }}
              >
                {`${Math.round(
                  graphData?.total_bookings_percentage_for_period || 0
                )}%`}
              </div>
              <div
                className="progress confirmed"
                style={{
                  width: `${
                    graphData?.total_confirmed_percentage_for_period || 0
                  }%`,
                }}
              >
                {`${Math.round(
                  graphData?.total_confirmed_percentage_for_period || 0
                )}%`}
              </div>
              <div
                className="progress declined"
                style={{
                  width: `${
                    graphData?.total_declined_percentage_for_period || 0
                  }%`,
                }}
              >
                {`${
                  Math.round(graphData?.total_declined_percentage_for_period) ||
                  0
                }%`}
              </div>
              <div
                className="progress cancelled"
                style={{
                  width: `${
                    graphData?.total_cancelled_percentage_for_period || 0
                  }%`,
                }}
              >
                {`${
                  Math.round(
                    graphData?.total_cancelled_percentage_for_period
                  ) || 0
                }%`}
              </div>
            </div>
            <div className="d-flex">
              <span>
                <span className="mr-3 d-flex align-center">
                  <div className="round-indicator"></div>
                  <span className="text-16 font-400 text-grey">
                    All Bookings - {graphData?.total_bookings_for_period || 0}
                  </span>
                </span>
              </span>
              <span>
                <span className="mr-3 d-flex align-center">
                  <div className="round-indicator confirmed"></div>
                  <span className="text-16 font-400 text-grey">
                    Confirmed - {graphData?.total_confirmed_for_period || 0}
                  </span>
                </span>
              </span>
              <span>
                <span className="mr-3 d-flex align-center">
                  <div className="round-indicator declined"></div>
                  <span className="text-16 font-400 text-grey">
                    Declined - {graphData?.total_declined_for_period || 0}
                  </span>
                </span>
              </span>
              <span>
                <span className="d-flex align-center">
                  <div className="round-indicator cancelled"></div>
                  <span className="text-16 font-400 text-grey">
                    Cancelled - {graphData?.total_cancelled_for_period || 0}
                  </span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(BookingsAnalytics);
