import React, { useState, useGlobal } from "reactn";
import ReactDOM from "react-dom";
import { ReactComponent as Logo } from "../../../assets/icons/adplist.svg";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import "./login.scss";
import {
  authenticate,
  authenticateGoogle,
} from "../../../api/services/account.service";
import { withRouter } from "react-router-dom";

import { useEffect } from "react";
//import { GoogleLogin } from "react-google-login";
import queryString from "query-string";

const LoginPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  //const [authCredential, setAuthCredential] = useGlobal("authCredential");
  const [authCredential, setAuthCredential] = useState(null);

  const [state, setState] = useState({
    username: "",
    password: "",
    error: null,
    loginRequested: false,
    authenticating: false,
  });

  const onLogin = (code) => {
    setState({
      ...state,
      loginRequested: true,
      error: null,
    });
    authenticateGoogle(code).then((response) => {
      if (response.success) {
        console.log("\nuser", response.user);

        if (response.user.is_superuser) {
          setCurrentUser(response.user);
          props.history.push("/dashboard");
        } else {
          props.history.push("/login");
          setState({
            ...state,
            error: "Unable to login , Please login as a superuser.",
          });
        }
        return;
      }

      //failed
      //setAuthCredential(null);
      props.history.push("/login");
      setState({
        ...state,
        loginRequested: false,
        error: response?.message,
      });
    });
  };

  const responseGoogle = (response) => {
    console.log("google", response);
    setState({ ...state, loginRequested: true, error: null });
    let authorization_code = response.code;
  };

  const responseGoogleError = (response) => {
    setState({ ...state, error: "Unable to signin" });
  };

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.code) {
      console.log(parsed.code);
      onLogin(parsed.code);
    }
  }, []);

  return (
    <div id="" className="login-page">
      <div className="inner-content">
        <div className="d-flex justify-center mb-50">
          <Logo />
        </div>

        <div>
          <Button
            loading={state.loginRequested}
            disabled={
              // state.username.length === 0 ||
              //state.password.length === 0 ||
              //state.loginRequested
              false
            }
            classes="secondary f-width mt-30"
            onClick={() => {
              //https://tenet.adplist.org/
              window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=openid profile email&prompt=consent&response_type=code&redirect_uri=${process.env.REACT_APP_DASHBOARD_BASE_URL}login/google/redirect&client_id=484956564402-ura0g7cp5b2lgn86h41g1ubvvpqjtl0d.apps.googleusercontent.com`;
              // if (authCredential) {
              // onLogin();
              /* } else {
                setState({ ...state, error: null });
                googleSignInPopup({
                  onSuccess: (result) => {
                    console.log(result);
                    if (result.credential) {
                      setAuthCredential(result.credential);
                      onLogin();
                    } else {
                      setState({ ...state, error: "Google sign-in failed" });
                    }
                  },
                  onError: (error) => {
                    console.log(error);
                    setAuthCredential(null);
                    setState({ ...state, error: error?.message });
                  },
                });
              } */
            }}
          >
            Login with Google
          </Button>

          <div
            className="mt-20 text-danger mt-10 text-center"
            hidden={!state.error}
          >
            {state.error}
          </div>
          {/* <div class="g-signin2" data-onsuccess="onSignIn"></div> */}
          <div className="d-flex justify-center mt-10">
            {/*  <GoogleLogin
              disabled={state.loginRequested}
              className="w-200"
              clientId="484956564402-ura0g7cp5b2lgn86h41g1ubvvpqjtl0d.apps.googleusercontent.com"
              buttonText={
                <div className="text-16">
                  {" "}
                  {state.loginRequested
                    ? "Please wait..."
                    : "Signin with Google"}
                </div>
              }
              responseType="code"
              fetchBasicProfile={false}
              prompt="consent"
              //accessType="offline"
              grantType="authorization_code"
              isSignedIn={false}
              onSuccess={responseGoogle}
              onFailure={responseGoogleError}
              cookiePolicy={"single_host_origin"}
              redirectUri={`login/google/redirect`}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LoginPage);
