import { useState, useEffect, useGlobal } from "reactn";
import useSWR from "swr";
import LoaderPane from "components/loader-pane/loader-pane.component";
import { Table } from "react-bootstrap";
import { mentorUrl } from "utils/Http";
import { designerUrl } from "utils/Http";
import PaginationBar from "components/pagination-bar/pagination-bar.component";
import { apiBaseUrl } from "api/api";
import InputField from "components/form/input-field/input-field.component";

import SuspendedUserModal from "./suspended-user-modal/suspended-user-modal.component";
import ConfirmModal from "components/modal/confirm-modal/confirm-modal.component";
import {
  blockOrUnblockUser,
  revokeUserSuspension,
} from "../../api/services/account.service";

import "./suspended-user.styles.scss";
import ConfirmDeactivateUserModal from "components/modal/confirm-deactivate-user-modal/confirm-deactivate-user-modal.component";

export const SuspendedUsersSearch = ({ query, setQuery }) => {
  return (
    <InputField
      placeholder="Search"
      classes="small mb-20"
      isSearch
      value={query}
      onChange={(ev) => {
        setQuery(ev.target.value);
      }}
    />
  );
};

const SuspendedUsers = ({ identityType = "", query = "", setTotalCount }) => {
  const [currentUser] = useGlobal("currentUser");

  const [activeSuspension, setActiveSuspension] = useState(null);

  const [paginationState, setPaginationState] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    remoteNext: null,
    remotePrev: null,
  });

  const [modalState, setModalState] = useState({
    show: false,
  });

  const [confirmRevokeModalState, setConfirmRevokeModalState] = useState({
    show: false,
    processing: false,
    success: null,
    error: null,
  });

  const [confirmDeactivateModalState, setConfirmDeactivateModalState] =
    useState({
      show: false,
      error: null,
      processing: false,
    });

  const getUrl = () => {
    let path = `${apiBaseUrl}user/suspension/list/?offset=${
      paginationState.page * paginationState.pageSize
    }&limit=${paginationState.pageSize}`;

    if (identityType) path += `&identity_type=${identityType}`;

    if (query) path += `&query=${query}`;

    return path;
  };

  const { data, error, mutate } = useSWR(getUrl());

  useEffect(() => {
    if (data) {
      setTotalCount(data.count);
    }
  }, [data, setTotalCount]);

  const onConfirmRevokeUserSuspension = (reason) => {
    setConfirmRevokeModalState({
      ...confirmRevokeModalState,
      processing: true,
      error: null,
    });

    revokeUserSuspension(currentUser.token.actual, activeSuspension.id).then(
      (response) => {
        if (response.success) {
          setConfirmRevokeModalState({
            ...confirmRevokeModalState,
            processing: false,
            success: `${
              activeSuspension.user.identity_type === "Mentor"
                ? activeSuspension.user.mentor.name
                : activeSuspension.user.designer.name
            } suspension restored successfully. User will now be able to book sessions.`,
          });

          const updatedActiveSuspension = activeSuspension;
          updatedActiveSuspension.status = response.data.status;
          updatedActiveSuspension.revoked_at = response.data.revoked_at;

          setActiveSuspension(updatedActiveSuspension);

          mutate();

          return;
        }

        setConfirmRevokeModalState({
          ...confirmRevokeModalState,
          processing: false,
          error: response.message,
        });
      }
    );
  };

  const onConfirmDeactivateUser = (reason) => {
    setConfirmDeactivateModalState({
      ...confirmDeactivateModalState,
      processing: true,
      error: null,
    });

    blockOrUnblockUser(currentUser.token.actual, {
      pk: activeSuspension.user.id,
      status: false,
      reason: reason ?? null,
    }).then((response) => {
      if (response.success) {
        setConfirmDeactivateModalState({
          ...confirmDeactivateModalState,
          processing: false,
          success: "User deactivated successfully",
        });

        const updatedActiveSuspension = activeSuspension;
        updatedActiveSuspension.user.is_active = false;

        setActiveSuspension(updatedActiveSuspension);

        return;
      }

      setConfirmDeactivateModalState({
        ...confirmDeactivateModalState,
        processing: false,
        error: response.message,
      });
    });
  };

  return (
    <>
      <LoaderPane
        loading={!data && !error}
        error={error?.message}
        noRecords={data && data?.results.length === 0}
        onReload={() => {
          mutate();
        }}
      />

      <ConfirmModal
        show={confirmRevokeModalState.show}
        title="Confirm account restoration"
        description={`Please confirm account restoration for for ${
          activeSuspension?.user?.identity_type === "Mentor"
            ? activeSuspension?.user?.mentor?.name
            : activeSuspension?.user?.designer?.name
        }. This will allow the user to book sessions.`}
        processing={confirmRevokeModalState.processing}
        success={confirmRevokeModalState.success}
        error={confirmRevokeModalState.error}
        onConfirm={onConfirmRevokeUserSuspension}
        onHide={() => {
          setConfirmRevokeModalState({
            ...confirmRevokeModalState,
            show: false,
            success: null,
          });

          setModalState({
            ...modalState,
            show: true,
          });
        }}
      />

      <ConfirmDeactivateUserModal
        show={confirmDeactivateModalState.show}
        error={confirmDeactivateModalState.error}
        processing={confirmDeactivateModalState.processing}
        userToDeactivateName={
          activeSuspension?.user.identity_type === "Mentor"
            ? activeSuspension?.user.mentor.name
            : activeSuspension?.user.designer.name
        }
        success={confirmDeactivateModalState.success}
        onHide={() => {
          setConfirmDeactivateModalState({
            ...confirmDeactivateModalState,
            show: false,
            success: false,
            error: null,
            processing: false,
          });
          setModalState({
            ...modalState,
            show: true,
          });
        }}
        onConfirm={onConfirmDeactivateUser}
      />

      <SuspendedUserModal
        show={modalState.show}
        onHide={() => setModalState({ ...modalState, show: false })}
        onRevokeSuspension={() => {
          setModalState({ ...modalState, show: false });
          setConfirmRevokeModalState({
            ...confirmRevokeModalState,
            show: true,
          });
        }}
        onDeactivateUser={() => {
          setModalState({ ...modalState, show: false });
          setConfirmDeactivateModalState({
            ...confirmDeactivateModalState,
            show: true,
          });
        }}
        suspension={activeSuspension}
      />

      {data?.results.length > 0 && (
        <Table bordered responsive className="table-white">
          <thead>
            <tr>
              <th>Name</th>
              <th>Job Title</th>
              <th>Latest Activity</th>
              <th>Suspension Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.results?.map((suspension) => {
              return (
                <tr key={suspension.id}>
                  <td>
                    <a
                      href={
                        suspension.user.identity_type === "Mentor"
                          ? mentorUrl(suspension.user.mentor.slug)
                          : designerUrl(suspension.user.designer.slug)
                      }
                      target="_blank"
                      rel="noreferrer">
                      <div className="d-flex pointer">
                        <div className="pic-small mr-3">
                          <img
                            className="img-f-w-obj-cover"
                            src={
                              suspension.user.identity_type === "Mentor"
                                ? suspension.user.mentor.profile_photo_url
                                : suspension.user.designer.profile_photo_url
                            }
                            alt=""
                          />
                        </div>
                        <span className="text-16 text-grey font-500">
                          {suspension.user.identity_type === "Mentor"
                            ? suspension.user.mentor.name
                            : suspension.user.designer.name}
                        </span>
                      </div>
                    </a>
                  </td>

                  <td>
                    <span className="text-16 text-grey font-500">
                      {suspension.user.identity_type === "Mentor"
                        ? suspension.user.mentor.title
                        : suspension.user.designer.title}
                    </span>
                  </td>

                  <td>
                    <span className="text-16 text-grey font-500">
                      {suspension.suspension_appeal
                        ? new Date(
                            suspension.suspension_appeal.created_at
                          ).toDateString()
                        : new Date(suspension.suspended_at).toDateString()}{" "}
                      LT
                    </span>
                  </td>
                  <td>
                    <span
                      className={`suspension-status-text ${suspension.status.toLowerCase()}`}>
                      {suspension.status}
                    </span>
                  </td>
                  <td>
                    <span
                      className="text-16 text-success font-500 pointer"
                      onClick={() => {
                        setActiveSuspension(suspension);
                        setModalState({
                          ...modalState,
                          show: true,
                        });
                      }}>
                      View
                    </span>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={4}>
                <PaginationBar
                  page={paginationState.page}
                  pageSize={paginationState.pageSize}
                  totalItems={data?.count}
                  onChangePage={(newPage) => {
                    setPaginationState({ ...paginationState, page: newPage });
                  }}
                  onChangeRowsPerPage={(size) => {
                    setPaginationState({
                      ...paginationState,
                      pageSize: size,
                      page: 0,
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};

export default SuspendedUsers;
