import { useState, useEffect, Fragment } from "reactn";

import { deleteInProductReleaseService } from "services/InProductReleaseAnnouncementService";
import SideModal from "../side-modal/side-modal.component";
import ConfirmModal from "components/modal/confirm-modal/confirm-modal.component";

import FeatureModal from "./components/FeatureModal";

const FeatureReleaseModal = ({
  show,
  onHide,
  mutateCount,
  mutateActive,
  mutateInactive,
  type = "new",
  featureRelease,
}) => {
  /**
   * variable
   */
  const initialState = {
    disabled: true,
    visibility_count: "",
    release_page_url: "",
    target_audience: null,
    in_product_release_announcement_id: featureRelease?.id || null,

    releaseId: [],
    title: [],
    description: [],
    media_file: [],
    error: null,
    active: false,
    processing: false,
  };

  /**
   * state
   */
  const [state, setState] = useState(initialState);
  const [confirmDelete, setConfirmDelete] = useState({
    show: false,
    error: "",
    processing: false,
  });

  /**
   * functions
   */
  const mutate = () => mutateCount() | mutateActive() | mutateInactive();
  const reset = () => {
    setState(initialState);
  };

  const handleDelete = () => {
    setConfirmDelete((e) => ({ ...e, processing: true }));

    deleteInProductReleaseService(featureRelease?.id).then((res) => {
      if (res.success) {
        setConfirmDelete((e) => ({ ...e, processing: false }));
        reset();
        mutate();
        onHide();
      } else {
        setConfirmDelete((e) => ({
          ...e,
          processing: false,
          error:
            res.message ||
            "Failed to delete feature release. Please contact support to update your permissions and try again.",
        }));
      }
      setConfirmDelete((e) => ({ ...e, show: false, processing: false }));
    });
  };

  /**
   * effect
   */
  useEffect(() => {
    if (featureRelease) {
      setState((prevState) => ({
        ...prevState,
        visibility_count: featureRelease?.visibility_count || "",
        release_page_url: featureRelease?.release_page_url || "",
        target_audience: featureRelease?.target_audience || null,
        in_product_release_announcement_id: featureRelease?.id || null,
        disabled: !featureRelease?.active,
      }));

      if (featureRelease?.announcement_slides) {
        setState((prevState) => ({
          ...prevState,
          releaseId:
            featureRelease?.announcement_slides?.map((e) => e.id) || [],
          title: featureRelease.announcement_slides?.map((e) => e.title) || [],
          description:
            featureRelease.announcement_slides?.map((e) => e.description) || [],
          media_file:
            featureRelease.announcement_slides?.map((e) => e.media_file) || [],
        }));
      }
    }
  }, [featureRelease]);

  return (
    <Fragment>
      <SideModal show={show} onHide={onHide} onBlurHide={false}>
        <FeatureModal
          {...{
            type,
            state,
            reset,
            mutate,
            onHide,
            setState,
            featureRelease,
            setConfirmDelete,
          }}
        ></FeatureModal>

        <ConfirmModal
          show={confirmDelete.show}
          title="Delete feature release"
          description="Do you want to delete this feature release?"
          error={confirmDelete.error}
          processing={confirmDelete.processing}
          onHide={() => setConfirmDelete((e) => ({ ...e, show: false }))}
          onConfirm={() => handleDelete()}
        />

        <ConfirmModal />
      </SideModal>
    </Fragment>
  );
};

export default FeatureReleaseModal;
