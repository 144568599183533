import { useState, useGlobal, useEffect } from "reactn";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../components/button/button.component";
import InputField from "../../components/form/input-field/input-field.component";
import GroupSessionModal from "../../components/modal/group-session-modal/group-session-modal.component";
import { fetchData, mentorUrl, apiBaseUrl, SWRFetcher } from "../../api/api";
import PaginationBar from "../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../components/page-layout/page-layout.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";

const GroupSessionsPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");

  const [query, setQuery] = useState("");
  const [queryPast, setQueryPast] = useState("");
  const [queryCancelled, setQueryCancelled] = useState("");
  const [queryDisabled, setQueryDisabled] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const [state, setState] = useState({
    currentSession: null,
    showModal: true,
    sessionUrl: null,
  });

  const [activePaginate, setActivePaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [pastPaginate, setPastPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [disabledPaginate, setDisabledPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [cancelledPaginate, setCancelledPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  //active, cancelled, disabled
  const getURL = (
    q = query,
    limit = activePaginate.pageSize,
    offset = activePaginate.pageSize * activePaginate.page,
    filter = "upcoming"
  ) => {
    return `${apiBaseUrl}group-session/?q=${q}&limit=${limit}&offset=${offset}&filter=${filter}`;
  };

  //totals
  const { data: totalUpcomming } = useSWR(getURL("", 1, 0));
  const { data: totalPast } = useSWR(getURL("", 1, 0, "past"));
  const { data: totalCancelled } = useSWR(getURL("", 1, 0, "cancelled"));
  const { data: totalDisabled } = useSWR(getURL("", 1, 0, "disabled"));

  const [canFetchTab1, setCanFetchTab1] = useState(false);
  const [canFetchTab2, setCanFetchTab2] = useState(false);
  const [canFetchTab3, setCanFetchTab3] = useState(false);
  //data
  const {
    data: activeSessionsData,
    error: activeSessionsError,
    mutate: mutateActiveSessions,
  } = useSWR(getURL(query));

  const {
    data: pastSessionsData,
    error: pastSessionsError,
    mutate: mutatePastSessions,
  } = useSWR(
    canFetchTab1 &&
      getURL(
        queryPast,
        pastPaginate.pageSize,
        pastPaginate.pageSize * pastPaginate.page,
        "past"
      )
  );

  const {
    data: cancelledSessionsData,
    error: cancelledSessionsError,
    mutate: mutateCancelledSessions,
  } = useSWR(
    canFetchTab2 &&
      getURL(
        queryCancelled,
        cancelledPaginate.pageSize,
        cancelledPaginate.pageSize * cancelledPaginate.page,
        "cancelled"
      )
  );

  const {
    data: disabledSessionsData,
    error: disabledSessionsError,
    mutate: mutateDisabledSessions,
  } = useSWR(
    canFetchTab3 &&
      getURL(
        queryDisabled,
        disabledPaginate.pageSize,
        disabledPaginate.pageSize * disabledPaginate.page,
        "disabled"
      )
  );

  const onSessionUpdated = () => {
    mutateActiveSessions(getURL(query));
    mutatePastSessions(
      getURL(
        queryPast,
        pastPaginate.pageSize,
        pastPaginate.pageSize * pastPaginate.page,
        "past"
      )
    );
    mutateCancelledSessions(
      getURL(
        queryCancelled,
        cancelledPaginate.pageSize,
        cancelledPaginate.pageSize * cancelledPaginate.page,
        "cancelled"
      )
    );
    mutateDisabledSessions(
      getURL(
        queryDisabled,
        disabledPaginate.pageSize,
        disabledPaginate.pageSize * disabledPaginate.page,
        "disabled"
      )
    );
  };

  return (
    <>
      <GroupSessionModal
        session={state.currentSession}
        token={currentUser.token.actual}
        show={state.showModal}
        onHide={() => {
          setState({ ...state, currentSession: null, showModal: false });
        }}
        onUpdated={onSessionUpdated}
      />

      <PageLayout activeLink="group-sessions" parentMenu="activities">
        <div>
          <div className="text-grey text-20 font-bold mb-20"></div>
          <div className="d-flex space-between  mb-20">
            <div className="text-grey text-20 font-bold">Group Sessions</div>
          </div>
          <Row>
            <Col md={7}>
              <div className="d-flex flex-wrap mb-20">
                <Button
                  classes={`pill success ${activeTab == 0} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(0);
                  }}
                >
                  Upcoming ({totalUpcomming?.count || 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab == 1} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(1);
                    if (!canFetchTab1) setCanFetchTab1(true);
                  }}
                >
                  Past ({totalPast?.count || 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab == 2} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(2);
                    if (!canFetchTab2) setCanFetchTab2(true);
                  }}
                >
                  Cancelled ({totalCancelled?.count || 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab == 3} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(3);
                    if (!canFetchTab3) setCanFetchTab3(true);
                  }}
                >
                  Disabled ({totalDisabled?.count || 0})
                </Button>
              </div>
            </Col>
            <Col md={5}>
              {activeTab == 0 && (
                <InputField
                  placeholder="Search  sessions"
                  classes="small mb-20"
                  isSearch
                  value={query}
                  onChange={(ev) => {
                    //mutateActiveSessions(ev.target.value)
                    setQuery(ev.target.value);
                  }}
                />
              )}

              {activeTab == 1 && (
                <InputField
                  placeholder="Search past sessions"
                  classes="small mb-20"
                  isSearch
                  value={queryPast}
                  onChange={(ev) => {
                    setQueryPast(ev.target.value);
                  }}
                />
              )}

              {activeTab == 2 && (
                <InputField
                  placeholder="Search cancelled sessions"
                  classes="small mb-20"
                  isSearch
                  value={queryCancelled}
                  onChange={(ev) => {
                    setQueryCancelled(ev.target.value);
                  }}
                />
              )}

              {activeTab == 3 && (
                <InputField
                  placeholder="Search disabled sessions"
                  classes="small mb-20"
                  isSearch
                  value={queryDisabled}
                  onChange={(ev) => {
                    setQueryDisabled(ev.target.value);
                  }}
                />
              )}
            </Col>
          </Row>

          {activeTab === 0 && (
            <LoaderPane
              loading={!activeSessionsData && !activeSessionsError}
              error={activeSessionsError?.message}
              noRecords={
                !activeSessionsError &&
                activeSessionsData &&
                activeSessionsData["count"] === 0
              }
              onReload={() => {
                mutateActiveSessions();
              }}
            />
          )}

          {activeTab == 0 &&
            activeSessionsData &&
            !activeSessionsError &&
            activeSessionsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Mentor</th>
                      <th>Session</th>
                      <th># of Cohost</th>
                      <th>Date of session</th>
                      <th>Time</th>
                      <th># of RSVP</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeSessionsData?.results?.map((session) => (
                      <tr key={session.id}>
                        <td>
                          <a
                            href={
                              session?.host?.slug
                                ? mentorUrl(session.host.slug)
                                : "#"
                            }
                            target="_blank"
                          >
                            <div className="d-flex pointer">
                              <div className="pic-small mr-3">
                                <img
                                  className="img-f-w-obj-cover"
                                  src={session?.host?.profile_photo_url}
                                  alt=""
                                />
                              </div>
                              <span className="text-16 text-grey font-500">
                                {session?.host?.name}{" "}
                              </span>
                            </div>
                          </a>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500">
                            {session?.name}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500">
                            {session?.total_co_hosts}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {new Date(
                              session?.date_and_time
                            ).toLocaleDateString()}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {new Date(
                              session?.date_and_time
                            ).toLocaleTimeString()}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500 pointer">{`${session?.booked_seats} / ${session?.rsvp_limit}`}</span>
                        </td>
                        <td>
                          <span
                            className="text-16 text-success font-500 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                currentSession: session,
                                showModal: true,
                              });
                            }}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={7}>
                        <PaginationBar
                          page={activePaginate.page}
                          pageSize={activePaginate.pageSize}
                          totalItems={activeSessionsData["count"]}
                          onChangePage={(newPage) => {
                            setActivePaginate({
                              ...activePaginate,
                              page: newPage,
                            });
                            //mutateActiveSessions()
                          }}
                          onChangeRowsPerPage={(size) => {
                            //mutateActiveSessions(query,size, size * activePaginate.page)
                            setActivePaginate({
                              ...activePaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

          {/* past sessions */}
          {activeTab === 1 && (
            <LoaderPane
              loading={!pastSessionsData && !pastSessionsError}
              error={pastSessionsError?.message}
              noRecords={
                !pastSessionsError &&
                pastSessionsData &&
                pastSessionsData["count"] === 0
              }
              onReload={() => {
                mutatePastSessions();
              }}
            />
          )}
          {activeTab == 1 &&
            pastSessionsData &&
            !pastSessionsError &&
            pastSessionsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Mentor</th>
                      <th>Session</th>
                      <th># of Cohost</th>
                      <th>Date of session</th>
                      <th>Time</th>
                      <th># of RSVP</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pastSessionsData?.results?.map((session) => (
                      <tr key={session.id}>
                        <td>
                          <a
                            href={
                              session?.host?.slug
                                ? mentorUrl(session.host.slug)
                                : "#"
                            }
                            target="_blank"
                          >
                            <div className="d-flex pointer">
                              <div className="pic-small mr-3">
                                <img
                                  className="img-f-w-obj-cover"
                                  src={session?.host?.profile_photo_url}
                                  alt=""
                                />
                              </div>
                              <span className="text-16 text-grey font-500">
                                {session?.host?.name}{" "}
                              </span>
                            </div>
                          </a>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500">
                            {session?.name}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500">
                            {session?.total_co_hosts}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {new Date(
                              session?.date_and_time
                            ).toLocaleDateString()}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {new Date(
                              session?.date_and_time
                            ).toLocaleTimeString()}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500 pointer">{`${session?.booked_seats} / ${session?.rsvp_limit}`}</span>
                        </td>
                        <td>
                          <span
                            className="text-16 text-success font-500 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                currentSession: session,
                                showModal: true,
                              });
                            }}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={7}>
                        <PaginationBar
                          page={pastPaginate.page}
                          pageSize={pastPaginate.pageSize}
                          totalItems={pastSessionsData["count"]}
                          onChangePage={(newPage) => {
                            setPastPaginate({ ...pastPaginate, page: newPage });
                            //mutatePastSessions()
                          }}
                          onChangeRowsPerPage={(size) => {
                            //mutatePastSessions(queryPast,size, size * pastPaginate.page,"past")
                            setPastPaginate({
                              ...pastPaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

          {/* cancelled sessions */}
          {activeTab === 2 && (
            <LoaderPane
              loading={!cancelledSessionsData && !cancelledSessionsError}
              error={cancelledSessionsError?.message}
              noRecords={
                !cancelledSessionsError &&
                cancelledSessionsData &&
                cancelledSessionsData["count"] === 0
              }
              onReload={() => {
                mutateCancelledSessions();
              }}
            />
          )}
          {activeTab == 2 &&
            cancelledSessionsData &&
            !cancelledSessionsError &&
            cancelledSessionsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Mentor</th>
                      <th>Session</th>
                      <th># of Cohost</th>
                      <th>Date of session</th>
                      <th>Time</th>
                      <th># of RSVP</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cancelledSessionsData?.results?.map((session) => (
                      <tr key={session.id}>
                        <td>
                          <a
                            href={
                              session?.host?.slug
                                ? mentorUrl(session.host.slug)
                                : "#"
                            }
                            target="_blank"
                          >
                            <div className="d-flex pointer">
                              <div className="pic-small mr-3">
                                <img
                                  className="img-f-w-obj-cover"
                                  src={session?.host?.profile_photo_url}
                                  alt=""
                                />
                              </div>
                              <span className="text-16 text-grey font-500">
                                {session?.host?.name}{" "}
                              </span>
                            </div>
                          </a>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500">
                            {session?.name}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500">
                            {session?.total_co_hosts}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {new Date(
                              session?.date_and_time
                            ).toLocaleDateString()}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {new Date(
                              session?.date_and_time
                            ).toLocaleTimeString()}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500 pointer">{`${session?.booked_seats} / ${session?.rsvp_limit}`}</span>
                        </td>
                        <td>
                          <span
                            className="text-16 text-success font-500 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                currentSession: session,
                                showModal: true,
                              });
                            }}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={7}>
                        <PaginationBar
                          page={cancelledPaginate.page}
                          pageSize={cancelledPaginate.pageSize}
                          totalItems={cancelledSessionsData["count"]}
                          onChangePage={(newPage) => {
                            setCancelledPaginate({
                              ...cancelledPaginate,
                              page: newPage,
                            });
                            //mutateCancelledSessions()
                          }}
                          onChangeRowsPerPage={(size) => {
                            //mutateCancelledSessions(queryCancelled,size, size * cancelledPaginate.page,"cancelled")
                            setCancelledPaginate({
                              ...cancelledPaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

          {/* disabled */}
          {activeTab === 3 && (
            <LoaderPane
              loading={!disabledSessionsData && !disabledSessionsError}
              error={disabledSessionsError?.message}
              noRecords={
                !disabledSessionsError &&
                disabledSessionsData &&
                disabledSessionsData["count"] === 0
              }
              onReload={() => {
                mutateDisabledSessions();
              }}
            />
          )}
          {activeTab == 3 &&
            disabledSessionsData &&
            !disabledSessionsError &&
            disabledSessionsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Mentor</th>
                      <th>Session</th>
                      <th># of Cohost</th>
                      <th>Date of session</th>
                      <th>Time</th>
                      <th># of RSVP</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {disabledSessionsData?.results?.map((session) => (
                      <tr key={session.id}>
                        <td>
                          <a
                            href={
                              session?.host?.slug
                                ? mentorUrl(session.host.slug)
                                : "#"
                            }
                            target="_blank"
                          >
                            <div className="d-flex pointer">
                              <div className="pic-small mr-3">
                                <img
                                  className="img-f-w-obj-cover"
                                  src={session?.host?.profile_photo_url}
                                  alt=""
                                />
                              </div>
                              <span className="text-16 text-grey font-500">
                                {session?.host?.name}{" "}
                              </span>
                            </div>
                          </a>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500">
                            {session?.name}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500">
                            {session?.total_co_hosts}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {new Date(
                              session?.date_and_time
                            ).toLocaleDateString()}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {new Date(
                              session?.date_and_time
                            ).toLocaleTimeString()}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-500 pointer">
                            {session?.rsvp_limit}
                          </span>
                        </td>
                        <td>
                          <span
                            className="text-16 text-success font-500 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                currentSession: session,
                                showModal: true,
                              });
                            }}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={7}>
                        <PaginationBar
                          page={disabledPaginate.page}
                          pageSize={disabledPaginate.pageSize}
                          totalItems={disabledSessionsData["count"]}
                          onChangePage={(newPage) => {
                            setDisabledPaginate({
                              ...disabledPaginate,
                              page: newPage,
                            });
                            //mutateDisabledSessions()
                          }}
                          onChangeRowsPerPage={(size) => {
                            //mutateDisabledSessions(queryDisabled,size, size * disabledPaginate.page,"disabled")
                            setDisabledPaginate({
                              ...disabledPaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
        </div>
      </PageLayout>
    </>
  );
};

export default GroupSessionsPage;
