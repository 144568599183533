import { useState, useGlobal, useEffect } from "reactn";
import { Link } from "react-router-dom";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import { fetchData, putData } from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import HaveAccess from "../../../access-control.jsx";
import "./advanced-tracker.styles.scss";
import ExportButton from "../../../components/export-button/export-button.component";
import Select from "../../../components/form/select/select.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";

const AdvancedTrackerPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const canWrite = HaveAccess("analytics", "Write", currentUser);

  const [_userMetricsData, _setUserMetricsData] = useGlobal("_userMetricsData");
  const [_userGeoData, _setUserGeoData] = useGlobal("_userGeoData");

  const [state, setState] = useState({
    activeTab: 0,
    fetching: false,
    canFetch: true,
    error: null,
    query: "",
  });

  const [year, setYear] = useState(2021);

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const {
    data: userMetricsData,
    error: userMetricsError,
    mutate: mutateUserMetrics,
  } = useSWR(
    state.activeTab === 0 && `dashboard/analytics/adt/user-metric/?year=${year}`
  );

  const {
    data: userGeoData,
    error: userGeoError,
    mutate: mutateUserGeo,
  } = useSWR(
    state.activeTab === 1 &&
      `dashboard/analytics/adt/user-by-country/?year=${year}`
  );

  useEffect(() => {
    if (userMetricsData && !userMetricsError) {
      _setUserMetricsData(userMetricsData);
    }

    if (userGeoData && !userGeoError) {
      _setUserGeoData(userGeoData);
    }
  }, [userMetricsData, userGeoData]);

  const active_members = _userMetricsData?.active_members;
  const active_mentors = _userMetricsData?.active_mentors;
  const inactive_mentors = _userMetricsData?.inactive_mentors;
  const registered_members = _userMetricsData?.registered_members;
  const registered_mentors = _userMetricsData?.registered_mentors;

  const TR = ({ title = "Total", k, data }) => {
    return (
      <tr>
        <td className="bg-grey">
          <span className="text-16 text-grey font-500"> {title}</span>
        </td>
        {data.map((dt, i) => {
          return (
            <td key={i}>
              <span style={{ color: "#05051B" }} className="text-16 font-500">
                {dt[[k]]}
              </span>
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <>
      {/*  <ConfirmModal
        show={state.showConfirmModal}
        token={currentUser.token.actual}
        title={state.confirmTitle}
        description={state.confirmDescription}
        processing={state.confirmProcessing}
        error={state.confirmError}
        onHide={() => {
          setState({ ...state, confirmError: null, showConfirmModal: false });
        }}
        onConfirm={onLangDisableOrEnable}
      />
 */}
      <PageLayout activeLink="advanced-tracker" parentMenu="analytics">
        <div className="advanced-tracker-page">
          <div className="text-grey text-20 font-400 mb-20">Advanced DT</div>

          <Row>
            <Col md={7}>
              <div className="d-flex flex-wrap align-center mb-20">
                <Button
                  classes={`pill success ${state.activeTab == 0} mr-2  mb-20`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 0 });
                  }}
                >
                  User Metrics
                </Button>
                <Button
                  classes={`pill success ${state.activeTab == 1}  mb-20 mr-3`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 1 });
                  }}
                >
                  Users by Geo
                </Button>

                {/* <Button
                  classes={`pill success ${state.activeTab == 1}  mb-20 mr-3`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 1 });
                  }}
                >
                  Engagement Metrics
                </Button>
                
                <Button
                  classes={`pill success ${state.activeTab == 3}  mb-20`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 3 });
                  }}
                >
                  Cohorts
                </Button> */}
              </div>
            </Col>

            <Col md={5}>
              {/* {state.activeTab == 1 && (
                <InputField
                  placeholder="Search disabled  languages"
                  classes="small mb-20"
                  isSearch
                  value={""}
                  onChange={(ev) => {}}
                />
              )} */}
            </Col>
          </Row>

          <div className="d-flex flex-end align-center mt-20">
            {/* <ExportButton label="Export" url={null} fileName="file" /> */}
            <div style={{ width: 130 }} className="ml-3">
              <Select
                noLabel
                placeholder="Year"
                value={{ label: year, value: year }}
                options={[
                  { label: "2021", value: 2021 },
                  { label: "2020", value: 2020 },
                ]}
                onChange={(selected) => {
                  setYear(selected.value);
                }}
              />
            </div>
          </div>

          <div hidden={state.activeTab != 0}>
            <LoaderPane
              loading={!userMetricsData && !userMetricsError}
              error={userMetricsError?.message}
              noRecords={
                !userMetricsError &&
                userMetricsData &&
                userMetricsData?.length == 0
              }
              onReload={() => {
                mutateUserMetrics();
              }}
            />

            {registered_mentors && (
              <div>
                <div className="text-14 font-bold mb-20 text-uppercase">
                  Registered Mentors
                </div>
                <Table responsive className="table-white acvanced-table">
                  <thead>
                    <tr>
                      <th className="bg-grey">EVENT</th>
                      <th>JAN</th>
                      <th>FEB</th>
                      <th>MAR</th>
                      <th>APR</th>
                      <th>MAY</th>
                      <th>JUNE</th>
                      <th>JULY</th>
                      <th>AUG</th>
                      <th>SEPT</th>
                      <th>OCT</th>
                      <th>NOV</th>
                      <th>DEC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TR title="Total" k="total" data={registered_mentors} />
                    <TR
                      title="Existing"
                      k="existing"
                      data={registered_mentors}
                    />
                    <TR title="New" k="new" data={registered_mentors} />
                  </tbody>
                </Table>
              </div>
            )}

            {active_mentors && (
              <div>
                <div className="text-14 font-bold mb-20 mt-40 text-uppercase">
                  Active Mentors
                </div>
                <Table responsive className="table-white acvanced-table">
                  <thead>
                    <tr>
                      <th className="bg-grey">EVENT</th>
                      <th>JAN</th>
                      <th>FEB</th>
                      <th>MAR</th>
                      <th>APR</th>
                      <th>MAY</th>
                      <th>JUNE</th>
                      <th>JULY</th>
                      <th>AUG</th>
                      <th>SEPT</th>
                      <th>OCT</th>
                      <th>NOV</th>
                      <th>DEC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TR title="Total" k="total" data={active_mentors} />
                    <TR title="Existing" k="existing" data={active_mentors} />
                    <TR title="New" k="new" data={active_mentors} />
                    <TR title="Return" k="return" data={active_mentors} />
                    <TR title="Churn" k="churned" data={active_mentors} />
                  </tbody>
                </Table>
              </div>
            )}

            {inactive_mentors && (
              <div>
                <div className="text-14 font-bold mb-20 mt-40 text-uppercase">
                  Inactive Mentors
                </div>
                <Table responsive className="table-white acvanced-table">
                  <thead>
                    <tr>
                      <th className="bg-grey">EVENT</th>
                      <th>JAN</th>
                      <th>FEB</th>
                      <th>MAR</th>
                      <th>APR</th>
                      <th>MAY</th>
                      <th>JUNE</th>
                      <th>JULY</th>
                      <th>AUG</th>
                      <th>SEPT</th>
                      <th>OCT</th>
                      <th>NOV</th>
                      <th>DEC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TR title="Unbooked" k="unbooked" data={inactive_mentors} />
                    <TR
                      title="Unresponsive"
                      k="unresponsive"
                      data={inactive_mentors}
                    />
                  </tbody>
                </Table>
              </div>
            )}

            {registered_members && (
              <div>
                <div className="text-14 font-bold mb-20 mt-40 text-uppercase">
                  Registered Members
                </div>
                <Table responsive className="table-white acvanced-table">
                  <thead>
                    <tr>
                      <th className="bg-grey">EVENT</th>
                      <th>JAN</th>
                      <th>FEB</th>
                      <th>MAR</th>
                      <th>APR</th>
                      <th>MAY</th>
                      <th>JUNE</th>
                      <th>JULY</th>
                      <th>AUG</th>
                      <th>SEPT</th>
                      <th>OCT</th>
                      <th>NOV</th>
                      <th>DEC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TR title="Total" k="total" data={registered_members} />
                    <TR
                      title="Existing"
                      k="existing"
                      data={registered_members}
                    />
                    <TR title="New" k="new" data={registered_members} />
                  </tbody>
                </Table>
              </div>
            )}

            {active_members && (
              <div>
                <div className="text-14 font-bold mb-20 mt-40 text-uppercase">
                  Active Members
                </div>
                <Table responsive className="table-white acvanced-table">
                  <thead>
                    <tr>
                      <th className="bg-grey">EVENT</th>
                      <th>JAN</th>
                      <th>FEB</th>
                      <th>MAR</th>
                      <th>APR</th>
                      <th>MAY</th>
                      <th>JUNE</th>
                      <th>JULY</th>
                      <th>AUG</th>
                      <th>SEPT</th>
                      <th>OCT</th>
                      <th>NOV</th>
                      <th>DEC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TR title="Total" k="total" data={active_members} />
                    <TR title="Existing" k="existing" data={active_members} />
                    <TR title="New" k="new" data={active_members} />
                  </tbody>
                </Table>
              </div>
            )}
          </div>

          {/** users by Geo */}
          <div hidden={state.activeTab != 1}>
            <LoaderPane
              loading={!userGeoData && !userGeoError}
              error={userGeoError?.message}
              noRecords={
                !userGeoError && userGeoData && userGeoData?.length == 0
              }
              onReload={() => {
                mutateUserGeo();
              }}
            />

            {_userGeoData?.mentors && (
              <div>
                <div className="text-14 font-bold mb-20 text-uppercase">
                  Mentors
                </div>
                <Table responsive className="table-white acvanced-table">
                  <thead>
                    <tr>
                      <th className="bg-grey">Region</th>
                      <th>JAN</th>
                      <th>FEB</th>
                      <th>MAR</th>
                      <th>APR</th>
                      <th>MAY</th>
                      <th>JUNE</th>
                      <th>JULY</th>
                      <th>AUG</th>
                      <th>SEPT</th>
                      <th>OCT</th>
                      <th>NOV</th>
                      <th>DEC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_userGeoData?.mentors.map((rowData) => {
                      return (
                        <tr>
                          <td className="bg-grey">
                            <span className="text-16 text-grey font-500">
                              {" "}
                              {rowData.country}
                            </span>
                          </td>
                          {rowData?.data.map((dt, i) => {
                            return (
                              <td key={i}>
                                <span
                                  style={{ color: "#05051B" }}
                                  className="text-16 font-500"
                                >
                                  {dt.total}
                                </span>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}

            {_userGeoData?.active_mentors && (
              <div>
                <div className="text-14 font-bold mb-20 mt-40 text-uppercase">
                  Active Mentors
                </div>
                <Table responsive className="table-white acvanced-table">
                  <thead>
                    <tr>
                      <th className="bg-grey">Region</th>
                      <th>JAN</th>
                      <th>FEB</th>
                      <th>MAR</th>
                      <th>APR</th>
                      <th>MAY</th>
                      <th>JUNE</th>
                      <th>JULY</th>
                      <th>AUG</th>
                      <th>SEPT</th>
                      <th>OCT</th>
                      <th>NOV</th>
                      <th>DEC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_userGeoData?.active_mentors.map((rowData) => {
                      return (
                        <tr>
                          <td className="bg-grey">
                            <span className="text-16 text-grey font-500">
                              {" "}
                              {rowData.country}
                            </span>
                          </td>
                          {rowData?.data.map((dt, i) => {
                            return (
                              <td key={i}>
                                <span
                                  style={{ color: "#05051B" }}
                                  className="text-16 font-500"
                                >
                                  {dt.total}
                                </span>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}

            {_userGeoData?.members && (
              <div>
                <div className="text-14 font-bold mb-20 mt-40 text-uppercase">
                  Members
                </div>
                <Table responsive className="table-white acvanced-table">
                  <thead>
                    <tr>
                      <th className="bg-grey">Region</th>
                      <th>JAN</th>
                      <th>FEB</th>
                      <th>MAR</th>
                      <th>APR</th>
                      <th>MAY</th>
                      <th>JUNE</th>
                      <th>JULY</th>
                      <th>AUG</th>
                      <th>SEPT</th>
                      <th>OCT</th>
                      <th>NOV</th>
                      <th>DEC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_userGeoData?.members.map((rowData) => {
                      return (
                        <tr>
                          <td className="bg-grey">
                            <span className="text-16 text-grey font-500">
                              {" "}
                              {rowData.country}
                            </span>
                          </td>
                          {rowData?.data.map((dt, i) => {
                            return (
                              <td key={i}>
                                <span
                                  style={{ color: "#05051B" }}
                                  className="text-16 font-500"
                                >
                                  {dt.total}
                                </span>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}

            {_userGeoData?.active_members && (
              <div>
                <div className="text-14 font-bold mb-20 mt-40  text-uppercase">
                  Active Members
                </div>
                <Table responsive className="table-white acvanced-table">
                  <thead>
                    <tr>
                      <th className="bg-grey">Region</th>
                      <th>JAN</th>
                      <th>FEB</th>
                      <th>MAR</th>
                      <th>APR</th>
                      <th>MAY</th>
                      <th>JUNE</th>
                      <th>JULY</th>
                      <th>AUG</th>
                      <th>SEPT</th>
                      <th>OCT</th>
                      <th>NOV</th>
                      <th>DEC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_userGeoData?.active_members.map((rowData) => {
                      return (
                        <tr>
                          <td className="bg-grey">
                            <span className="text-16 text-grey font-500">
                              {" "}
                              {rowData.country}
                            </span>
                          </td>
                          {rowData?.data.map((dt, i) => {
                            return (
                              <td key={i}>
                                <span
                                  style={{ color: "#05051B" }}
                                  className="text-16 font-500"
                                >
                                  {dt.total}
                                </span>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default AdvancedTrackerPage;
