import ButtonDropdown, {
  MenuItem,
} from "components/button-dropdown/button-dropdown";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import InputFIeld from "components/form/input-field/input-field.component";
import { useState } from "reactn";
import { apiBaseUrl } from "../../../api/api";
import useSWR from "swr";
import { useEffect } from "reactn";
import LoaderPane from "components/loader-pane/loader-pane.component";
import { Table } from "react-bootstrap";
import { designerUrl, mentorUrl } from "utils/Http";
import PaginationBar from "components/pagination-bar/pagination-bar.component";

export const BlockedUsersSearch = ({ query, setQuery }) => {
  return (
    <InputFIeld
      placeholder="Search"
      classes="small mb-20"
      isSearch
      value={query}
      onChange={(ev) => {
        setQuery(ev.target.value);
      }}
    />
  );
};

export const BlockedUsersSortBy = ({ sortBy, setSortBy }) => {
  return (
    <div className="d-flex mr-10">
      <ButtonDropdown
        classes="mw-200px"
        title={
          <div className="d-flex align-center text-truncate">
            <FilterIcon /> &nbsp;&nbsp;
            <span>{sortBy.label || "Sort by"}</span>
          </div>
        }>
        <MenuItem
          active={sortBy.value === "recently_blocked"}
          onClick={() =>
            setSortBy({
              label: "Recently blocked",
              value: "recently_blocked",
            })
          }>
          Recently blocked
        </MenuItem>
        <MenuItem
          active={sortBy.value === "most_blocked"}
          onClick={() =>
            setSortBy({
              label: "Most blocked",
              value: "most_blocked",
            })
          }>
          Most blocked
        </MenuItem>
        <MenuItem
          active={sortBy.value === "blocked_a_while_back"}
          onClick={() =>
            setSortBy({
              label: "Blocked a while back",
              value: "blocked_a_while_back",
            })
          }>
          Blocked a while back
        </MenuItem>
      </ButtonDropdown>
    </div>
  );
};

const BlockedUsers = ({ query = "", sortBy = "", setTotalCount }) => {
  const [paginationState, setPaginationState] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    remoteNext: null,
    remotePrev: null,
  });

  const getUrl = () => {
    let path = `${apiBaseUrl}user/blocked/list/?offset=${
      paginationState.page * paginationState.pageSize
    }&limit=${paginationState.pageSize}`;

    if (query) path += `&query=${query}`;

    if (sortBy) path += `&sort_by=${sortBy}`;

    return path;
  };

  const { data, error, mutate } = useSWR(getUrl());

  useEffect(() => {
    if (data) {
      setTotalCount(data.count);
    }
  }, [data, setTotalCount]);

  return (
    <>
      <LoaderPane
        loading={!data && !error}
        error={error?.message}
        noRecords={data && data?.results.length === 0}
        onReload={() => {
          mutate();
        }}
      />
      {data?.results.length > 0 && (
        <Table bordered responsive className="table-white">
          <thead>
            <tr>
              <th>Blocked user</th>
              <th>Blocked by</th>
              <th>Date blocked</th>
            </tr>
          </thead>
          <tbody>
            {data?.results?.map((blockedUser) => {
              return (
                <tr key={blockedUser.id}>
                  <td>
                    <a
                      href={
                        blockedUser.blocked_user.identity_type === "Mentor"
                          ? mentorUrl(blockedUser.blocked_user.mentor.slug)
                          : designerUrl(blockedUser.blocked_user.designer.slug)
                      }
                      target="_blank"
                      rel="noreferrer">
                      <div className="d-flex pointer">
                        <div className="pic-small mr-3">
                          <img
                            className="img-f-w-obj-cover"
                            src={
                              blockedUser.blocked_user.identity_type ===
                              "Mentor"
                                ? blockedUser.blocked_user.mentor
                                    .profile_photo_url
                                : blockedUser.blocked_user.designer
                                    .profile_photo_url
                            }
                            alt=""
                          />
                        </div>
                        <span className="text-16 text-grey font-500">
                          {blockedUser.blocked_user.identity_type === "Mentor"
                            ? blockedUser.blocked_user.mentor.name
                            : blockedUser.blocked_user.designer.name}
                        </span>
                      </div>
                    </a>
                  </td>

                  <td>
                    <a
                      href={
                        blockedUser.blocked_by.identity_type === "Mentor"
                          ? mentorUrl(blockedUser.blocked_by.mentor.slug)
                          : designerUrl(blockedUser.blocked_by.designer.slug)
                      }
                      target="_blank"
                      rel="noreferrer">
                      <div className="d-flex pointer">
                        <div className="pic-small mr-3">
                          <img
                            className="img-f-w-obj-cover"
                            src={
                              blockedUser.blocked_by.identity_type === "Mentor"
                                ? blockedUser.blocked_by.mentor
                                    .profile_photo_url
                                : blockedUser.blocked_by.designer
                                    .profile_photo_url
                            }
                            alt=""
                          />
                        </div>
                        <span className="text-16 text-grey font-500">
                          {blockedUser.blocked_by.identity_type === "Mentor"
                            ? blockedUser.blocked_by.mentor.name
                            : blockedUser.blocked_by.designer.name}
                        </span>
                      </div>
                    </a>
                  </td>
                  <td>
                    <span className="text-16 text-grey font-500">
                      {new Date(blockedUser.created_at).toDateString()} LT
                    </span>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={4}>
                <PaginationBar
                  page={paginationState.page}
                  pageSize={paginationState.pageSize}
                  totalItems={data?.count}
                  onChangePage={(newPage) => {
                    setPaginationState({ ...paginationState, page: newPage });
                  }}
                  onChangeRowsPerPage={(size) => {
                    setPaginationState({
                      ...paginationState,
                      pageSize: size,
                      page: 0,
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};

export default BlockedUsers;
