import { useState, useGlobal, useEffect } from "reactn";
import SummaryCard from "../../components/card/summary-card/summary-card.component";
import { Table, Row, Col } from "react-bootstrap";
import MentorSideModal from "../../components/modal/mentor-side-modal/side-modal.component";
import { apiBaseUrl, SWRFetcher } from "../../api/api";
import { withRouter } from "react-router-dom";
import PaginationBar from "../../components/pagination-bar/pagination-bar.component";
import InputField from "../../components/form/input-field/input-field.component";
import PageLayout from "../../components/page-layout/page-layout.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { CanReadOrWrite } from "../../access-control.jsx";
import ButtonDropdown, {
  MenuItem,
} from "../../components/button-dropdown/button-dropdown";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import Button from "components/button/button.component";
import ConfirmMarkMentorAsInReviewModal from "components/modal/confirm-mark-mentor-as-in-review-modal/confirm-mark-mentor-as-in-review-modal.component";
import { markMentorAsInReview } from "api/services/mentor.service";
import { resetUserPhoneNumber } from "api/services/account.service";
import ConfirmModal from "components/modal/confirm-modal/confirm-modal.component";
import { sanitizeLinkedIn } from "utils/helpers";

const DashboardPage = () => {
  const [currentUser] = useGlobal("currentUser");
  const canReadOrWriteUser = CanReadOrWrite("user", currentUser);
  const canReadOrWriteBooking = CanReadOrWrite("booking", currentUser);
  const canReadOrWriteReview = CanReadOrWrite("review", currentUser);

  const [mentors] = useGlobal("mentors");
  const [totalMentors] = useGlobal("totalMentors");

  const [pendingMentors] = useGlobal("pendingMentors");
  const [totalPendingMentors] = useGlobal("totalPendingMentors");

  const [designers] = useGlobal("designers");

  const [query, setQuery] = useState("");

  const [activeTab, setActiveTab] = useState(0);

  const [state, setState] = useState({
    showMentorModal: false,
    currentMentor: null,
    fetching: false,
    canFetch: true,
    error: null,
    totalPending: 0,
    fetchingDesigner: false,
    canFetchDesigner: true,
    designerError: null,
    phoneNumberCleared: false,
    sort: {
      text: "",
      value: "",
    },
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    nextURL: null,
    prevURL: null,
  });

  const [inReviewPaginate, setInReviewPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    nextURL: null,
    prevURL: null,
  });

  const [
    confirmMarkMentorAsInReviewModalState,
    setConfirmMarkMentorAsInReviewModalState,
  ] = useState({
    show: false,
    error: null,
    processing: false,
  });

  const [confirmModalState, setConfirmModalState] = useState({
    show: false,
    success: false,
    error: null,
    processing: false,
    title: "Clear phone number",
    description: "",
  });

  const { data: totalBookingsData } = useSWR(
    canReadOrWriteBooking &&
      `${process.env.REACT_APP_CALENDAR_API}booking/count/?filter=Confirmed`,
    SWRFetcher(currentUser)
  );

  const getURL = (q, limit, offset, select, sort = state?.sort?.value) => {
    return `${apiBaseUrl}mentor/list/tenet/?q=${q}&limit=${limit}&offset=${offset}&select=${select}&sort=${sort}`;
  };

  const {
    data: pendingMentorsData,
    error: pendingMentorsError,
    mutate: mutatePendingMentors,
  } = useSWR(
    canReadOrWriteUser &&
      getURL(
        query,
        paginate.pageSize,
        paginate.pageSize * paginate.page,
        "pending"
      )
  );

  const {
    data: inReviewMentorsData,
    error: inReviewMentorsError,
    mutate: mutateInReviewMentors,
  } = useSWR(
    canReadOrWriteUser &&
      getURL(
        query,
        inReviewPaginate.pageSize,
        inReviewPaginate.pageSize * inReviewPaginate.page,
        "in-review"
      )
  );

  const { data: totalMentorsData, mutate: mutateTotalMentors } = useSWR(
    canReadOrWriteUser && "mentor/count/"
  );

  const { data: totalDesignersData } = useSWR(
    canReadOrWriteUser && "member/count/"
  );

  const { data: totalReviewsData } = useSWR(
    canReadOrWriteReview && "review/?limit=1&offset=0"
  );

  const { data: totalPendingData, mutate: mutateTotalPending } = useSWR(
    canReadOrWriteUser && "mentor/list/tenet/?limit=1&offset=0&select=pending"
  );

  function onMentorUpdated(info) {
    const mentor = info.data;
    if (info.type === "approved" || "rejected") {
      if (info.type === "approved") mutateTotalMentors(totalMentors + 1, false);

      let pm = pendingMentorsData?.results.filter((el) => el.id !== mentor.id);
      let c = pendingMentorsData.count - 1;

      mutatePendingMentors(
        { ...pendingMentorsData, count: c, results: pm },
        false
      );
      mutateTotalPending(
        { ...totalPendingData, count: totalPendingData?.count - 1 },
        false
      );
    } else {
      mutatePendingMentors();
      mutateTotalPending();
    }
  }

  useEffect(() => {}, [
    query,
    mentors,
    pendingMentors,
    state.pendingMentors,
    totalPendingMentors,
    designers,
    state.canFetch,
  ]);

  const onConfirmMarkMentorAsInReview = (reason) => {
    setConfirmMarkMentorAsInReviewModalState({
      ...confirmMarkMentorAsInReviewModalState,
      processing: true,
      error: null,
    });

    markMentorAsInReview(currentUser.token.actual, {
      pk: state.currentMentor.id,
      reason: reason,
    }).then((response) => {
      if (response.success) {
        setConfirmMarkMentorAsInReviewModalState({
          ...confirmMarkMentorAsInReviewModalState,
          processing: false,
          success: "Mentor successfully marked as in review",
        });

        setState({
          ...state,
          currentMentor: response.data,
        });

        mutateInReviewMentors();
        mutatePendingMentors();

        return;
      }

      setConfirmMarkMentorAsInReviewModalState({
        ...confirmMarkMentorAsInReviewModalState,
        processing: false,
        error: response.message,
      });
    });
  };

  const onClearPhoneNumber = () => {
    setConfirmModalState({
      ...confirmModalState,
      processing: true,
      error: null,
    });

    resetUserPhoneNumber(
      currentUser.token.actual,
      state.currentMentor.identity_id
    ).then((response) => {
      if (response.success) {
        setConfirmModalState({
          ...confirmModalState,
          processing: false,
          success: "Phone number reset successful",
        });

        setState({
          ...state,
          phoneNumberCleared: true,
        });

        return;
      }

      setConfirmModalState({
        ...confirmModalState,
        processing: false,
        error: response.message,
      });
    });
  };

  return (
    <div>
      <MentorSideModal
        mentor={state.currentMentor}
        token={currentUser.token.actual}
        show={state.showMentorModal}
        phoneNumberCleared={state.phoneNumberCleared}
        onHide={() => {
          setState({ ...state, showMentorModal: false, currentMentor: null });
        }}
        onUpdated={onMentorUpdated}
        onMarkInReview={() => {
          setState({ ...state, showMentorModal: false });
          setConfirmMarkMentorAsInReviewModalState({
            ...confirmMarkMentorAsInReviewModalState,
            show: true,
          });
        }}
        onClearPhoneNumber={(phoneNumberWithCountryCode, mentorName) => {
          setState({ ...state, showMentorModal: false });
          setConfirmModalState({
            ...confirmModalState,
            show: true,
            description: `Please confirm clearing of phone number ${phoneNumberWithCountryCode} for ${mentorName}. User will no longer be able to book sessions until they add a new verified phone number.`,
          });
        }}
      />

      <ConfirmModal
        show={confirmModalState.show}
        processing={confirmModalState.processing}
        title={confirmModalState.title}
        description={confirmModalState.description}
        error={confirmModalState.error}
        success={confirmModalState.success}
        onHide={() => {
          setConfirmModalState({
            ...confirmModalState,
            show: false,
            success: false,
            error: null,
            processing: false,
          });
          setState({ ...state, showMentorModal: true });
        }}
        onConfirm={() => onClearPhoneNumber()}
      />

      <ConfirmMarkMentorAsInReviewModal
        show={confirmMarkMentorAsInReviewModalState.show}
        error={confirmMarkMentorAsInReviewModalState.error}
        processing={confirmMarkMentorAsInReviewModalState.processing}
        mentor={state.currentMentor}
        success={confirmMarkMentorAsInReviewModalState.success}
        onHide={() => {
          setConfirmMarkMentorAsInReviewModalState({
            ...confirmMarkMentorAsInReviewModalState,
            show: false,
            success: false,
            error: null,
            processing: false,
          });
          setState({ ...state, showMentorModal: true });
        }}
        onConfirm={(reason) => onConfirmMarkMentorAsInReview(reason)}
      />

      <PageLayout activeLink="dashboard">
        <div className="text-grey mb-30">Our key metrics</div>
        <Row>
          {canReadOrWriteUser && (
            <Col md={3} xs={6}>
              <SummaryCard
                description="Mentors"
                value={totalMentorsData || 0}
              />
            </Col>
          )}
          {canReadOrWriteUser && (
            <Col md={3} xs={6}>
              <SummaryCard
                iconType="account"
                description="Members"
                value={totalDesignersData || 0}
              />
            </Col>
          )}
          {CanReadOrWrite("review", currentUser) && (
            <Col md={3} xs={6}>
              <SummaryCard
                description="Reviews"
                value={totalReviewsData?.count || 0}
              />
            </Col>
          )}
          {CanReadOrWrite("booking", currentUser) && (
            <Col md={3} xs={6}>
              <SummaryCard
                description="Bookings"
                iconType="account"
                value={totalBookingsData || 0}
              />
            </Col>
          )}
        </Row>
        <div hidden={!canReadOrWriteUser}>
          <div className="mt-40">
            <span className="text-grey mb-30 text-20">Mentor Applications</span>
          </div>

          <Row className="mt-40">
            <Col md={7}>
              <div className="d-flex flex-wrap mb-20">
                <Button
                  classes={`pill success ${activeTab === 0} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(0);
                  }}>
                  Pending (
                  {pendingMentorsData ? pendingMentorsData["count"] : 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab === 1} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(1);
                  }}>
                  In Review (
                  {inReviewMentorsData ? inReviewMentorsData["count"] : 0})
                </Button>
              </div>
            </Col>
            <Col md={5}>
              <InputField
                placeholder="Search"
                classes="small"
                isSearch
                value={query}
                onChange={(ev) => {
                  setQuery(ev.target.value);
                }}
              />
            </Col>
            <Col>
              <div className="d-flex mb-15">
                <ButtonDropdown
                  title={
                    <div className="d-flex align-center">
                      <FilterIcon /> &nbsp;&nbsp;
                      <span>{state.sort.text || "Filter by all"}</span>
                    </div>
                  }>
                  <MenuItem
                    active={state?.sort.value === ""}
                    onClick={() => {
                      setState({
                        ...state,
                        sort: {
                          text: "Filter by all",
                          value: "",
                        },
                      });
                    }}>
                    Filter by all
                  </MenuItem>
                  <MenuItem
                    active={state?.sort.value === "name"}
                    onClick={() => {
                      setState({
                        ...state,
                        sort: {
                          text: "Alphabetical order",
                          value: "name",
                        },
                      });
                    }}>
                    Alphabetical order
                  </MenuItem>
                </ButtonDropdown>
              </div>
            </Col>
          </Row>
          {/* )} */}

          {activeTab === 0 && (
            <>
              <LoaderPane
                loading={!pendingMentorsData && !pendingMentorsError}
                error={pendingMentorsError?.message}
                noRecords={
                  !pendingMentorsError &&
                  pendingMentorsData &&
                  pendingMentorsData["count"] === 0
                }
                onReload={() => {
                  mutatePendingMentors();
                }}
              />

              {pendingMentorsData &&
                !pendingMentorsError &&
                pendingMentorsData["count"] > 0 && (
                  <div>
                    <Table bordered responsive className="table-white">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Application Date</th>
                          <th>Job Title</th>
                          <th>Company</th>
                          <th>LinkedIn</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pendingMentorsData?.results.map((mentor) => (
                          <tr key={mentor.id}>
                            <td>
                              <div className="d-flex">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={mentor.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {mentor.name}{" "}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="text-16 text-grey-light font-500">
                                {new Date(mentor?.date_joined).toDateString()}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="text-16 text-grey-light font-500">
                                {mentor.title}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="text-16 text-grey-light font-500 pointer">
                                {mentor.employer}
                              </span>
                            </td>
                            <td>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={sanitizeLinkedIn(mentor?.linkedin_url)}>
                                <div className="text-success text-16 font-500 pointer">
                                  LinkedIn
                                </div>
                              </a>
                            </td>
                            <td>
                              <span
                                className="text-16 text-success font-500 pointer"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    currentMentor: mentor,
                                    showMentorModal: true,
                                    phoneNumberCleared: false,
                                  });
                                }}>
                                View
                              </span>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={6}>
                            <PaginationBar
                              page={paginate.page}
                              pageSize={paginate.pageSize}
                              totalItems={pendingMentorsData["count"]}
                              onChangePage={(newPage) => {
                                setPaginate({ ...paginate, page: newPage });
                              }}
                              onChangeRowsPerPage={(size) => {
                                setPaginate({
                                  ...paginate,
                                  pageSize: size,
                                  page: 0,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
            </>
          )}

          {activeTab === 1 && (
            <>
              <LoaderPane
                loading={!inReviewMentorsData && !inReviewMentorsError}
                error={inReviewMentorsError?.message}
                noRecords={
                  !inReviewMentorsError &&
                  inReviewMentorsData &&
                  inReviewMentorsData["count"] === 0
                }
                onReload={() => {
                  mutateInReviewMentors();
                }}
              />

              {inReviewMentorsData &&
                !inReviewMentorsError &&
                inReviewMentorsData["count"] > 0 && (
                  <div>
                    <Table bordered responsive className="table-white">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Application Date</th>
                          <th>Job Title</th>
                          <th>Company</th>
                          <th>In Review Date</th>
                          <th>LinkedIn</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {inReviewMentorsData?.results.map((mentor) => (
                          <tr key={mentor.id}>
                            <td>
                              <div className="d-flex">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={mentor.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {mentor.name}{" "}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="text-16 text-grey-light font-500">
                                {new Date(mentor?.date_joined).toDateString()}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="text-16 text-grey-light font-500">
                                {mentor.title}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="text-16 text-grey-light font-500 pointer">
                                {mentor.employer}
                              </span>
                            </td>
                            <td>
                              <span className="text-16 text-grey-light font-500">
                                {new Date(
                                  mentor?.in_review_marked_at
                                ).toDateString()}{" "}
                              </span>
                            </td>
                            <td>
                              <a
                                href={sanitizeLinkedIn(mentor?.linkedin_url)}
                                target="_blank"
                                rel="noreferrer">
                                <div className="text-success text-16 font-500 pointer">
                                  LinkedIn
                                </div>
                              </a>
                            </td>
                            <td>
                              <span
                                className="text-16 text-success font-500 pointer"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    currentMentor: mentor,
                                    showMentorModal: true,
                                    phoneNumberCleared: false,
                                  });
                                }}>
                                View
                              </span>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={7}>
                            <PaginationBar
                              page={inReviewPaginate.page}
                              pageSize={inReviewPaginate.pageSize}
                              totalItems={inReviewMentorsData["count"]}
                              onChangePage={(newPage) => {
                                setInReviewPaginate({
                                  ...inReviewPaginate,
                                  page: newPage,
                                });
                              }}
                              onChangeRowsPerPage={(size) => {
                                setInReviewPaginate({
                                  ...inReviewPaginate,
                                  pageSize: size,
                                  page: 0,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
            </>
          )}
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(DashboardPage);
