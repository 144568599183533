import { postData, putData } from "../api";

export const changePassword = async (authToken, data) => {
  const response = await postData("account/change-password/", data, authToken);

  if (response.success) {
    return {
      success: response.data.success,
      message: response.data.message,
    };
  }

  return response;
};

export const resetUserPassword = async (authToken, data) => {
  const response = await postData(
    "account/reset-user-password/",
    data,
    authToken
  );

  if (response.success) {
    return {
      success: response.data.success,
      message: response.data.message,
    };
  }

  return response;
};

export const approveOrRejectMentor = async (authToken, data) => {
  return await putData(`mentor/${data.pk}/${data.status}/`, null, authToken);
};

export const markMentorAsInReview = async (authToken, data) => {
  return await putData(
    `mentor/${data.pk}/mark-in-review/`,
    { reason: data.reason },
    authToken
  );
};
