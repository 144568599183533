import React, { useState, useGlobal } from "reactn";
import PageLayout from "../../../components/page-layout/page-layout.component";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import {
  LineChart,
  Line,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import ExportButton from "../../../components/export-button/export-button.component";
import DateRangeInput from "../../../components/form/date-range/date-range.component";
import { getAnalytics } from "../../../api/services/analytics.service";
import {
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

const ReviewsAnalytics = () => {
  const [currentUser] = useGlobal("currentUser");
  const [ReviewsGraphData, setReviewsGraphData] = useGlobal("ReviewsGraphData");

  const [state, setState] = useState({
    processing: false,
    error: null,
    success: null,
  });

  const [graphData, setGraphData] = useState(ReviewsGraphData);

  const [weekOrMonth, setWeekOrMonth] = useState("month");
  const [range, setRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);

  const [exportFile, setExportFIle] = useState({
    exporting: false,
    error: null,
    url: `review/analytics/export/?start_date=${range[0].startDate
      .toISOString()
      .slice(0, 10)}&end_date=${range[0].endDate.toISOString().slice(0, 10)}`,
  });

  return (
    <div>
      <PageLayout activeLink="reviews-analytics" parentMenu="analytics">
        <div className="text-grey text-20 font-bold mb-30">
          Reviews Analytics
        </div>
        <Row>
          <Col md={6} className="d-flex flex-end">
            <span
              className={`${
                weekOrMonth === "week"
                  ? "text-grey font-bold"
                  : "text-grey-light"
              } mt-20 pointer mr-2`}
              onClick={() => {
                setWeekOrMonth("week");
                setRange([
                  {
                    startDate: startOfWeek(new Date()),
                    endDate: endOfWeek(new Date()),
                    key: "selection",
                  },
                ]);
              }}
            >
              This week
            </span>
            <span
              className={`${
                weekOrMonth === "month"
                  ? "text-grey font-bold"
                  : "text-grey-light"
              } mt-20 pointer`}
              onClick={() => {
                setWeekOrMonth("month");
                setRange([
                  {
                    startDate: startOfMonth(new Date()),
                    endDate: endOfMonth(new Date()),
                    key: "selection",
                  },
                ]);
              }}
            >
              This Month
            </span>
          </Col>
          <Col md={4} className="px-0">
            <DateRangeInput
              placeholder="Custom range"
              ranges={range}
              onDateChange={(data) => {
                setRange(data);
              }}
            />
          </Col>
          <Col md={2}>
            <Button
              classes="secondary pl-2 pr-2 w-100"
              disabled={state.processing}
              loading={state.processing}
              onClick={() => {
                setState({ ...state, processing: true, error: null });
                let start_date = range[0].startDate.toISOString().slice(0, 10);
                let end_date = range[0].endDate.toISOString().slice(0, 10);

                getAnalytics(
                  currentUser.token.actual,
                  `review/analytics/?start_date=${start_date}&end_date=${end_date}`
                ).then((response) => {
                  if (response.success) {
                    setState({ ...state, processing: false, error: null });
                    setGraphData(response.data);
                    setReviewsGraphData(response.data);
                    return;
                  }
                  setState({
                    ...state,
                    processing: false,
                    error: response.message,
                  });
                });
              }}
            >
              Apply
            </Button>
          </Col>
          <Col className="d-flex flex-end">
            {state.error && (
              <div className="w-500 text-danger"> {state.error}</div>
            )}
          </Col>
        </Row>

        <div className="card pr-3 mt-20">
          <Row className="mt-20">
            <Col md={3}></Col>

            <Col md={6} />

            <Col md={3}>
              <div className="d-flex flex-end">
                {graphData?.data && (
                  <ExportButton
                    label="Export"
                    url={exportFile.url}
                    fileName="reviews-analytics"
                  />
                )}
              </div>
            </Col>
          </Row>

          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={800}
              height={350}
              data={graphData?.data}
              margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="XAxis" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(ReviewsAnalytics);
