import { useState, useGlobal, useEffect } from "reactn";
import SideModal from "../side-modal/side-modal.component";
import { Modal } from "react-bootstrap";
import { postData, putData, mentorUrl, designerUrl } from "../../../api/api";

const ReviewModal = ({
  show,
  onHide,
  review = null,
  token,
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    description: "",
    processing: false,
    error: null,
    review: review,
    reviewedBy: null,
  });

  useEffect(() => {
    if (review !== null) {
      const { reviewed_by } = review;

      const identity_type = reviewed_by.identity_type;
      setState({
        ...state,
        review: review,
        reviewedBy: reviewed_by,
      });
    }
  }, [state.review, review]);

  return (
    <>
      {review && state.review && (
        <SideModal show={show} onHide={onHide} onBlurHide={true}>
          <Modal.Body className="scrollbar">
            <div className="modal-inner">
              <div className="content-medium">
                <div>
                  <div className="text-grey-light text-12 font-400 mb-15">
                    {" "}
                    {state.review.date_reviewed.split("T")[0]}
                  </div>

                  <div className="text-grey text-16 font-400 mb-30">
                    {" "}
                    {state.review.review}
                  </div>
                  <div className="line mb-1"></div>
                  <div className="text-grey-light text-12 font-400 mb-10">
                    Ratings
                  </div>
                  <div className="text-grey-light text-16 font-400 mb-15">
                    {state.review.rating}
                  </div>

                  <div className="text-grey-light text-12 font-400 mb-15">
                    {state.review.reviewed_by.identity_type === "Mentor"
                      ? "Mentor"
                      : "Mentee"}
                  </div>
                  <a
                    href={
                      state.review.reviewed_by.identity_type === "Mentor"
                        ? mentorUrl(state.reviewedBy.slug)
                        : designerUrl(state.reviewedBy.slug)
                    }
                    target="_blank"
                  >
                    <div className="d-flex">
                      <div className="pic-small mr-3">
                        <img
                          className="img-f-w-obj-cover"
                          src={state.reviewedBy.profile_photo_url}
                          alt=""
                        />
                      </div>
                      <span className="text-16 text-grey font-500">
                        {state.reviewedBy.name_string}{" "}
                      </span>
                    </div>
                  </a>

                  <div className="text-grey-light text-12 font-400 mb-15 mt-20">
                    Mentor
                  </div>
                  <a href={mentorUrl(state.review.mentor.slug)} target="_blank">
                    <div className="d-flex pointer">
                      <div className="pic-small mr-3">
                        <img
                          className="img-f-w-obj-cover"
                          src={state.review.mentor.profile_photo_url}
                          alt=""
                        />
                      </div>
                      <span className="text-16 text-grey font-500">
                        {state.review.mentor.name}{" "}
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </Modal.Body>
        </SideModal>
      )}
    </>
  );
};

export default ReviewModal;
