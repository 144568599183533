import { useState, useGlobal } from "reactn";
import useSWR from "swr";

import FeatureReleaseModal from "components/modal/feature-release-modal/feature-release-modal.component";
import PageLayout from "components/page-layout/page-layout.component";
import FeaturesTable from "./components/Table";
import HaveAccess from "access-control.jsx";
import Header from "./components/Header";

const FeatureReleasePage = (props) => {
  /**
   * config
   */
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const canWrite = HaveAccess("featureRelease", "Write", currentUser);

  /**
   * states
   */
  const [searchActive, setSearchActive] = useState("");
  const [searchInactive, setSearchInactive] = useState("");
  const [activeTab, setActiveTab] = useState("active");
  const [sideModal, setSideModal] = useState({
    show: false,
    type: "new",
    data: null,
  });
  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });
  const [paginateInactiveData, setPaginateInactiveData] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  /**
   *
   * @param {String} q
   * @param {Number} limit
   * @param {Number} offset
   * @param {String} active
   * @returns String
   */
  //active,
  const getURL = (
    q = "",
    limit = paginate.pageSize,
    offset = paginate.page * paginate.pageSize,
    active = "true"
  ) => {
    return `${
      process.env.REACT_APP_BASE_API
    }in-product-release-announcement/?limit=${limit}&offset=${offset}&active=${active}${
      q ? "&q=" + q : ""
    }`;
  };

  const getCountURL = () => {
    return `${process.env.REACT_APP_BASE_API}in-product-release-announcement/`;
  };

  const { data: count, mutate: mutateCount } = useSWR(getCountURL());

  const inactiveCount = count?.filter((e) => e.active === false)?.length;
  const activeCount = count?.filter((e) => e.active)?.length;

  const {
    data: activeReleaseData,
    error: activeReleaseError,
    mutate: mutateActiveRelease,
  } = useSWR(
    activeTab === "active" &&
      getURL(
        searchActive,
        paginate.pageSize,
        paginate.pageSize * paginate.page,
        "true"
      )
  );

  const {
    data: inactiveReleaseData,
    error: inactiveReleaseError,
    mutate: mutateInactiveRelease,
  } = useSWR(
    activeTab === "inactive" &&
      getURL(
        searchInactive,
        paginateInactiveData.pageSize,
        paginateInactiveData.pageSize * paginateInactiveData.page,
        "false"
      )
  );

  return (
    <>
      <FeatureReleaseModal
        mutateCount={mutateCount}
        mutateActive={mutateActiveRelease}
        mutateInactive={mutateInactiveRelease}
        show={sideModal?.show}
        type={sideModal?.type}
        featureRelease={sideModal?.data}
        onHide={() => setSideModal({ ...sideModal, show: false })}
      />

      <PageLayout activeLink="feature-release" parentMenu="activities">
        <div>
          <div className="text-grey text-20 font-bold mb-20"></div>
          <div className="d-flex space-between  mb-20">
            <div className="text-grey text-20 font-bold">Feature Releases</div>
          </div>

          <Header
            {...{
              count: {
                active: activeCount,
                inactive: inactiveCount,
              },
              search: {
                active: searchActive,
                inactive: searchInactive,
              },
              activeTab,
              setActiveTab,
              setSideModal,
              setSearchActive,
              setSearchInactive,
              canWrite,
            }}
          />

          {activeTab === "active" && (
            <FeaturesTable
              {...{
                setSideModal,
                data: activeReleaseData,
                error: activeReleaseError,
                mutate: mutateActiveRelease,
                paginate,
                setPaginate,
                canWrite,
              }}
            />
          )}

          {activeTab === "inactive" && (
            <FeaturesTable
              {...{
                setSideModal,
                data: inactiveReleaseData,
                error: inactiveReleaseError,
                mutate: mutateInactiveRelease,
                paginate: paginateInactiveData,
                setPaginate: setPaginateInactiveData,
                canWrite,
              }}
            />
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default FeatureReleasePage;
