import Select from "components/form/select/select.component";
import InputFIeld from "components/form/input-field/input-field.component";
import SideModal from "components/modal/side-modal/side-modal.component";
import { useState, useEffect, useGlobal } from "reactn";
import ToggleButton from "components/form/toggle-button/toggle-button.component";
import Button from "components/button/button.component";
import { createSkill, updateSkill } from "api/services/skill.service";
import { Modal } from "react-bootstrap";

const SkillModal = ({ show, skill, motivationList, onHide }) => {
  const [currentUser] = useGlobal("currentUser");

  const [name, setName] = useState("");
  const [skillType, setSkillType] = useState("");
  const [motivation, setMotivation] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (skill) {
      setName(skill.name);
      setSkillType(skill.skill_type);
      setMotivation(skill.motivation);
      setDisabled(skill.disabled);
    } else {
      setName("");
      setSkillType("");
      setMotivation(null);
      setDisabled(false);
    }

    setLoading(false);
    setError(null);
  }, [skill]);

  const onPublishSkill = async () => {
    setError(null);
    setLoading(true);

    let data = {
      name: name,
      skill_type: skillType,
      disabled: disabled,
    };

    if (motivation) data["motivation_id"] = motivation.id;

    if (skill) {
      // update
      const reponse = await updateSkill({
        authToken: currentUser.token.actual,
        id: skill.id,
        data: data,
      });

      setLoading(false);

      if (reponse.success) {
        onHide(true);

        return;
      }

      setError(reponse.message);
    } else {
      // create new one
      const reponse = await createSkill({
        authToken: currentUser.token.actual,
        data: data,
      });

      setLoading(false);

      if (reponse.success) {
        onHide(true);

        return;
      }

      setError(reponse.message);
    }
  };

  return (
    <SideModal
      show={show}
      onHide={() => {
        // reset();
        onHide();
      }}
      onBlurHide={false}>
      <Modal.Body className="scrollbar">
        <div className="modal-inner">
          <div className="content-medium">
            <div className="text-dark text-20 font-bold mb-30">
              {skill ? "Edit " : "Add new "} skill
            </div>

            <div className="mb-20">
              <InputFIeld
                label="Name"
                placeholder={"eg. Negotiating salary"}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mb-20">
              <Select
                label="Type of skill"
                placeholder="Select skill type"
                value={[
                  {
                    label: skillType || "Select skill type",
                    value: skillType,
                  },
                ]}
                options={[
                  { label: "Soft", value: "Soft" },
                  { label: "Applied", value: "Applied" },
                ]}
                onChange={(selected) => {
                  setSkillType(selected.value);
                }}
              />
            </div>

            <div className="mb-20">
              <Select
                label="Motivation (Optional)"
                placeholder="Select motivation"
                value={[
                  {
                    label: motivation
                      ? motivation.description
                      : "Select motivation",
                    value: motivation ? motivation.id : "",
                  },
                ]}
                options={
                  motivationList
                    ? [
                        { label: "Select motivation", value: "" },
                        ...motivationList
                          .filter((m) => !m.disabled)
                          .map((motivationItem) => {
                            return {
                              label: motivationItem.description,
                              value: motivationItem.id,
                            };
                          }),
                      ]
                    : []
                }
                onChange={(selected) => {
                  setMotivation(
                    motivationList.find((item) => item.id === selected.value)
                  );
                }}
              />
              <p className="text-grey-dark text-14 mt-10">
                When you select a motivation for a skill, a topic pack is
                automatically created for it. This allows mentors and mentees to
                be able to select this skill as a topic for mentorship sessions.
                {skill && (
                  <span className="text-danger">
                    <br /> <br />
                    NB: Removing an already set motivation will automatically
                    delete any topic pack created for this skill.
                  </span>
                )}
              </p>
            </div>

            <div>
              <ToggleButton
                label="Disabled"
                checked={disabled}
                // blocked={state.processing}
                onChange={(value) => {
                  setDisabled(value);
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className="modal-footer-inner"
          style={{ paddingBottom: `${window.bottomSpace}` }}>
          {error && !loading && (
            <div className="text-danger text-16 mb-10">{error}</div>
          )}

          <div className="d-flex">
            <Button
              classes="small secondary mr-2"
              loading={loading}
              disabled={name.length === 0 || skillType.length === 0 || loading}
              onClick={onPublishSkill}>
              Publish
            </Button>
            <Button
              classes="small info"
              disabled={loading}
              onClick={() => {
                // reset();
                onHide();
              }}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </SideModal>
  );
};

export default SkillModal;
