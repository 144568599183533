import Select from "components/form/select/select.component";
import InputFIeld from "components/form/input-field/input-field.component";
import SideModal from "components/modal/side-modal/side-modal.component";
import { useState, useEffect, useGlobal } from "reactn";
import ToggleButton from "components/form/toggle-button/toggle-button.component";
import Button from "components/button/button.component";
import {
  createSEO,
  updateSEO,
  createFAQ,
  updateFAQ,
} from "api/services/seo.service";
import { Modal } from "react-bootstrap";
import TextArea from "components/form/text-area/text-area.component";
import useSWR from "swr";

const SEOModal = ({ show, SEO, onHide }) => {
  const [currentUser] = useGlobal("currentUser");

  const [_SEO, _SetSEO] = useState(null);
  const [name, setName] = useState("");
  const [primaryType, setPrimaryType] = useState("");
  const [secondaryType, setSecondaryType] = useState("");
  const [addFAQs, setAddFAQs] = useState(false);
  const [primaryKeyword, setPrimaryKeyword] = useState("");
  const [secondaryKeyword, setSecondaryKeyword] = useState("");
  const [header, setHeader] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [countryIso, setCountryIso] = useState(null);
  const [expertiseId, setExpertiseId] = useState(null);
  const [disciplineId, setDisciplineId] = useState(null);

  //{ question: "", answer: "" }
  const [faqs, setFaqs] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const refresh = (seo) => {
    _SetSEO({ ...seo });
    setName(seo?.name);
    setPrimaryType(seo?.primary_keyword_type);
    setSecondaryType(seo?.secondary_keyword_type);
    setHeader(seo?.header_description);
    setDisabled(seo?.disabled);
    //set country
    if (seo?.country) {
      setCountryIso(seo?.country?.iso);
      if (seo?.primary_keyword_type == "Country") {
        setPrimaryKeyword(seo?.country?.name);
      } else if (seo?.secondary_keyword_type == "Country") {
        setSecondaryKeyword(seo?.country?.name);
      }
    }
    //set expertise
    if (seo?.expertise) {
      setExpertiseId(seo?.expertise.id);
      if (seo?.primary_keyword_type == "Expertise") {
        setPrimaryKeyword(seo?.expertise?.description);
      } else if (seo?.secondary_keyword_type == "Expertise") {
        setSecondaryKeyword(seo?.expertise?.description);
      }
    }
    if (seo?.discipline) {
      setDisciplineId(seo?.discipline.id);
      if (seo?.primary_keyword_type == "Discipline") {
        setPrimaryKeyword(seo?.expertise?.name);
      } else if (seo?.secondary_keyword_type == "Discipline") {
        setSecondaryKeyword(seo?.expertise?.name);
      }
    }
  };

  useEffect(() => {
    if (SEO) {
      refresh(SEO);
    } else {
      _SetSEO(null);
      setSecondaryKeyword(null);
      setName("");
      setPrimaryType("");
      setPrimaryKeyword("");
      setSecondaryType("");
      setSecondaryKeyword("");
      setHeader("");
      setDisabled(false);
      setCountryIso("");
      setExpertiseId("");
      setDisciplineId("");
      setFaqs([]);
      setAddFAQs(false);
    }

    setLoading(false);
    setError(null);
  }, [SEO, show]);

  useEffect(() => {
    if (!show) {
      setFaqs([]);
    }
  }, [show]);

  const {
    data: faqsData,
    error: faqsError,
    mutate: mutateFaqs,
  } = useSWR(_SEO?.id && `seo-page/faq/list/?seo_page_id=${_SEO?.id}`);

  useEffect(() => {
    if (faqsData && _SEO?.id) {
      setFaqs([...faqsData]);
      setAddFAQs(true);
    }
  }, [faqsData]);

  const onPublish = async () => {
    setError(null);
    setLoading(true);

    let data = {
      name: name,
      primary_keyword_type: primaryType,
      secondary_keyword_type: secondaryType,
      country_iso: countryIso,
      expertise_id: expertiseId,
      discipline_id: disciplineId,
      header_description: header,
      disabled: disabled,
    };

    const response = _SEO?.id
      ? await updateSEO({
          authToken: currentUser.token.actual,
          id: _SEO.id,
          data: data,
        })
      : await createSEO({
          authToken: currentUser.token.actual,
          data: data,
        });

    setLoading(false);

    if (response.success) {
      var toBeUpdateFaqs =
        faqs?.filter(
          (f) => !f?.id && f?.question?.length > 0 && f?.answer?.length > 0
        ) || [];
      if (toBeUpdateFaqs?.length > 0) {
        addMultiFAQs(response?.data?.id, toBeUpdateFaqs);
      }

      //refresh(response.data);
      onHide(true);

      return;
    }

    setError(response.message);
  };

  const onAddFAQs = async (index = 0) => {
    setError(null);
    //setLoading(true);
    let f = faqs;
    f.splice(index, 1, { ...faqs[index], processing: true });
    setFaqs([...f]);

    let data = {
      question: faqs[index]?.question,
      answer: faqs[index]?.answer,
    };

    const response = faqs[index]?.id
      ? await updateFAQ({
          authToken: currentUser.token.actual,
          id: faqs[index]?.id,
          data: data,
        })
      : await createFAQ({
          authToken: currentUser.token.actual,
          data: { ...data, seo_page_id: _SEO?.id },
        });

    if (response.success) {
      let fs = faqs;
      fs.splice(index, 1, { ...response?.data });
      setFaqs([...fs]);
      mutateFaqs();

      return;
    }

    setError(response.message);
  };

  const addMultiFAQs = async (seoID, allFaqs) => {
    for (let index = 0; index < allFaqs.length; index++) {
      var faq = allFaqs[index];

      let data = { question: faq?.question, answer: faq?.answer };
      faq?.id
        ? updateFAQ({
            authToken: currentUser.token.actual,
            id: faq?.id,
            data: data,
          })
        : createFAQ({
            authToken: currentUser.token.actual,
            data: { ...data, seo_page_id: seoID },
          });
    }
    setFaqs([]);
  };

  const { data: countryData, error: countryError } = useSWR("country/");

  const countryOptions = () => {
    return countryData?.map((c) => ({ label: c?.name, value: c?.name })) || [];
  };

  const { data: expertiseData, error: expertiseError } = useSWR("expertise/?");

  const expertiseOptions = () => {
    return (
      expertiseData.map((exp) => ({
        label: exp?.description,
        value: exp?.description,
      })) || []
    );
  };

  const { data: disciplineData, error: disciplineError } = useSWR(
    "discipline/?select=active"
  );

  const disciplineOptions = () => {
    return (
      disciplineData?.map((c) => ({ label: c?.name, value: c?.name })) || []
    );
  };

  const options = (k) => {
    switch (k) {
      case "Country":
        return countryOptions();
      case "Expertise":
        return expertiseOptions();
      case "Discipline":
        return disciplineOptions();
      default:
        return [];
    }
  };

  const keywordTypes = [
    { label: "Expertise", value: "Expertise" },
    { label: "Discipline", value: "Discipline" },
    { label: "Country", value: "Country" },
  ];

  const keywordTypeOptions = (exclude) => {
    return keywordTypes.filter((k) => k.value != exclude);
  };

  const setExtras = (keywordType, value) => {
    if (keywordType == "Country") {
      let iso = countryData?.find((c) => c.name == value)?.iso;
      setCountryIso(iso);
    } else if (keywordType == "Expertise") {
      let id = expertiseData?.find((exp) => exp.description == value)?.id;
      setExpertiseId(id);
    } else if (keywordType == "Discipline") {
      let id = disciplineData?.find((d) => d.name == value)?.id;
      setDisciplineId(id);
    }
  };

  return (
    <SideModal
      show={show}
      onHide={() => {
        // reset();
        onHide();
      }}
      onBlurHide={false}>
      <Modal.Body className="scrollbar">
        <div className="modal-inner">
          <div className="content-medium">
            <div className="text-dark text-20 font-bold mb-30">
              {_SEO ? "Edit " : "Add new "} SEO Page
            </div>

            <div className="mb-20">
              <InputFIeld
                label="Name"
                placeholder={"eg. Page name"}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mb-20">
              <Select
                label="Select primary keyword type"
                placeholder="Select an expertise, discipline or country"
                value={[
                  {
                    label:
                      primaryType ||
                      "Select an expertise, discipline or country",
                    value: primaryType,
                  },
                ]}
                options={[...keywordTypeOptions(secondaryType)]}
                onChange={(selected) => {
                  setPrimaryType(selected.value);
                  setPrimaryKeyword("");
                }}
              />
            </div>

            <div className="mb-20">
              <Select
                label={`Select ${primaryType}`}
                placeholder={`Select ${primaryType}`}
                value={[
                  {
                    label: primaryKeyword || `Select ${primaryType}`,
                    value: primaryKeyword,
                  },
                ]}
                options={[
                  { label: `Select`, value: "" },
                  ...options(primaryType),
                ]}
                onChange={(selected) => {
                  setPrimaryKeyword(selected.value);
                  setExtras(primaryType, selected.value);
                }}
              />
            </div>

            <div className="mb-20">
              <Select
                label="Select secondary keyword type"
                placeholder="Select an expertise, discipline or country"
                value={[
                  {
                    label:
                      secondaryType ||
                      "Select an expertise, discipline or country",
                    value: secondaryType,
                  },
                ]}
                options={[
                  { label: `Select`, value: "" },
                  ...keywordTypeOptions(primaryType),
                ]}
                onChange={(selected) => {
                  setSecondaryType(selected.value);
                  setSecondaryKeyword("");
                }}
              />
            </div>

            <div className="mb-20">
              <Select
                label={`Select ${secondaryType != null ? secondaryType : ""}`}
                placeholder="Select secondary"
                value={[
                  {
                    label:
                      secondaryKeyword ||
                      `Select  ${secondaryType != null ? secondaryType : ""}`,
                    value: secondaryKeyword,
                  },
                ]}
                options={[
                  {
                    label: `Select ${
                      secondaryType != null ? secondaryType : ""
                    }`,
                    value: "",
                  },
                  ...options(secondaryType),
                ]}
                onChange={(selected) => {
                  setSecondaryKeyword(selected.value);
                  setExtras(secondaryType, selected.value);
                }}
              />
            </div>
            <div className="mb-20">
              <TextArea
                value={header}
                label={"Header"}
                placeholder={"Enter header description"}
                onChange={(ev) => {
                  setHeader(ev.target.value);
                }}
              />
            </div>
            <div className="d-flex mb-4">
              <ToggleButton
                //label="Disable"
                checked={disabled}
                onChange={(value) => {
                  setDisabled(value);
                }}
              />
              <span className="pl-1">Disabled</span>
            </div>

            {
              <div hidden={faqsData?.length > 0}>
                <div className="d-flex">
                  <ToggleButton
                    // label="Add FAQs"
                    checked={addFAQs}
                    // blocked={state.processing}
                    onChange={(value) => {
                      setAddFAQs(value);
                      if (value && faqs?.length == 0) {
                        setFaqs([{ question: "", answer: "" }]);
                      }
                    }}
                  />
                  <span className="pl-1">Add FAQs</span>
                </div>
              </div>
            }
            {(addFAQs || faqsData?.length > 0) && (
              <div>
                {faqs?.map((faq, i) => {
                  return (
                    <div key={i}>
                      <div className="mb-20">
                        <InputFIeld
                          label={`Question ${i + 1}`}
                          placeholder={"Enter question"}
                          value={faq?.question}
                          onChange={(e) => {
                            let f = faqs;
                            f.splice(i, 1, {
                              ...faq,
                              question: e.target.value,
                            });
                            setFaqs([...f]);
                          }}
                        />
                      </div>

                      <div className="mb-20">
                        <TextArea
                          label={`Answer ${i + 1}`}
                          value={faq?.answer}
                          onChange={(e) => {
                            let f = faqs;
                            f.splice(i, 1, { ...faq, answer: e.target.value });
                            setFaqs([...f]);
                          }}
                        />
                      </div>
                      <div className="d-flex flex-end">
                        {!faq?.id && (
                          <Button
                            disabled={faq?.processing}
                            classes={"small info mb-5 mr-1"}
                            onClick={() => {
                              let f = faqs;
                              f.splice(i, 1);
                              setFaqs([...f]);
                            }}>
                            Remove
                          </Button>
                        )}
                        {faq?.id && (
                          <Button
                            loading={faq?.processing}
                            disabled={
                              faq.question?.length == 0 ||
                              faq.answer?.length == 0 ||
                              faq?.processing
                            }
                            onClick={() => {
                              onAddFAQs(i);
                            }}
                            classes={"small success mb-5"}>
                            Save
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
                <span
                  className="text-success pointer"
                  onClick={() => {
                    setFaqs([...faqs, { question: "", answer: "" }]);
                  }}>
                  Add more questions
                </span>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className="modal-footer-inner"
          style={{ paddingBottom: `${window.bottomSpace}` }}>
          {error && !loading && (
            <div className="text-danger text-16 mb-10">{error}</div>
          )}

          <div className="d-flex">
            <Button
              classes="small secondary mr-2"
              loading={loading}
              disabled={
                name?.length === 0 || primaryType?.length === 0 || loading
              }
              onClick={onPublish}>
              Publish SEO Page
            </Button>
            <Button
              classes="small info"
              disabled={loading}
              onClick={() => {
                // reset();
                onHide();
              }}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </SideModal>
  );
};

export default SEOModal;
