import { useState, useGlobal, useEffect } from "reactn";
import { Table, Row, Col } from "react-bootstrap";
import Button from "../../components/button/button.component";
import InputField from "../../components/form/input-field/input-field.component";
import MentorSideModal from "../../components/modal/mentor-side-modal/side-modal.component";
import { apiBaseUrl, mentorUrl } from "../../api/api";
import PaginationBar from "../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../components/page-layout/page-layout.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import ExportButton from "../../components/export-button/export-button.component";
import ButtonDropdown, {
  MenuItem,
} from "../../components/button-dropdown/button-dropdown";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import ConfirmDeactivateUserModal from "../../components/modal/confirm-deactivate-user-modal/confirm-deactivate-user-modal.component";
import {
  blockOrUnblockUser,
  resetUserPhoneNumber,
} from "api/services/account.service";
import ConfirmMarkMentorAsInReviewModal from "components/modal/confirm-mark-mentor-as-in-review-modal/confirm-mark-mentor-as-in-review-modal.component";
import { markMentorAsInReview } from "api/services/mentor.service";
import ConfirmModal from "components/modal/confirm-modal/confirm-modal.component";
import { sanitizeLinkedIn } from "utils/helpers";

const MentorPage = (props) => {
  const [currentUser] = useGlobal("currentUser");

  const [query, setQuery] = useState("");
  const [queryPending, setQueryPending] = useState("");
  const [queryInReview, setQueryInReview] = useState("");
  const [queryOnBreak, setQueryOnBreak] = useState("");
  const [queryBlocked, setQueryBlocked] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const [state, setState] = useState({
    activeTab: 0,
    showSideModal: false,
    currentMentor: null,
    fetching: false,
    canFetch: true,
    error: null,
    phoneNumberCleared: false,
    sort: { text: "", value: "" },
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [pendingPaginate, setPendingPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [inReviewPaginate, setInReviewPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [onBreakPaginate, setOnBreakPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [blockedPaginate, setBlockedPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [confirmModalState, setConfirmModalState] = useState({
    show: false,
    success: false,
    error: null,
    processing: false,
    title: "Clear phone number",
    description: "",
  });

  const [confirmDeactivateModalState, setConfirmDeactivateModalState] =
    useState({
      show: false,
      error: null,
      processing: false,
    });

  const [
    confirmMarkMentorAsInReviewModalState,
    setConfirmMarkMentorAsInReviewModalState,
  ] = useState({
    show: false,
    error: null,
    processing: false,
  });

  //active,
  const getURL = (q, limit, offset, select, sort = "") => {
    return `${apiBaseUrl}mentor/list/tenet/?q=${q}&limit=${limit}&offset=${offset}&select=${select}&sort=${sort}`;
  };

  const { data: totalApprovedData, mutate: mutateTotalApproved } = useSWR(
    getURL("", 1, 1, "approved")
  );

  const { data: totalPendingData, mutate: mutateTotalPending } = useSWR(
    getURL("", 1, 1, "pending")
  );

  const { data: totalInReviewData, mutate: mutateTotalInReview } = useSWR(
    getURL("", 1, 1, "in-review")
  );

  const { data: totalBlockedData, mutate: mutateTotalBlocked } = useSWR(
    getURL("", 1, 1, "blocked")
  );

  const { data: totalOnBreakData, mutate: mutateTotalOnBreak } = useSWR(
    getURL("", 1, 1, "on-break")
  );

  const [canfetchTab1, setCanFetchTab1] = useState(false);
  const [canfetchTab2, setCanFetchTab2] = useState(false);
  const [canfetchTab3, setCanFetchTab3] = useState(false);
  const [canfetchTab4, setCanFetchTab4] = useState(false);

  const {
    data: approvedMentorsData,
    error: approvedMentorsError,
    mutate: mutateApprovedMentors,
  } = useSWR(
    getURL(
      query,
      paginate.pageSize,
      paginate.pageSize * paginate.page,
      "approved",
      activeTab === 0 ? state.sort?.value : ""
    )
  );

  const {
    data: pendingMentorsData,
    error: pendingMentorsError,
    mutate: mutatePendingMentors,
  } = useSWR(
    canfetchTab1 &&
      getURL(
        queryPending,
        pendingPaginate.pageSize,
        pendingPaginate.pageSize * pendingPaginate.page,
        "pending",
        activeTab === 1 ? state.sort?.value : ""
      )
  );

  const {
    data: inReviewMentorsData,
    error: inReviewMentorsError,
    mutate: mutateInReviewMentors,
  } = useSWR(
    canfetchTab2 &&
      getURL(
        queryInReview,
        inReviewPaginate.pageSize,
        inReviewPaginate.pageSize * inReviewPaginate.page,
        "in-review",
        activeTab === 2 ? state.sort?.value : ""
      )
  );

  const {
    data: onBreakMentorsData,
    error: onBreakMentorsError,
    mutate: mutateOnBreakMentors,
  } = useSWR(
    canfetchTab3 &&
      getURL(
        queryOnBreak,
        onBreakPaginate.pageSize,
        onBreakPaginate.pageSize * onBreakPaginate.page,
        "on-break",
        activeTab === 3 ? state.sort?.value : ""
      )
  );

  const {
    data: blockedMentorsData,
    error: blockedMentorsError,
    mutate: mutateBlockedMentors,
  } = useSWR(
    canfetchTab4 &&
      getURL(
        queryBlocked,
        blockedPaginate.pageSize,
        blockedPaginate.pageSize * blockedPaginate.page,
        "blocked",
        activeTab === 4 ? state.sort?.value : ""
      )
  );

  useEffect(() => {});

  //approved or reject mentor --callback
  function onMentorUpdated(info) {
    const mentor = info?.data;
    let newData =
      activeTab === 0
        ? approvedMentorsData
        : activeTab === 1
        ? pendingMentorsData
        : activeTab === 2
        ? inReviewMentorsData
        : activeTab === 3
        ? onBreakMentorsData
        : blockedMentorsData;

    let totalCount =
      activeTab === 0
        ? totalApprovedData?.count
        : activeTab === 1
        ? totalPendingData?.count
        : activeTab === 2
        ? totalInReviewData
        : activeTab === 3
        ? totalOnBreakData?.count
        : totalBlockedData?.count;

    if (info.type === "deleted") {
      let r = newData?.results?.filter((el) => el.id !== mentor.id);
      let count = newData.count - 1;
      totalCount = totalCount - 1;
      newData = { ...newData, count: count, results: r };

      if (activeTab === 0) {
        mutateApprovedMentors(newData, false);
        mutateTotalApproved({ ...totalApprovedData, count: totalCount }, false);
      } else if (activeTab === 1) {
        //no delete for pending
        mutatePendingMentors(newData, false);
        mutateTotalPending({ ...totalPendingData, count: totalCount }, false);
      } else if (activeTab === 2) {
        mutateInReviewMentors(newData, false);
        mutateTotalInReview({ ...totalInReviewData, count: totalCount }, false);
      } else if (activeTab === 3) {
        mutateOnBreakMentors(newData, false);
        mutateTotalOnBreak({ ...totalOnBreakData, count: totalCount }, false);
      } else {
        mutateBlockedMentors(newData, false);
        mutateTotalBlocked({ ...totalBlockedData, count: totalCount }, false);
      }
    } else if (info.type === "updated") {
      let r = newData?.results?.filter((el) => el.id !== mentor.id);
      r.push(mentor);
      newData = { ...newData, results: r };
      activeTab === 0
        ? mutateApprovedMentors(newData, false)
        : activeTab === 1
        ? mutatePendingMentors(newData, false)
        : activeTab === 2
        ? mutateInReviewMentors(newData, false)
        : activeTab === 3
        ? mutateOnBreakMentors(newData, false)
        : mutateBlockedMentors(newData, false);
    } else if (info.type === "approved" || info.type === "rejected") {
      if (info.type === "approved") {
        mutateTotalApproved(
          { ...totalApprovedData, count: totalApprovedData?.count + 1 },
          false
        );
      }

      let pm = pendingMentorsData?.results.filter((el) => el.id !== mentor.id);
      let c = pendingMentorsData.count - 1;
      mutatePendingMentors(
        { ...pendingMentorsData, count: c, results: pm },
        false
      );

      let inReviewMentorsFiltered = inReviewMentorsData?.results.filter(
        (el) => el.id !== mentor.id
      );
      let inReviewCount = inReviewMentorsData.count - 1;
      mutateInReviewMentors(
        {
          ...inReviewMentorsData,
          count: inReviewCount,
          results: inReviewMentorsFiltered,
        },
        false
      );

      mutateTotalPending(
        { ...totalPendingData, count: totalPendingData?.count - 1 },
        false
      );

      mutateTotalInReview(
        { ...totalInReviewData, count: inReviewCount },
        false
      );
    } else if (info.type === "blocked" || info.type === "unblocked") {
      let r = newData?.results?.filter((el) => el.id !== mentor.id);
      let count =
        info.type === "blocked" ? newData?.count - 1 : newData?.count + 1;
      totalCount = info.type === "blocked" ? totalCount - 1 : totalCount + 1;

      newData = { ...newData, count: count, results: r };

      let tc =
        info.type === "blocked"
          ? totalBlockedData?.count + 1
          : totalBlockedData?.count - 1;
      mutateTotalBlocked({ ...totalBlockedData, count: tc }, false);

      if (activeTab === 0) {
        mutateApprovedMentors(newData, false);
        mutateTotalApproved({ ...totalApprovedData, count: totalCount }, false);
      } else if (activeTab === 1) {
        mutatePendingMentors(newData, false);
        mutateTotalPending({ ...totalPendingData, count: totalCount }, false);
      } else if (activeTab === 2) {
        mutateInReviewMentors(newData, false);
        mutateTotalInReview({ ...totalInReviewData, count: totalCount }, false);
      } else if (activeTab === 3) {
        mutateOnBreakMentors(newData, false);
        mutateTotalOnBreak({ ...totalOnBreakData, count: totalCount }, false);
      } else {
        mutateBlockedMentors(newData, false);
        let totalApproved =
          info.type === "blocked"
            ? totalApprovedData?.count - 1
            : totalApprovedData?.count + 1;
        mutateTotalApproved(
          { ...totalApprovedData, count: totalApproved },
          false
        );
      }
    }
  }

  const onConfirmDeactivateUser = (reason) => {
    setConfirmDeactivateModalState({
      ...confirmDeactivateModalState,
      processing: true,
      error: null,
    });

    blockOrUnblockUser(currentUser.token.actual, {
      pk: state.currentMentor.identity_id,
      status: false,
      reason: reason ?? null,
    }).then((response) => {
      if (response.success) {
        setConfirmDeactivateModalState({
          ...confirmDeactivateModalState,
          processing: false,
          success: "Mentor deactivated successfully",
        });

        // update mentor data
        setState({
          ...state,
          currentMentor: {
            ...response.data.mentor,
            last_deactivated_info: {
              last_deactivated_at: response.data.last_deactivated_at,
              last_deactivated_by: response.data.last_deactivated_by,
              last_deactivated_reason: response.data.last_deactivated_reason,
            },
          },
        });

        onMentorUpdated({
          type: "blocked",
          data: response.data.mentor,
        });

        return;
      }

      setConfirmDeactivateModalState({
        ...confirmDeactivateModalState,
        processing: false,
        error: response.message,
      });
    });
  };

  const onConfirmMarkMentorAsInReview = (reason) => {
    setConfirmMarkMentorAsInReviewModalState({
      ...confirmMarkMentorAsInReviewModalState,
      processing: true,
      error: null,
    });

    markMentorAsInReview(currentUser.token.actual, {
      pk: state.currentMentor.id,
      reason: reason,
    }).then((response) => {
      if (response.success) {
        setConfirmMarkMentorAsInReviewModalState({
          ...confirmMarkMentorAsInReviewModalState,
          processing: false,
          success: "Mentor successfully marked as in review",
        });

        setState({
          ...state,
          currentMentor: response.data,
        });

        mutateInReviewMentors();
        mutatePendingMentors();

        return;
      }

      setConfirmMarkMentorAsInReviewModalState({
        ...confirmMarkMentorAsInReviewModalState,
        processing: false,
        error: response.message,
      });
    });
  };

  const onClearPhoneNumber = () => {
    setConfirmModalState({
      ...confirmModalState,
      processing: true,
      error: null,
    });

    resetUserPhoneNumber(
      currentUser.token.actual,
      state.currentMentor.identity_id
    ).then((response) => {
      if (response.success) {
        setConfirmModalState({
          ...confirmModalState,
          processing: false,
          success: "Phone number reset successful",
        });

        setState({
          ...state,
          phoneNumberCleared: true,
        });

        return;
      }

      setConfirmModalState({
        ...confirmModalState,
        processing: false,
        error: response.message,
      });
    });
  };

  return (
    <>
      <MentorSideModal
        mentor={state.currentMentor}
        token={currentUser.token.actual}
        show={state.showSideModal}
        phoneNumberCleared={state.phoneNumberCleared}
        onHide={() => {
          setState({ ...state, showSideModal: false, currentMentor: null });
        }}
        onUpdated={onMentorUpdated}
        onDeactivateUser={() => {
          setState({ ...state, showSideModal: false });
          setConfirmDeactivateModalState({
            ...confirmDeactivateModalState,
            show: true,
          });
        }}
        onMarkInReview={() => {
          setState({ ...state, showSideModal: false });
          setConfirmMarkMentorAsInReviewModalState({
            ...confirmMarkMentorAsInReviewModalState,
            show: true,
          });
        }}
        onClearPhoneNumber={(phoneNumberWithCountryCode, mentorName) => {
          setState({ ...state, showSideModal: false });
          setConfirmModalState({
            ...confirmModalState,
            show: true,
            description: `Please confirm clearing of phone number ${phoneNumberWithCountryCode} for ${mentorName}. User will no longer be able to book sessions until they add a new verified phone number.`,
          });
        }}
      />

      <ConfirmModal
        show={confirmModalState.show}
        processing={confirmModalState.processing}
        title={confirmModalState.title}
        description={confirmModalState.description}
        error={confirmModalState.error}
        success={confirmModalState.success}
        onHide={() => {
          setConfirmModalState({
            ...confirmModalState,
            show: false,
            success: false,
            error: null,
            processing: false,
          });
          setState({ ...state, showSideModal: true });
        }}
        onConfirm={() => onClearPhoneNumber()}
      />

      <ConfirmDeactivateUserModal
        show={confirmDeactivateModalState.show}
        error={confirmDeactivateModalState.error}
        processing={confirmDeactivateModalState.processing}
        userToDeactivateName={state.currentMentor?.name}
        success={confirmDeactivateModalState.success}
        onHide={() => {
          setConfirmDeactivateModalState({
            ...confirmDeactivateModalState,
            show: false,
            success: false,
            error: null,
            processing: false,
          });
          setState({ ...state, showSideModal: true });
        }}
        onConfirm={onConfirmDeactivateUser}
      />

      <ConfirmMarkMentorAsInReviewModal
        show={confirmMarkMentorAsInReviewModalState.show}
        error={confirmMarkMentorAsInReviewModalState.error}
        processing={confirmMarkMentorAsInReviewModalState.processing}
        mentor={state.currentMentor}
        success={confirmMarkMentorAsInReviewModalState.success}
        onHide={() => {
          setConfirmMarkMentorAsInReviewModalState({
            ...confirmMarkMentorAsInReviewModalState,
            show: false,
            success: false,
            error: null,
            processing: false,
          });
          setState({ ...state, showSideModal: true });
        }}
        onConfirm={(reason) => onConfirmMarkMentorAsInReview(reason)}
      />

      <PageLayout activeLink="mentor">
        <div>
          <div className="text-grey text-20 font-bold mb-20"></div>
          <div className="d-flex space-between  mb-20">
            <div className="text-grey text-20 font-bold">Mentors</div>
            <div>
              <ExportButton
                label="Export All to Excel"
                url={`mentor/export`}
                fileName={`mentors`}
              />
            </div>
          </div>
          <Row>
            <Col md={7}>
              <div className="d-flex flex-wrap mb-20">
                <Button
                  classes={`pill success ${activeTab === 0} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(0);
                  }}>
                  Approved ({totalApprovedData?.count || 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab === 1} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(1);
                    if (!canfetchTab1) setCanFetchTab1(true);
                  }}>
                  Pending ({totalPendingData?.count || 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab === 2} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(2);
                    if (!canfetchTab2) setCanFetchTab2(true);
                  }}>
                  In Review ({totalInReviewData?.count || 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab === 3} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(3);
                    if (!canfetchTab3) setCanFetchTab3(true);
                  }}>
                  Onbreak ({totalOnBreakData?.count || 0})
                </Button>
                <Button
                  classes={`pill success ${activeTab === 4} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(4);
                    if (!canfetchTab4) setCanFetchTab4(true);
                  }}>
                  Deactivated ({totalBlockedData?.count || 0})
                </Button>
              </div>
            </Col>
            <Col md={5}>
              {activeTab === 0 && (
                <InputField
                  placeholder="Search"
                  classes="small mb-20"
                  isSearch
                  value={query}
                  onChange={(ev) => {
                    setQuery(ev.target.value);
                  }}
                />
              )}

              {activeTab === 1 && (
                <InputField
                  placeholder="Search pending mentors"
                  classes="small mb-20"
                  isSearch
                  value={queryPending}
                  onChange={(ev) => {
                    setQueryPending(ev.target.value);
                  }}
                />
              )}

              {activeTab === 2 && (
                <InputField
                  placeholder="Search in review mentors"
                  classes="small mb-20"
                  isSearch
                  value={queryInReview}
                  onChange={(ev) => {
                    setQueryInReview(ev.target.value);
                  }}
                />
              )}

              {activeTab === 3 && (
                <InputField
                  placeholder="Search mentors on break"
                  classes="small mb-20"
                  isSearch
                  value={queryOnBreak}
                  onChange={(ev) => {
                    setQueryOnBreak(ev.target.value);
                  }}
                />
              )}

              {activeTab === 4 && (
                <InputField
                  placeholder="Search deactivated mentors"
                  classes="small mb-20"
                  isSearch
                  value={queryBlocked}
                  onChange={(ev) => {
                    setQueryBlocked(ev.target.value);
                  }}
                />
              )}
            </Col>
            <Col>
              <div className="d-flex mb-15">
                <ButtonDropdown
                  title={
                    <div className="d-flex align-center">
                      <FilterIcon /> &nbsp;&nbsp;
                      <span>{state.sort.text || "Filter by all"}</span>
                    </div>
                  }>
                  <MenuItem
                    active={state?.sort.value === ""}
                    onClick={() => {
                      setState({
                        ...state,
                        sort: {
                          text: "Filter by all",
                          value: "",
                        },
                      });
                    }}>
                    Filter by all
                  </MenuItem>
                  <MenuItem
                    active={state?.sort.value === "name"}
                    onClick={() => {
                      setState({
                        ...state,
                        sort: {
                          text: "Alphabetical order",
                          value: "name",
                        },
                      });
                    }}>
                    Alphabetical order
                  </MenuItem>
                </ButtonDropdown>
              </div>
            </Col>
          </Row>

          {activeTab === 0 && (
            <LoaderPane
              loading={!approvedMentorsData && !approvedMentorsError}
              error={approvedMentorsError?.message}
              noRecords={
                !approvedMentorsError &&
                approvedMentorsData &&
                approvedMentorsData["count"] === 0
              }
              onReload={() => {
                mutateApprovedMentors();
              }}
            />
          )}

          {activeTab === 0 &&
            approvedMentorsData &&
            !approvedMentorsError &&
            approvedMentorsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Job Title</th>
                      <th>Company</th>
                      <th>LinkedIn</th>
                      <th>Date Approved</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvedMentorsData?.results.map((mentor) => (
                      <tr key={mentor.id}>
                        <td>
                          <a href={mentorUrl(mentor.slug)} target="_blank">
                            <div className="d-flex pointer">
                              <div className="pic-small mr-3">
                                <img
                                  className="img-f-w-obj-cover"
                                  src={mentor.profile_photo_url}
                                  alt=""
                                />
                              </div>
                              <span className="text-16 text-grey font-500">
                                {mentor.name}{" "}
                              </span>
                            </div>
                          </a>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {mentor.title}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {mentor.employer}
                          </span>
                        </td>
                        <td>
                          <a
                            href={sanitizeLinkedIn(mentor?.linkedin_url)}
                            target="_blank">
                            <div className="text-success text-16 font-500 pointer">
                              LinkedIn
                            </div>
                          </a>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {new Date(mentor.date_verified).toDateString()}
                          </span>
                        </td>
                        <td>
                          <span
                            className="text-16 text-success font-500 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                currentMentor: mentor,
                                showSideModal: true,
                                phoneNumberCleared: false,
                              });
                            }}>
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={6}>
                        <PaginationBar
                          page={paginate.page}
                          pageSize={paginate.pageSize}
                          totalItems={approvedMentorsData["count"]}
                          onChangePage={(newPage) => {
                            setPaginate({ ...paginate, page: newPage });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setPaginate({
                              ...paginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

          {activeTab === 1 && (
            <LoaderPane
              loading={!pendingMentorsData && !pendingMentorsError}
              error={pendingMentorsError?.message}
              noRecords={
                !pendingMentorsError &&
                pendingMentorsData &&
                pendingMentorsData["count"] === 0
              }
              onReload={() => {
                mutatePendingMentors();
              }}
            />
          )}

          {activeTab === 1 &&
            pendingMentorsData &&
            !pendingMentorsError &&
            pendingMentorsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Application Date</th>
                      <th>Job Title</th>
                      <th>Company</th>
                      <th>LinkedIn</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingMentorsData?.results.map((mentor) => (
                      <tr key={mentor.id}>
                        <td>
                          <a href={mentorUrl(mentor.slug)} target="_blank">
                            <div className="d-flex pointer">
                              <div className="pic-small mr-3">
                                <img
                                  className="img-f-w-obj-cover"
                                  src={mentor.profile_photo_url}
                                  alt=""
                                />
                              </div>
                              <span className="text-16 text-grey font-500">
                                {mentor.name}{" "}
                              </span>
                            </div>
                          </a>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {new Date(mentor?.date_joined).toDateString()}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {mentor.title}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {mentor.employer}
                          </span>
                        </td>
                        <td>
                          <a
                            href={sanitizeLinkedIn(mentor?.linkedin_url)}
                            target="_blank">
                            <div className="text-success text-16 font-500 pointer">
                              LinkedIn
                            </div>
                          </a>
                        </td>
                        <td>
                          <span
                            className="text-16 text-success font-500 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                currentMentor: mentor,
                                showSideModal: true,
                                phoneNumberCleared: false,
                              });
                            }}>
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={6}>
                        <PaginationBar
                          page={pendingPaginate.page}
                          pageSize={pendingPaginate.pageSize}
                          totalItems={pendingMentorsData["count"]}
                          onChangePage={(newPage) => {
                            setPendingPaginate({
                              ...pendingPaginate,
                              page: newPage,
                            });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setPendingPaginate({
                              ...pendingPaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

          {activeTab === 2 && (
            <>
              <LoaderPane
                loading={!inReviewMentorsData && !inReviewMentorsError}
                error={inReviewMentorsError?.message}
                noRecords={
                  !inReviewMentorsError &&
                  inReviewMentorsData &&
                  inReviewMentorsData["count"] === 0
                }
                onReload={() => {
                  mutateInReviewMentors();
                }}
              />

              {inReviewMentorsData &&
                !inReviewMentorsError &&
                inReviewMentorsData["count"] > 0 && (
                  <div>
                    <Table bordered responsive className="table-white">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Application Date</th>
                          <th>Job Title</th>
                          <th>Company</th>
                          <th>In Review Date</th>
                          <th>LinkedIn</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {inReviewMentorsData?.results.map((mentor) => (
                          <tr key={mentor.id}>
                            <td>
                              <a
                                href={mentorUrl(mentor.slug)}
                                target="_blank"
                                rel="noreferrer">
                                <div className="d-flex pointer">
                                  <div className="pic-small mr-3">
                                    <img
                                      className="img-f-w-obj-cover"
                                      src={mentor.profile_photo_url}
                                      alt=""
                                    />
                                  </div>
                                  <span className="text-16 text-grey font-500">
                                    {mentor.name}{" "}
                                  </span>
                                </div>
                              </a>
                            </td>
                            <td>
                              <span className="text-16 text-grey-light font-500">
                                {new Date(mentor?.date_joined).toDateString()}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="text-16 text-grey-light font-500">
                                {mentor.title}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="text-16 text-grey-light font-500">
                                {mentor.employer}
                              </span>
                            </td>
                            <td>
                              <span className="text-16 text-grey-light font-500">
                                {new Date(
                                  mentor?.in_review_marked_at
                                ).toDateString()}{" "}
                              </span>
                            </td>
                            <td>
                              <a
                                href={sanitizeLinkedIn(mentor?.linkedin_url)}
                                target="_blank"
                                rel="noreferrer">
                                <div className="text-success text-16 font-500 pointer">
                                  LinkedIn
                                </div>
                              </a>
                            </td>
                            <td>
                              <span
                                className="text-16 text-success font-500 pointer"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    currentMentor: mentor,
                                    showSideModal: true,
                                    phoneNumberCleared: false,
                                  });
                                }}>
                                View
                              </span>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={7}>
                            <PaginationBar
                              page={inReviewPaginate.page}
                              pageSize={inReviewPaginate.pageSize}
                              totalItems={inReviewMentorsData["count"]}
                              onChangePage={(newPage) => {
                                setInReviewPaginate({
                                  ...inReviewPaginate,
                                  page: newPage,
                                });
                              }}
                              onChangeRowsPerPage={(size) => {
                                setInReviewPaginate({
                                  ...inReviewPaginate,
                                  pageSize: size,
                                  page: 0,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
            </>
          )}

          {activeTab === 3 && (
            <LoaderPane
              loading={!onBreakMentorsData && !onBreakMentorsError}
              error={onBreakMentorsError?.message}
              noRecords={
                !onBreakMentorsError &&
                onBreakMentorsData &&
                onBreakMentorsData["count"] === 0
              }
              onReload={() => {
                mutateOnBreakMentors();
              }}
            />
          )}

          {activeTab === 3 &&
            onBreakMentorsData &&
            !onBreakMentorsError &&
            onBreakMentorsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Job Title</th>
                      <th>Company</th>
                      <th>LinkedIn</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {onBreakMentorsData?.results.map((mentor) => (
                      <tr key={mentor.id}>
                        <td>
                          <a href={mentorUrl(mentor.slug)} target="_blank">
                            <div className="d-flex pointer">
                              <div className="pic-small mr-3">
                                <img
                                  className="img-f-w-obj-cover"
                                  src={mentor.profile_photo_url}
                                  alt=""
                                />
                              </div>
                              <span className="text-16 text-grey font-500">
                                {mentor.name}{" "}
                              </span>
                            </div>
                          </a>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {mentor.title}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {mentor.employer}
                          </span>
                        </td>
                        <td>
                          <a
                            href={sanitizeLinkedIn(mentor?.linkedin_url)}
                            target="_blank">
                            <div className="text-success text-16 font-500 pointer">
                              LinkedIn
                            </div>
                          </a>
                        </td>
                        <td>
                          <span
                            className="text-16 text-success font-500 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                currentMentor: mentor,
                                showSideModal: true,
                                phoneNumberCleared: false,
                              });
                            }}>
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={5}>
                        <PaginationBar
                          page={onBreakPaginate.page}
                          pageSize={onBreakPaginate.pageSize}
                          totalItems={onBreakMentorsData["count"]}
                          onChangePage={(newPage) => {
                            setOnBreakPaginate({
                              ...onBreakPaginate,
                              page: newPage,
                            });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setOnBreakPaginate({
                              ...onBreakPaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

          {activeTab === 4 && (
            <LoaderPane
              loading={!blockedMentorsData && !blockedMentorsError}
              error={blockedMentorsError?.message}
              noRecords={
                !blockedMentorsError &&
                blockedMentorsData &&
                blockedMentorsData["count"] === 0
              }
              onReload={() => {
                mutateBlockedMentors();
              }}
            />
          )}

          {activeTab === 4 &&
            blockedMentorsData &&
            !blockedMentorsError &&
            blockedMentorsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Job Title</th>
                      <th>Company</th>
                      <th>LinkedIn</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blockedMentorsData?.results.map((mentor) => (
                      <tr key={mentor.id}>
                        <td>
                          <a href={mentorUrl(mentor.slug)} target="_blank">
                            <div className="d-flex pointer">
                              <div className="pic-small mr-3">
                                <img
                                  className="img-f-w-obj-cover"
                                  src={mentor.profile_photo_url}
                                  alt=""
                                />
                              </div>
                              <span className="text-16 text-grey font-500">
                                {mentor.name}{" "}
                              </span>
                            </div>
                          </a>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {mentor.title}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-500">
                            {mentor.employer}
                          </span>
                        </td>
                        <td>
                          <a
                            href={sanitizeLinkedIn(mentor?.linkedin_url)}
                            target="_blank">
                            <div className="text-success text-16 font-500 pointer">
                              LinkedIn
                            </div>
                          </a>
                        </td>
                        <td>
                          <span
                            className="text-16 text-success font-500 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                currentMentor: mentor,
                                showSideModal: true,
                                phoneNumberCleared: false,
                              });
                            }}>
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={5}>
                        <PaginationBar
                          page={blockedPaginate.page}
                          pageSize={blockedPaginate.pageSize}
                          totalItems={blockedMentorsData["count"]}
                          onChangePage={(newPage) => {
                            setBlockedPaginate({
                              ...blockedPaginate,
                              page: newPage,
                            });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setBlockedPaginate({
                              ...blockedPaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
        </div>
      </PageLayout>
    </>
  );
};

export default MentorPage;
