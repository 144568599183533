import { useState, useGlobal, Fragment } from "reactn";
import { format, formatDistance } from "date-fns";
import { Col, Modal, Row } from "react-bootstrap";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar-2.svg";
import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { mentorUrl, designerUrl } from "../../../api/api";
import SideModal from "../side-modal/side-modal.component";
import Button from "components/button/button.component";
import HaveAccess from "../../../access-control";
import LoaderPane from "components/loader-pane/loader-pane.component";
import useSWR from "swr";

const BookingsModal = ({
  show,
  onHide,
  bookingId = null,
  onUnmarkNoShow,
  markBookingAsNoShow,
  changeBookingNoShowMarkedBy,
}) => {
  const [currentUser] = useGlobal("currentUser");

  const [state] = useState({
    processing: false,
    error: null,
  });

  const {
    data: booking,
    error,
    mutate,
  } = useSWR(
    bookingId && `${process.env.REACT_APP_CALENDAR_API}booking/${bookingId}/`
  );

  const dateBooked = booking?.date_and_time && new Date(booking?.date_and_time);
  const dateCancelled =
    booking?.cancelled?.date_cancelled &&
    new Date(booking?.cancelled?.date_cancelled);
  const dateDeclined =
    booking?.declined?.date_declined &&
    new Date(booking?.declined?.date_declined);

  /**
   * function
   * @param {*} dateString
   * @param {*} addTime
   * @returns String
   */
  const formatTime = (dateString, addTime = 0) => {
    let d = new Date(dateString);
    d.setTime(d.getTime() + addTime * 60000);
    return d.toISOString().split("T")[1].slice(0, 5);
  };

  const CancelledOrDeclinedBy = (booking) => {
    const takenBy =
      booking?.status === "Cancelled"
        ? booking?.cancelled?.cancelled_by
        : booking?.declined?.declined_by;
    const identity_type = takenBy?.identity_type;
    return (
      <div className="">
        <div className="text-grey-light text-12 font-400 mb-10 mt-30">
          {booking?.status} By
        </div>
        <a
          href={
            identity_type === "Mentor"
              ? mentorUrl(takenBy.slug)
              : designerUrl(takenBy.slug)
          }
          target="_blank"
          rel="noreferrer">
          <div className="d-flex align-center pointer mb-2">
            <div className="pic-small mr-3">
              <img
                className="img-f-w-obj-cover"
                src={takenBy?.profile_photo_url}
                alt=""
              />
            </div>
            <span className="text-16 text-grey font-500 mt-1">
              {takenBy?.name}{" "}
            </span>
          </div>
        </a>
      </div>
    );
  };

  const markedBy = (booking) => {
    const markedBy = booking?.no_show?.marked_by;
    const identity_type = markedBy?.identity_type;
    return (
      <div className="">
        <div className="text-grey-light text-12 font-400 mb-10 mt-30">
          No-show marked By
        </div>
        <a
          href={
            identity_type === "Mentor"
              ? mentorUrl(markedBy?.slug)
              : designerUrl(markedBy?.slug)
          }
          target="_blank"
          rel="noreferrer">
          <div className="d-flex align-center pointer ">
            <div className="pic-small mr-3">
              <img
                className="img-f-w-obj-cover"
                src={markedBy?.profile_photo_url}
                alt=""
              />
            </div>
            <span className="text-16 text-grey font-500 mt-1">
              {markedBy?.name}{" "}
            </span>
          </div>
        </a>

        <Row>
          <Col md={6}>
            <div className="text-grey-light text-12 font-400 mb-10 mt-30">
              No-show marked on
            </div>
            <span className="text-16 text-grey font-400">
              {new Date(booking?.no_show?.date_marked).toUTCString()}
            </span>
          </Col>

          <Col md={6} hidden={!booking?.no_show?.unmarked_at}>
            <div className="text-grey-light text-12 font-400 mb-10 mt-30">
              No-show unmarked on
            </div>
            <span className="text-16 text-grey font-400">
              {new Date(booking?.no_show?.unmarked_at).toUTCString()}
            </span>
          </Col>
        </Row>

        <Row hidden={!booking?.no_show?.unmarked_at}>
          <Col md={6}>
            <div className="text-grey-light text-12 font-400 mb-10 mt-30">
              Unmarked by
            </div>
            <span className="text-16 text-grey font-400">
              {booking?.no_show?.unmarked_by?.name}
            </span>
          </Col>

          <Col md={6}>
            <div className="text-grey-light text-12 font-400 mb-10 mt-30">
              Reason to unmark
            </div>
            <span className="text-16 text-grey font-400">
              {booking?.no_show?.unmarked_reason}
            </span>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <SideModal show={show} onHide={onHide} onBlurHide={true}>
        <Modal.Body className="scrollbar">
          <div className="modal-inner">
            <div className="content-medium">
              <div>
                <div className="text-grey text-20 font-bold mb-15">
                  {" "}
                  Bookings details
                </div>

                <LoaderPane
                  loading={!booking && !error}
                  error={error && "An error occurred"}
                  noRecord={null}
                  onReload={() => {
                    mutate();
                  }}
                />

                {booking && (
                  <div>
                    <div className="text-grey text-16 font-400 mb-30"></div>
                    <div className="line mb-1"></div>
                    <div className="text-grey-light text-12 font-400 mb-15">
                      Mentee
                    </div>

                    <a
                      href={
                        booking?.mentee.identity_type === "Mentor"
                          ? mentorUrl(booking?.mentee?.slug)
                          : designerUrl(booking?.mentee?.slug)
                      }
                      target="_blank"
                      rel="noreferrer">
                      <div className="d-flex align-center pointer ">
                        <div className="pic-small mr-3">
                          <img
                            className="img-f-w-obj-cover"
                            src={booking?.mentee.profile_photo_url}
                            alt=""
                          />
                        </div>
                        <span className="text-16 text-grey font-500">
                          {booking?.mentee?.name}{" "}
                        </span>
                      </div>
                    </a>

                    <div className="text-grey-light text-12 font-400 mb-15 mt-30">
                      Mentor
                    </div>
                    <a
                      href={mentorUrl(booking?.mentor.slug)}
                      target="_blank"
                      rel="noreferrer">
                      <div className="d-flex align-center pointer">
                        <div className="pic-small mr-3">
                          <img
                            className="img-f-w-obj-cover"
                            src={booking?.mentor.profile_photo_url}
                            alt=""
                          />
                        </div>
                        <span className="text-16 text-grey font-500">
                          {booking?.mentor.name}{" "}
                        </span>
                      </div>
                    </a>

                    <div className="text-grey-light text-12 font-400 mb-10 mt-30">
                      Date and Time
                    </div>
                    <div className=" text-16 font-400">
                      <span className="mr-3">
                        <Calendar />{" "}
                        {new Date(booking?.date_and_time).toUTCString()}
                      </span>
                      {/* <span className="mr-3">
                        <Clock />
                        &nbsp;
                        {booking?.date_and_time.split("T")[1].slice(0, 5)} -
                        &nbsp;
                        {booking &&
                          formatTime(
                            booking?.date_and_time,
                            booking?.duration_in_minutes
                          )}
                      </span>
                      <span className="mr-3"> </span> */}
                    </div>

                    <div className="text-grey-light text-12 font-400 mb-10 mt-30">
                      Duration in minutes
                    </div>
                    <div className=" text-16 font-400">
                      <span className="mr-3">
                        <Clock />
                        &nbsp;
                        {booking?.duration_in_minutes} minutes
                      </span>
                    </div>

                    {booking?.topics && (
                      <>
                        <div className="text-grey-light text-12 font-400 mb-10 mt-30">
                          Mentee is interested in
                        </div>
                        <div className=" text-16 font-400">
                          {booking?.topics}
                        </div>
                      </>
                    )}

                    <div className="text-grey-light text-12 font-400 mb-10 mt-30">
                      Note from Mentee
                    </div>
                    <div className=" text-16 font-400">{booking?.message}</div>
                    {booking?.confirmed && (
                      <>
                        <div className="text-grey-light text-12 font-400 mb-10 mt-30">
                          Location of meeting
                        </div>
                        <span className="text-16 text-grey font-400">
                          {booking?.confirmed.location_type}
                        </span>
                        <a
                          href={booking?.confirmed.location}
                          className="text-success text-16 ml-2">
                          {booking?.confirmed.location}
                        </a>
                      </>
                    )}

                    <div className="text-grey-light text-12 font-400 mb-10 mt-30">
                      Status
                    </div>
                    <span className="text-16 font-400">{booking?.status}</span>

                    {(booking?.cancelled || booking?.declined) && (
                      <>
                        {CancelledOrDeclinedBy(booking)}

                        <div className="text-grey-light text-12 font-400 mb-10 mt-30">
                          Cancellation/Declined Date and Time
                        </div>
                        <span className="text-16 font-400">
                          {(dateCancelled || dateDeclined) && (
                            <Fragment>
                              <Calendar />{" "}
                              {`${(
                                dateCancelled || dateDeclined
                              ).toDateString()} at ${format(
                                dateCancelled || dateDeclined,
                                "hh:mm:ss aa z"
                              )}`}
                            </Fragment>
                          )}
                        </span>

                        {dateCancelled && dateBooked && (
                          <Fragment>
                            <div className="text-grey-light text-12 font-400 mb-10 mt-30">
                              Cancellation Interval
                            </div>
                            <span className="text-16 font-400 text-capitalize">
                              {formatDistance(dateBooked, dateCancelled)}
                            </span>
                          </Fragment>
                        )}

                        <div className="text-grey-light text-12 font-400 mb-10 mt-30">
                          Reason
                        </div>
                        <span className="text-16 font-400">
                          {booking?.cancelled?.reason ||
                            booking?.declined?.reason}
                        </span>
                      </>
                    )}

                    {booking?.no_show && <div> {markedBy(booking)} </div>}

                    <div className="text-grey-light text-12 font-400 mb-10 mt-30">
                      Created {new Date(booking?.date_created).toString()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>

        {(booking?.status === "NoShow" || booking?.status === "Confirmed") &&
          HaveAccess("user", "Write", currentUser) && (
            <Modal.Footer>
              <div
                className="modal-footer-inner"
                style={{ paddingBottom: "40px" }}>
                {state.error && (
                  <div className="text-danger text-16 mb-10">{state.error}</div>
                )}

                {booking.status === "NoShow" && (
                  <>
                    <Button
                      classes="small secondary mr-15"
                      loading={state.processing}
                      disabled={state.processing}
                      onClick={onUnmarkNoShow}>
                      Unmark no-show
                    </Button>
                    <Button
                      classes="small secondary outline"
                      loading={state.processing}
                      disabled={state.processing}
                      onClick={() => changeBookingNoShowMarkedBy(booking)}>
                      {`Mark ${booking?.no_show?.marked_by.name} as no-show`}
                    </Button>
                  </>
                )}

                {booking.status === "Confirmed" && (
                  <Button
                    classes="small secondary"
                    loading={state.processing}
                    disabled={state.processing}
                    onClick={() => markBookingAsNoShow(booking)}>
                    Mark as no-show
                  </Button>
                )}
              </div>
            </Modal.Footer>
          )}
      </SideModal>
    </>
  );
};

export default BookingsModal;
