import Button from "components/button/button.component";
import PageLayout from "components/page-layout/page-layout.component";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { canWrite } from "access-control";
import { Col, Row, Table } from "react-bootstrap";
import { useGlobal, useState, useEffect } from "reactn";
import InputFIeld from "components/form/input-field/input-field.component";
import useSWR from "swr";
import { apiBaseUrl } from "api/api";

import LoaderPane from "components/loader-pane/loader-pane.component";
import PaginationBar from "components/pagination-bar/pagination-bar.component";
import SEOModal from "./seo.modal";

const Search = ({ query, setQuery }) => {
  return (
    <InputFIeld
      placeholder="Search"
      classes="small mb-20"
      isSearch
      value={query}
      onChange={(ev) => {
        setQuery(ev.target.value);
      }}
    />
  );
};

const SEOTable = ({
  active,
  select = "active",
  query,
  editAction,
  enableAction,
  disableAction,
  setTotalCount,
  setMutateAction,
}) => {
  const [paginationState, setPaginationState] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    remoteNext: null,
    remotePrev: null,
  });

  const getUrl = () => {
    let path = `${apiBaseUrl}seo-page/list/?offset=${
      paginationState.page * paginationState.pageSize
    }&limit=${paginationState.pageSize}`;

    if (select) path += `&filter=${select}`;

    if (active && query) path += `&query=${query}`;

    return path;
  };

  const { data, error, mutate } = useSWR(getUrl());

  useEffect(() => {
    if (data) {
      setTotalCount(data.count);
    }

    setMutateAction(() => () => mutate());
  }, [data, mutate, setTotalCount, setMutateAction]);

  return (
    <>
      <LoaderPane
        loading={!data && !error}
        error={error?.message}
        noRecords={data && data?.results.length === 0}
        onReload={() => {
          mutate();
        }}
      />
      {data && data.results && data.results.length > 0 && (
        <Table bordered responsive className="table-white">
          <thead>
            <tr>
              <th>Page Name</th>
              <th>Keywords</th>
              <th>Page URL</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.results?.map((seo) => (
              <tr key={seo.id}>
                <td>
                  <span className="text-16 text-grey font-500">{seo.name}</span>
                </td>

                <td>
                  <span className="text-16 text-grey font-500">
                    {seo.primary_keyword_type}
                    {seo.secondary_keyword_type
                      ? `, ${seo.secondary_keyword_type}`
                      : ""}
                  </span>
                </td>

                <td>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://adplist.org/${seo.url_slug}`}
                    className="text-16 text-success font-500 pointer">
                    {seo.url_slug ? `adplist.org/${seo.url_slug}` : ""}
                  </a>
                </td>

                {canWrite && (
                  <td>
                    <span
                      className="text-16 text-success font-500 pointer"
                      onClick={() => editAction(seo)}>
                      Edit
                    </span>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {seo.disabled ? (
                      <span
                        className="text-16 text-grey font-500 pointer"
                        onClick={() => enableAction(seo)}>
                        Enable
                      </span>
                    ) : (
                      <span
                        className="text-16 text-warning font-500 pointer"
                        onClick={() => disableAction(seo)}>
                        Disable
                      </span>
                    )}
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td colSpan={4}>
                <PaginationBar
                  page={paginationState.page}
                  pageSize={paginationState.pageSize}
                  totalItems={data?.count}
                  onChangePage={(newPage) => {
                    setPaginationState({ ...paginationState, page: newPage });
                  }}
                  onChangeRowsPerPage={(size) => {
                    setPaginationState({
                      ...paginationState,
                      pageSize: size,
                      page: 0,
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};

const SEOPage = () => {
  const [currentUser] = useGlobal("currentUser");
  const [activeTab, setActiveTab] = useState(0);
  const [query, setQuery] = useState("");

  const [activeTotalCount, setActiveTotalCount] = useState(0);
  const [disabledTotalCount, setDisabledTotalCount] = useState(0);

  const [modalState, setModalState] = useState({
    show: false,
    SEO: null,
  });

  const [mutateActive, setMutateActive] = useState(() => {});
  const [mutateDisabled, setMutateDisabled] = useState(() => {});

  const canWriteSEO = canWrite("seoPage", currentUser);

  return (
    <div>
      <SEOModal
        show={modalState.show}
        SEO={modalState.SEO}
        onHide={(refresh = false) => {
          if (refresh) {
            mutateActive();
            mutateDisabled();
          }
          setModalState({
            ...modalState,
            show: false,
          });
        }}
      />

      <PageLayout activeLink="seo" parentMenu="activities">
        <div className="text-grey text-20 font-450 mb-20">
          SEO Page Creation
        </div>
        <Row>
          <Col>
            <div className="d-flex flex-wrap mb-20">
              {canWriteSEO && (
                <Button
                  classes={`small success w-150  mb-10 mr-3`}
                  onClick={() =>
                    setModalState({
                      SEO: null,
                      show: true,
                    })
                  }>
                  <PlusIcon /> <span className="pl-2">Add new SEO</span>
                </Button>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <div className="d-flex flex-wrap align-center mb-20">
              <Button
                classes={`pill success ${activeTab === 0} mr-2  mb-20`}
                onClick={() => {
                  setActiveTab(0);
                  setQuery("");
                }}>
                Active ({activeTotalCount})
              </Button>

              <Button
                classes={`pill success ${activeTab === 1}  mb-20 mr-3`}
                onClick={() => {
                  setActiveTab(1);
                  setQuery("");
                }}>
                Disabled ({disabledTotalCount})
              </Button>
            </div>
          </Col>

          <Col md={8}>
            <div className="d-flex flex-wrap flex-end">
              <Search query={query} setQuery={setQuery} />
            </div>
          </Col>
        </Row>

        <div className={activeTab === 0 ? "" : "hidden"}>
          <SEOTable
            active={activeTab === 0}
            query={query}
            editAction={(SEO) =>
              setModalState({
                SEO: SEO,
                show: true,
              })
            }
            disableAction={(SEO) =>
              setModalState({
                SEO: {
                  ...SEO,
                  disabled: true,
                },
                show: true,
              })
            }
            setTotalCount={setActiveTotalCount}
            setMutateAction={setMutateActive}
          />
        </div>

        <div className={activeTab === 1 ? "" : "hidden"}>
          <SEOTable
            active={activeTab === 1}
            select="disabled"
            query={query}
            editAction={(SEO) =>
              setModalState({
                SEO: SEO,
                show: true,
              })
            }
            enableAction={(SEO) =>
              setModalState({
                SEO: {
                  ...SEO,
                  disabled: false,
                },
                show: true,
              })
            }
            setTotalCount={setDisabledTotalCount}
            setMutateAction={setMutateDisabled}
          />
        </div>
      </PageLayout>
    </div>
  );
};

export default SEOPage;
