import { postData, putData } from "api/api";

export const createSEO = async ({ authToken, data }) => {
  return await postData(`seo-page/create/`, data, authToken);
};

export const updateSEO = async ({ authToken, id, data }) => {
  return await putData(`seo-page/${id}/update/`, data, authToken);
};

export const createFAQ = async ({ authToken, data }) => {
  return await postData(`seo-page/faq/create/`, data, authToken);
};

export const updateFAQ = async ({ authToken, id, data }) => {
  return await putData(`seo-page/faq/${id}/update/`, data, authToken);
};
