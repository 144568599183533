import { Col, Modal, Row } from "react-bootstrap";
import { useState, useGlobal } from "reactn";
import { ReactComponent as RoundClose } from "../../../assets/icons/round-close.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location.svg";
import Button from "components/button/button.component";
import HaveAccess from "access-control";

const SuspendedUserModal = ({
  show,
  onHide,
  suspension,
  onRevokeSuspension,
  onDeactivateUser,
}) => {
  const [currentUser] = useGlobal("currentUser");

  return (
    <div>
      {show && suspension && (
        <div>
          <Modal
            className="side-modal"
            show={show}
            onHide={onHide}
            aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header>
              <div className="close" onClick={onHide}>
                <RoundClose />
              </div>
            </Modal.Header>
            <Modal.Body className="scrollbar">
              <div className="modal-inner">
                {/* profile*/}
                <div>
                  <div className="designer-pic mb-30">
                    <img
                      className="img-f-w-obj-cover"
                      src={
                        suspension.user.identity_type === "Mentor"
                          ? suspension.user.mentor.profile_photo_url
                          : suspension.user.designer.profile_photo_url
                      }
                      alt=""
                    />
                  </div>
                  <div className="text-grey text-20 font-bold">
                    {suspension.user.identity_type === "Mentor"
                      ? suspension.user.mentor.name
                      : suspension.user.designer.name}
                  </div>

                  <div className="text-grey text-16 font-500">
                    {suspension.user.identity_type === "Mentor"
                      ? suspension.user.mentor.title
                      : suspension.user.designer.title}

                    {suspension.user.identity_type === "Mentor" ? (
                      suspension.user.mentor.employer && (
                        <span className="text-primary pl-2">
                          {suspension.user.mentor.employer}
                        </span>
                      )
                    ) : (
                      <span className="text-success pl-2">
                        {suspension.user.designer.employer}
                      </span>
                    )}
                  </div>

                  <div className="text-grey text-12 font-bold mt-10 d-flex">
                    <LocationIcon />{" "}
                    {suspension.user.identity_type === "Mentor"
                      ? suspension.user.mentor.country.name
                      : suspension.user.designer.country.name}
                  </div>

                  <Row>
                    <Col md={6}>
                      <div>
                        <div className="text-grey-light text-16 mt-25">
                          Date of suspension
                        </div>
                        <div className="text-grey text-16 font-500">
                          {new Date(suspension.suspended_at).toDateString()} LT
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div>
                        <div className="text-grey-light text-16 mt-25">
                          Date of appeal
                        </div>
                        <div className="text-grey text-16 font-500">
                          {suspension.suspension_appeal
                            ? `${new Date(
                                suspension.suspension_appeal.created_at
                              ).toDateString()} LT`
                            : "N/A"}
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div>
                        <div className="text-grey-light text-16 mt-25">
                          Reason for suspension
                        </div>
                        <div className="text-grey text-16 font-500">
                          {suspension.reason}
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div>
                        <div className="text-grey-light text-16 mt-25">
                          Average attendance rate
                        </div>
                        <div className="text-grey text-16 font-500">
                          Calculating...
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div>
                        <div className="text-grey-light text-16 mt-25">
                          Total number of suspensions
                        </div>
                        <div className="text-grey text-16 font-500">
                          {suspension.total_suspension} suspension(s)
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div>
                        <div className="text-grey-light text-16 mt-25">
                          Last suspension revoked
                        </div>
                        <div className="text-grey text-16 font-500">
                          {suspension.last_suspension_revoked_at
                            ? `${new Date(
                                suspension.last_suspension_revoked_at
                              ).toDateString()}
                          LT`
                            : "N/A"}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {suspension.suspension_appeal && (
                    <Row>
                      <Col md={12}>
                        <div>
                          <div className="text-grey-light text-16 mt-25">
                            Evidence submitted
                          </div>
                          <div className="text-dark text-16 font-500">
                            {suspension.suspension_appeal.reasons.map(
                              (reason) => (
                                <div>- {reason.reason}</div>
                              )
                            )}
                          </div>

                          <div className="text-dark text-16 font-500 mt-10">
                            {suspension.suspension_appeal
                              .supporting_file_url ? (
                              <a
                                href={
                                  suspension.suspension_appeal
                                    .supporting_file_url
                                }
                                target="_blank"
                                rel="noreferrer">
                                View supporting file
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </div>

                        <div>
                          <div className="text-grey-light text-16 mt-25">
                            Note for the team
                          </div>

                          <div className="text-dark text-16 font-500">
                            {suspension.suspension_appeal.description}
                          </div>
                        </div>

                        <div>
                          <div className="text-grey-light text-16 mt-25">
                            Email
                          </div>

                          <div className="text-success text-16 font-500">
                            {suspension.user.email}
                          </div>
                        </div>

                        <div>
                          <div className="text-grey-light text-16 mt-25">
                            LinkedIn
                          </div>

                          <div className="text-success text-16 font-500">
                            {suspension.user.identity_type === "Mentor"
                              ? suspension.user.mentor.linkedin_url
                              : suspension.user.designer.linkedin_url}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </Modal.Body>
            {HaveAccess("user", "Write", currentUser) && (
              <Modal.Footer>
                <div
                  className="modal-footer-inner"
                  style={{ paddingBottom: "40px" }}>
                  {/* {state.error && (
                    <div className="text-danger text-16 mb-10">
                      {state.error}
                    </div>
                  )} */}

                  <div className="d-flex align-center f-width">
                    {(suspension.status === "Active" ||
                      suspension.status === "Appealed") && (
                      <Button
                        classes="small secondary mr-3"
                        loading={false}
                        disabled={false}
                        onClick={onRevokeSuspension}>
                        Restore account
                      </Button>
                    )}

                    {suspension.user.is_active && (
                      <Button
                        classes="small danger"
                        loading={false}
                        disabled={false}
                        onClick={onDeactivateUser}>
                        Deactivate
                      </Button>
                    )}
                  </div>
                </div>
              </Modal.Footer>
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};

export default SuspendedUserModal;
