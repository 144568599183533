import React, { useState, useEffect } from "react";
import { DateRange } from "react-date-range";
import "./date-range.styles.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar.svg";

const DateRangeInput = ({
  placeholder,
  startDate = null,
  endDate = null,
  ranges = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ],
  onDateChange = () => {},
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show && ranges[0]?.startDate !== ranges[0]?.endDate) setShow(false);
  }, [ranges, ranges[0]?.startDate, ranges[0]?.endDate]);

  return (
    <div className="date-range-container" onClick={() => setShow(true)}>
      <div className="d-flex space-between">
        <div>
          {ranges[0]?.startDate.toISOString().slice(0, 10)} -{" "}
          {ranges[0]?.endDate.toISOString().slice(0, 10)}
        </div>
        <Calendar />
      </div>

      {show && (
        <div className="range-container">
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              onDateChange([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={ranges}
          />
        </div>
      )}
    </div>
  );
};

export default DateRangeInput;
