import { useState, useGlobal, useEffect } from "reactn";
import { Link } from "react-router-dom";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import PartnerModal from "../../../components/modal/partner-modal/partner-modal.component";
import { fetchData, putData } from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import HaveAccess from "../../../access-control.jsx";
import ButtonDropdown, {
  MenuItem,
} from "../../../components/button-dropdown/button-dropdown";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import MentorSideModal from "../../../components/modal/mentor-side-modal/side-modal.component";
import DesignerSideModal from "../../../components/modal/designer-side-modal/side-modal.component";

const PartnersPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [partners, setPartners] = useGlobal("partners");
  const canWrite = HaveAccess("partner", "Write", currentUser);

  const [state, setState] = useState({
    activeTab: 0,
    showMentorModal: false,
    showMemberModal: false,
    currentMember: null,
    currentMentor: null,
    filterByPartner: { text: "", value: "" },
    filter_by: "all",
    showPartnerModal: false,
    PartnerModalType: null,
    currentPartner: null,
    showConfirmModal: false,
    confirmDescription: "",
    confirmProcessing: false,
    confirmTitle: "",
    confirmError: null,
    fetching: false,
    canFetch: true,
    error: null,
    query: "",
    queryDisabled: "",
    activePartners: [], //partners.filter(partner => partner.disabled === false),
    disabledPartners: [], // partners.filter(partner => partner.disabled === true),
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 20,
  });

  const [disabledPaginate, setDisabledPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [allPaginate, setAllPaginate] = useState({
    page: 0,
    pageSize: 10,
  });

  const {
    data: partnersData,
    error,
    mutate,
  } = useSWR(`partner/`, {
    onSuccess: (_partners) => {
      setPartners(_partners);
    },
  });

  const [canFetchTab1, setCanFetchTab1] = useState(false);
  const [canFetchTab2, setCanFetchTab2] = useState(false);

  const {
    data: allDirectory,
    error: allError,
    mutate: mutateAll,
  } = useSWR(
    canFetchTab2 &&
      `partner/directory/?limit=${allPaginate.pageSize}&offset=${
        allPaginate.pageSize * allPaginate.page
      }&partner_id=${state.filterByPartner.value || -1}&filter_by=${
        state.filter_by
      }`
  );

  //totals
  const { data: totalDirectory } = useSWR(
    `partner/directory/?limit=1&offset=0&partner_id=-1&filter_by=all`
  );

  const { data: totalAll } = useSWR(
    `partner/directory/?limit=1&offset=0&partner_id=${
      state.filterByPartner.value || -1
    }&filter_by=all`
  );

  const { data: totalMentors } = useSWR(
    `partner/directory/?limit=1&offset=0&partner_id=${
      state.filterByPartner.value || -1
    }&filter_by=Mentor`
  );

  const { data: totalMembers } = useSWR(
    `partner/directory/?limit=1&offset=0&partner_id=${
      state.filterByPartner.value || -1
    }&filter_by=Designer`
  );

  useEffect(() => {
    if (partners) {
      setState({
        ...state,
        canFetch: false,
        activePartners:
          partners?.filter((partner) => partner.disabled === false) || [],
        disabledPartners:
          partners?.filter((partner) => partner.disabled === true) || [],
      });
    }
  }, [partners, partners?.length]);

  //edit or add --callback
  function onPartnerUpdated(data) {
    setState({
      ...state,
      activePartners: data.filter((partner) => partner.disabled === false),
      disabledPartners: data.filter((partner) => partner.disabled === true),
      showPartnerModal: false,
    });

    setPartners(data);
  }

  //confirm disable or enable partner
  function onPartnerDisableOrEnable() {
    setState({ ...state, confirmProcessing: true });
    const formData = new FormData();
    formData.append("name", state.currentPartner.name);
    formData.append("disabled", state.currentPartner.disabled ? false : true);

    putData(
      `partner/${state.currentPartner.id}/`,
      formData,
      currentUser.token.actual,
      true
    ).then((response) => {
      if (response.success) {
        let current = partners.filter(
          (partner) => partner.id !== state.currentPartner["id"]
        );
        let newData = [...current, response.data];
        setState({
          ...state,
          confirmProcessing: false,
          confirmError: null,
          showConfirmModal: false,
          activePartners: newData.filter(
            (partner) => partner.disabled === false
          ),
          disabledPartners: newData.filter(
            (partner) => partner.disabled === true
          ),
        });
        setPartners(newData);
        return;
      }
      setState({
        ...state,
        confirmProcessing: false,
        confirmError: response.message,
      });
    });
  }

  //active filtering
  const activePartners = () => {
    let active = state.activePartners;
    let totalItems = active.length;

    if (state.query.length > 0) {
      active = active.filter((partner) =>
        partner.name.toLowerCase().includes(state.query)
      );
      //list length
      totalItems = active.length;
    }

    // paginate
    const { page, pageSize } = paginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: active.slice(start, end), totalItems: totalItems };
  };

  //disabled mentors filtering
  const disabledPartners = () => {
    let disabled = state.disabledPartners;
    let totalItems = disabled.length;

    if (state.queryDisabled.length > 0) {
      disabled = disabled.filter((partner) =>
        partner.name.toLowerCase().includes(state.queryDisabled)
      );
      //list length
      totalItems = disabled.length;
    }

    // paginate
    const { page, pageSize } = disabledPaginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: disabled.slice(start, end), totalItems: totalItems };
  };

  return (
    <>
      <PartnerModal
        partner={state.currentPartner}
        token={currentUser.token.actual}
        type={state.PartnerModalType}
        show={state.showPartnerModal}
        onHide={() => {
          setState({ ...state, showPartnerModal: false });
        }}
        onUpdated={onPartnerUpdated}
      />

      <MentorSideModal
        mentor={state.currentMentor}
        token={currentUser.token.actual}
        show={state.showMentorModal}
        onHide={() => {
          setState({ ...state, showMentorModal: false, currentMentor: null });
        }}
        onUpdated={() => {
          mutateAll();
        }}
      />

      <DesignerSideModal
        show={state.showMemberModal}
        onHide={() =>
          setState({ ...state, showMemberModal: false, currentMember: null })
        }
        token={currentUser.token.actual}
        designer={state.currentMember}
        onUpdated={() => {
          mutateAll();
        }}
      />

      <ConfirmModal
        show={state.showConfirmModal}
        token={currentUser.token.actual}
        title={state.confirmTitle}
        description={state.confirmDescription}
        processing={state.confirmProcessing}
        error={state.confirmError}
        onHide={() => {
          setState({ ...state, confirmError: null, showConfirmModal: false });
        }}
        onConfirm={onPartnerDisableOrEnable}
      />

      <PageLayout activeLink="partners" parentMenu="demographics">
        <div>
          <div className="text-grey text-20 font-bold mb-20">Partners</div>

          <Row>
            <Col md={7}>
              <div className="d-flex flex-wrap align-center mb-20">
                <Button
                  classes={`pill success ${state.activeTab == 0} mr-2  mb-20`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 0 });
                  }}
                >
                  Active({state.activePartners.length})
                </Button>

                <Button
                  classes={`pill success ${state.activeTab == 1}  mb-20 mr-3`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 1 });
                  }}
                >
                  Disabled({state.disabledPartners.length})
                </Button>

                <Button
                  classes={`pill success ${state.activeTab > 1}  mb-20 mr-3`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 2 });
                    setCanFetchTab2(true);
                  }}
                >
                  Directory({totalDirectory?.count || 0})
                </Button>

                {canWrite && (
                  <Button
                    classes={`small success w-150  mb-20`}
                    onClick={() => {
                      setState({
                        ...state,
                        PartnerModalType: "Add",
                        showPartnerModal: true,
                      });
                    }}
                  >
                    <PlusIcon /> <span className="pl-2">Add Partner</span>
                  </Button>
                )}
              </div>
            </Col>

            <Col md={5}>
              {state.activeTab == 0 && (
                <InputField
                  placeholder="Search  partners"
                  classes="small mb-20"
                  isSearch
                  value={state.query}
                  onChange={(ev) => {
                    setState({ ...state, query: ev.target.value });
                    if (paginate.page > 0)
                      setPaginate({ ...paginate, page: 0 });
                  }}
                />
              )}

              {state.activeTab == 1 && (
                <InputField
                  placeholder="Search disabled  partners"
                  classes="small mb-20"
                  isSearch
                  value={state.queryDisabled}
                  onChange={(ev) => {
                    setState({ ...state, queryDisabled: ev.target.value });
                    if (disabledPaginate.page > 0)
                      setDisabledPaginate({ ...disabledPaginate, page: 0 });
                  }}
                />
              )}
            </Col>
          </Row>

          <div hidden={state.activeTab < 2}>
            <div className="grey-hr mb-30" />
            <div className="d-flex flex-wrap">
              <div className="mr-2">
                <ButtonDropdown
                  title={
                    <div className="d-flex align-center">
                      <FilterIcon /> &nbsp;&nbsp;
                      <span>
                        {state?.filterByPartner.text || "Filter by Partner"}
                      </span>
                    </div>
                  }
                >
                  <MenuItem
                    active={!state?.filterByPartner.value}
                    onClick={() => {
                      setState({
                        ...state,
                        filterByPartner: {
                          text: "",
                          value: "",
                        },
                      });
                    }}
                  >
                    All
                  </MenuItem>
                  {partners?.map((partner) => (
                    <MenuItem
                      active={state?.filterByPartner.value === partner.id}
                      onClick={() => {
                        setState({
                          ...state,
                          filterByPartner: {
                            text: partner.name,
                            value: partner.id,
                          },
                        });
                      }}
                    >
                      {partner.name}
                    </MenuItem>
                  ))}
                </ButtonDropdown>
              </div>

              <Button
                classes={`pill info h-43 mb-20 mr-2`}
                onClick={() => {
                  setState({ ...state, filter_by: "all" });
                  setAllPaginate({ page: 0, pageSize: 10 });
                }}
              >
                <span
                  className={`text-14 font-400 px-2 ${
                    state.filter_by === "all" ? "text-success" : ""
                  }`}
                >
                  # All: {totalAll?.count || 0}
                </span>
              </Button>
              <Button
                classes={`pill info h-43 mb-20 mr-2`}
                onClick={() => {
                  setState({ ...state, filter_by: "Mentor" });
                  setAllPaginate({ page: 0, pageSize: 10 });
                }}
              >
                <span
                  className={`text-14 font-400 px-2 ${
                    state.filter_by === "Mentor" ? "text-success" : ""
                  }`}
                >
                  # Mentors: {totalMentors?.count || 0}
                </span>
              </Button>

              <Button
                classes={`pill info h-43 mb-20`}
                onClick={() => {
                  setState({ ...state, filter_by: "Designer" });
                  setAllPaginate({ page: 0, pageSize: 10 });
                }}
              >
                <span
                  className={`text-14 font-400 px-2 ${
                    state.filter_by === "Designer" ? "text-success" : ""
                  }`}
                >
                  # Members: {totalMembers?.count || 0}
                </span>
              </Button>
            </div>
          </div>

          <LoaderPane
            loading={!partnersData && !error}
            error={error?.message}
            noRecords={!error && partnersData?.length === 0}
            onReload={() => {
              mutate();
            }}
          />

          {/*no active partners */}
          {partnersData &&
            state.activeTab == 0 &&
            activePartners().items.length == 0 && (
              <div className="text-grey text-16 mb-20 text-center">
                {" "}
                No active Partners{" "}
              </div>
            )}

          {state.activeTab == 0 && state.activePartners.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Partner</th>
                    <th>Website</th>
                    <th>Email</th>
                    <th>Affiliate&nbsp;Code</th>
                    <th>Color&nbsp;Code</th>
                    <th>Text&nbsp;Color</th>
                    <th>Mentors</th>
                    <th>Members</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {activePartners().items.map((partner) => (
                    <tr key={partner.id}>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner.name}{" "}
                        </span>
                      </td>
                      <td>
                        <a
                          href={partner?.website_url}
                          target="_blank"
                          className="text-16 text-success font-400"
                        >
                          <div className="text-truncate w-200">
                            {partner?.website_url}
                          </div>
                        </a>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.email || "--"}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.affiliate_code}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.color_code}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.text_color}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.total_mentors}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.total_members}
                        </span>
                      </td>
                      <td>
                        <span
                          hidden={!canWrite}
                          className="text-16 text-success font-400 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              PartnerModalType: "Edit",
                              currentPartner: partner,
                              showPartnerModal: true,
                            });
                          }}
                        >
                          Edit
                        </span>

                        <span
                          hidden={!canWrite}
                          className="text-16 text-warning font-400 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              currentPartner: partner,
                              confirmTitle: "Disable  partner",
                              confirmDescription: `Do you want to disable this  partner [ ${partner.name} ] `,
                              showConfirmModal: true,
                            });
                          }}
                        >
                          Disable
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={8}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={100}
                        onChangePage={(newPage) => {
                          setPaginate({ ...paginate, page: newPage });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({ ...paginate, pageSize: size, page: 0 });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}

          {/*no disabled partner */}
          {partnersData &&
            state.activeTab == 1 &&
            disabledPartners().items.length == 0 && (
              <div className="text-grey text-16 mb-20 text-center">
                {" "}
                No disabled partners{" "}
              </div>
            )}

          {state.activeTab == 1 && state.disabledPartners.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Partner</th>
                    <th>Website</th>
                    <th>Email</th>
                    <th>Affiliate&nbsp;Code</th>
                    <th>Color&nbsp;Code</th>
                    <th>Text&nbsp;Color</th>
                    <th>Mentors</th>
                    <th>Members</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {disabledPartners().items.map((partner) => (
                    <tr key={partner.id}>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.name}{" "}
                        </span>
                      </td>
                      <td>
                        <a
                          href={partner?.website_url}
                          target="_blank"
                          className="text-16 text-success font-400"
                        >
                          <div className="text-truncate w-200">
                            {partner?.website_url}
                          </div>
                        </a>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.email || "--"}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.affiliate_code}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.color_code}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.text_color}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.total_mentors}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {partner?.total_members}{" "}
                        </span>
                      </td>
                      <td>
                        <span
                          hidden={!canWrite}
                          className="text-16 text-success font-400 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              PartnerModalType: "Edit",
                              currentPartner: partner,
                              showPartnerModal: true,
                            });
                          }}
                        >
                          Edit
                        </span>

                        <span
                          hidden={!canWrite}
                          className="text-16 text-success font-400 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              currentPartner: partner,
                              confirmTitle: "Enable  partner",
                              confirmDescription: `Do you want to enable this  partner [ ${partner.name} ] `,
                              showConfirmModal: true,
                            });
                          }}
                        >
                          Enable
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={8}>
                      <PaginationBar
                        page={disabledPaginate.page}
                        pageSize={disabledPaginate.pageSize}
                        totalItems={100}
                        onChangePage={(newPage) => {
                          setDisabledPaginate({
                            ...disabledPaginate,
                            page: newPage,
                          });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setDisabledPaginate({
                            ...disabledPaginate,
                            pageSize: size,
                            page: 0,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}

          {
            //directory
          }
          {state.activeTab === 2 && (
            <div>
              <div>
                <LoaderPane
                  loading={!allDirectory && !allError}
                  error={allError?.message}
                  noRecords={
                    !allError &&
                    allDirectory &&
                    allDirectory?.results?.length === 0
                  }
                  onReload={() => {
                    mutateAll();
                  }}
                />
                {allDirectory && !allError && allDirectory["count"] > 0 && (
                  <div>
                    <Table bordered responsive className="table-white">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Company</th>
                          <th>Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allDirectory?.results.map((item) => {
                          const data = item.mentor || item.designer;

                          const type = item.mentor ? "Mentor" : "Member";
                          return (
                            <tr key={item.id}>
                              <td>
                                <div className="d-flex">
                                  <div className="pic-small mr-3">
                                    <img
                                      className="img-f-w-obj-cover"
                                      src={item.profile_photo_url}
                                      alt=""
                                    />
                                  </div>
                                  <span className="text-16 text-grey font-500">
                                    {item.name}{" "}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <span className="text-16 text-grey-light font-500 pointer">
                                  {data?.partner?.name || "--"}
                                </span>
                              </td>
                              <td>{type}</td>
                              <td>
                                <span
                                  className="text-16 text-success font-500 pointer"
                                  onClick={() => {
                                    item.mentor
                                      ? setState({
                                          ...state,
                                          currentMentor: data,
                                          showMentorModal: true,
                                        })
                                      : setState({
                                          ...state,
                                          currentMember: data,
                                          showMemberModal: true,
                                        });
                                  }}
                                >
                                  View
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan={4}>
                            <PaginationBar
                              page={allPaginate.page}
                              pageSize={allPaginate.pageSize}
                              totalItems={allDirectory["count"]}
                              onChangePage={(newPage) => {
                                setAllPaginate({
                                  ...allPaginate,
                                  page: newPage,
                                });
                              }}
                              onChangeRowsPerPage={(size) => {
                                setAllPaginate({
                                  ...allPaginate,
                                  pageSize: size,
                                  page: 0,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default PartnersPage;
