import { useState, useGlobal, useEffect } from "reactn";
import { Link } from "react-router-dom";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import { fetchData, putData } from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
import LanguageModal from "../../../components/modal/language-modal/language-modal.component";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import HaveAccess from "../../../access-control.jsx";

const LanguagesPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const canWrite = HaveAccess("language", "Write", currentUser);
  const [languages, setLanguages] = useGlobal("languages");

  const [state, setState] = useState({
    activeTab: 0,
    showLanguageModal: false,
    langModalType: null,
    currentlanguage: null,
    showConfirmModal: false,
    confirmDescription: "",
    confirmProcessing: false,
    confirmTitle: "",
    confirmError: null,
    fetching: false,
    canFetch: true,
    error: null,
    query: "",
    queryDisabled: "",
    activeLanguages: languages.filter((lang) => lang.disabled === false),
    disabledLanguages: languages.filter((lang) => lang.disabled === true),
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [disabledPaginate, setDisabledPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (state.canFetch && !state.fetching && languages.length < 1) {
      setState({
        ...state,
        fetching: true,
        canFetch: false,
      });

      let authToken = currentUser.token.actual;
      fetchData("language/", authToken).then((response) => {
        if (response.success) {
          setLanguages(response.data);
          setState({
            ...state,
            fetching: false,
            canFetch: false,
            error: null,
            activeLanguages: response.data.filter(
              (lang) => lang.disabled === false
            ),
            disabledLanguages: response.data.filter(
              (lang) => lang.disabled === true
            ),
          });

          return;
        }
        setState({
          ...state,
          fetching: false,
          canFetch: false,
          error: response.message,
        });
      });
    }
  }, [
    languages,
    state.activeLanguages,
    state.disabledLanguages,
    state.canFetch,
    state.limit,
    paginate,
  ]);

  //edit or add language --callback
  function onLanguageUpdated(data) {
    setState({
      ...state,
      activeLanguages: data.filter((lang) => lang.disabled !== true),
      disabledLanguages: data.filter((lang) => lang.disabled === true),
      showLanguageModal: false,
    });
    setLanguages(data);
  }
  //confirm disable or enable language
  function onLangDisableOrEnable() {
    setState({ ...state, confirmProcessing: true });
    let data = {
      description: state.currentLanguage.description,
      disabled: state.currentLanguage.disabled ? false : true,
    };
    putData(
      `language/${state.currentLanguage.id}/`,
      data,
      currentUser.token.actual
    ).then((response) => {
      if (response.success) {
        let current = languages.filter(
          (lang) => lang.id !== state.currentLanguage["id"]
        );
        let newData = [...current, response.data];
        setState({
          ...state,
          confirmProcessing: false,
          confirmError: null,
          showConfirmModal: false,
          activeLanguages: newData.filter((lang) => lang.disabled === false),
          disabledLanguages: newData.filter((lang) => lang.disabled === true),
        });
        setLanguages(newData);

        return;
      }
      setState({
        ...state,
        confirmProcessing: false,
        confirmError: response.message,
      });
    });
  }

  //active filtering
  const activeLanguages = () => {
    let active = state.activeLanguages;
    let totalItems = active.length;

    if (state.query.length > 0) {
      active = active.filter((lang) =>
        lang.description.toLowerCase().includes(state.query)
      );
      //list length
      totalItems = active.length;
    }

    // paginate
    const { page, pageSize } = paginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: active.slice(start, end), totalItems: totalItems };
  };

  //disabled mentors filtering
  const disabledLanguages = () => {
    let disabled = state.disabledLanguages;
    let totalItems = disabled.length;

    if (state.queryDisabled.length > 0) {
      disabled = disabled.filter(
        (lang) =>
          lang["id"].includes(state.queryDisabled) ||
          lang.description.toLowerCase().includes(state.queryDisabled)
      );
      //list length
      totalItems = disabled.length;
    }

    // paginate
    const { page, pageSize } = disabledPaginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: disabled.slice(start, end), totalItems: totalItems };
  };

  return (
    <>
      <LanguageModal
        language={state.currentLanguage}
        token={currentUser.token.actual}
        type={state.langModalType}
        show={state.showLanguageModal}
        onHide={() => {
          setState({ ...state, showLanguageModal: false });
        }}
        onUpdated={onLanguageUpdated}
      />

      <ConfirmModal
        show={state.showConfirmModal}
        token={currentUser.token.actual}
        title={state.confirmTitle}
        description={state.confirmDescription}
        processing={state.confirmProcessing}
        error={state.confirmError}
        onHide={() => {
          setState({ ...state, confirmError: null, showConfirmModal: false });
        }}
        onConfirm={onLangDisableOrEnable}
      />

      <PageLayout activeLink="languages" parentMenu="demographics">
        <div>
          <div className="text-grey text-20 font-bold mb-20">Languages</div>

          <Row>
            <Col md={7}>
              <div className="d-flex flex-wrap align-center mb-20">
                <Button
                  classes={`pill success ${state.activeTab == 0} mr-2  mb-20`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 0 });
                  }}
                >
                  Active({state.activeLanguages.length})
                </Button>

                <Button
                  classes={`pill success ${state.activeTab == 1}  mb-20 mr-3`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 1 });
                  }}
                >
                  Disabled({state.disabledLanguages.length})
                </Button>
                {canWrite && (
                  <Button
                    classes={`small success w-200  mb-20`}
                    onClick={() => {
                      setState({
                        ...state,
                        langModalType: "Add",
                        showLanguageModal: true,
                      });
                    }}
                  >
                    <PlusIcon /> <span className="pl-2"> Add Language</span>
                  </Button>
                )}
              </div>
            </Col>

            <Col md={5}>
              {state.activeTab == 0 && (
                <InputField
                  placeholder="Search  languages"
                  classes="small mb-20"
                  isSearch
                  value={state.query}
                  onChange={(ev) => {
                    setState({ ...state, query: ev.target.value });
                    if (paginate.page > 0)
                      setPaginate({ ...paginate, page: 0 });
                  }}
                />
              )}

              {state.activeTab == 1 && (
                <InputField
                  placeholder="Search disabled  languages"
                  classes="small mb-20"
                  isSearch
                  value={state.queryDisabled}
                  onChange={(ev) => {
                    setState({ ...state, queryDisabled: ev.target.value });
                    if (disabledPaginate.page > 0)
                      setDisabledPaginate({ ...disabledPaginate, page: 0 });
                  }}
                />
              )}
            </Col>
          </Row>

          {state.activeTab == 0 && state.activeLanguages.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Language</th>
                    {/*<th>Number of Mentors</th>
                    <th>Number of Designers</th>*/}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {activeLanguages().items.map((language) => (
                    <tr key={language.id}>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {language.description}{" "}
                        </span>
                      </td>
                      {/*<td>
                        <span className="text-16 text-grey font-400"> </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-400"></span>
                      </td>*/}
                      <td>
                        {canWrite && (
                          <>
                            <span
                              className="text-16 text-success font-400 pointer mr-2"
                              onClick={() => {
                                setState({
                                  ...state,
                                  currentLanguage: language,
                                  langModalType: "Edit",
                                  showLanguageModal: true,
                                });
                              }}
                            >
                              Edit
                            </span>

                            <span
                              className="text-16 text-warning font-400 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  currentLanguage: language,
                                  confirmTitle: "Disable Language",
                                  confirmDescription: `Do you want to disable this language [ ${language.description} ] `,
                                  showConfirmModal: true,
                                });
                              }}
                            >
                              Disable
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={2}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={activeLanguages().totalItems}
                        onChangePage={(newPage) => {
                          setPaginate({ ...paginate, page: newPage });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({ ...paginate, pageSize: size, page: 0 });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {/*no active languages */}
          {state.activeTab == 0 &&
            state.activeLanguages.length == 0 &&
            !state.fetching && (
              <div className="text-grey text-16 mb-20">
                {" "}
                No active Languages{" "}
              </div>
            )}

          {state.activeTab == 1 && state.disabledLanguages.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Language</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {disabledLanguages().items.map((language) => (
                    <tr key={language.id}>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {language.description}{" "}
                        </span>
                      </td>

                      <td>
                        <span
                          hidden={!canWrite}
                          className="text-16 text-success font-400 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              currentLanguage: language,
                              langModalType: "Edit",
                              showLanguageModal: true,
                            });
                          }}
                        >
                          Edit
                        </span>

                        <span
                          hidden={!canWrite}
                          className="text-16 text-grey font-400 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              currentLanguage: language,
                              confirmTitle: "Enable Language",
                              confirmDescription: `Do you want to enable this language [ ${language.description} ] `,
                              showConfirmModal: true,
                            });
                          }}
                        >
                          Enable
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={2}>
                      <PaginationBar
                        page={disabledPaginate.page}
                        pageSize={disabledPaginate.pageSize}
                        totalItems={disabledLanguages().totalItems}
                        onChangePage={(newPage) => {
                          setDisabledPaginate({
                            ...disabledPaginate,
                            page: newPage,
                          });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setDisabledPaginate({
                            ...disabledPaginate,
                            pageSize: size,
                            page: 0,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {/*no disabled language */}
          {state.activeTab == 1 &&
            state.disabledLanguages.length == 0 &&
            !state.fetching && (
              <div className="text-grey text-16 mb-20">
                {" "}
                No disabled languages{" "}
              </div>
            )}

          <div className="mt-10 d-flex justify-center">
            <Spinner
              hidden={!state.fetching || state.query.length > 0}
              animation="border"
              variant="primary"
            />
          </div>

          <div className="mt-30" hidden={!state.error || state.fetching}>
            <span className="mt-10 text-danger">{state.error}.</span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => setState({ ...state, canFetch: true })}
            >
              TRY AGAIN
            </span>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default LanguagesPage;
