import { useState, useGlobal, useEffect } from "reactn";
import { Link } from "react-router-dom";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../components/button/button.component";
import InputField from "../../components/form/input-field/input-field.component";
import ReviewModal from "../../components/modal/review-modal/review-modal.component";
import {
  fetchData,
  fetchFile,
  apiBaseUrl,
  mentorUrl,
  designerUrl,
} from "../../api/api";
import PaginationBar from "../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../components/page-layout/page-layout.component";
import fileDownload from "js-file-download";
import ExportButton from "../../components/export-button/export-button.component";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import useSWR from "swr";
import ButtonDropdown, {
  MenuItem,
} from "../../components/button-dropdown/button-dropdown";

import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";

const ReviewsPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");

  const [reviews, setReviews] = useGlobal("reviews");
  const [totalReviews, setTotalReviews] = useGlobal("totalReviews");

  const [query, setQuery] = useState("");
  const [queryDisabled, setQueryDisabled] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const [exportReviews, setExportReviews] = useState({
    exporting: false,
    error: null,
  });

  const [state, setState] = useState({
    showReviewsModal: false,
    currentReview: null,
    fetching: false,
    canFetch: true,
    error: null,
    sort: {
      text: "",
      value: "",
    },
  });

  const [stateDisabled, setStateDisabled] = useState({
    fetching: false,
    canFetch: true,
    error: null,
    disabledReviews: [],
    totalDisabled: 0,
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const url = () => {
    let u = `review/?limit=${paginate.pageSize}&offset=${
      paginate.page * paginate.pageSize
    }`;
    if (query) u += `&q=${query}`;
    if (state.sort.value) u += `&sort_by=${state.sort.value}`;
    return u;
  };

  const { data: totalReviewsData } = useSWR("review/?limit=1&offset=0");

  const { data: reviewsData, error, mutate } = useSWR(url());

  useEffect(() => {});

  //edit or disable --callback
  function onReviewsUpdated(data) {
    setState({
      ...state,
      activeReviews: data,
    });
  }

  return (
    <>
      <ReviewModal
        review={state.currentReview}
        token={currentUser.token.actual}
        show={state.showReviewsModal}
        onHide={() => {
          setState({ ...state, showReviewsModal: false });
        }}
        onUpdated={onReviewsUpdated}
      />
      <PageLayout activeLink="reviews" parentMenu="activities">
        <div>
          <div className="d-flex space-between  mb-20">
            <div className="text-grey text-20 font-bold">
              Reviews
              <span className="text-16">({totalReviewsData?.count || 0})</span>
            </div>

            <ExportButton
              label="Export"
              url={`review/export/?&limit=${
                paginate.pageSize
              }&offset=0&q=${query}${
                state.sort.value ? `&sort_by=${state.sort.value}` : ""
              }`}
              fileName="reivews"
            />
          </div>

          <Row>
            <Col md={7} className="pr-0">
              <div className="d-flex flex-end">
                <ButtonDropdown
                  title={
                    <div className="d-flex align-center">
                      <FilterIcon /> &nbsp;&nbsp;
                      <span>{state.sort.text || "Sort review by"}</span>
                    </div>
                  }>
                  <MenuItem
                    active={state?.sort.value === "highest"}
                    onClick={() => {
                      setState({
                        ...state,
                        sort: {
                          text: "Highest rated (5 to 1)",
                          value: "highest",
                        },
                      });
                    }}>
                    Highest rated (5 to 1)
                  </MenuItem>
                  <MenuItem
                    active={state?.sort.value === "lowest"}
                    onClick={() => {
                      setState({
                        ...state,
                        sort: {
                          text: "Lowest rated (1 to 5)",
                          value: "lowest",
                        },
                      });
                    }}>
                    Lowest rated (1 to 5)
                  </MenuItem>
                  <MenuItem
                    active={state?.sort.value === "recent"}
                    onClick={() => {
                      setState({
                        ...state,
                        sort: {
                          text: "Most recent",
                          value: "recent",
                        },
                      });
                    }}>
                    Most recent
                  </MenuItem>
                  <MenuItem
                    active={state?.sort.value === "oldest"}
                    onClick={() => {
                      setState({
                        ...state,
                        sort: {
                          text: "Oldest",
                          value: "oldest",
                        },
                      });
                    }}>
                    Oldest
                  </MenuItem>
                </ButtonDropdown>
              </div>
            </Col>

            <Col md={5}>
              {activeTab == 0 && (
                <InputField
                  placeholder="Search  reviews"
                  classes="small mb-20"
                  isSearch
                  value={query}
                  onChange={(ev) => {
                    setQuery(ev.target.value);
                  }}
                />
              )}
            </Col>
          </Row>

          <LoaderPane
            loading={!reviewsData && !error}
            error={error?.message}
            noRecords={
              !error && reviewsData && reviewsData?.results.length === 0
            }
            onReload={() => {
              mutate();
            }}
          />

          {reviewsData?.results?.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Mentee</th>
                    <th>Mentor</th>
                    <th>Stars</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {reviewsData?.results?.map((review) => {
                    const { reviewed_by } = review;

                    const identity_type = reviewed_by.identity_type;
                    const reviewedBy = reviewed_by; //identity_type==="Mentor" ? reviewed_by["mentor"] : reviewed_by["designer"]
                    return (
                      <tr key={review.id}>
                        <td>
                          <a
                            href={
                              identity_type === "Mentor"
                                ? mentorUrl(reviewedBy.slug)
                                : designerUrl(reviewedBy.slug)
                            }
                            target="_blank">
                            <div className="d-flex pointer">
                              <div className="pic-small mr-3">
                                <img
                                  className="img-f-w-obj-cover"
                                  src={reviewedBy.profile_photo_url}
                                  alt=""
                                />
                              </div>
                              <span className="text-16 text-grey font-500">
                                {reviewedBy.name_string}{" "}
                              </span>
                            </div>
                          </a>
                        </td>
                        <td>
                          <a
                            href={mentorUrl(review.mentor?.slug)}
                            target="_blank"
                            rel="noreferrer">
                            <div className="d-flex pointer">
                              <div className="pic-small mr-3">
                                <img
                                  className="img-f-w-obj-cover"
                                  src={review.mentor?.profile_photo_url}
                                  alt=""
                                />
                              </div>
                              <span className="text-16 text-grey font-500">
                                {review.mentor?.name}{" "}
                              </span>
                            </div>
                          </a>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light  font-400">
                            {review.rating}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-400">
                            {
                              new Date(review.date_reviewed).toDateString()
                              //review.date_reviewed.split("T")[0]
                            }
                          </span>
                        </td>
                        <td>
                          <span
                            className="text-16 text-success font-400 pointer mr-2"
                            onClick={() => {
                              setState({
                                ...state,
                                currentReview: review,
                                showReviewsModal: true,
                              });
                            }}>
                            View
                          </span>

                          {/* <span className="text-16 text-warning font-400 pointer"
                                            onClick={()=>{
                                                setState({...state, currentReview: review, showReviewsModal: true}) 
                                            }}>Disable</span> */}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={5}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={reviewsData?.count}
                        onChangePage={(newPage) => {
                          setPaginate({ ...paginate, page: newPage });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({ ...paginate, pageSize: size, page: 0 });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default ReviewsPage;
