import pagedown from "pagedown";
import TurndownService from "turndown";

export const isNotEmpty = (value) =>
  value !== null && value !== undefined && value !== "";

export const inputChecks = (input, showErrors = false) => {
  let params = {};
  let errors = [];
  let errorMessage = null;

  Object.keys(input).forEach((key) => {
    if (isNotEmpty(input[key])) {
      params[key] = input[key];
    } else {
      errors.push(key);
      let fieldErrors = errors.join(", ");
      errorMessage = `${fieldErrors} must not be left blank`;
    }
  });

  if (errors.length === 0) {
    return params;
  }

  if (showErrors) {
    return errorMessage;
  }

  return false;
};

export const sanitizeLinkedIn = (link) => {
  let linkedinUrl = "https://www.linkedin.com/in/";

  linkedinUrl =
    "https://www.linkedin.com/in/" +
    link.replace(/(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\//g, "");

  return linkedinUrl;
};

export const textToHtml = (text = " ") => {
  let converter = new pagedown.Converter();
  let html = converter.makeHtml(text);
  return html;
};

export const htmlToMarkdown = (html = " ") => {
  let markdown = new TurndownService().turndown(html);
  return markdown;
};
