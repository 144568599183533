import { useState, useGlobal, useEffect } from "reactn";
import { Modal } from "react-bootstrap";

import { postData, putData } from "../../../api/services/announcement.service";
import InputField from "../../form/input-field/input-field.component";
import Button from "../../../components/button/button.component";
import SideModal from "../side-modal/side-modal.component";

const AnnouncementModal = ({
  show,
  onHide,
  type = "Add",
  announcement,
  canWrite,
  token,
  onUpdated = () => {},
}) => {
  const [currentUser] = useGlobal("currentUser");

  const [state, setState] = useState({
    title: "",
    external_link: "",
    created_by_id: "",
    status: "draft",
    processing: false,
    drafting: false,
    error: null,
  });

  useEffect(() => {});

  return (
    <>
      {(announcement || type === "Add" || type === "Edit") && (
        <SideModal
          show={show}
          onHide={() => {
            setState({ ...state, error: null });
            onHide();
          }}
          onBlurHide={false}
        >
          <Modal.Body className="scrollbar">
            <div className="modal-inner">
              <div className="content-medium">
                {/* add announcement */}
                {(type === "Add" || type === "Edit") && (
                  <div>
                    <div className="text-dark text-20 font-bold mb-30">
                      {type === "Add" ? "Add new " : "Edit"} Announcement
                    </div>
                    <InputField
                      label="Title"
                      placeholder={"Title"}
                      disabled={!canWrite}
                      value={state.title}
                      onChange={(e) =>
                        setState({ ...state, title: e.target.value })
                      }
                    />

                    <InputField
                      label="External link"
                      placeholder={"https://"}
                      disabled={!canWrite}
                      value={state.external_link}
                      classes="mt-20"
                      onChange={(e) =>
                        setState({ ...state, external_link: e.target.value })
                      }
                    />
                  </div>
                )}

                {/* view */}
                {type === "View" && (
                  <div>
                    <div className="text-dark text-20 font-bold mb-30">
                      Announcement details
                    </div>

                    <div className="text-dark text-16 font-bold">Title</div>
                    <div className="text-grey text-16">
                      {announcement.title}
                    </div>

                    <div className="text-dark text-16 font-bold mt-30">
                      External link
                    </div>
                    <a
                      href={`${announcement.url}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-grey text-16"
                    >
                      {announcement.url}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="modal-footer-inner"
              style={{ paddingBottom: `${window.bottomSpace}` }}
            >
              {(state.error || state.drafting || state.processing) && (
                <div className="text-danger text-16 mb-10">{state.error}</div>
              )}

              <div className="d-flex">
                {canWrite && (type === "Add" || type === "Edit") && (
                  <div>
                    <Button
                      classes="small secondary mr-2"
                      loading={state.processing}
                      disabled={
                        state.title.length < 1 ||
                        state.external_link.length < 1 ||
                        state.processing
                      }
                      onClick={() => {
                        setState({ ...state, processing: true, error: null });
                        if (type === "Add") {
                          postData(
                            "notification/announcement/",
                            {
                              title: state.title,
                              url: state.external_link,
                              status: "Sent",
                              created_by_id: currentUser.id,
                            },
                            token
                          ).then((resp) => {
                            if (resp.success) {
                              setState({
                                title: "",
                                external_link: "",
                                processing: false,
                                error: null,
                              });
                              onUpdated(resp.data);
                              onHide();
                              return;
                            }
                            setState({
                              ...state,
                              processing: false,
                              error: resp.message,
                            });
                          });
                        } else {
                          let id = announcement["id"];
                          let created_by_id = announcement.created_by_id;
                          let status = announcement.status;
                          putData(
                            "notification/announcement/" + id + "/",
                            {
                              title: state.title,
                              url: state.external_link,
                              status: status,
                              created_by_id: created_by_id,
                            },
                            token
                          ).then((resp) => {
                            if (resp.success) {
                              setState({
                                title: "",
                                external_link: "",
                                processing: false,
                                error: null,
                              });
                              onUpdated(resp.data);
                              onHide();
                              return;
                            }
                            setState({
                              ...state,
                              processing: false,
                              error: resp.message,
                            });
                          });
                        }
                      }}
                    >
                      {type === "Add" ? "Send" : "Save"}
                    </Button>
                    <Button
                      classes="small info"
                      disabled={state.processing}
                      onClick={() => {
                        setState({ ...state, description: "", error: null });
                        onHide();
                      }}
                    >
                      Cancel
                    </Button>

                    {canWrite && type === "Add" && (
                      <Button
                        classes="small info ml-3"
                        loading={state.drafting}
                        disabled={
                          state.title.length < 1 ||
                          state.external_link.length < 1 ||
                          state.drafting
                        }
                        onClick={() => {
                          setState({ ...state, drafting: true, error: null });
                          postData(
                            "notification/announcement/",
                            {
                              title: state.title,
                              url: state.external_link,
                              status: "Draft",
                              created_by_id: currentUser.id,
                            },
                            token
                          ).then((resp) => {
                            if (resp.success) {
                              setState({
                                title: "",
                                external_link: "",
                                drafting: false,
                                error: null,
                              });
                              onUpdated(resp.data);
                              onHide();
                              return;
                            }
                            setState({
                              ...state,
                              drafting: false,
                              error: resp.message,
                            });
                          });
                        }}
                      >
                        Save to draft
                      </Button>
                    )}
                  </div>
                )}

                {/* if sent */}
                {announcement && canWrite && announcement.status === "Sent" && (
                  <div>
                    <Button
                      classes="small danger"
                      disabled={state.processing}
                      onClick={() => {
                        setState({ ...state, processing: true, error: null });
                        let id = announcement["id"];
                        let created_by_id = announcement.created_by_id;
                        putData(
                          "notification/announcement/" + id + "/",
                          {
                            title: announcement.title,
                            url: announcement.url,
                            status: "Revoked",
                            created_by_id: created_by_id,
                          },
                          token
                        ).then((resp) => {
                          if (resp.success) {
                            setState({
                              title: "",
                              external_link: "",
                              processing: false,
                              error: null,
                            });
                            onUpdated(resp.data);
                            onHide();
                            return;
                          }
                          setState({
                            ...state,
                            processing: false,
                            error: resp.message,
                          });
                        });
                      }}
                    >
                      Revoke
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Modal.Footer>
        </SideModal>
      )}
    </>
  );
};

export default AnnouncementModal;
