import {
  FormControl,
  Select,
  MenuItem as SelectMenuItem,
} from "@material-ui/core";
import { useState } from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import Button from "components/button/button.component";
import InputFIeld from "components/form/input-field/input-field.component";
import TextArea from "components/form/text-area/text-area.component";

const ConfirmMarkMentorAsInReviewModal = ({
  show,
  onHide,
  mentor,
  success = null,
  processing = false,
  error = null,
  onConfirm = (reason) => {},
  onBlurHide = false,
}) => {
  const [reason, setReason] = useState("");
  const [customReason, setCustomReason] = useState("");

  return (
    <div>
      <Modal
        className="center-modal"
        show={show}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header>
          <div className="font-500">
            <span className="font-bold">
              Mark mentor application as In Review
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          {mentor && (
            <div>
              <div className="text-grey" style={{ marginTop: "-15px" }}>
                Select a reason below to mark this mentor application as In
                Review
              </div>

              <div className="mt-15">
                <div className="text-grey-light text-16 font-400 mb-10">
                  Select reason
                </div>

                <FormControl fullWidth variant="outlined" className="mt-20">
                  <Select
                    value={reason}
                    onChange={(event) => {
                      setReason(event.target.value);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}>
                    <SelectMenuItem value="">Select reason</SelectMenuItem>
                    <SelectMenuItem value="Profile photo needs to be updated">
                      Profile photo needs to be updated
                    </SelectMenuItem>
                    <SelectMenuItem value="Bio needs to be improved">
                      Bio needs to be improved
                    </SelectMenuItem>
                    <SelectMenuItem value="Both bio and profile photo need updating">
                      Both bio and profile photo need updating
                    </SelectMenuItem>
                    <SelectMenuItem value="Other">Other</SelectMenuItem>
                  </Select>

                  <div className="mt-3" hidden={reason !== "Other"}>
                    <TextArea
                      value={customReason}
                      onChange={(event) => setCustomReason(event.target.value)}
                    />
                  </div>
                </FormControl>
              </div>

              {success && !processing && (
                <div className="mt-10 text-success text-center">{success}</div>
              )}

              {error && (!processing || !success) && (
                <div className="mt-10 text-danger text-center">{error}</div>
              )}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="f-width">
            <Row>
              <Col xs={6}>
                <Button
                  classes="small info f-width"
                  disabled={processing}
                  onClick={onHide}>
                  {success ? "Close" : "Cancel"}
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  classes="small secondary f-width"
                  hidden={success}
                  loading={processing}
                  disabled={
                    processing ||
                    !reason ||
                    (reason === "Other" && !customReason)
                  }
                  onClick={() =>
                    onConfirm(reason === "Other" ? customReason : reason)
                  }>
                  Confirm
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmMarkMentorAsInReviewModal;
