import Button from "components/button/button.component";
import PageLayout from "components/page-layout/page-layout.component";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { canWrite } from "access-control";
import { Col, Row, Table } from "react-bootstrap";
import { useGlobal, useState, useEffect } from "reactn";
import InputFIeld from "components/form/input-field/input-field.component";
import useSWR from "swr";
import { apiBaseUrl } from "api/api";
import ButtonDropdown from "components/button-dropdown/button-dropdown";

import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import { MenuItem } from "@material-ui/core";
import LoaderPane from "components/loader-pane/loader-pane.component";
import PaginationBar from "components/pagination-bar/pagination-bar.component";
import DisciplineModal from "./discipline.modal";

const Search = ({ query, setQuery }) => {
  return (
    <InputFIeld
      placeholder="Search"
      classes="small mb-20"
      isSearch
      value={query}
      onChange={(ev) => {
        setQuery(ev.target.value);
      }}
    />
  );
};

const Filter = ({ data, filter, setFilter }) => {
  return (
    <div className="d-flex mr-10">
      {data && (
        <ButtonDropdown
          classes="mw-200px"
          title={
            <div className="d-flex align-center text-truncate">
              <FilterIcon /> &nbsp;&nbsp;
              <span>{data ? filter.label || "Filter" : "..."}</span>
            </div>
          }>
          <MenuItem
            active={filter.value === ""}
            onClick={() =>
              setFilter({
                label: "Filter",
                value: "",
              })
            }>
            Show all
          </MenuItem>
          {data
            .filter((m) => !m.disabled)
            .map((expertise) => (
              <MenuItem
                active={filter.value === expertise.id}
                onClick={() =>
                  setFilter({
                    label: expertise.description,
                    value: expertise.id,
                  })
                }>
                {expertise.description}
              </MenuItem>
            ))}
        </ButtonDropdown>
      )}
    </div>
  );
};

const DisciplinesTable = ({
  active,
  select = "active",
  query,
  filter,
  editAction,
  enableAction,
  disableAction,
  setTotalCount,
  setMutateAction,
}) => {
  const [paginationState, setPaginationState] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    remoteNext: null,
    remotePrev: null,
  });

  const getUrl = () => {
    let path = `${apiBaseUrl}discipline/?offset=${
      paginationState.page * paginationState.pageSize
    }&limit=${paginationState.pageSize}`;

    if (select) path += `&select=${select}`;

    if (active && query) path += `&search=${query}`;
    if (active && filter) path += `&expertise_id=${filter}`;

    return path;
  };

  const { data, error, mutate } = useSWR(getUrl());

  useEffect(() => {
    if (data) {
      setTotalCount(data.count);
    }

    setMutateAction(() => () => mutate());
  }, [data, mutate, setTotalCount, setMutateAction]);

  return (
    <>
      <LoaderPane
        loading={!data && !error}
        error={error?.message}
        noRecords={data && data?.results.length === 0}
        onReload={() => {
          mutate();
        }}
      />
      {data?.results.length > 0 && (
        <Table bordered responsive className="table-white">
          <thead>
            <tr>
              <th>Name</th>
              <th># of mentors</th>
              <th>Expertise</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.results?.map((discipline) => (
              <tr key={discipline.id}>
                <td>
                  <span className="text-16 text-grey font-500">
                    {discipline.name}
                  </span>
                </td>

                <td>
                  <span className="text-16 text-grey font-500">
                    {discipline.total_mentors}
                  </span>
                </td>

                <td>
                  <span className="text-16 text-grey font-500">
                    {discipline.expertise.description}
                  </span>
                </td>
                <td>
                  <span
                    className="text-16 text-success font-500 pointer"
                    onClick={() => editAction(discipline)}>
                    Edit
                  </span>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {discipline.disabled ? (
                    <span
                      className="text-16 text-grey font-500 pointer"
                      onClick={() => enableAction(discipline)}>
                      Enable
                    </span>
                  ) : (
                    <span
                      className="text-16 text-warning font-500 pointer"
                      onClick={() => disableAction(discipline)}>
                      Disable
                    </span>
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={4}>
                <PaginationBar
                  page={paginationState.page}
                  pageSize={paginationState.pageSize}
                  totalItems={data?.count}
                  onChangePage={(newPage) => {
                    setPaginationState({ ...paginationState, page: newPage });
                  }}
                  onChangeRowsPerPage={(size) => {
                    setPaginationState({
                      ...paginationState,
                      pageSize: size,
                      page: 0,
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};

const DisciplinesPage = () => {
  const [currentUser] = useGlobal("currentUser");
  const [activeTab, setActiveTab] = useState(0);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState({
    label: "",
    value: "",
  });

  const [activeTotalCount, setActiveTotalCount] = useState(0);
  const [disabledTotalCount, setDisabledTotalCount] = useState(0);

  const [modalState, setModalState] = useState({
    show: false,
    discipline: null,
  });

  const [mutateActive, setMutateActive] = useState(() => {});
  const [mutateDisabled, setMutateDisabled] = useState(() => {});

  const canWriteDiscipline = canWrite("discipline", currentUser);

  const { data: expertise } = useSWR(`${apiBaseUrl}expertise/`);

  return (
    <div>
      <DisciplineModal
        show={modalState.show}
        discipline={modalState.discipline}
        expertiseList={expertise}
        onHide={(refresh = false) => {
          if (refresh) {
            mutateActive();
            mutateDisabled();
          }
          setModalState({
            ...modalState,
            show: false,
          });
        }}
      />

      <PageLayout activeLink="disciplines" parentMenu="demographics">
        <div className="text-grey text-20 font-450 mb-20">Disciplines</div>
        <Row>
          <Col>
            <div className="d-flex flex-wrap mb-20">
              {canWriteDiscipline && (
                <Button
                  classes={`small success w-150  mb-10 mr-3`}
                  onClick={() => {
                    setModalState({
                      discipline: null,
                      show: true,
                    });
                  }}>
                  <PlusIcon /> <span className="pl-2">Add new discipline</span>
                </Button>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <div className="d-flex flex-wrap align-center mb-20">
              <Button
                classes={`pill success ${activeTab === 0} mr-2  mb-20`}
                onClick={() => {
                  setActiveTab(0);
                  setFilter({
                    label: "",
                    value: "",
                  });
                  setQuery("");
                }}>
                Active ({activeTotalCount})
              </Button>

              <Button
                classes={`pill success ${activeTab === 1}  mb-20 mr-3`}
                onClick={() => {
                  setActiveTab(1);
                  setFilter({
                    label: "",
                    value: "",
                  });
                  setQuery("");
                }}>
                Disabled ({disabledTotalCount})
              </Button>
            </div>
          </Col>

          <Col md={8}>
            <div className="d-flex flex-wrap flex-end">
              <Filter data={expertise} filter={filter} setFilter={setFilter} />
              <Search query={query} setQuery={setQuery} />
            </div>
          </Col>
        </Row>

        <div className={activeTab === 0 ? "" : "hidden"}>
          <DisciplinesTable
            active={activeTab === 0}
            query={query}
            filter={filter.value}
            editAction={(discipline) =>
              setModalState({
                discipline: discipline,
                show: true,
              })
            }
            disableAction={(discipline) =>
              setModalState({
                discipline: {
                  ...discipline,
                  disabled: true,
                },
                show: true,
              })
            }
            setTotalCount={setActiveTotalCount}
            setMutateAction={setMutateActive}
          />
        </div>

        <div className={activeTab === 1 ? "" : "hidden"}>
          <DisciplinesTable
            active={activeTab === 1}
            select="disabled"
            query={query}
            filter={filter.value}
            editAction={(discipline) =>
              setModalState({
                discipline: discipline,
                show: true,
              })
            }
            enableAction={(discipline) =>
              setModalState({
                discipline: {
                  ...discipline,
                  disabled: false,
                },
                show: true,
              })
            }
            setTotalCount={setDisabledTotalCount}
            setMutateAction={setMutateDisabled}
          />
        </div>
      </PageLayout>
    </div>
  );
};

export default DisciplinesPage;
