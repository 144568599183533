import { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Button from "../../../components/button/button.component";

const ConfirmModal = ({
  show,
  onHide,
  title,
  description,
  success = null,
  processing = false,
  error = null,
  onConfirm = () => {},
  onBlurHide = false,
}) => {
  return (
    <div>
      <Modal
        className="center-modal"
        show={show}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="font-500">{title}</div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className="">
            {!success && <div className="text-grey">{description}</div>}

            {success && !processing && (
              <div className="mt-10 text-success text-center">{success}</div>
            )}

            {error && (!processing || !success) && (
              <div className="mt-10 text-danger text-center">{error}</div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="f-width">
            <Row>
              <Col xs={6}>
                <Button
                  classes="small secondary f-width"
                  hidden={success}
                  loading={processing}
                  disabled={processing}
                  onClick={onConfirm}
                >
                  Confirm
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  classes="small info f-width"
                  disabled={processing}
                  onClick={onHide}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
