import { useState, useGlobal, useEffect } from "reactn";
import { Link } from "react-router-dom";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../components/button/button.component";
import InputField from "../../components/form/input-field/input-field.component";
import PartnerModal from "../../components/modal/partner-modal/partner-modal.component";
import { fetchData, putData, postData } from "../../api/api";
import PaginationBar from "../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../components/page-layout/page-layout.component";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import ConfirmModal from "../../components/modal/confirm-modal/confirm-modal.component";
import Select from "../../components/form/select/select.component";
import ToggleButton from "../../components/form/toggle-button/toggle-button.component";
import { endOfWeek } from "date-fns";
import HaveAccess from "../../access-control.jsx";

const BannerPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [activeBanner, setActiveBanner] = useGlobal("activeBanner");

  const canWrite = HaveAccess("banner", "Write", currentUser);

  const [state, setState] = useState({
    description: "",
    link: "",
    background_color: "",
    text_color: "",
    valid_till: endOfWeek(new Date()).toISOString().slice(0, 10),
    active: false,
    private: false,
    fetching: false,
    canFetch: true,
    error: null,
  });

  const [confirm, setConfirm] = useState({
    show: false,
    description: "Update banner with current data",
    processing: false,
    title: "Update",
    error: null,
    success: null,
  });

  const onProcessBanner = () => {
    setConfirm({
      ...confirm,
      processing: true,
      error: null,
    });
    let vt = `${state?.valid_till.toString().replace("/", "-")}T00:00:00`;
    let banner = {
      description: state.description,
      link: state.link,
      background_color: state.background_color,
      text_color: state.text_color,
      valid_till: vt,
      private: state.private,
      active: state.active,
    };
    postData("banner/", banner, currentUser.token.actual).then((response) => {
      if (response.success) {
        setConfirm({
          ...confirm,
          success: "Banner updated successfully.",
          error: null,
        });
        setActiveBanner({ ...banner, valid_till: state?.valid_till });
        return;
      }

      setConfirm({
        ...confirm,
        error: response?.message || "Failed to process banner",
      });
    });
  };

  const fetchBanner = () => {
    setState({ ...state, fetching: true, error: null });
    fetchData("banner/current/", currentUser.token.actual).then((response) => {
      //console.log(response)
      if (response.success) {
        setState({
          ...state,
          fetching: false,
          canFetch: false,
        });
        let d = response.data.valid_till.slice(0, 10);
        //d=d.replace("-","/")
        setActiveBanner({
          ...response.data,
          valid_till: d,
        });
        return;
      }

      if (response.message === "We could not find what you are looking for") {
        setState({ ...state, fetching: false, canFetch: false, error: null });
        return;
      }

      setState({
        ...state,
        fetching: false,
        canFetch: false,
        error: response.message,
      });
    });
  };

  useEffect(() => {
    if (activeBanner) {
      setState({ ...state, ...activeBanner });
      //console.log(activeBanner)
    }

    if (state.canFetch && !activeBanner && !state.fetching) {
      fetchBanner();
    }
  }, [activeBanner]);

  return (
    <>
      <ConfirmModal
        show={confirm.show}
        token={currentUser.token.actual}
        title={confirm.title}
        description={confirm.description}
        processing={confirm.processing}
        success={confirm.success}
        error={confirm.error}
        onHide={() => {
          setConfirm({
            ...confirm,
            error: null,
            success: null,
            processing: false,
            show: false,
          });
        }}
        onConfirm={() => {
          onProcessBanner();
        }}
      />

      <PageLayout activeLink="banner" parentMenu="activities">
        <div style={{ maxWidth: "530px" }}>
          <div className="text-grey text-20 font-bold mb-20">
            Banner Information
          </div>

          <div className="mt-10 d-flex justify-center">
            <Spinner
              hidden={!state.fetching}
              animation="border"
              variant="primary"
            />
          </div>

          <div className="mt-30" hidden={!state.error || state.fetching}>
            <span className="mt-10 text-danger">{state.error}.</span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => setState({ ...state, canFetch: true })}
            >
              Reload
            </span>
          </div>
          <Row>
            <Col md={12}>
              <InputField
                label="Description"
                placeholder="Banner description"
                classes="mb-20"
                value={state?.description}
                disabled={!canWrite}
                onChange={(ev) => {
                  setState({ ...state, description: ev.target.value });
                }}
              />
            </Col>
            <Col md={12}>
              <InputField
                label="External link"
                placeholder="https://"
                classes="mb-20"
                value={state?.link}
                disabled={!canWrite}
                onChange={(ev) => {
                  setState({ ...state, link: ev.target.value });
                }}
              />
            </Col>
            <Col xs={6}>
              <InputField
                label="Background color"
                placeholder="eg. #cfcfcf"
                classes="mb-20"
                value={state?.background_color}
                disabled={!canWrite}
                onChange={(ev) => {
                  setState({ ...state, background_color: ev.target.value });
                }}
              />
            </Col>
            <Col xs={6}>
              <InputField
                label="Text color"
                placeholder="eg. #cfcfcf"
                classes="mb-20"
                value={state?.text_color}
                disabled={!canWrite}
                onChange={(ev) => {
                  setState({ ...state, text_color: ev.target.value });
                }}
              />
            </Col>
            <Col sm={6} xs={12} className="">
              <InputField
                label="Duration"
                placeholder=""
                classes=""
                disabled={!canWrite}
                value={state.valid_till}
                type="date"
                onChange={(e) => {
                  setState({ ...state, valid_till: e.target.value });
                }}
              />
            </Col>

            <Col sm={3} xs={6} className="d-flex  mt-50 mb-20">
              <ToggleButton
                checked={state.active}
                locked={false}
                loading={false}
                disabled={!canWrite}
                onChange={() => {
                  setState({ ...state, active: state.active ? false : true });
                }}
              />
              <span className="text-16 font-500  pl-2">Active</span>
            </Col>

            <Col sm={3} xs={6} className="d-flex  mt-50 mb-20">
              <ToggleButton
                checked={state.private}
                locked={false}
                loading={false}
                disabled={!canWrite}
                onChange={() => {
                  setState({ ...state, private: state.private ? false : true });
                }}
              />
              <span className="text-16 font-500  pl-2">Private</span>
            </Col>

            <Col xs={12} className="mb-40">
              {canWrite && (
                <Button
                  classes={`small secondary  mb-20 mt-30`}
                  disabled={
                    state.description.length === 0 ||
                    state.link.length === 0 ||
                    state.background_color.length === 0 ||
                    state.text_color.length === 0
                  }
                  onClick={() => {
                    setConfirm({
                      ...confirm,
                      show: true,
                    });
                  }}
                >
                  Publish changes
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </PageLayout>
    </>
  );
};

export default BannerPage;
