import { useState, useGlobal } from "reactn";
import { withRouter } from "react-router-dom";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { CanReadOrWrite } from "../../access-control.jsx";

import "./sidebar.styles.scss";

const Sidebar = (props) => {
  const [showAnalytics, setShowAnalytics] = useState(
    props.analytics ? true : false
  );
  const [showDemo, setShowDemo] = useState(props.demographics ? true : false);
  const [active, setActive] = useState(props.active);
  const [activeMenu, setActiveMenu] = useState(props.parentMenu);

  const [currentUser] = useGlobal("currentUser");

  let permissions = [];
  if (currentUser && currentUser.superuser_role) {
    permissions = currentUser.superuser_role.permissions;
  }

  return (
    <div className={`sidebar ${props.toggle} scrollbar`}>
      <div>
        <div className="side-nav">
          <span
            className={`${props.active == "dashboard"}`}
            onClick={() => props.history.push(`/dashboard`)}>
            Dashboard
          </span>
        </div>
      </div>
      {permissions.find(
        (m) => m.access_control.tag === "User" && m.permission_type !== "None"
      ) && (
        <div className="mt-40">
          <div
            className="nav-header text-16 text-grey-light mb-10 pointer"
            onClick={() => {
              setActiveMenu(activeMenu === "users" ? "" : "users");
            }}>
            Users{" "}
            <span className="ml-1">
              {" "}
              <ChevronDown />
            </span>
          </div>

          {activeMenu === "users" && (
            <div>
              <div className="side-nav">
                <span
                  className={`${props.active == "mentor"}`}
                  onClick={() => props.history.push(`/mentor`)}>
                  Mentors
                </span>
              </div>
              <div className="side-nav">
                <span
                  className={`${props.active == "members"}`}
                  onClick={() => props.history.push(`/members`)}>
                  Members
                </span>
              </div>
              <div className="side-nav">
                <span
                  className={`${props.active == "limbo"}`}
                  onClick={() => props.history.push(`/limbo`)}>
                  Limbo Users
                </span>
              </div>
              <div className="side-nav">
                <span
                  className={`${props.active == "reported-profiles"}`}
                  onClick={() => props.history.push(`/reported-profiles`)}>
                  Reported | Blocked
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="mt-30">
        <div
          className="nav-header text-16 text-grey-light mb-10 pointer"
          onClick={() => {
            setActiveMenu(activeMenu === "activities" ? "" : "activities");
          }}>
          Activities{" "}
          <span className="ml-1">
            {" "}
            <ChevronDown />
          </span>
        </div>
        {activeMenu === "activities" && (
          <>
            {permissions.find(
              (m) =>
                m.access_control.tag === "Booking" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active == "bookings"}`}
                  onClick={() => props.history.push(`/activities/bookings`)}>
                  Bookings
                </span>
              </div>
            )}
            {permissions.find(
              (m) =>
                m.access_control.tag === "GroupSession" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active == "group-sessions"}`}
                  onClick={() =>
                    props.history.push(`/activities/group-sessions`)
                  }>
                  Group Sessions
                </span>
              </div>
            )}
            {permissions.find(
              (m) =>
                m.access_control.tag === "Review" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active == "reviews"}`}
                  onClick={() => props.history.push(`/activities/reviews`)}>
                  Reviews
                </span>
              </div>
            )}
            {permissions.find(
              (m) =>
                m.access_control.tag === "InProductReleaseAnnouncement" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active == "feature-release"}`}
                  onClick={() =>
                    props.history.push(`/activities/feature-release`)
                  }>
                  Feature Release
                </span>
              </div>
            )}
            {permissions.find(
              (m) =>
                m.access_control.tag === "SEOPage" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active === "seo"}`}
                  onClick={() => props.history.push(`/activities/seo`)}>
                  SEO Page Creation
                </span>
              </div>
            )}
            {permissions.find(
              (m) =>
                m.access_control.tag === "Announcement" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active === "announcements"}`}
                  onClick={() =>
                    props.history.push(`/activities/announcements`)
                  }>
                  Announcements
                </span>
              </div>
            )}
            {permissions.find(
              (m) =>
                m.access_control.tag === "Banner" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active === "banner"}`}
                  onClick={() => props.history.push(`/activities/banner`)}>
                  Banner
                </span>
              </div>
            )}
            {permissions.find(
              (m) =>
                m.access_control.tag === "FAQs" && m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active === "faqs"}`}
                  onClick={() => props.history.push(`/activities/faqs`)}>
                  FAQs
                </span>
              </div>
            )}
          </>
        )}
      </div>

      <div className="mt-40">
        <div
          className="nav-header text-16 text-grey-light mb-10 pointer"
          onClick={() => {
            setActiveMenu(activeMenu === "demographics" ? "" : "demographics");
          }}>
          Demographics{" "}
          <span className="ml-1">
            {" "}
            <ChevronDown />
          </span>
        </div>
        {activeMenu === "demographics" && (
          <>
            {permissions.find(
              (m) =>
                m.access_control.tag === "Language" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active == "languages"}`}
                  onClick={() => props.history.push(`/languages`)}>
                  Languages
                </span>
              </div>
            )}
            {/* {permissions.find(
              (m) =>
                m.access_control.tag === "TopicOfInterest" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active == "topics-of-interests"}`}
                  onClick={() => props.history.push(`/topics-of-interests`)}
                >
                  Topics of interests
                </span>
              </div>
            )} */}
            {/** */}
            {permissions.find(
              (m) =>
                m.access_control.tag === "TopicPack" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active == "topic-packs"}`}
                  onClick={() =>
                    props.history.push(`/demographics/topic-packs`)
                  }>
                  Topic Packs
                </span>
              </div>
            )}
            {permissions.find(
              (m) =>
                m.access_control.tag === "LevelOfExperience" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active === "experience"}`}
                  onClick={() =>
                    props.history.push(`/demographics/level-experience`)
                  }>
                  Level of Experience
                </span>
              </div>
            )}

            {permissions.find(
              (m) =>
                m.access_control.tag === "Expertise" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active === "expertise"}`}
                  onClick={() => props.history.push(`/expertise`)}>
                  Expertise
                </span>
              </div>
            )}

            {permissions.find(
              (m) =>
                m.access_control.tag === "Discipline" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active === "disciplines"}`}
                  onClick={() =>
                    props.history.push(`/demographics/disciplines`)
                  }>
                  Disciplines
                </span>
              </div>
            )}

            {permissions.find(
              (m) =>
                m.access_control.tag === "Skill" && m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active === "skills"}`}
                  onClick={() => props.history.push(`/demographics/skills`)}>
                  Skills
                </span>
              </div>
            )}

            {permissions.find(
              (m) =>
                m.access_control.tag === "Tool" && m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active === "tools"}`}
                  onClick={() => props.history.push(`/demographics/tools`)}>
                  Tools
                </span>
              </div>
            )}

            {permissions.find(
              (m) =>
                m.access_control.tag === "Country" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active === "countries"}`}
                  onClick={() => props.history.push(`/countries`)}>
                  Countries
                </span>
              </div>
            )}
            {permissions.find(
              (m) =>
                m.access_control.tag === "Interest" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active == "interest"}`}
                  onClick={() => props.history.push(`/interest`)}>
                  Interest
                </span>
              </div>
            )}
            {permissions.find(
              (m) =>
                m.access_control.tag === "Industry" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active == "industry"}`}
                  onClick={() => props.history.push(`/industry`)}>
                  Industry
                </span>
              </div>
            )}

            {permissions.find(
              (m) =>
                m.access_control.tag === "Partner" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active == "partners"}`}
                  onClick={() => props.history.push(`/partners`)}>
                  Partners
                </span>
              </div>
            )}
          </>
        )}
      </div>

      {permissions.find(
        (m) =>
          m.access_control.tag === "Analytics" && m.permission_type !== "None"
      ) && (
        <div className="mt-30">
          <div
            className="nav-header text-16 text-grey-light mb-10 pointer"
            onClick={() => {
              setActiveMenu(activeMenu === "analytics" ? "" : "analytics");
            }}>
            Analytics{" "}
            <span className="ml-1">
              {" "}
              <ChevronDown />
            </span>
          </div>
          {activeMenu === "analytics" && (
            <>
              <div className="side-nav">
                <span
                  className={`${props.active === "users-analytics"}`}
                  onClick={() => props.history.push(`/analytics/users`)}>
                  Users
                </span>
              </div>
              <div className="side-nav">
                <span
                  className={`${props.active === "reviews-analytics"}`}
                  onClick={() => props.history.push(`/analytics/reviews`)}>
                  Reviews
                </span>
              </div>
              <div className="side-nav">
                <span
                  className={`${props.active === "notes-sent-analytics"}`}
                  onClick={() => props.history.push(`/analytics/notes-sent`)}>
                  Notes sent
                </span>
              </div>
              <div className="side-nav">
                <span
                  className={`${props.active === "messages-sent-analytics"}`}
                  onClick={() =>
                    props.history.push(`/analytics/messages-sent`)
                  }>
                  Q&amp;A{" "}
                </span>
              </div>
              <div className="side-nav">
                <span
                  className={`${props.active === "group-sessions-analytics"}`}
                  onClick={() =>
                    props.history.push(`/analytics/group-sessions`)
                  }>
                  Group Sessions
                </span>
              </div>
              <div className="side-nav">
                <span
                  className={`${props.active === "bookings-analytics"}`}
                  onClick={() => props.history.push(`/analytics/bookings`)}>
                  Bookings
                </span>
              </div>
              <div className="side-nav">
                <span
                  className={`${props.active === "feature-tracker"}`}
                  onClick={() =>
                    props.history.push(`/analytics/feature-tracker`)
                  }>
                  Feature Tracker
                </span>
              </div>
              {CanReadOrWrite("analytics", currentUser) && (
                <div className="side-nav">
                  <span
                    className={`${props.active == "advance-tracker"}`}
                    onClick={() =>
                      props.history.push(`/analytics/advanced-tracker`)
                    }>
                    Advanced DT
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      )}

      <div className="mt-30">
        <div
          className="nav-header text-16 text-grey-light mb-10 pointer"
          onClick={() => {
            setActiveMenu(activeMenu === "settings" ? "" : "settings");
          }}>
          Settings{" "}
          <span className="ml-1">
            {" "}
            <ChevronDown />
          </span>
        </div>
        {activeMenu === "settings" && (
          <>
            {permissions.find(
              (m) =>
                m.access_control.tag === "AdminUserAndRole" &&
                m.permission_type !== "None"
            ) && (
              <div className="side-nav">
                <span
                  className={`${props.active === "users-and-roles"}`}
                  onClick={() =>
                    props.history.push(`/settings/users-and-roles`)
                  }>
                  Users and Roles
                </span>
              </div>
            )}
            <div className="side-nav">
              <span
                className={`${props.active === "account-settings"}`}
                onClick={() =>
                  props.history.push(`/settings/account-settings`)
                }>
                Account Settings
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
