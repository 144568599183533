import React from "react";
import { Table as BootstrapTable } from "react-bootstrap";
import PaginationBar from "../../../../components/pagination-bar/pagination-bar.component";
import LoaderPane from "../../../../components/loader-pane/loader-pane.component";

const Table = ({
  data,
  error,
  mutate,
  paginate,
  setPaginate,
  setSideModal,
  canWrite,
}) => {
  /**
   * function
   */
  const getAudienceName = (key = "") => {
    let userType = "--";
    key = String(key)?.toLowerCase();
    switch (key) {
      case "designer":
        userType = "Mentees";
        break;
      case "mentor":
        userType = "Mentors";
        break;
      case "all":
        userType = "All users";
        break;
      case "limbo":
        userType = "Limbo";
        break;

      default:
        return key;
    }

    return userType;
  };

  return (
    <div>
      {!data || data["count"] === 0 ? (
        <LoaderPane
          loading={!data && !error}
          error={error?.message}
          noRecords={!error && data && data["count"] === 0}
          onReload={() => {
            mutate();
          }}
        />
      ) : (
        data &&
        !error &&
        data["count"] > 0 && (
          <BootstrapTable bordered responsive className="table-white">
            <thead>
              <tr>
                <th>Title</th>
                <th>Audience</th>
                <th>Type</th>
                {canWrite && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {data?.results?.map((release) => (
                <tr key={release?.id}>
                  <td>
                    <div className="d-flex pointer">
                      <span className="text-16 text-grey font-500">
                        {release?.title ||
                          release?.announcement_slides[0]?.title ||
                          "--"}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex pointer">
                      <span className="text-16 text-grey font-500">
                        {getAudienceName(release?.target_audience)}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span className="text-16 text-grey font-500">
                      {release?.announcement_slides?.length > 1
                        ? "Multi-step"
                        : "Single"}
                    </span>
                  </td>

                  {canWrite && (
                    <td>
                      <span
                        className="text-16 text-success font-500 pointer"
                        onClick={() =>
                          setSideModal({
                            show: true,
                            type: "edit",
                            data: release,
                          })
                        }
                      >
                        Update
                      </span>
                    </td>
                  )}
                </tr>
              ))}
              <tr>
                <td colSpan={6}>
                  <PaginationBar
                    page={paginate.page}
                    pageSize={paginate.pageSize}
                    totalItems={data["count"]}
                    onChangePage={(newPage) => {
                      setPaginate({ ...paginate, page: newPage });
                    }}
                    onChangeRowsPerPage={(size) => {
                      setPaginate({
                        ...paginate,
                        pageSize: size,
                        page: 0,
                      });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </BootstrapTable>
        )
      )}
    </div>
  );
};

export default Table;
