import { useState, useGlobal, useEffect } from "reactn";
import { Link } from "react-router-dom";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import FaqModal from "../../../components/modal/faq-modal/faq-modal.component";
import {
  fetchFile,
  apiBaseUrl,
  deleteData,
  SWRFetcher,
} from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
import ExportButton from "../../../components/export-button/export-button.component";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import useSWR from "swr";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import HaveAccess from "../../../access-control.jsx";

const FAQsPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const canWrite = HaveAccess("faqs", "Write", currentUser);

  const [exportFaq, setExportFaq] = useState({
    exporting: false,
    error: null,
  });

  const [query, setQuery] = useState("");
  const [canFetch, setCanfetch] = useState(false);
  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const getURL = (
    q = query,
    limit = paginate.pageSize,
    offset = paginate.pageSize * paginate.page
  ) => {
    return `faq/?q=${q}&limit=${limit}&offset=${offset}`;
  };

  const {
    data: responseData,
    error: fetchError,
    mutate: reload,
  } = useSWR(getURL(), SWRFetcher(currentUser));

  const [state, setState] = useState({
    showModal: false,
    currentFaq: null,
    modalType: "Add",
  });

  const [stateDelete, setStateDelete] = useState({
    showModal: false,
    currentFaq: null,
    title: "Delete Faq",
    description: "Do you want to delete this faq?",
    processing: false,
    error: null,
  });

  useEffect(() => {});

  //
  function onFaqUpdated(data) {
    reload();
  }

  const onDeleteFaq = () => {
    setStateDelete({ ...stateDelete, processing: true, error: null });
    let id = stateDelete.currentFaq["id"];
    deleteData("faq/" + id + "/", currentUser.token.actual).then((resp) => {
      if (resp.success) {
        setStateDelete({
          ...stateDelete,
          processing: false,
          error: null,
          showModal: false,
        });
        onFaqUpdated(resp.data);

        return;
      }
      setStateDelete({
        ...stateDelete,
        processing: false,
        error: resp.message,
      });
    });
  };

  return (
    <>
      <FaqModal
        faq={state.currentFaq}
        type={state.modalType}
        token={currentUser.token.actual}
        show={state.showModal}
        onHide={() => {
          setState({ ...state, showModal: false, currentFaq: null });
        }}
        onUpdated={onFaqUpdated}
      />

      <ConfirmModal
        show={stateDelete.showModal}
        title="Delete Faq"
        description={stateDelete.description}
        error={stateDelete.error}
        processing={stateDelete.processing}
        onHide={() => {
          setStateDelete({ ...stateDelete, showModal: false, error: null });
        }}
        onConfirm={onDeleteFaq}
      />

      <PageLayout activeLink="faqs" parentMenu="activities">
        <div>
          <div className="d-flex space-between  mb-20">
            <div className="text-grey text-20 font-bold">
              FAQs<span className="text-16"></span>
            </div>

            {/* <ExportButton label="Export to CVS"
                        url="account/report/review/export/"
                        fileName="faqs"
                    /> */}
          </div>

          <Row>
            <Col md={3} xs={12}>
              {canWrite && (
                <Button
                  classes={`small success mb-20`}
                  onClick={() => {
                    setState({ ...state, modalType: "Add", showModal: true });
                  }}
                >
                  <PlusIcon /> <span className="pl-2">Add new faq</span>
                </Button>
              )}
            </Col>
            <Col md={4} />

            <Col md={5}>
              <InputField
                placeholder="Search  FAQ"
                classes="small mb-20"
                isSearch
                value={query}
                onChange={(ev) => {
                  reload(getURL(ev.target.value, paginate.pageSize, 0));
                  setQuery(ev.target.value);
                }}
              />
            </Col>
          </Row>

          <LoaderPane
            loading={!responseData && !fetchError}
            error={fetchError?.message}
            noRecords={
              !fetchError && responseData && responseData?.length === 0
            }
            onReload={() => {
              reload();
            }}
          />

          {responseData && !fetchError && responseData?.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {responseData &&
                    responseData?.map((faq) => (
                      <tr key={faq.id}>
                        <td>
                          <span className="text-16 text-grey font-400">
                            {faq.title}{" "}
                          </span>
                        </td>

                        <td>
                          {canWrite && (
                            <>
                              <span
                                className="text-16 text-success font-400 pointer mr-3"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    currentFaq: faq,
                                    modalType: "Edit",
                                    showModal: true,
                                  });
                                }}
                              >
                                Edit
                              </span>

                              <span
                                className="text-16 text-danger font-400 pointer mr-2"
                                onClick={() => {
                                  setStateDelete({
                                    ...stateDelete,
                                    currentFaq: faq,
                                    showModal: true,
                                  });
                                }}
                              >
                                Delete
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  {/* <tr>
                            <td colSpan={2}>
                                <PaginationBar 
                                page={paginate.page} 
                                pageSize={paginate.pageSize}
                                totalItems={responseData["count"]}
                                onChangePage={(newPage)=>{
                                    setPaginate({...paginate, page: newPage })
                                }} 
                                onChangeRowsPerPage={(size)=>{
                                    setPaginate({...paginate, pageSize: size, page: 0 })
                                }}/>
                            </td>
                        </tr> */}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default FAQsPage;
