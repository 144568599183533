import {
  FormControl,
  Select,
  MenuItem as SelectMenuItem,
} from "@material-ui/core";
import { useState } from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import Button from "components/button/button.component";

const ConfirmDeactivateUserModal = ({
  show,
  onHide,
  userToDeactivateName,
  success = null,
  processing = false,
  error = null,
  onConfirm = (reason) => {},
  onBlurHide = false,
}) => {
  const [reason, setReason] = useState("");

  return (
    <div>
      <Modal
        className="center-modal"
        show={show}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header>
          <div className="font-500">Deactivate user</div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div>
            <div className="text-grey" style={{ marginTop: "-21px" }}>
              Do you want to deactivate <b>{userToDeactivateName}'s</b> ADPList
              account? This would not permit them to log into the platform
            </div>

            <div className="mt-25">
              <div className="text-grey-light text-16 font-400 mb-10">
                Select reason to deactivate
              </div>

              <FormControl fullWidth variant="outlined" className="mt-20">
                <Select
                  value={reason}
                  onChange={(event) => {
                    setReason(event.target.value);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}>
                  <SelectMenuItem value="">Select one reason</SelectMenuItem>
                  <SelectMenuItem value="At Customer’s request">
                    At Customer’s request
                  </SelectMenuItem>
                  <SelectMenuItem value="Spam">Spam</SelectMenuItem>
                  <SelectMenuItem value="Scam">Scam</SelectMenuItem>
                  <SelectMenuItem value="Multiple reports">
                    Multiple reports
                  </SelectMenuItem>
                  <SelectMenuItem value="Inappropriate/ Offensive behaviour">
                    Inappropriate/ Offensive behaviour
                  </SelectMenuItem>
                  <SelectMenuItem value="Duplicate account">
                    Duplicate account
                  </SelectMenuItem>
                </Select>
              </FormControl>
            </div>

            {success && !processing && (
              <div className="mt-25 text-success text-center">{success}</div>
            )}

            {error && (!processing || !success) && (
              <div className="mt-25 text-danger text-center">{error}</div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="f-width">
            <Row>
              <Col xs={6}>
                <Button
                  classes="small info f-width"
                  disabled={processing}
                  onClick={onHide}>
                  {success ? "Close" : "Cancel"}
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  classes="small danger f-width"
                  hidden={success}
                  loading={processing}
                  disabled={processing}
                  onClick={() => onConfirm(reason)}>
                  Confirm
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmDeactivateUserModal;
