import { Fragment } from "react";
import SlideLogic from "./Slide/SlideLogic";

const Slide = ({
  type,
  state,
  mutate,
  setState,
  slideCount,
  setSlideCount,
  featureRelease,
}) => {
  return (
    <Fragment>
      {type?.toLowerCase() === "new"
        ? slideCount > 0 &&
          Array.from({ length: slideCount }, (slide, index) => (
            <SlideLogic
              key={index}
              index={index}
              {...{
                type,
                state,
                mutate,
                setState,
                slideCount,
                setSlideCount,
                featureRelease,
              }}
            />
          ))
        : type?.toLowerCase() === "edit" &&
          (featureRelease?.announcement_slides?.length > 0
            ? featureRelease?.announcement_slides?.map((slide, index) => (
                <SlideLogic
                  key={index}
                  index={index}
                  id={slide.id}
                  {...{
                    type,
                    state,
                    mutate,
                    setState,
                    slideCount,
                    setSlideCount,
                    featureRelease,
                  }}
                />
              ))
            : slideCount > 0
            ? Array.from({ length: slideCount }, (slide, index) => (
                <SlideLogic
                  key={index}
                  index={index + featureRelease?.announcement_slides?.length}
                  {...{
                    type,
                    state,
                    mutate,
                    setState,
                    slideCount,
                    setSlideCount,
                    featureRelease,
                  }}
                />
              ))
            : null)}
    </Fragment>
  );
};

export default Slide;
