import { useState, useGlobal, useEffect } from "reactn";
import { Link } from "react-router-dom";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import ExpertiseModal from "../../../components/modal/expertise-modal/expertise-modal.component";
import { fetchData, putData } from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import HaveAccess from "../../../access-control.jsx";

const ExpertisePage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [expertise, setExpertise] = useGlobal("expertise");
  const canWrite = HaveAccess("topicOfInterest", "Write", currentUser);

  const [state, setState] = useState({
    activeTab: 0,
    showExpertiseModal: false,
    ExpModalType: null,
    currentexpertise: null,
    fetching: false,
    canFetch: true,
    error: null,
    query: "",
    queryDisabled: "",
    activeExpertise: expertise.filter((exp) => exp.disabled === false),
    disabledExpertise: expertise.filter((exp) => exp.disabled === true),
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [disabledPaginate, setDisabledPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (state.canFetch && !state.fetching && expertise.length == 0) {
      setState({
        ...state,
        fetching: true,
        canFetch: false,
      });

      let authToken = currentUser.token.actual;
      fetchData("expertise/", authToken, false).then((response) => {
        if (response.success) {
          setState({
            ...state,
            fetching: false,
            canFetch: false,
            error: null,
            activeExpertise: response.data.filter(
              (exp) => exp.disabled === false
            ),
            disabledExpertise: response.data.filter(
              (exp) => exp.disabled === true
            ),
          });
          setExpertise(response.data);
          return;
        }
        setState({
          ...state,
          fetching: false,
          canFetch: false,
          error: response.message,
        });
      });
    }
  }, [
    expertise,
    state.activeExpertise,
    state.disabledExpertise,
    state.canFetch,
    state.limit,
    paginate,
  ]);

  //edit or add --callback
  function onExpertiseUpdated(data) {
    setState({
      ...state,
      activeExpertise: data.filter((exp) => exp.disabled === false),
      disabledExpertise: data.filter((exp) => exp.disabled === true),
      showExpertiseModal: false,
    });
    setExpertise(data);
  }

  //confirm disable or enable interest
  function onExpertiseDisableOrEnable() {
    setState({ ...state, confirmProcessing: true });
    let data = {
      description: state.currentExpertise.description,
      disabled: state.currentExpertise.disabled ? false : true,
    };
    putData(
      `expertise/${state.currentExpertise.id}/`,
      data,
      currentUser.token.actual
    ).then((response) => {
      if (response.success) {
        let current = expertise.filter(
          (exp) => exp.id !== state.currentExpertise["id"]
        );
        let newData = [...current, response.data];
        setState({
          ...state,
          confirmProcessing: false,
          confirmError: null,
          showConfirmModal: false,
          activeExpertise: newData.filter((exp) => exp.disabled === false),
          disabledExpertise: newData.filter((exp) => exp.disabled === true),
        });
        setExpertise(newData);

        return;
      }
      setState({
        ...state,
        confirmProcessing: false,
        confirmError: response.message,
      });
    });
  }

  //active filtering
  const activeExpertise = () => {
    let active = state.activeExpertise;
    let totalItems = active.length;

    if (state.query.length > 0) {
      active = active.filter((exp) =>
        exp.description.toLowerCase().includes(state.query)
      );
      //list length
      totalItems = active.length;
    }

    // paginate
    const { page, pageSize } = paginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: active.slice(start, end), totalItems: totalItems };
  };

  //disabled mentors filtering
  const disabledExpertise = () => {
    let disabled = state.disabledExpertise;
    let totalItems = disabled.length;

    if (state.query.length > 0) {
      disabled = disabled.filter((exp) =>
        exp.description.toLowerCase().includes(state.query.toLowerCase())
      );
      //list length
      totalItems = disabled.length;
    }

    // paginate
    const { page, pageSize } = disabledPaginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: disabled.slice(start, end), totalItems: totalItems };
  };

  return (
    <>
      <ExpertiseModal
        currentExpertise={state.currentExpertise}
        token={currentUser.token.actual}
        show={state.showExpertiseModal}
        type={state.ExpModalType}
        onHide={() => {
          setState({ ...state, showExpertiseModal: false });
        }}
        onUpdated={onExpertiseUpdated}
      />

      <ConfirmModal
        show={state.showConfirmModal}
        token={currentUser.token.actual}
        title={state.confirmTitle}
        description={state.confirmDescription}
        processing={state.confirmProcessing}
        error={state.confirmError}
        onHide={() => {
          setState({ ...state, confirmError: null, showConfirmModal: false });
        }}
        onConfirm={onExpertiseDisableOrEnable}
      />

      <PageLayout activeLink="expertise" parentMenu="demographics">
        <div>
          <div className="text-grey text-20 font-bold mb-20">Expertise</div>

          <Row>
            <Col md={7}>
              <div className="d-flex flex-wrap align-center mb-20">
                <Button
                  classes={`pill success ${state.activeTab == 0} mr-2  mb-20`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 0 });
                  }}
                >
                  Active({state.activeExpertise.length})
                </Button>

                <Button
                  classes={`pill success ${state.activeTab == 1}  mb-20 mr-3`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 1 });
                  }}
                >
                  Disabled({state.disabledExpertise.length})
                </Button>
                {canWrite && (
                  <Button
                    classes={`small success w-200  mb-20`}
                    onClick={() => {
                      setState({
                        ...state,
                        ExpModalType: "Add",
                        showExpertiseModal: true,
                      });
                    }}
                  >
                    <PlusIcon /> <span className="pl-2">Add Expertise</span>
                  </Button>
                )}
              </div>
            </Col>

            <Col md={5}>
              {state.activeTab == 0 && (
                <InputField
                  placeholder="Search  expertise"
                  classes="small mb-20"
                  isSearch
                  value={state.query}
                  onChange={(ev) => {
                    setState({ ...state, query: ev.target.value });
                    if (paginate.page > 0)
                      setPaginate({ ...paginate, page: 0 });
                  }}
                />
              )}

              {state.activeTab == 1 && (
                <InputField
                  placeholder="Search disabled  expertise"
                  classes="small mb-20"
                  isSearch
                  value={state.queryDisabled}
                  onChange={(ev) => {
                    setState({ ...state, queryDisabled: ev.target.value });
                    if (disabledPaginate.page > 0)
                      setDisabledPaginate({ ...disabledPaginate, page: 0 });
                  }}
                />
              )}
            </Col>
          </Row>

          {state.activeTab == 0 && state.activeExpertise.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Expertise</th>
                    <th>Color Code</th>
                    {/*<th>Number of Mentors</th>
                    <th>Number of Designers</th>*/}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {activeExpertise().items.map((exp) => (
                    <tr key={exp.id}>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {exp.description}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {exp.color_code}{" "}
                        </span>
                      </td>
                      {/*<td>
                        <span className="text-16 text-grey font-400"> </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-400"></span>
                      </td>*/}
                      <td>
                        <span
                          hidden={!canWrite}
                          className="text-16 text-success font-400 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              ExpModalType: "Edit",
                              currentExpertise: exp,
                              showExpertiseModal: true,
                            });
                          }}
                        >
                          Edit
                        </span>

                        <span
                          hidden={!canWrite}
                          className="text-16 text-warning font-400 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              currentExpertise: exp,
                              confirmTitle: "Disable Expertise",
                              confirmDescription: `Do you want to disable this expertise [ ${exp.description} ] `,
                              showConfirmModal: true,
                            });
                          }}
                        >
                          Disable
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={3}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={activeExpertise().totalItems}
                        onChangePage={(newPage) => {
                          setPaginate({ ...paginate, page: newPage });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({ ...paginate, pageSize: size, page: 0 });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {/*no active expertise */}
          {state.activeTab == 0 &&
            state.activeExpertise.length == 0 &&
            !state.fetching && (
              <div className="text-grey text-16 mb-20">
                {" "}
                No active Expertise{" "}
              </div>
            )}

          {state.activeTab == 1 && state.disabledExpertise.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Expertise</th>
                    <th>Color Code</th>
                    {/*  <th>Number of Mentors</th>
                    <th>Number of Designers</th> */}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {disabledExpertise().items.map((exp) => (
                    <tr key={exp.id}>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {exp.description}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {exp.color_code}{" "}
                        </span>
                      </td>
                      {/* <td>
                        <span className="text-16 text-grey font-400"> </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-400"></span>
                      </td> */}
                      <td>
                        <span
                          hidden={!canWrite}
                          className="text-16 text-success font-400 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              ExpModalType: "Edit",
                              currentExpertise: exp,
                              showExpertiseModal: true,
                            });
                          }}
                        >
                          Edit
                        </span>

                        <span
                          hidden={!canWrite}
                          className="text-16 text-grey font-400 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              currentExpertise: exp,
                              confirmTitle: "Enable Expertise",
                              confirmDescription: `Do you want to enable this expertise [ ${exp.description} ] `,
                              showConfirmModal: true,
                            });
                          }}
                        >
                          Enable
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={3}>
                      <PaginationBar
                        page={disabledPaginate.page}
                        pageSize={disabledPaginate.pageSize}
                        totalItems={disabledExpertise().totalItems}
                        onChangePage={(newPage) => {
                          setDisabledPaginate({
                            ...disabledPaginate,
                            page: newPage,
                          });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setDisabledPaginate({
                            ...disabledPaginate,
                            pageSize: size,
                            page: 0,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {/*no disabled expertise */}
          {state.activeTab == 1 &&
            state.disabledExpertise.length == 0 &&
            !state.fetching && (
              <div className="text-grey text-16 mb-20">
                {" "}
                No disabled expertise{" "}
              </div>
            )}

          <div className="mt-10 d-flex justify-center">
            <Spinner
              hidden={!state.fetching || state.query.length > 0}
              animation="border"
              variant="primary"
            />
          </div>

          <div className="mt-30" hidden={!state.error || state.fetching}>
            <span className="mt-10 text-danger">{state.error}.</span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => setState({ ...state, canFetch: true })}
            >
              TRY AGAIN
            </span>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default ExpertisePage;
