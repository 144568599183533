import { useGlobal, useEffect } from "reactn";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import { SWRFetcher } from "./api/api";
import LoginPage from "./pages/auth/login/login.page.jsx";
import DashboardPage from "./pages/dashboard/dashboard.page";
import MentorPage from "./pages/mentor/mentor.page";
import DesignerPage from "./pages/designer/designer.page";
import GuestPage from "./pages/guest/guest.page";
import ReviewsPage from "./pages/reviews/reviews.page";
import LanguagesPage from "./pages/demographics/languages/languages.page";
import TopicsOfInterestsPage from "./pages/demographics/topics-of-interests/topics-of-interests.page";
import ExpertisePage from "./pages/demographics/expertise/expertise.page";
import CountriesPage from "./pages/demographics/countries/countries.page";
import ReportedProfilesPage from "./pages/reported-profiles/reported-profiles.page";
import AnnouncementsPage from "./pages/announcements/announcements.page";
import UsersAnalyticsPage from "./pages/analytics/users/users-analytics.page";
import ReviewsAnalyticsPage from "./pages/analytics/reviews/reviews-analytics.page";
import MessagesSentAnalyticsPage from "./pages/analytics/messages-sent/messages-sent-analytics.page";
import NotesSentAnalyticsPage from "./pages/analytics/notes-sent/notes-sent-analytics.page";
import GroupSessionsPage from "./pages/group-sessions/group-sessions.page";
import GroupSessionsAnalyticsPage from "./pages/analytics/group-sessions/group-sessions-analytics.page";
import IndustryPage from "./pages/demographics/industry/industry.page";
import InterestPage from "./pages/demographics/interest/interest.page";
import PartnersPage from "./pages/demographics/partners/partners.page";
import BannerPage from "./pages/banner/banner.page";
import BookingsPage from "./pages/activities/bookings/bookings.page";
import FeatureReleasePage from "./pages/activities/feature-release/feature-release.page";
import BookingsAnlyticsPage from "./pages/analytics/bookings/bookings-analytics.page";
import FAQsPage from "./pages/activities/faqs/faqs.page";
import FeatureTrackerPage from "./pages/analytics/feature-tracker/feature-tracker.page";
import UsersAndRolesPage from "./pages/settings/users-and-roles/users-and-roles.page";
import AccountSettingsPage from "./pages/settings/account-settings/account-settings.page";
import AdvancedTrackerPage from "./pages/analytics/advanced-tracker/advanced-tracker.page";
import TopicPacksPage from "./pages/demographics/topic-packs/topic-packs.page";
import ExperiencePage from "./pages/demographics/level-experience/experience.page";
import ToolsPage from "pages/demographics/tools/tools.page";
import DisciplinesPage from "pages/demographics/disciplines/disciplines.page";
import SkillsPage from "pages/demographics/skills/skills.page";

import "./app.scss";
import SEOPage from "pages/activities/seo/seo.page";

const App = ({ history }) => {
  const [currentUser] = useGlobal("currentUser");

  useEffect(() => {
    history.replace(window.history.href);
    //check if mobile
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      window.bottomSpace = 80;
    } else {
      window.bottomSpace = 0;
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        fetcher: SWRFetcher(currentUser),
        shouldRetryOnError: false,
        errorRetryCount: 1,
        onError: (error, key) => {
          //alert(error)
          if (String(error) === "Error: Request failed with status code 401") {
            localStorage.clear();
            window.location.href = "/login";
          }
        },
      }}>
      <div>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              currentUser ? <DashboardPage /> : <Redirect to="/login" />
            }
          />
          <Route
            exact
            path="/dashboard"
            render={() =>
              currentUser ? <DashboardPage /> : <Redirect to="/login" />
            }
          />
          <Route
            exact
            path="/login/google/redirect"
            render={() => <LoginPage />}
          />

          <Route
            exact
            path="/login"
            render={() =>
              currentUser ? <Redirect to="/dashboard" /> : <LoginPage />
            }
          />

          {currentUser && (
            <>
              <Route
                exact
                path="/mentor"
                render={() =>
                  currentUser ? <MentorPage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/members"
                render={() =>
                  currentUser ? <DesignerPage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/limbo"
                render={() =>
                  currentUser ? <GuestPage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/languages"
                render={() =>
                  currentUser ? <LanguagesPage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/topics-of-interests"
                render={() =>
                  currentUser ? (
                    <TopicsOfInterestsPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />

              <Route
                exact
                path="/expertise"
                render={() =>
                  currentUser ? <ExpertisePage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/countries"
                render={() =>
                  currentUser ? <CountriesPage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/reported-profiles"
                render={() =>
                  currentUser ? (
                    <ReportedProfilesPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/industry"
                render={() =>
                  currentUser ? <IndustryPage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/interest"
                render={() =>
                  currentUser ? <InterestPage /> : <Redirect to="/login" />
                }
              />
              <Route
                exact
                path="/partners"
                render={() =>
                  currentUser ? <PartnersPage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/analytics/users"
                render={() =>
                  currentUser ? (
                    <UsersAnalyticsPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/analytics/reviews"
                render={() =>
                  currentUser ? (
                    <ReviewsAnalyticsPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/analytics/notes-sent"
                render={() =>
                  currentUser ? (
                    <NotesSentAnalyticsPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />

              <Route
                exact
                path="/analytics/messages-sent"
                render={() =>
                  currentUser ? (
                    <MessagesSentAnalyticsPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/analytics/group-sessions"
                render={() =>
                  currentUser ? (
                    <GroupSessionsAnalyticsPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/analytics/bookings"
                render={() =>
                  currentUser ? (
                    <BookingsAnlyticsPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/analytics/feature-tracker"
                render={() =>
                  currentUser ? (
                    <FeatureTrackerPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/analytics/advanced-tracker"
                render={() =>
                  currentUser ? (
                    <AdvancedTrackerPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />

              <Route
                exact
                path="/activities/bookings"
                render={() =>
                  currentUser ? <BookingsPage /> : <Redirect to="/login" />
                }
              />
              <Route
                exact
                path="/activities/group-sessions"
                render={() =>
                  currentUser ? <GroupSessionsPage /> : <Redirect to="/login" />
                }
              />
              <Route
                exact
                path="/activities/reviews"
                render={() =>
                  currentUser ? <ReviewsPage /> : <Redirect to="/login" />
                }
              />
              <Route
                exact
                path="/activities/feature-release"
                render={() =>
                  currentUser ? (
                    <FeatureReleasePage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/activities/seo"
                render={() =>
                  currentUser ? <SEOPage /> : <Redirect to="/login" />
                }
              />
              <Route
                exact
                path="/activities/banner"
                render={() =>
                  currentUser ? <BannerPage /> : <Redirect to="/login" />
                }
              />
              <Route
                exact
                path="/activities/announcements"
                render={() =>
                  currentUser ? <AnnouncementsPage /> : <Redirect to="/login" />
                }
              />
              <Route
                exact
                path="/activities/faqs"
                render={() =>
                  currentUser ? <FAQsPage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/demographics/topic-packs"
                render={() =>
                  currentUser ? <TopicPacksPage /> : <Redirect to="/login" />
                }
              />
              <Route
                exact
                path="/demographics/level-experience"
                render={() =>
                  currentUser ? <ExperiencePage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/demographics/tools"
                render={() =>
                  currentUser ? <ToolsPage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/demographics/disciplines"
                render={() =>
                  currentUser ? <DisciplinesPage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/demographics/skills"
                render={() =>
                  currentUser ? <SkillsPage /> : <Redirect to="/login" />
                }
              />

              <Route
                exact
                path="/settings/users-and-roles"
                render={() =>
                  currentUser ? <UsersAndRolesPage /> : <Redirect to="/login" />
                }
              />
              <Route
                exact
                path="/settings/account-settings"
                render={() =>
                  currentUser ? (
                    <AccountSettingsPage />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
            </>
          )}
        </Switch>
      </div>
    </SWRConfig>
  );
};

export default withRouter(App);
