import { useState, useGlobal, useEffect } from "reactn";
import SideModal from "../side-modal/side-modal.component";
import InputField from "../../form/input-field/input-field.component";
import FileInput from "../../form/file-input/file-input.component";
import Button from "../../button/button.component";
import { Modal } from "react-bootstrap";
import { postData, putData } from "../../../api/api";
import ToggleButton from "../../form/toggle-button/toggle-button.component";

const LevelModal = ({
  show,
  onHide,
  type = "Add",
  level,
  token,
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    level: "",
    disabled: true,
    processing: false,
    error: null,
    success: null,
  });

  useEffect(() => {
    level
      ? setState({
          level: level?.level,
          disabled: level?.disabled,
          processing: false,
          error: null,
          success: null,
        })
      : reset();
  }, [show, level]);

  const reset = () => {
    setState({
      level: "",
      disabled: true,
      processing: false,
      error: null,
      success: null,
    });
  };

  return (
    <>
      {(level || type === "Add") && (
        <SideModal
          show={show}
          onHide={() => {
            reset();
            onHide();
          }}
          onBlurHide={false}
        >
          <Modal.Body className="scrollbar">
            <div className="modal-inner">
              <div className="content-medium">
                {/* add level */}
                <div>
                  <div className="text-dark text-20 font-bold mb-30">
                    {type === "Add" ? "Add" : "Edit"} Level
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Title"
                      placeholder={"eg. Level"}
                      value={state.level}
                      onChange={(e) =>
                        setState({ ...state, level: e.target.value })
                      }
                    />
                  </div>
                  {/* <div className="mb-20">
                    <InputField
                      label="Color code"
                      placeholder={"eg. #ffffff"}
                      value={state.color_code}
                      onChange={(e) =>
                        setState({ ...state, color_code: e.target.value })
                      }
                    />
                  </div> */}
                  <div>
                    <ToggleButton
                      label="Disabled"
                      checked={state.disabled}
                      //loading={state.canCreateSessionProcessing}
                      blocked={state.processing}
                      onChange={() => {
                        let d = state.disabled ? false : true;
                        setState({ ...state, disabled: d });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="modal-footer-inner"
              style={{ paddingBottom: `${window.bottomSpace}` }}
            >
              {state.error && !state.processing && (
                <div className="text-danger text-16 mb-10">{state.error}</div>
              )}

              {state.success && !state.processing && (
                <div className="text-success text-16 mb-10">
                  {state.success}
                </div>
              )}

              <div className="d-flex">
                <Button
                  classes="small secondary mr-2"
                  loading={state.processing}
                  disabled={state.level.length < 1 || state.processing}
                  onClick={() => {
                    setState({
                      ...state,
                      processing: true,
                      error: null,
                      success: null,
                    });
                    const data = new FormData();
                    data.append("level", state.level);
                    data.append("disabled", state.disabled);

                    if (type === "Add") {
                      postData("level-of-experience/", data, token).then(
                        (resp) => {
                          if (resp.success) {
                            onHide();
                            onUpdated();
                            return;
                          }
                          setState({
                            ...state,
                            processing: false,
                            error:
                              resp.message ||
                              "Failed to add level. Please try again.",
                          });
                        }
                      );
                    } else {
                      let id = level["id"];
                      putData(
                        "level-of-experience/" + id + "/",
                        data,
                        token,
                        true
                      ).then((resp) => {
                        if (resp.success) {
                          setState({
                            ...state,
                            processing: false,
                            success: "Level updated",
                            ...resp.data,
                          });
                          onUpdated(resp.data);
                          return;
                        }
                        setState({
                          ...state,
                          processing: false,
                          error:
                            resp.message ||
                            "Failed to update level. Please try again.",
                        });
                      });
                    }
                  }}
                >
                  {type === "Add" ? "Add" : "Update"} Level
                </Button>
                <Button
                  classes="small info"
                  disabled={state.processing}
                  onClick={() => {
                    reset();
                    onHide();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </SideModal>
      )}
    </>
  );
};

export default LevelModal;
