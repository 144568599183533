import { useState, useEffect } from "reactn";
import SideModal from "../side-modal/side-modal.component";
import { Table, Modal, Row, Col } from "react-bootstrap";
import InputField from "../../../components/form/input-field/input-field.component";
import Button from "../../../components/button/button.component";
import Editor from "../../../components/form/editor/editor.component";
import ToggleButton from "../../../components/form/toggle-button/toggle-button.component";
import { postData, putData } from "../../../api/api";

const RolesModal = ({
  show,
  onHide,
  accessControls = null,
  type,
  canWrite,
  role,
  token,
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    name: "",
    description: "",
    processing: false,
    error: null,
  });

  const isSuperAdmin = role?.name == "Super Admin";

  const [perms, setPerms] = useState([
    /* 
        {
            access_control_id: 2,
            permission_type: "Write"
        } */
  ]);

  const reset = () => {
    setState({
      name: "",
      description: "",
      processing: false,
      error: null,
    });

    if (type === "Add" && accessControls) {
      let allPerms = [];
      accessControls?.forEach((access_control) =>
        allPerms.push({
          access_control_id: access_control.id,
          permission_type: "None",
        })
      );
      setPerms(allPerms);
    }
  };

  useEffect(() => {
    reset();

    if (type === "Edit" && role) {
      setState({
        ...state,
        name: role.name,
        description: role.description,
      });

      let allPerms = [];
      role.permissions?.forEach((perm) =>
        allPerms.push({
          access_control_id: perm.access_control.id,
          permission_type: perm.permission_type,
        })
      );
      setPerms(allPerms);
      console.log(allPerms);
    }
  }, [show, type]);

  const onPublishRole = () => {
    setState({
      ...state,
      error: null,
      processing: true,
    });

    let body = {
      name: state.name,
      description: state.description,
      permissions: perms,
    };

    if (type === "Add") {
      postData(`dashboard/role/`, body, token).then((response) => {
        if (response.success) {
          setState({
            ...state,
            processing: false,
          });
          onUpdated();
          onHide();
          return true;
        }
        setState({
          ...state,
          error: response.error || "Unable to publish role.",
        });
      });
    } else {
      putData(`dashboard/role/${role.id}/`, body, token).then((response) => {
        if (response.success) {
          setState({
            ...state,
            processing: false,
          });
          onUpdated();
          onHide();
          return true;
        }
        setState({
          ...state,
          error: response.error || "Unable to publish role.",
        });
      });
    }
  };

  return (
    <>
      <SideModal show={show} onHide={onHide} onBlurHide={true}>
        <Modal.Body className="scrollbar">
          <div className="modal-inner">
            <div className="content-medium">
              <div className="text-dark text-20 font-bold mb-30">
                {type === "Add" ? "Add new " : !canWrite ? "" : "Edit"} Role
              </div>
              <Row>
                <Col md={12} className="mb-30">
                  <InputField
                    label="Name"
                    value={state.name}
                    placeholder="eg. Admin"
                    disabled={!canWrite}
                    onChange={(ev) => {
                      setState({ ...state, name: ev.target.value });
                    }}
                  />
                </Col>

                <Col md={12}>
                  <InputField
                    label="Description"
                    value={state.description}
                    placeholder=""
                    disabled={!canWrite}
                    onChange={(ev) => {
                      setState({ ...state, description: ev.target.value });
                    }}
                  />
                </Col>
                <Col>
                  <Table bordered responsive className="table-white mt-20">
                    <thead>
                      <tr>
                        <th>
                          <span className="">Access Controls</span>
                        </th>
                        <th colSpan={2}>Permissions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>Read</td>
                        <td>Write</td>
                      </tr>
                      {accessControls &&
                        accessControls.map((accessControl) => {
                          const permission = perms.find(
                            (perm) => perm.access_control_id == accessControl.id
                          );

                          return (
                            <tr key={accessControl.id}>
                              <td>
                                <span className="text-16 font-400">
                                  {accessControl.name}
                                </span>
                              </td>
                              <td>
                                <ToggleButton
                                  checked={
                                    permission?.permission_type === "Read"
                                  }
                                  disabled={!canWrite || isSuperAdmin}
                                  onChange={(value) => {
                                    // retrieve permission
                                    let permission = perms.find(
                                      (perm) =>
                                        perm.access_control_id ===
                                        accessControl.id
                                    );

                                    let newPerm = !permission;
                                    if (newPerm) {
                                      permission = {
                                        access_control_id: accessControl.id,
                                      };
                                    }

                                    permission.permission_type = value
                                      ? "Read"
                                      : "None";

                                    let newPerms = [...perms];
                                    if (newPerm) {
                                      newPerms.push(permission);
                                    } else {
                                      // find index of permission
                                      let permissionIndex = perms.findIndex(
                                        (perm) =>
                                          perm.access_control_id ===
                                          accessControl.id
                                      );

                                      // replace the existing one with the new one
                                      newPerms.splice(
                                        permissionIndex,
                                        1,
                                        permission
                                      );
                                    }

                                    setPerms(newPerms);
                                  }}
                                />
                              </td>
                              <td>
                                <ToggleButton
                                  checked={
                                    permission?.permission_type === "Write"
                                  }
                                  disabled={!canWrite || isSuperAdmin}
                                  onChange={(value) => {
                                    // retrieve permission
                                    let permission = perms.find(
                                      (perm) =>
                                        perm.access_control_id ===
                                        accessControl.id
                                    );

                                    let newPerm = !permission;
                                    if (newPerm) {
                                      permission = {
                                        access_control_id: accessControl.id,
                                      };
                                    }

                                    permission.permission_type = value
                                      ? "Write"
                                      : "None";

                                    let newPerms = [...perms];
                                    if (newPerm) {
                                      newPerms.push(permission);
                                    } else {
                                      // find index of permission
                                      let permissionIndex = perms.findIndex(
                                        (perm) =>
                                          perm.access_control_id ===
                                          accessControl.id
                                      );

                                      // replace the existing one with the new one
                                      newPerms.splice(
                                        permissionIndex,
                                        1,
                                        permission
                                      );
                                    }

                                    setPerms(newPerms);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        {canWrite && !isSuperAdmin && (
          <Modal.Footer>
            <div
              className="modal-footer-inner"
              style={{ paddingBottom: `${window.bottomSpace}` }}
            >
              {state.error && (
                <div className="ml-3 mb-2 text-danger">{state.error}</div>
              )}
              <Col xs={5}>
                <Button
                  secondary
                  classes="small w-100 mt-10 secondary"
                  loading={state.processing}
                  disabled={
                    state.processing ||
                    state.name.length === 0 ||
                    state.description.length === 0 ||
                    perms.length === 0
                  }
                  onClick={onPublishRole}
                >
                  Publish Role
                </Button>
              </Col>
            </div>
          </Modal.Footer>
        )}
      </SideModal>
    </>
  );
};

export default RolesModal;
