import { useState, useGlobal, useEffect } from "reactn";
import { Link } from "react-router-dom";
import Sidebar from "../../../components/sidebar/sidebar.component";
import Navbar from "../../../components/navbar/navbar.component";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import TopicPacksModal from "../../../components/modal/topic-packs-modal/topic-packs-modal.component";
import { deleteData, fetchData, putData } from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import HaveAccess from "../../../access-control.jsx";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import MotivationModal from "../../../components/modal/motivation-modal/motivation-modal.component";

const TopicPacksPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const canWriteTopic = HaveAccess("topicPack", "Write", currentUser);
  const canWriteMotivation = HaveAccess("motivation", "Write", currentUser);

  const [state, setState] = useState({
    activeTab: 0,
    showTopicModal: false,
    topicModalType: "Add",
    currentTopic: null,
    currentMotiv: null,
    motivModalType: "Add",
    showMotivModal: false,
  });

  const [query, setQuery] = useState("");

  const [paginate, setPaginate] = useState({
    page: 0,
    pageSize: 10,
  });

  const [paginate2, setPaginate2] = useState({
    page: 0,
    pageSize: 10,
  });

  const [canFetchTab1, setCanFetchTab1] = useState(false);

  const {
    data: topicsData,
    error: topicsError,
    mutate: mutateTopics,
  } = useSWR(
    `topic-pack/?offset=${paginate.pageSize * paginate.page}&limit=${
      paginate.pageSize
    }&q=${query}`
  );

  const {
    data: motivationData,
    error: motivationError,
    mutate: mutateMotivation,
  } = useSWR(
    canFetchTab1 &&
      `motivation/?offset=${paginate.pageSize * paginate.page}&limit=${
        paginate.pageSize
      }`
  );

  const { data: expertiseData, error: expertiseError } = useSWR(`expertise/`);

  const { data: topicsTotal, mutate: mutateTopicsTotal } = useSWR(
    `topic-pack/?offset=0&limit=1`
  );

  const { data: motivationTotal, mutate: mutateMotivationTotal } = useSWR(
    `motivation/?offset=0&limit=1`
  );

  const [confirm, setConfirm] = useState({
    title: "Confirm",
    description: "",
    processing: false,
    error: null,
    success: "",
    data: null,
    action: "",
  });

  useEffect(() => {}, []);

  //edit or add --callback
  function onTopicUpdated() {
    mutateTopics();
    mutateTopicsTotal();
  }

  function onMotivationUpdated() {
    mutateMotivation();
    mutateMotivationTotal();
  }

  //confirm d
  function onDeleteMotivation() {
    setConfirm({ ...confirm, show: true, processing: true, error: null });

    deleteData(
      `motivation/${confirm?.data.id}/`,
      currentUser.token.actual
    ).then((response) => {
      if (response.success) {
        setConfirm({
          ...confirm,
          processing: false,
          success: "",
          show: false,
          data: null,
          action: "",
        });
        onMotivationUpdated();
        return;
      }

      setConfirm({
        ...confirm,
        processing: false,
        error: response.message || "unable to delete",
      });
    });
  }

  return (
    <>
      <TopicPacksModal
        topic={state.currentTopic}
        token={currentUser.token.actual}
        type={state.topicModalType}
        show={state.showTopicModal}
        motivations={motivationData?.results}
        expertise={expertiseData}
        onHide={() => {
          setState({ ...state, showTopicModal: false });
        }}
        onUpdated={onTopicUpdated}
      />

      <MotivationModal
        show={state.showMotivModal}
        token={currentUser.token.actual}
        motivation={state.currentMotiv}
        type={state.motivModalType}
        onHide={() => {
          setState({ ...state, showMotivModal: false });
        }}
        onUpdated={onMotivationUpdated}
      />

      <ConfirmModal
        {...confirm}
        onHide={() => {
          setConfirm({ ...confirm, error: null, success: null, show: false });
        }}
        onConfirm={() => {
          if (confirm.action == "deleteMotiv") {
            onDeleteMotivation();
          }
        }}
      />

      <PageLayout activeLink="topic-packs" parentMenu="demographics">
        <div>
          <div className="text-grey text-20 font-450 mb-20">Topic Packs</div>
          <Row>
            <Col>
              <div className="d-flex flex-wrap mb-20">
                {canWriteTopic && (
                  <Button
                    classes={`small success w-150  mb-10 mr-3`}
                    onClick={() => {
                      if (!canFetchTab1) setCanFetchTab1(true);
                      setState({
                        ...state,
                        topicModalType: "Add",
                        showTopicModal: true,
                        currentTopic: null,
                      });
                    }}
                  >
                    <PlusIcon /> <span className="pl-2">Add new Topic</span>
                  </Button>
                )}
                {canWriteMotivation && (
                  <Button
                    classes={`small secondary w-200  mb-10`}
                    onClick={() => {
                      setState({
                        ...state,
                        motivModalType: "Add",
                        showMotivModal: true,
                        currentMotiv: null,
                      });
                    }}
                  >
                    <PlusIcon /> <span className="pl-2">Add Motivation</span>
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={7}>
              <div className="d-flex flex-wrap align-center mb-20">
                <Button
                  classes={`pill success ${state.activeTab == 0} mr-2  mb-20`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 0 });
                  }}
                >
                  Topics({topicsTotal?.["count"] || 0})
                </Button>

                <Button
                  classes={`pill success ${state.activeTab == 1}  mb-20 mr-3`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 1 });
                    if (!canFetchTab1) setCanFetchTab1(true);
                  }}
                >
                  Motivation ({motivationTotal?.["count"] || 0})
                </Button>
              </div>
            </Col>

            {state.activeTab == 0 && (
              <Col md={5}>
                <InputField
                  placeholder="Search  topics"
                  classes="small mb-20"
                  isSearch
                  value={query}
                  onChange={(ev) => {
                    setQuery(ev.target.value);
                    if (paginate.page > 0)
                      setPaginate({ ...paginate, page: 0 });
                  }}
                />
              </Col>
            )}
          </Row>

          {state.activeTab == 0 && (
            <LoaderPane
              loading={!topicsData && !topicsError}
              error={topicsError?.message}
              onReload={() => {
                mutateTopics();
              }}
              noRecords={
                !topicsError && topicsData && topicsData?.results.length === 0
              }
            />
          )}

          {state.activeTab == 0 && topicsData?.results.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Topic Name</th>
                    <th>Number of Mentors</th>
                    <th>Motivation</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {topicsData?.results.map((topic) => (
                    <tr key={topic.id}>
                      <td>
                        <span className="text-16 text-grey font-450">
                          {topic.title}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {topic.num_of_mentors}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {" "}
                          {topic?.motivation?.description ||
                            topic?.motivation ||
                            "N/A"}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`text-16 ${
                            topic?.disabled ? "text-warning" : "text-grey"
                          } font-400`}
                        >
                          {topic?.disabled ? "Disabled" : "Active"}
                        </span>
                      </td>
                      <td>
                        <span
                          className="text-16 text-success font-500 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              topicModalType: "View",
                              currentTopic: topic,
                              showTopicModal: true,
                            });
                          }}
                        >
                          View
                        </span>
                        <span
                          hidden={!canWriteTopic}
                          className="text-16 text-success font-500 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              topicModalType: "Edit",
                              currentTopic: topic,
                              showTopicModal: true,
                            });
                          }}
                        >
                          / Edit
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={5}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={topicsData["count"]}
                        onChangePage={(newPage) => {
                          setPaginate({ ...paginate, page: newPage });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({ ...paginate, pageSize: size, page: 0 });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}

          {state.activeTab == 1 && (
            <LoaderPane
              loading={!motivationData && !motivationError}
              error={motivationData?.message}
              onReload={() => {
                mutateMotivation();
              }}
              noRecords={
                !motivationError &&
                motivationData &&
                motivationData?.results.length === 0
              }
            />
          )}

          {state.activeTab == 1 && motivationData?.results.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Motivation</th>
                    <th># of topics</th>
                    <th>Color Code</th>
                    {/*<th>Number of Designers</th> */}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {motivationData?.results.map((motiv) => (
                    <tr key={motiv.id}>
                      <td>
                        <span className="text-16 text-grey font-500">
                          {motiv.description}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {motiv.num_of_topics}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {motiv.color_code}{" "}
                        </span>
                      </td>
                      {/* <td>
                        <span className="text-16 text-grey font-400"> </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey-light font-400"></span>
                      </td> */}
                      <td>
                        <span
                          hidden={!canWriteMotivation}
                          className="text-16 text-success font-400 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              motivModalType: "Edit",
                              currentMotiv: motiv,
                              showMotivModal: true,
                            });
                          }}
                        >
                          Edit
                        </span>

                        <span
                          hidden={!canWriteMotivation}
                          className="text-16 text-danger font-400 pointer"
                          onClick={() => {
                            setConfirm({
                              ...confirm,
                              title: (
                                <span className="text-danger">
                                  {" "}
                                  Delete Motivation
                                </span>
                              ),
                              data: motiv,
                              action: "deleteMotiv",
                              description: `Confirm to delete this Motivation \n [${motiv.description}] `,
                              show: true,
                            });
                          }}
                        >
                          Delete
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4}>
                      <PaginationBar
                        page={paginate2.page}
                        pageSize={paginate2.pageSize}
                        totalItems={motivationData["count"]}
                        onChangePage={(newPage) => {
                          setPaginate2({
                            ...paginate2,
                            page: newPage,
                          });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate2({
                            pageSize: size,
                            page: 0,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default TopicPacksPage;
