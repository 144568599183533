import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import TurndownService from "turndown";
import pagedown from "pagedown";

import { postData, putData } from "../../../api/api";
import InputField from "../../form/input-field/input-field.component";
import Button from "../../../components/button/button.component";
import SideModal from "../side-modal/side-modal.component";
import Editor from "../../form/editor/editor.component";

const FaqModal = ({
  show,
  onHide,
  type = "Add",
  faq,
  token,
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    id: "",
    title: "",
    description: "",
    processing: false,
    error: null,
  });

  const reset = () => {
    setState({
      id: "",
      title: "",
      description: "",
      processing: false,
      error: null,
    });
  };

  const textToHtml = (text = " ") => {
    let converter = new pagedown.Converter();
    let html = converter.makeHtml(text);
    //console.log("converted",html)
    return html;
  };

  useEffect(() => {
    reset();

    if (faq) {
      setState({
        processing: false,
        error: null,
        id: faq?.title,
        title: faq?.title,
        description: textToHtml(faq?.description),
      });
    }
    // eslint-disable-next-line
  }, [show]);

  return (
    <>
      <SideModal
        show={show}
        onHide={() => {
          //setState({...state, error:null});
          onHide();
        }}
        onBlurHide={false}
      >
        <Modal.Body className="scrollbar">
          <div className="modal-inner">
            <div className="content-medium">
              <div>
                <div className="text-dark text-20 font-bold mb-30">
                  {type === "Add" ? "Add new " : "Edit"} Faq
                </div>
                <InputField
                  label="Title"
                  placeholder={"Title"}
                  value={state?.title}
                  onChange={(e) =>
                    setState({ ...state, title: e.target.value })
                  }
                />
                <div className="mt-30" />

                <Editor
                  label="Description"
                  initialValue={textToHtml(faq?.description)}
                  value={state?.description}
                  onEditorChange={(value, editor) => {
                    setState({
                      ...state,
                      description: value,
                    });
                    // console.log(value)
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="modal-footer-inner"
            style={{ paddingBottom: `${window.bottomSpace}` }}
          >
            {state.error && !state.processing && (
              <div className="text-danger text-16 mb-10">{state.error}</div>
            )}

            <div className="d-flex">
              <div>
                <Button
                  classes="small secondary mr-2"
                  loading={state.processing}
                  disabled={
                    state.title.length < 1 ||
                    state.description.length < 1 ||
                    state.processing
                  }
                  onClick={() => {
                    setState({ ...state, processing: true, error: null });
                    const markdown = new TurndownService().turndown(
                      state?.description
                    );
                    console.log(markdown);
                    if (type === "Add") {
                      postData(
                        "faq/",
                        { title: state.title, description: markdown },
                        token
                      ).then((resp) => {
                        if (resp.success) {
                          setState({
                            title: "",
                            description: "",
                            processing: false,
                            error: null,
                          });
                          onUpdated(resp.data);
                          onHide();
                          return;
                        }
                        setState({
                          ...state,
                          processing: false,
                          error: resp.message,
                        });
                      });
                    } else {
                      let id = faq["id"];
                      putData(
                        "faq/" + id + "/",
                        { title: state.title, description: markdown },
                        token
                      ).then((resp) => {
                        if (resp.success) {
                          setState({
                            title: "",
                            description: "",
                            processing: false,
                            error: null,
                          });
                          onUpdated(resp.data);
                          onHide();
                          return;
                        }
                        setState({
                          ...state,
                          processing: false,
                          error: resp.message,
                        });
                      });
                    }
                  }}
                >
                  {type === "Add" ? "Publish FAQ" : "Update FAQ"}
                </Button>
                <Button
                  classes="small info"
                  disabled={state.processing}
                  onClick={() => {
                    //setState({...state, error: null})
                    onHide();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </SideModal>
    </>
  );
};

export default FaqModal;
