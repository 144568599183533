// import error messages
import ERRORS from "../errors";

import { apiBaseUrl, postData, putData, createConfig } from "../api";
// import axios
const axios = require("axios").default;

export const authenticate = async (username, password) => {
  // initiate request
  try {
    let response = await axios.post(
      `${apiBaseUrl}user/authenticate/with-email/`,
      {
        username,
        password,
      }
    );
    return {
      success: true,
      user: response.data,
      message: response.message,
    };
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return { success: false, message: error.response.data.message };
      }

      if (error.response.status === 400) {
        return {
          success: false,
          message: ERRORS.REQUIRED_INFO_MISSING,
        };
      }

      return { success: false, message: ERRORS.UNKNOWN_SERVER_ERROR };
    }

    // could not contact server or could not receive a response
    return { success: false, message: ERRORS.UNKNOWN_ERROR };
  }
};

export const authenticateGoogle = async (code) => {
  // initiate request
  try {
    let response = await axios.post(
      `${apiBaseUrl}user/authenticate-su/with-google/`,
      { authorization_code: code }
    );
    return {
      success: true,
      user: response.data,
      message: response.message,
    };
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return { success: false, message: error.response.data.message };
      }

      if (error.response.status === 404) {
        return {
          success: false,
          message: error.response.data.message || "Unauthorized user", //ERRORS.REQUIRED_INFO_MISSING,
        };
      }

      return {
        success: false,
        message: error.response.data.message || ERRORS.UNKNOWN_SERVER_ERROR,
      };
    }

    // could not contact server or could not receive a response
    return { success: false, message: ERRORS.UNKNOWN_ERROR };
  }
};

export const changePassword = async (authToken, data) => {
  const response = await postData("account/change-password", data, authToken);

  if (response.success) {
    return {
      success: response.data.success,
      message: response.data.message,
    };
  }

  return response;
};

export const resetUserPassword = async (authToken, data) => {
  const response = await postData(
    "account/reset-user-password",
    data,
    authToken
  );

  if (response.success) {
    return {
      success: response.data.success,
      message: response.data.message,
    };
  }

  return response;
};

export const deactivateUser = async (authToken, data) =>
  await postData("account/deactivate", data, authToken);

export const blockOrUnblockUser = async (authToken, data) => {
  return await putData(
    `user/update-active-status/?id=${data.pk}`,
    { is_active: data.status, reason: data.reason ?? null },
    authToken
  );
};

export const revokeUserSuspension = async (authToken, suspensionId) => {
  return await putData(
    `user/suspension/${suspensionId}/revoke/`,
    {},
    authToken
  );
};

export const deleteUser = async (authToken, identity_id) => {
  // initiate request
  try {
    let response = await axios.delete(
      `${apiBaseUrl}user/delete/?id=${identity_id}`,
      createConfig(authToken)
    );

    return {
      success: true,
      data: response.data,
      message: response.message,
    };
  } catch (error) {
    if (error.response) {
      //user has review or report
      if (error.response.status === 403) {
        return {
          success: false,
          message: error.response.data?.error?.toString(),
        };
      }

      return {
        success: false,
        message:
          error.response.data?.message ||
          error.response.data?.error?.toString() ||
          `Request failed with status code ${error.response.status}`,
      };
    }
    // could not contact server or could not receive a response
    return { success: false, message: ERRORS.UNKNOWN_ERROR };
  }
};

export const deleteDashboardUser = async (authToken, id) => {
  // initiate request
  try {
    let response = await axios.delete(
      `${apiBaseUrl}dashboard/user/${id}`,
      createConfig(authToken)
    );

    return {
      success: true,
      data: response.data,
      message: response.message,
    };
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        message:
          error.response.data?.message ||
          error.response.data?.error?.toString() ||
          `Request failed with  status code ${error.response.status} `,
      };
    }
    // could not contact server or could not receive a response
    return { success: false, message: ERRORS.UNKNOWN_ERROR };
  }
};

export const resetUserPhoneNumber = async (authToken, mentorIdentityId) => {
  return await putData(
    `user/phone-number/reset/?user_identity_id=${mentorIdentityId}`,
    {},
    authToken
  );
};
