import { Row, Col } from "react-bootstrap";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import InputField from "../../../../components/form/input-field/input-field.component";
import Button from "../../../../components/button/button.component";

const Header = ({
  canWrite,
  count,
  search,
  activeTab,
  setActiveTab,
  setSideModal,
  setSearchActive,
  setSearchInactive,
}) => {
  return (
    <div>
      <Row>
        <Col md={8}>
          <div className="d-flex flex-wrap mb-20">
            <Button
              classes={`pill success ${activeTab === "active"} mb-10 mr-3`}
              disabled={activeTab === null}
              onClick={() => {
                setActiveTab("active");
              }}
            >
              Active ({count?.active || 0})
            </Button>

            <Button
              classes={`pill success ${activeTab === "inactive"} mb-10`}
              disabled={activeTab === null}
              onClick={() => {
                setActiveTab("inactive");
              }}
            >
              Inactive ({count?.inactive || 0})
            </Button>

            {canWrite && (
              <Button
                classes={`small success mb-20 ml-40`}
                onClick={() => {
                  setSideModal({
                    type: "new",
                    show: true,
                  });
                }}
              >
                <PlusIcon /> <span className="pl-2">New feature release</span>
              </Button>
            )}
          </div>
        </Col>
        <Col md={4}>
          {activeTab === "active" && count?.active > 0 && (
            <InputField
              placeholder="Search for feature release"
              classes="small mb-20"
              isSearch
              value={search.active}
              onChange={(ev) => {
                setSearchActive(ev.target.value);
              }}
            />
          )}

          {activeTab === "inactive" && count?.inactive > 0 && (
            <InputField
              placeholder="Search for feature release"
              classes="small mb-20"
              isSearch
              value={search.inactive}
              onChange={(ev) => {
                setSearchInactive(ev.target.value);
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Header;
