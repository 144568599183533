import { useState, useEffect, useGlobal } from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-right-success.svg";
import { ReactComponent as RoundClose } from "../../../assets/icons/round-close.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location.svg";
import { blockOrUnblockUser, deleteUser } from "../../../api/services/account.service"; // prettier-ignore
import { putData, SWRFetcher } from "../../../api/api";

import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import ToggleButton from "../../../components/form/toggle-button/toggle-button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import Select from "../../../components/form/select/select.component";
import Button from "../../../components/button/button.component";
import HaveAccess from "../../../access-control.jsx";
import converter from "../../converter";
import useSWR from "swr";

const DesignerSideModal = ({
  show,
  onHide,
  token,
  designer,
  onUpdated = () => {},
  onDeactivateUser,
  onClearPhoneNumber,
}) => {
  const [currentUser] = useGlobal("currentUser");

  const [state, setState] = useState({
    blocked: false,
    processing: false,
    newEmail: "",
    showChangeEmail: false,
    showChangePartner: false,
    partner: null,
    insiderAccess: false,
    insiderAccessProcessing: false,
  });

  const [confirmation, setConfirmation] = useState({
    show: false,
    description: "",
    processing: false,
    success: null,
    error: null,
  });

  const reset = () => {
    setState({
      blocked: false,
      processing: false,
      newEmail: "",
      showChangeEmail: false,
      showChangePartner: false,
      partner: null,
      insiderAccess: false,
      insiderAccessProcessing: false,
    });

    setActiveDesigner(null);
  };

  const [activeDesigner, setActiveDesigner] = useState(designer);

  useEffect(() => {
    if (!activeDesigner && designer) setActiveDesigner(designer);

    designer &&
      setState({
        ...state,
        blocked: designer.disabled || !designer.is_active,
        email: designer?.email,
        partner: activeDesigner?.partner,
        insiderAccess: designer?.insider_access,
      });
  }, [designer?.id, activeDesigner, show]);

  const {
    data: partnersData,
    error: partnersError,
    mutate: mutatePartners,
  } = useSWR(designer && "partner/", SWRFetcher(currentUser));

  const [allPartners, setAllPartners] = useState([]);

  if (partnersData && !partnersError && allPartners.length === 0) {
    let p = [];
    partnersData.map((partner) =>
      p.push({ label: partner?.name, value: partner })
    );
    setAllPartners(p);
  }

  const onConfirmDelete = () => {
    setConfirmation({
      show: true,
      processing: true,
      error: null,
      success: null,
      title: "Delete Designer",
      description: "Do you want to delete this Designer?",
    });
    deleteUser(token, designer.identity_id).then((response) => {
      if (response.success) {
        setConfirmation({
          ...confirmation,
          show: false,
          processing: false,
          success: response?.message || "Successful.",
        });

        let info = { type: "deleted", data: designer };
        onUpdated(info);
        onHide();

        return;
      }

      setConfirmation({
        ...confirmation,
        processing: false,
        error: response.message,
      });
    });
  };

  const onChangeEmail = () => {
    setConfirmation({
      ...confirmation,
      processing: true,
      error: null,
      success: null,
      type: "change",
    });
    putData(
      `user/update-email/?id=${designer?.identity_id}`,
      { email: state.newEmail },
      token
    ).then((response) => {
      if (response.success) {
        setConfirmation({
          ...confirmation,
          processing: false,
          success: "Email changed successfully",
        });

        setState({
          ...state,
          email: state.newEmail,
          showChangeEmail: false,
        });
        let info = {
          type: "updated",
          data: { ...designer, email: state.newEmail },
        };
        onUpdated(info);
        return;
      }
      setConfirmation({
        ...confirmation,
        processing: false,
        error:
          response?.message ||
          response?.data.data?.email?.toString() ||
          "Unable to change email",
      });
    });
  };

  const onChangePartner = () => {
    setConfirmation({
      ...confirmation,
      processing: true,
      error: null,
      success: null,
      type: "change_partner",
    });
    putData(
      `member/update/?member_id=${designer?.id}`,
      { partner_id: state?.newPartner?.value.id },
      token
    ).then((response) => {
      if (response.success) {
        setConfirmation({
          ...confirmation,
          processing: false,
          success: "Updated successfully",
        });

        setState({
          ...state,
          partner: state.newPartner.value,
          showChangePartner: false,
        });
        setActiveDesigner({
          ...activeDesigner,
          partner: state.newPartner.value,
        });

        let info = {
          type: "updated",
          data: { ...designer, partner: state.newPartner.value },
        };
        onUpdated(info);
        return;
      }
      setConfirmation({
        ...confirmation,
        processing: false,
        error: response?.message || "Unable to update partner organization",
      });
    });
  };

  const onRemovePartner = () => {
    setConfirmation({
      ...confirmation,
      processing: true,
      error: null,
      success: null,
      type: "remove_partner",
    });
    putData(
      `member/update/?member_id=${designer?.id}`,
      { partner_id: -1 },
      token
    ).then((response) => {
      if (response.success) {
        setConfirmation({
          ...confirmation,
          processing: false,
          success: "Partner removed successfully",
        });

        setState({
          ...state,
          newPartner: null,
          partner: null,
        });
        setActiveDesigner({ ...activeDesigner, partner: null });

        let info = { type: "updated", data: { ...designer, partner: null } };
        onUpdated(info);
        return;
      }
      setConfirmation({
        ...confirmation,
        processing: false,
        error: response?.message || "Unable to remove partner organization",
      });
    });
  };

  return (
    <div>
      {designer && (
        <div>
          {confirmation.show && (
            <ConfirmModal
              show={confirmation.show}
              title={confirmation.title}
              description={confirmation.description}
              error={confirmation.error}
              processing={confirmation.processing}
              success={confirmation.success}
              onHide={() => {
                setConfirmation({
                  show: false,
                  error: null,
                  success: null,
                  title: "",
                  description: "",
                  type: "",
                });
                if (confirmation.type === "delete") {
                  onHide();
                  reset();
                }
              }}
              onConfirm={() => {
                if (confirmation.type === "delete") {
                  onConfirmDelete();
                } else if (confirmation.type === "change") {
                  onChangeEmail();
                } else if (confirmation.type === "change_partner") {
                  onChangePartner();
                } else if (confirmation.type === "remove_partner") {
                  onRemovePartner();
                }
              }}
            />
          )}

          <Modal
            className="side-modal"
            show={show && !confirmation.show}
            onHide={() => {
              reset();
              onHide();
            }} //() => setShow(false)
            //dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header>
              <div
                className="close"
                onClick={() => {
                  reset();
                  onHide();
                }}>
                <RoundClose />
              </div>
            </Modal.Header>
            <Modal.Body className="scrollbar">
              <div className="modal-inner">
                {/* profile*/}
                <div>
                  <div className="designer-pic mb-30">
                    <img
                      className="img-f-w-obj-cover"
                      src={designer.profile_photo_url}
                      alt=""
                    />
                  </div>
                  <div className="text-grey text-20 font-bold">
                    {designer.name}
                  </div>

                  <div className="text-grey text-16 font-500">
                    {designer.title}

                    {designer.employer && (
                      <span className="text-primary pl-2">
                        {designer.employer}
                      </span>
                    )}
                  </div>

                  {designer.country.name && (
                    <div className="text-grey text-12 font-bold mt-20 mb-10 d-flex">
                      <LocationIcon /> {designer.country.name}
                    </div>
                  )}

                  {designer?.bio && (
                    <div>
                      <div className="text-grey-light text-16 mt-20">About</div>
                      <div
                        className="text-grey text-16 font-500"
                        dangerouslySetInnerHTML={{
                          __html: converter.makeHtml(designer.bio),
                        }}
                      />
                    </div>
                  )}

                  {designer?.account_type && (
                    <div class="mb-3">
                      <div className="text-grey-light text-16">
                        Account type
                      </div>
                      <div className="w-100 d-flex flex-wrap">
                        <span className="text-grey text-16 font-500">
                          {designer.account_type}
                        </span>
                      </div>
                    </div>
                  )}

                  {designer.interested_in_remote && (
                    <div>
                      <div className="text-grey-light text-16 mt-20">
                        Interested in
                      </div>
                      <div className="text-grey text-16 font-500">
                        {designer.interested_in_remote ? "Remote,  " : ""}
                        {designer.looking_for}
                      </div>
                    </div>
                  )}

                  {designer.expertise.length > 0 && (
                    <div>
                      <div className="text-grey-light text-16 mt-20">
                        Expertise in
                      </div>
                      <div className="w-100 d-flex flex-wrap">
                        {designer?.expertise &&
                          designer?.expertise.map((exp) => (
                            <span
                              className="text-grey text-16 font-500 pr-2"
                              key={exp.id}>
                              {" "}
                              {exp.description},{" "}
                            </span>
                          ))}
                      </div>
                    </div>
                  )}

                  {designer.languages.length > 0 && (
                    <div>
                      <div className="text-grey-light text-16 mt-20">
                        Languages
                      </div>
                      <div className="text-grey text-16 font-500">
                        {designer.languages.map((lang) => (
                          <span key={lang.id}> {lang.description}, </span>
                        ))}
                      </div>
                    </div>
                  )}

                  {designer.email && (
                    <div>
                      <div className="text-grey-light text-16 mt-20">Email</div>
                      <span>
                        <a href={`mailto:${state?.email}`} target="_blank">
                          <span className="text-success text-16 font-500 pointer">
                            {state?.email} <ArrowUp />
                          </span>
                        </a>
                      </span>
                      {HaveAccess("user", "Write", currentUser) && (
                        <span
                          className="text-warning text-16 underline ml-1 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              showChangeEmail: state.showChangeEmail
                                ? false
                                : true,
                            });
                          }}>
                          {" "}
                          {state.showChangeEmail ? "Cancel" : "Change email"}
                        </span>
                      )}
                      {state.showChangeEmail && (
                        <Row className="d-flex mt-1">
                          <Col xs={9}>
                            <InputField
                              classes="small"
                              placeholder="Enter new email"
                              value={state.newEmail}
                              onChange={(e) => {
                                setState({
                                  ...state,
                                  newEmail: e.target.value,
                                });
                              }}
                            />
                          </Col>
                          <Col xs={3} className="pl-0">
                            <Button
                              classes="small secondary"
                              disabled={state.newEmail.length === 0}
                              onClick={() => {
                                setConfirmation({
                                  show: true,
                                  processing: false,
                                  error: null,
                                  success: null,
                                  title: "Change  Email",
                                  description: "Confirm to change email ",
                                  type: "change",
                                });
                              }}>
                              Change
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}

                  {designer.phone_number && (
                    <div>
                      <div className="text-grey-light text-16 mt-20">
                        Phone number
                      </div>
                      <span>
                        <a
                          href={`tel:+${designer.phone_number_country_code}${designer.phone_number}`}
                          target="_blank"
                          rel="noreferrer">
                          <span className="text-success text-16 font-500 pointer">
                            {`+${designer.phone_number_country_code} ${designer.phone_number}`}{" "}
                          </span>
                        </a>
                      </span>

                      {HaveAccess("user", "Write", currentUser) && (
                        <span
                          className="text-warning text-16 underline ml-1 pointer"
                          onClick={() =>
                            onClearPhoneNumber(
                              `+${designer.phone_number_country_code} ${designer.phone_number}`,
                              designer.name
                            )
                          }>
                          Clear number
                        </span>
                      )}
                    </div>
                  )}

                  <Row>
                    {designer.linkedin_url && (
                      <Col md={6}>
                        <div className="text-grey-light text-16 mt-20">
                          LinkedIn
                        </div>
                        <a href={designer.linkedin_url} target="_blank">
                          <div className="text-success text-16 font-500 pointer">
                            View Member LinkedIn
                            <ArrowUp />
                          </div>
                        </a>
                      </Col>
                    )}

                    {designer.twitter_url && (
                      <Col md={6}>
                        <div className="text-grey-light text-16 mt-20">
                          Twitter
                        </div>
                        <a href={designer.twitter_url} target="_blank">
                          <div className="text-success text-16 font-500 pointer">
                            View Member Twitter
                            <ArrowUp />
                          </div>
                        </a>
                      </Col>
                    )}

                    {designer.website_url && (
                      <Col md={6}>
                        <div className="text-grey-light text-16 mt-20">
                          Website
                        </div>
                        <a href={designer.website_url} target="_blank">
                          <div className="text-success text-16 font-500 pointer">
                            View Member Website
                            <ArrowUp />
                          </div>
                        </a>
                      </Col>
                    )}
                  </Row>

                  {designer.industries.length > 0 && (
                    <>
                      <div className="text-grey-light text-16 mt-20">
                        Industry
                      </div>
                      <div className="w-100 d-flex flex-wrap">
                        {designer.industries &&
                          designer.industries.map((ind) => (
                            <span className="interest-pill" key={ind.id}>
                              {" "}
                              {ind.description}
                            </span>
                          ))}
                      </div>
                    </>
                  )}

                  {designer.interests.length > 0 && (
                    <>
                      <div className="text-grey-light text-16 mt-20">
                        Interests
                      </div>
                      <div className="w-100 d-flex flex-wrap">
                        {designer.interests &&
                          designer.interests.map((interest) => (
                            <span className="interest-pill" key={interest.id}>
                              {interest.description}
                            </span>
                          ))}
                      </div>
                    </>
                  )}

                  {/* insider access */}
                  {HaveAccess("user", "Write", currentUser) && (
                    <div>
                      <div className="d-flex mb-2 mt-20">
                        <ToggleButton
                          checked={state.insiderAccess}
                          loading={state.insiderAccessProcessing}
                          blocked={
                            state.insiderAccessProcessing ||
                            state.processing ||
                            state.deleting
                          }
                          onChange={() => {
                            let checked = state.insiderAccess;
                            setState({
                              ...state,
                              insiderAccessProcessing: true,
                              insiderAccessError: null,
                              insiderAccess: checked ? false : true,
                            });

                            putData(
                              `user/insider-access/?id=${designer.identity_id}`,
                              { insider_access: checked ? false : true },
                              token
                            ).then((response) => {
                              if (response.success) {
                                setState({
                                  ...state,
                                  insiderAccessProcessing: false,
                                  insiderAccessError: null,
                                  insiderAccess: checked ? false : true,
                                });
                                onUpdated({
                                  type: "updated",
                                  data: {
                                    ...designer,
                                    insider_access: checked ? false : true,
                                  },
                                });
                                return;
                              }

                              setState({
                                ...state,
                                insiderAccessProcessing: false,
                                insiderAccess: checked,
                                insiderAccessError: response.message,
                              });
                            });
                          }}
                        />

                        <div className="text-16 font-500 ml-2">
                          Has insider access
                        </div>
                      </div>
                      {state?.insiderAccessError && (
                        <div className="text-danger">
                          {state.insiderAccessError}
                        </div>
                      )}
                    </div>
                  )}

                  {!state.blocked && (
                    <div>
                      {activeDesigner?.partner && (
                        <div className="text-grey-light text-16 mt-20">
                          Partner Organizaton
                        </div>
                      )}
                      <span className="text-16 font-500 mr-2">
                        {activeDesigner?.partner?.name}
                      </span>
                      {HaveAccess("user", "Write", currentUser) && (
                        <span
                          className="text-warning text-16 underline pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              showChangePartner: state.showChangePartner
                                ? false
                                : true,
                            });
                          }}>
                          {state.showChangePartner ? "Cancel" : "Update"}
                        </span>
                      )}
                      {HaveAccess("user", "Write", currentUser) &&
                        activeDesigner?.partner && (
                          <span
                            className="text-danger text-16 underline pointer  ml-3"
                            onClick={() => {
                              setConfirmation({
                                show: true,
                                processing: false,
                                error: null,
                                success: null,
                                title: "Remove Partner",
                                description: "Confirm to remove partner ",
                                type: "remove_partner",
                              });
                            }}>
                            Remove
                          </span>
                        )}

                      {state.showChangePartner && (
                        <>
                          {partnersData && !partnersError && (
                            <Row className="d-flex mt-1 mb-50">
                              <Col xs={8}>
                                <Select
                                  classes="mb-20"
                                  placeholder="Select an organization"
                                  value={state?.newPartner}
                                  options={[
                                    {
                                      label: "Select an organization",
                                      value: null,
                                    },
                                    ...allPartners,
                                  ]}
                                  onChange={(selectedPartner) => {
                                    setState({
                                      ...state,
                                      newPartner: selectedPartner,
                                    });
                                  }}
                                />
                              </Col>
                              <Col xs={4} className="pl-0">
                                <Button
                                  classes="secondary f-width"
                                  disabled={state.newPartner === null}
                                  onClick={() => {
                                    setConfirmation({
                                      show: true,
                                      processing: false,
                                      error: null,
                                      success: null,
                                      title: "Change Partner",
                                      description: "Confirm to update partner ",
                                      type: "change_partner",
                                    });
                                  }}>
                                  Update
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {state.blocked && (
                    <div>
                      <div>
                        <div className="mt-25">
                          <div className="text-grey-light text-16 font-400">
                            Deactivated at
                          </div>

                          <span className="text-16 text-dark font-bold">
                            {designer.last_deactivated_info
                              ? `${new Date(
                                  designer.last_deactivated_info.last_deactivated_at
                                ).toUTCString()}`
                              : "N/A"}
                          </span>
                        </div>
                      </div>

                      <div className="mt-25">
                        <div className="text-grey-light text-16 font-400">
                          Deactivated by
                        </div>

                        <span className="text-16 text-dark font-bold">
                          {designer.last_deactivated_info
                            ? `${designer.last_deactivated_info.last_deactivated_by.name}`
                            : "N/A"}
                        </span>
                        <br />
                        <span className="text-16 text-success font-bold">
                          {designer.last_deactivated_info
                            ? `${designer.last_deactivated_info.last_deactivated_by.email}`
                            : ""}
                        </span>
                      </div>

                      <div className="mt-25">
                        <div className="text-grey-light text-16 font-400">
                          Reason for deactivation
                        </div>

                        <span className="text-16 text-dark font-bold">
                          {designer.last_deactivated_info
                            ? designer.last_deactivated_info
                                .last_deactivated_reason
                            : "No reason provided"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
            {HaveAccess("user", "Write", currentUser) && (
              <Modal.Footer>
                <div
                  className="modal-footer-inner"
                  style={{ paddingBottom: "40px" }}>
                  {state.error && (
                    <div className="text-danger text-16 mb-10">
                      {state.error}
                    </div>
                  )}

                  <div className="d-flex align-center f-width">
                    {!state.blocked && (
                      <Button
                        classes="small secondary"
                        loading={state.processing}
                        disabled={state.processing}
                        onClick={onDeactivateUser}>
                        Deactivate
                      </Button>
                    )}

                    {state.blocked && (
                      <Button
                        classes="small success"
                        loading={state.processing}
                        disabled={state.processing}
                        onClick={() => {
                          setState({ ...state, processing: true, error: null });
                          blockOrUnblockUser(token, {
                            pk: designer.identity_id,
                            status: true,
                          }).then((response) => {
                            if (response.success) {
                              setState({
                                ...state,
                                processing: false,
                                blocked: false,
                              });
                              let info = {
                                type: "unblocked",
                                data: { ...designer, is_active: true },
                              };
                              onUpdated(info);
                              return;
                            }
                            setState({
                              ...state,
                              processing: false,
                              error: response.message,
                            });
                          });
                        }}>
                        Reactivate
                      </Button>
                    )}

                    <Button
                      classes="small danger ml-3"
                      loading={state.deleting}
                      disabled={state.deleting}
                      onClick={() => {
                        setConfirmation({
                          show: true,
                          processing: false,
                          error: null,
                          success: null,
                          title: "Delete Designer",
                          description: "Do you want to delete this Designer?",
                          type: "delete",
                        });
                      }}>
                      Delete
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};

export default DesignerSideModal;
