import { useState, useGlobal, useEffect } from "reactn";
import { Link } from "react-router-dom";
import Sidebar from "../../../components/sidebar/sidebar.component";
import Navbar from "../../../components/navbar/navbar.component";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import TopicPacksModal from "../../../components/modal/topic-packs-modal/topic-packs-modal.component";
import { deleteData, fetchData, putData } from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import HaveAccess from "../../../access-control.jsx";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import LevelModal from "../../../components/modal/level-modal/level-modal.component";

const ExperiencePage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  //const [topicPacks, setTopicPacks] = useGlobal("topicPacks");
  const canWrite = HaveAccess("levelOfExperience", "Write", currentUser);

  const [state, setState] = useState({
    activeTab: 0,
    showLevelModal: false,
    levelModalType: "Add",
    currentLevel: null,
    showMotivModal: false,
  });

  const [query, setQuery] = useState("");

  const [paginate, setPaginate] = useState({
    page: 0,
    pageSize: 10,
  });

  const [levels, setLevels] = useGlobal("levels");

  const {
    data: levelData,
    error: levelError,
    mutate: mutateLevel,
  } = useSWR(
    `level-of-experience/?offset=${paginate.pageSize * paginate.page}&limit=${
      paginate.pageSize
    }`, //&query=${query}
    {
      onSuccess: (data) => {
        setLevels(data);
      },
    }
  );

  const filter = () => {
    let l = levels || [];
    if (query?.length > 0)
      l = levels?.filter((item) => item.level.toLowerCase().includes(query));
    return l;
  };

  /*  const { data: levelTotal, mutate: mutateLevelTotal } = useSWR(
    `level-of-experience/?offset=0&limit=1`
  ); */

  const [confirm, setConfirm] = useState({
    title: "Confirm",
    description: "",
    processing: false,
    error: null,
    success: "",
    data: null,
    action: "",
  });

  useEffect(() => {}, []);

  //edit or add --callback

  function onLevelUpdated() {
    mutateLevel();
    // mutateLevelTotal();
  }

  //confirm d
  function onDeleteLevel() {
    setConfirm({ ...confirm, show: true, processing: true, error: null });

    deleteData(
      `level-of-experience/${confirm?.data.id}/`,
      currentUser.token.actual
    ).then((response) => {
      if (response.success) {
        setConfirm({
          ...confirm,
          processing: false,
          success: "",
          show: false,
          data: null,
          action: "",
        });
        onLevelUpdated();
        return;
      }

      setConfirm({
        ...confirm,
        processing: false,
        error: response.message || "unable to delete",
      });
    });
  }

  return (
    <>
      <LevelModal
        show={state.showLevelModal}
        token={currentUser.token.actual}
        level={state.currentLevel}
        type={state.levelModalType}
        onHide={() => {
          setState({ ...state, showLevelModal: false });
        }}
        onUpdated={onLevelUpdated}
      />

      <ConfirmModal
        {...confirm}
        onHide={() => {
          setConfirm({ ...confirm, error: null, success: null, show: false });
        }}
        onConfirm={() => {
          if (confirm.action == "deleteLevel") {
            onDeleteLevel();
          }
        }}
      />

      <PageLayout activeLink="experience" parentMenu="demographics">
        <div>
          <div className="text-grey text-20 font-450 mb-20">
            Level of Experience
          </div>

          <Row>
            {/* <Col md={7}>
              <div className="d-flex flex-wrap align-center mb-20">
                <Button
                  classes={`pill success ${state.activeTab == 0} mr-2  mb-20`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 0 });
                  }}
                >
                  Topics({topicsTotal["count"] || 0})
                </Button>

                <Button
                  classes={`pill success ${state.activeTab == 1}  mb-20 mr-3`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 1 });
                  }}
                >
                  Level ({levelTotal["count"] || 0})
                </Button>
              </div>
            </Col> */}
            <Col md={7}>
              {canWrite && (
                <div className="d-flex flex-wrap mb-20">
                  <Button
                    classes={`small success w-150  mb-10 mr-3`}
                    onClick={() => {
                      setState({
                        ...state,
                        levelModalType: "Add",
                        currentLevel: null,
                        showLevelModal: true,
                      });
                    }}>
                    <PlusIcon /> <span className="pl-2">Add Level</span>
                  </Button>
                </div>
              )}
            </Col>

            <Col md={5}>
              {state.activeTab == 0 && (
                <InputField
                  placeholder="Search"
                  classes="small mb-20"
                  isSearch
                  value={query}
                  onChange={(ev) => {
                    setQuery(ev.target.value);
                    //if (paginate.page > 0)
                    //  setPaginate({ ...paginate, page: 0 });
                  }}
                />
              )}
            </Col>
          </Row>

          <LoaderPane
            loading={!levelData && !levelError}
            error={levelData?.message}
            onReload={() => {
              mutateLevel();
            }}
            noRecords={!levelError && levelData && filter()?.length === 0}
          />

          {filter()?.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Level</th>
                    <th># of Mentors</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filter()?.map((level) => (
                    <tr key={level.id}>
                      <td>
                        <span className="text-16 text-grey font-500">
                          {level.level}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {level.num_of_mentors}{" "}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`text-16 ${
                            level?.disabled ? "text-warning" : "text-grey"
                          } font-400`}>
                          {level?.disabled ? "Disabled" : "Active"}
                        </span>
                      </td>
                      <td>
                        <div className="d-flex flex-end mr-3">
                          <span
                            hidden={!canWrite}
                            className="text-16 text-success font-400 pointer mr-2"
                            onClick={() => {
                              setState({
                                ...state,
                                levelModalType: "Edit",
                                currentLevel: level,
                                showLevelModal: true,
                              });
                            }}>
                            Edit
                          </span>

                          <span
                            hidden={!canWrite}
                            className="text-16 text-danger font-400 pointer"
                            onClick={() => {
                              setConfirm({
                                ...confirm,
                                title: (
                                  <span className="text-danger">
                                    {" "}
                                    Delete Level
                                  </span>
                                ),
                                data: level,
                                action: "deleteLevel",
                                description: `Confirm to delete this Level \n [${level.level}] `,
                                show: true,
                              });
                            }}>
                            Delete
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {/*  <tr>
                    <td colSpan={4}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={50}
                        onChangePage={(newPage) => {
                          setPaginate({
                            ...paginate,
                            page: newPage,
                          });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({
                            pageSize: size,
                            page: 0,
                          });
                        }}
                      />
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default ExperiencePage;
