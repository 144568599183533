import { Fragment, useState, useEffect } from "react";
import { updateInProductReleaseService, createInProductReleaseService} from "services/InProductReleaseAnnouncementService"; // prettier-ignore
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { helpers } from "utils";
import styled from "styled-components";

import ToggleButton from "components/form/toggle-button/toggle-button.component";
import InputField from "components/form/input-field/input-field.component";
import Select from "components/form/select/select.component";
import Button from "components/button/button.component";
import Notify from "components/Notify";
import Slide from "./Slide";

const FeatureModal = ({
  type,
  reset,
  mutate,
  onHide,
  state,
  setState,
  featureRelease,
  setConfirmDelete,
}) => {
  /**
   * state
   */
  const [slideCount, setSlideCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * variables
   */
  const audiences = [
    { label: "All users", value: "All" },
    { label: "Mentors", value: "Mentor" },
    { label: "Members", value: "Designer" },
    { label: "Limbo", value: "Limbo" },
  ];

  const isValidFeatureRelease =
    helpers.isNotEmpty(
      state?.target_audience || featureRelease?.target_audience
    ) ||
    helpers.isNotEmpty(
      state?.release_page_url || featureRelease?.release_page_url
    ) ||
    helpers.isNotEmpty(
      state?.visibility_count || featureRelease?.visibility_count
    );

  /**
   * effect
   *
   * reset entries
   */
  useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * functions
   */
  const sanitizeReleaseURL = (url) =>
    url.charAt(url.length - 1) === "/" ? url.slice(0, -1) : url;

  const inputChecks = (input) => {
    let params = {};
    let errors = [];
    Object.keys(input).forEach((key) => {
      if (helpers?.isNotEmpty(input[key])) {
        params[key] = input[key];
      } else {
        errors.push(key);
        let errorMessage = errors.join(", ");
        return setState({
          ...state,
          error: `${errorMessage} must not be left blank`,
        });
      }
    });

    if (errors.length === 0) {
      return params;
    }
    return false;
  };

  const handleUpdateInProductRelease = (id = featureRelease?.id) => {
    setState({ ...state, processing: true, error: null });
    let target_audience =
      state?.target_audience || featureRelease?.target_audience;
    let release_page_url =
      state?.release_page_url || featureRelease?.release_page_url;
    let visibility_count =
      state?.visibility_count || featureRelease?.visibility_count;
    let disabled = state?.disabled || featureRelease?.disabled;

    /**
     * If product release is disabled, then we need to update the disabled field only
     *
     * Backend throws an error if we try to update visibility_count in addition to the disabled field
     */
    let params = {
      target_audience,
      release_page_url: sanitizeReleaseURL(release_page_url),
      visibility_count,
      active: !disabled,
    };
    if (disabled) {
      params = { active: !disabled };
    } else {
      params = inputChecks(params);
    }

    if (id && params) {
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      updateInProductReleaseService(id, formData).then((res) => {
        if (res.success) {
          toast(<Notify type="success" body="Product Release updated" />);
          setState({
            ...state,
            processing: false,
            error: null,
            target_audience: res.target_audience,
            release_page_url: res.release_page_url,
            visibility_count: res.visibility_count,
            active: res.active,
          });
          mutate();
        } else {
          toast(
            <Notify type="error" body="Unable to update product release." />
          );
          setState({
            ...state,
            processing: false,
            error:
              res.message ||
              "Failed to add feature release. Please contact support to update your permissions and try again.",
          });
        }

        setIsLoading(false);
      });
    } else {
      setState({
        ...state,
        processing: false,
        error: null,
      });
    }
  };

  const handleCreateInProductRelease = (show = false) => {
    setState({ ...state, processing: true, error: null });
    /**
     * data
     */
    const { target_audience, release_page_url, visibility_count, active } =
      state;
    let params = {
      target_audience,
      release_page_url,
      visibility_count,
      active: active || show,
    };
    params = inputChecks(params);
    if (params) {
      createInProductReleaseService(params).then((res) => {
        if (!res.success) {
          toast(
            <Notify
              type="error"
              body={res.message || "Unable to create product release."}
            />
          );
          setState({
            ...state,
            processing: false,
            error:
              res?.data?.data?.release_page_url?.[0] ||
              res.message ||
              "Failed to add feature release. Please contact support to update your permissions and try again.",
          });
        } else {
          toast(<Notify type="success" body="Product release created" />);
          setState({
            ...state,
            ...res.data,
            processing: false,
            error: null,
            in_product_release_announcement_id: res.data.id,
          });
          mutate();
          if (show) {
            reset();
            toast(
              <Notify type="success" body="In Product release published" />
            );

            onHide();
          } else {
            setSlideCount((n) => n + 1); // increment slide count
          }
        }
      });
    } else {
      setState({ ...state, processing: false });
    }
  };

  return (
    <Fragment>
      <Modal.Body className="scrollbar">
        <div className="modal-inner">
          <div className="content-medium">
            <div>
              <p className="text-grey text-20 font-bold mb-15">
                {type?.toLowerCase() === "new"
                  ? "New feature release"
                  : "Update feature release"}
              </p>

              <Grid>
                <div className="mb-20">
                  <Select
                    label="Audience"
                    placeholder="Select Audience"
                    defaultValue={audiences?.filter(
                      (a) => a.value === featureRelease?.target_audience
                    )}
                    options={[
                      { label: "All users", value: "All" },
                      { label: "Mentors", value: "Mentor" },
                      { label: "Members", value: "Designer" },
                      { label: "Limbo", value: "Limbo" },
                    ]}
                    isSearchable={false}
                    onChange={(selected) => {
                      setState({ ...state, target_audience: selected.value });
                    }}
                  />
                </div>

                <div className="mb-20">
                  <InputField
                    label="Visibility count"
                    type="number"
                    // placeholder="1 (Show once), n (Show n number of times)"
                    {...(featureRelease?.visibility_count && {
                      value: () =>
                        state.visibility_count ||
                        featureRelease.visibility_count,
                    })}
                    value={state.visibility_count}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value < 0) value = Math.abs(value);
                      return setState({
                        ...state,
                        visibility_count: value,
                      });
                    }}
                  />
                </div>
              </Grid>

              <div className="mb-20">
                <InputField
                  label="Release page url to show on"
                  placeholder="https://"
                  {...(featureRelease?.release_page_url && {
                    value: () =>
                      state.release_page_url || featureRelease.release_page_url,
                  })}
                  value={state.release_page_url}
                  onChange={(e) =>
                    setState({ ...state, release_page_url: e.target.value })
                  }
                />
              </div>

              <div className="my-30">
                <div className="d-flex  align-items-center">
                  <ToggleButton
                    checked={
                      slideCount > 0 ||
                      featureRelease?.announcement_slides?.length > 0
                        ? state.disabled
                        : true
                    }
                    blocked={state.processing?.toString()}
                    onChange={() => {
                      if (
                        !(
                          slideCount > 0 ||
                          featureRelease?.announcement_slides?.length > 0
                        )
                      ) {
                        toast(
                          <Notify
                            type="error"
                            body="Cannot enable without at least one Slide added."
                          />
                        );
                      }
                      let d = state.disabled ? false : true;
                      setState({ ...state, disabled: d });
                    }}
                  />

                  <div className="text-grey text-14 ml-10">
                    Disable feature release (if active, feature will not appear
                    in app)
                  </div>
                </div>
              </div>

              {/* {state?.error && (
                <p className="text-danger my-1">{state.error}</p>
              )} */}
              {type?.toLowerCase() === "new" ? (
                slideCount < 1 && (
                  <Button
                    classes="small success my-30"
                    disabled={
                      state.processing ||
                      !state.target_audience ||
                      !state.release_page_url ||
                      !state.visibility_count
                    }
                    onClick={() => handleCreateInProductRelease()}
                  >
                    <span className="pl-2">Save and add slides</span>
                  </Button>
                )
              ) : type?.toLowerCase() === "edit" ? (
                featureRelease?.announcement_slides?.length < 1 &&
                slideCount < 1 ? (
                  <Button
                    classes="small secondary my-30"
                    loading={state.processing}
                    disabled={
                      state.processing ||
                      !state.target_audience ||
                      !state.release_page_url ||
                      !state.visibility_count
                    }
                    onClick={() =>
                      handleUpdateInProductRelease() |
                      setSlideCount((n) => n + 1)
                    }
                  >
                    Update and add slides
                  </Button>
                ) : (
                  <Button
                    classes="small secondary"
                    loading={isLoading}
                    disabled={
                      !helpers.isNotEmpty(
                        state.target_audience || featureRelease.target_audience
                      ) ||
                      !helpers.isNotEmpty(
                        state.release_page_url ||
                          featureRelease.release_page_url
                      ) ||
                      !helpers.isNotEmpty(
                        state.visibility_count ||
                          featureRelease.visibility_count
                      ) ||
                      state.processing
                    }
                    onClick={() =>
                      setIsLoading(true) | handleUpdateInProductRelease()
                    }
                  >
                    Update
                  </Button>
                )
              ) : (
                (slideCount < 1 && (
                  <Button
                    classes="small success my-30"
                    onClick={() => handleCreateInProductRelease()}
                  >
                    <span className="pl-2">Save and add slides</span>
                  </Button>
                )) ||
                null
              )}

              <Slide
                {...{
                  type,
                  state,
                  mutate,
                  setState,
                  slideCount,
                  setSlideCount,
                  featureRelease,
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="py-4">
        <div
          className="modal-footer-inner"
          style={{ paddingBottom: `${window.bottomSpace}` }}
        >
          {state.error && !state.processing && (
            <div className="text-danger text-16 mb-10">{state.error}</div>
          )}

          <div className="d-flex my-24">
            {type?.toLowerCase() === "new" && (
              <Button
                classes="small secondary mr-2"
                loading={state.processing}
                disabled={
                  slideCount < 1 || !isValidFeatureRelease || state.processing
                }
                onClick={() => handleCreateInProductRelease(true)}
              >
                Publish feature release
              </Button>
            )}

            <Button
              disabled={state.processing}
              classes={`small ${
                type?.toLowerCase() === "edit" ? "danger" : "info"
              }`}
              {...(type?.toLowerCase() === "edit" && {
                onClick: () => setConfirmDelete((e) => ({ ...e, show: true })),
              })}
              {...(type?.toLowerCase() === "new" && {
                onClick: () => reset() | onHide(),
              })}
            >
              {type?.toLowerCase() === "edit"
                ? "Delete feature release"
                : "Cancel"}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Fragment>
  );
};

/**
 * components
 */
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(100px, 215px);
  grid-gap: 16px;
  margin-top: 20px;
`;

export default FeatureModal;
