const ERRORS = {
  REQUIRED_INFO_MISSING: "Required info is missing",
  UNKNOWN_SERVER_ERROR:
    "An unknown server error occurred. This may be temporary, kindly try again in a short while, if the problem still persist, please contact support",
  UNKNOWN_ERROR:
    "An unknown error occurred, please check your network connectivity",
  NOT_FOUND: "We could not find what you are looking for",
  LOGIN_EXPIRED:
    "Your login is expired... Please logout and login again. This is done to keep you account secure.",
};

export default ERRORS;
