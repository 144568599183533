import { postData, putData, deleteData } from "utils/Http";

export const createInProductReleaseService = (payload) =>
  postData("in-product-release-announcement/", payload);

export const updateInProductReleaseService = (id, payload) =>
  putData(`in-product-release-announcement/${id}/`, payload);

export const deleteInProductReleaseService = (id) =>
  deleteData(`in-product-release-announcement/${id}/`);

export const createInProductReleaseSlideService = (payload) =>
  postData("in-product-release-announcement/slide/", payload, true);

export const updateInProductReleaseSlideService = (id, payload) =>
  putData(`in-product-release-announcement/slide/${id}/`, payload, true);
