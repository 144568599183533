import { useState, useGlobal, useEffect } from "reactn";
import SideModal from "../side-modal/side-modal.component";
import InputField from "../../form/input-field/input-field.component";
import FileInput from "../../form/file-input/file-input.component";
import Button from "../../button/button.component";
import { Modal } from "react-bootstrap";
import { postData, putData } from "../../../api/api";
import ToggleButton from "../../../components/form/toggle-button/toggle-button.component";

const MotivationModal = ({
  show,
  onHide,
  type = "Add",
  motivation,
  token,
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    description: "",
    color_code: "",
    info: "",
    disabled: true,
    processing: false,
    error: null,
    success: null,
  });

  useEffect(() => {
    motivation
      ? setState({
          description: motivation?.description,
          disabled: motivation?.disabled,
          color_code: motivation?.color_code,
          info: motivation?.info || "",
          processing: false,
          error: null,
          success: null,
        })
      : reset();
  }, [show, motivation]);

  const reset = () => {
    setState({
      description: "",
      color_code: "",
      info: "",
      disabled: true,
      processing: false,
      error: null,
      success: null,
    });
  };

  return (
    <>
      {(motivation || type === "Add") && (
        <SideModal
          show={show}
          onHide={() => {
            reset();
            onHide();
          }}
          onBlurHide={false}
        >
          <Modal.Body className="scrollbar">
            <div className="modal-inner">
              <div className="content-medium">
                {/* add motivation */}
                <div>
                  <div className="text-dark text-20 font-bold mb-30">
                    {type === "Add" ? "Add" : "Edit"} Motivation
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Title"
                      placeholder={"eg. Motivation"}
                      value={state.description}
                      onChange={(e) =>
                        setState({ ...state, description: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Color code"
                      placeholder={"eg. #ffffff"}
                      value={state.color_code}
                      onChange={(e) =>
                        setState({ ...state, color_code: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Description"
                      placeholder={""}
                      value={state.info}
                      onChange={(e) =>
                        setState({ ...state, info: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <ToggleButton
                      label="Disabled"
                      checked={state.disabled}
                      //loading={state.canCreateSessionProcessing}
                      blocked={state.processing}
                      onChange={() => {
                        let d = state.disabled ? false : true;
                        setState({ ...state, disabled: d });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="modal-footer-inner"
              style={{ paddingBottom: `${window.bottomSpace}` }}
            >
              {state.error && !state.processing && (
                <div className="text-danger text-16 mb-10">{state.error}</div>
              )}

              {state.success && !state.processing && (
                <div className="text-success text-16 mb-10">
                  {state.success}
                </div>
              )}

              <div className="d-flex">
                <Button
                  classes="small secondary mr-2"
                  loading={state.processing}
                  disabled={
                    state.description.length < 1 ||
                    state.color_code.length < 1 ||
                    state.processing
                  }
                  onClick={() => {
                    setState({
                      ...state,
                      processing: true,
                      error: null,
                      success: null,
                    });
                    const data = new FormData();
                    data.append("description", state.description);
                    data.append("color_code", state.color_code);
                    data.append("info", state.info);
                    data.append("disabled", state.disabled);

                    if (type === "Add") {
                      postData("motivation/", data, token).then((resp) => {
                        if (resp.success) {
                          onHide();
                          onUpdated();
                          return;
                        }
                        setState({
                          ...state,
                          processing: false,
                          error:
                            resp.message ||
                            "Failed to add motivation. Please try again.",
                        });
                      });
                    } else {
                      let id = motivation["id"];
                      putData("motivation/" + id + "/", data, token, true).then(
                        (resp) => {
                          if (resp.success) {
                            setState({
                              ...state,
                              processing: false,
                              success: "Motivation updated",
                              ...resp.data,
                            });
                            onUpdated(resp.data);
                            return;
                          }
                          setState({
                            ...state,
                            processing: false,
                            error:
                              resp.message ||
                              "Failed to update motivation. Please try again.",
                          });
                        }
                      );
                    }
                  }}
                >
                  {type === "Add" ? "Add" : "Update"} Motivation
                </Button>
                <Button
                  classes="small info"
                  disabled={state.processing}
                  onClick={() => {
                    reset();
                    onHide();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </SideModal>
      )}
    </>
  );
};

export default MotivationModal;
