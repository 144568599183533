import React from "react";
import "./input-field.styles.scss";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";

const InputFIeld = ({
  ref = React.createRef(),
  label,
  error,
  visible,
  classes,
  isSearch,
  rightIcon,
  ...otherProps
}) => (
  <div className={`input-field ${classes}`}>
    {label && <label>{label}</label>}
    <div className={`input-container ${visible}`}>
      <input ref={ref} {...otherProps} />
      {(isSearch || rightIcon) && (
        <div className="right-icon">
          {isSearch ? <SearchIcon /> : rightIcon}
        </div>
      )}
    </div>
    {error && <div className="text-danger mt-1">{error}</div>}
  </div>
);

export default InputFIeld;
