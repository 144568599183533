import { useState, useGlobal, useEffect } from "reactn";
import { Table, Row, Col, Spinner } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import { withRouter } from "react-router-dom";
import { fetchData, postData, apiBaseUrl, SWRFetcher } from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
import SummaryCard from "../../../components/card/summary-card/summary-card.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import ExportButton from "../../../components/export-button/export-button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";

const FeatureTrackerPage = () => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");

  const [features, setFeatures] = useGlobal("features");

  const [query, setQuery] = useState("");

  const [state, setState] = useState({
    activeTab: 0,
    showFeatureModal: false,
    currentFeature: null,
    fetching: false,
    features: features,
    canFetch: true,
    error: null,
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    nextURL: null,
    prevURL: null,
  });

  const [confirmation, setConfirmation] = useState({
    show: false,
    processing: false,
    error: null,
    success: null,
  });

  const {
    data: featureData,
    error: featureError,
    mutate: mutateFeature,
  } = useSWR(`dashboard/analytics/feature-tracker/`, SWRFetcher(currentUser));

  return (
    <>
      {/*  <ConfirmModal 
            show={confirmation.show} 
            onHide={()=>setConfirmation({...confirmation,show: false, error: null,success: false})} 
            token={currentUser.token.actual}
            title={"Send emails"}
            description={
                <div className="text-center">
                    You’re about to send <br/>“complete profile” <br/> emails to {featureMetricsData?.total_verified || 0} Limbo users
                </div>
            }
            success={confirmation.success}
            error={confirmation.error}
            processing={confirmation.processing}
            onConfirm={onConfirmSendMails}
        />  */}

      <PageLayout activeLink="feature-tracker" parentMenu="analytics">
        <div>
          <Row>
            <Col md={6} className="text-grey text-20 font-bold mb-20">
              Feature Tracker
            </Col>
            <Col md={6}>
              <div className="d-flex flex-end">
                <div className="mt-10 mr-3">
                  {/* <ExportButton label="Export"
                                    url={null}
                                    fileName="limbo"
                                />  */}
                </div>
              </div>
            </Col>

            <Col md={3} xs={6}>
              <SummaryCard
                description="Total Insiders"
                value={featureData?.total_insiders || "-"}
              />
            </Col>
          </Row>
          <Row className="mt-30">
            <Col md={7} />
            <Col md={5}>
              {/* <InputField placeholder="Search"
                            classes="small mb-20" isSearch
                            value={query}
                            onChange={(ev)=>{
                                setQuery(ev.target.value)
                            }}/> */}
            </Col>
          </Row>

          <LoaderPane
            loading={!featureData && !featureError}
            error={featureError?.message}
            noRecords={
              !featureError && featureData && featureData?.data.length < 1
            }
            onReload={() => {
              mutateFeature();
            }}
          />

          {featureData && !featureError && featureData?.data.length > 0 && (
            <Table bordered responsive className="table-white">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Active Users</th>
                  <th>Inactive Users</th>
                  <th>Total Users</th>
                </tr>
              </thead>
              <tbody>
                {featureData?.data.map((feature) => (
                  <tr key={feature.name}>
                    <td>
                      <div className="d-flex">
                        <span className="text-16 text-grey font-500">
                          {feature?.name}{" "}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span className="text-16 text-grey font-400">
                        {feature?.active_users}{" "}
                      </span>
                    </td>
                    <td>
                      <span className="text-16 text-grey font-400">
                        {feature?.inactive_users}{" "}
                      </span>
                    </td>
                    <td>
                      <span className="text-16 text-grey font-400">
                        {feature?.total_users}{" "}
                      </span>
                    </td>
                  </tr>
                ))}
                {/* <tr>
                            <td colSpan={4}>
                                <PaginationBar 
                                page={paginate.page} 
                                pageSize={paginate.pageSize}
                                totalItems={featureData["count"]}
                                onChangePage={(newPage)=>{
                                    setPaginate({...paginate, page: newPage })
                                }} 
                                onChangeRowsPerPage={(size)=>{
                                    setPaginate({...paginate, pageSize: size, page: 0 })
                                }}/>
                            </td>
                        </tr> */}
              </tbody>
            </Table>
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default withRouter(FeatureTrackerPage);
