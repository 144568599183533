export const tags = {
  user: "User",
  booking: "Booking",
  groupSession: "GroupSession",
  review: "Review",
  announcement: "Announcement",
  featureRelease: "InProductReleaseAnnouncement",
  banner: "Banner",
  faqs: "FAQs",
  language: "Language",
  topicOfInterest: "TopicOfInterest",
  expertise: "Expertise",
  country: "Country",
  interest: "Interest",
  industry: "Industry",
  partner: "Partner",
  analytics: "Analytics",
  adminUserAndRole: "AdminUserAndRole",
  topicPack: "TopicPack",
  levelOfExperience: "LevelOfExperience",
  motivation: "Motivation",
  discipline: "Discipline",
  skill: "Skill",
  tool: "Tool",
  seoPage: "SEOPage",
};

const HaveAccess = (tag, permission = "Read", currentUser) => {
  if (currentUser.superuser_role === "Admin") return true;

  //const [currentUser]=useGlobal("currentUser")
  const permissions = currentUser.superuser_role?.permissions || [];

  return permissions.find(
    (m) =>
      m.access_control.tag === tags[tag] && m.permission_type === permission
  );
};

export const canRead = (tag, currentUser) => {
  return HaveAccess(tag, "Read", currentUser);
};

export const canWrite = (tag, currentUser) => {
  return HaveAccess(tag, "Write", currentUser);
};

export const CanReadOrWrite = (tag, currentUser) => {
  const permissions = currentUser.superuser_role?.permissions || [];

  return permissions.find(
    (m) =>
      m.access_control.tag === tags[tag] &&
      (m.permission_type === "Read" || m.permission_type === "Write")
  );
};

export default HaveAccess;
