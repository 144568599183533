import { useState, useGlobal, useEffect } from "reactn";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import {
  fetchData,
  mentorUrl,
  apiBaseUrl,
  SWRFetcher,
  deleteData,
  putData,
} from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import UsersModal from "../../../components/modal/users-modal/users-modal.component";
import RolesModal from "../../../components/modal/roles-modal/roles-modal.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import HaveAccess from "../../../access-control.jsx";
import { deleteDashboardUser } from "../../../api/services/account.service";

const UsersAndRolesPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const canWrite = HaveAccess("adminUserAndRole", "Write", currentUser);

  const [query, setQuery] = useState("");
  const [queryRoles, setQueryRoles] = useState("");
  const [activeTab, setActiveTab] = useState(-1);

  const [state, setState] = useState({
    currentUser: null,
    showModal: false,
    userUrl: null,
    showRolesModal: false,
    activeRole: null,
    rolesModalType: "Add",
    allUsers: [],
    allRoles: [],
    newPassword: "",
  });

  const [usersPaginate, setUsersPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 30,
  });

  const [rolesPaginate, setRolesPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 30,
  });

  const [confirm, setConfirm] = useState({
    open: false,
    title: "Confirm",
    description: "",
    processing: false,
    success: null,
    error: null,
    data: null,
    action: "",
  });

  //active, cancelled, disabled
  /* const getURL = (
    q = "",//query,
    limit = usersPaginate.pageSize,
    offset = usersPaginate.pageSize * usersPaginate.page,
    filter = ""
  ) => {
    return `${apiBaseUrl}dashboard/user/?q=${q}&limit=${limit}&offset=${offset}&filter=${filter}`;
  }; */

  const {
    data: usersData,
    error: usersError,
    mutate: mutateUsers,
  } = useSWR(
    `dashboard/user/`, //getURL(query),
    SWRFetcher(currentUser)
  );

  const {
    data: rolesData,
    error: rolesError,
    mutate: mutateRoles,
  } = useSWR(`dashboard/role/`, SWRFetcher(currentUser));

  const {
    data: accessControlsData,
    error: accessControlsError,
    mutate: mutateAccessControls,
  } = useSWR(`dashboard/access-control/`, SWRFetcher(currentUser));

  const filterUsers = () => {
    //if(!state.profiles) return {items: [], "totalItems": 0 }
    let data = state.allUsers;
    let totalItems = data.length;

    if (query.length > 0) {
      data = data.filter(
        (profile) =>
          profile?.name?.toLowerCase().includes(query) ||
          profile?.username?.toLowerCase().includes(query) ||
          profile?.superuser_role?.name?.toLowerCase().includes(query)
      );
      //list length
      totalItems = data.length;
    }

    // paginate
    const { page, pageSize } = usersPaginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: data.slice(start, end), totalItems: totalItems };
  };

  const filterRoles = () => {
    //if(!state.profiles) return {items: [], "totalItems": 0 }
    let data = state.allRoles;
    let totalItems = data.length;

    if (queryRoles.length > 0) {
      data = data.filter(
        (role) =>
          role?.name?.toLowerCase().includes(queryRoles) ||
          role?.description?.toLowerCase().includes(queryRoles)
      );
      //list length
      totalItems = data.length;
    }

    // paginate
    const { page, pageSize } = rolesPaginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: data.slice(start, end), totalItems: totalItems };
  };

  useEffect(() => {
    if (usersData && !usersError) {
      setState({
        ...state,
        allUsers: usersData,
      });
    }

    if (activeTab == -1) setActiveTab(0);
  }, [activeTab, usersData, query, usersPaginate.page]);

  useEffect(() => {
    if (rolesData && !rolesError) {
      setState({
        ...state,
        allRoles: rolesData,
      });
    }
  }, [rolesData, queryRoles, rolesPaginate.page]);

  const onActivateOrDeactivateUser = () => {
    setConfirm({
      ...confirm,
      success: null,
      error: null,
      processing: true,
    });
    putData(
      `dashboard/user/${confirm.data.id}/update-status/`,
      { is_active: confirm.data.is_active ? false : true },
      currentUser?.token.actual
    ).then((response) => {
      if (response.success) {
        setConfirm({
          ...confirm,
          processing: false,
          success: "User status updated",
        });
        mutateUsers();
        return;
      }
      setConfirm({
        ...confirm,
        processing: false,
        error: response.message || "Unable to update user status.",
      });
    });
  };

  const onDeleteUser = () => {
    setConfirm({
      ...confirm,
      success: null,
      error: null,
      processing: true,
    });

    deleteDashboardUser(currentUser?.token.actual, confirm.data.id).then(
      (response) => {
        if (response.success) {
          setConfirm({
            ...confirm,
            processing: false,
            success: "User deleted",
          });
          mutateUsers();
          return;
        }
        setConfirm({
          ...confirm,
          processing: false,
          error: response.message || "Unable to delete user",
        });
      }
    );
  };

  const onDeleteRole = () => {
    setConfirm({
      ...confirm,
      success: null,
      error: null,
      processing: true,
    });
    deleteData(
      `dashboard/role/${confirm.data.id}/`,
      currentUser?.token.actual
    ).then((response) => {
      if (response.success) {
        setConfirm({
          ...confirm,
          processing: false,
          success: "Role deleted",
        });
        mutateRoles();
        return;
      }
      setConfirm({
        ...confirm,
        processing: false,
        error: response.message || "Unable to delete Role",
      });
    });
  };

  const onResetPassword = () => {
    if (state.newPassword.length === 0) {
      setConfirm({ ...confirm, error: "Please enter password!" });
      return;
    }
    setConfirm({
      ...confirm,
      success: null,
      error: null,
      processing: true,
    });
    putData(
      `dashboard/user/${confirm.data.id}/reset-password/`,
      { new_password: state.newPassword },
      currentUser?.token.actual
    ).then((response) => {
      if (response.success) {
        setConfirm({
          ...confirm,
          processing: false,
          success: "User password resetted",
        });
        setState({ ...state, newPassword: "" });
        mutateUsers();
        return;
      }
      setConfirm({
        ...confirm,
        processing: false,
        error: response.message || "Unable to reset password.",
      });
    });
  };

  const onUserUpdated = () => {
    mutateUsers();
  };

  const onRolesUpdated = () => {
    mutateRoles();
  };

  return (
    <>
      <UsersModal
        user={state.currentUser}
        token={currentUser?.token.actual}
        show={state.showModal}
        canWrite={canWrite}
        roles={rolesData}
        onHide={() => {
          setState({ ...state, currentUser: null, showModal: false });
        }}
        onUpdated={onUserUpdated}
      />

      <RolesModal
        user={state.currentUser}
        token={currentUser?.token.actual}
        type={state.rolesModalType}
        canWrite={canWrite}
        role={state.activeRole}
        show={state.showRolesModal}
        accessControls={accessControlsData}
        onHide={() => {
          setState({ ...state, activeRole: null, showRolesModal: false });
        }}
        onUpdated={onRolesUpdated}
      />

      <ConfirmModal
        show={confirm.show}
        title={confirm.title}
        description={confirm.description}
        processing={confirm.processing}
        success={confirm.success}
        error={confirm.error}
        onHide={() => {
          setConfirm({
            ...confirm,
            error: null,
            success: null,
            processing: false,
            show: false,
            data: null,
          });
        }}
        onConfirm={() => {
          if (confirm.action === "deleteUser") {
            onDeleteUser();
          } else if (confirm.action === "activateOrDeactivateUser") {
            onActivateOrDeactivateUser();
          } else if (confirm.action === "deleteRole") {
            onDeleteRole();
          } else if (confirm.action === "resetPassword") {
            onResetPassword();
          }
        }}
      />

      <PageLayout activeLink="users-and-roles" parentMenu="settings">
        <div>
          <div className="text-grey text-20 font-bold mb-20"></div>
          <div className="d-flex space-between  mb-20">
            <div className="text-grey text-20 font-500">Users and Roles</div>
          </div>
          {canWrite && (
            <div className="d-flex flex-wrap mb-20">
              <Button
                classes={`small success w-200  mb-20 mr-2`}
                onClick={() => {
                  setState({
                    ...state,
                    usersModalType: "Add",
                    showModal: true,
                  });
                }}
              >
                <PlusIcon /> <span className="pl-2">Add new user</span>
              </Button>
              <Button
                classes={`small secondary w-200  mb-20`}
                onClick={() => {
                  setActiveTab(1);
                  setState({
                    ...state,
                    activeRole: null,
                    rolesModalType: "Add",
                    showRolesModal: true,
                  });
                }}
              >
                <PlusIcon /> <span className="pl-2">Add new role</span>
              </Button>
            </div>
          )}
          <Row>
            <Col md={7}>
              <div className="d-flex flex-wrap mb-20">
                <Button
                  classes={`pill success ${activeTab == 0} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(0);
                  }}
                >
                  Users
                </Button>

                <Button
                  classes={`pill success ${activeTab == 1} mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(1);
                  }}
                >
                  Roles
                </Button>
              </div>
            </Col>
            <Col md={5}>
              {activeTab == 0 && (
                <InputField
                  placeholder="Search "
                  classes="small mb-20"
                  isSearch
                  value={query}
                  onChange={(ev) => {
                    //mutateUsers(ev.target.value)
                    setQuery(ev.target.value);
                  }}
                />
              )}

              {activeTab == 1 && (
                <InputField
                  placeholder="Search "
                  classes="small mb-20"
                  isSearch
                  value={queryRoles}
                  onChange={(ev) => {
                    setQueryRoles(ev.target.value);
                  }}
                />
              )}
            </Col>
          </Row>

          {activeTab === 0 && (
            <LoaderPane
              loading={!usersData && !usersError}
              error={usersError?.message}
              noRecords={
                filterUsers().totalItems === 0 ||
                (!usersError && usersData && usersData.length === 0)
              }
              onReload={() => {
                mutateUsers();
              }}
            />
          )}

          {activeTab == 0 &&
            usersData &&
            !usersError &&
            usersData?.length > 0 && (
              <div>
                <Table bordered responsive className="table-white mt-2">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterUsers().items?.map((user) => {
                      const active = user?.is_active;
                      return (
                        <tr key={user?.id}>
                          <td>
                            <span className="text-16 text-grey font-500">
                              {user?.name || "--"}{" "}
                            </span>
                          </td>
                          <td>
                            <span className="text-16 text-grey font-400">
                              {user?.username}
                            </span>
                          </td>
                          <td>
                            <span className="text-16 text-grey-light font-400">
                              {user?.superuser_role?.name}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`text-16 ${
                                active ? "text-success" : "text-danger"
                              } font-500 pointer`}
                            >
                              {active ? "Active" : "Inactive"}
                            </span>
                          </td>
                          <td>
                            <span
                              hidden={
                                !canWrite ||
                                user?.username === "samuel@adplist.org"
                              }
                              className={`text-16  ${
                                active ? "text-warning" : "text-success"
                              } font-500 pointer`}
                              onClick={() => {
                                setConfirm({
                                  title: "Update User status",
                                  description: `Confirm to ${
                                    active ? "Deactivate" : "Activate"
                                  }  User [${user?.name}]`,
                                  show: true,
                                  success: null,
                                  error: null,
                                  processing: false,
                                  action: "activateOrDeactivateUser",
                                  data: user,
                                });
                              }}
                            >
                              {active ? "Deactivate" : "Activate"}
                            </span>
                          </td>
                          <td>
                            <span
                              hidden={
                                !canWrite ||
                                user?.username === "samuel@adplist.org"
                              }
                              className={`text-16 text-danger font-500 pointer`}
                              onClick={() => {
                                setConfirm({
                                  title: "Delete User",
                                  description: `Confirm to delete User [${user?.name}]`,
                                  show: true,
                                  success: null,
                                  error: null,
                                  processing: false,
                                  action: "deleteUser",
                                  data: user,
                                });
                              }}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    {/* <tr>
                    <td colSpan={6}>
                      <PaginationBar
                        page={usersPaginate.page}
                        pageSize={usersPaginate.pageSize}
                        totalItems={usersData?.length}
                        onChangePage={newPage => {
                          setUsersPaginate({ ...usersPaginate, page: newPage });
                          //mutateUsers()
                        }}
                        onChangeRowsPerPage={size => {
                          //mutateUsers(query,size, size * usersPaginate.page)
                          setUsersPaginate({ ...usersPaginate, pageSize: size, page: 0 });
                        }}
                      />
                    </td>
                  </tr> */}
                  </tbody>
                </Table>
              </div>
            )}

          {/* past users */}
          {activeTab === 1 && (
            <LoaderPane
              loading={!rolesData && !rolesError}
              error={rolesError?.message}
              noRecords={
                filterRoles().totalItems === 0 ||
                (!rolesError && rolesData && rolesData?.length === 0)
              }
              onReload={() => {
                mutateRoles();
              }}
            />
          )}
          {activeTab == 1 &&
            rolesData &&
            !rolesError &&
            rolesData?.length > 0 && (
              <div>
                <Table bordered responsive className="table-white mt-2">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th># of Users</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterRoles().items?.map((role) => (
                      <tr key={role.id}>
                        <td>
                          <span className="text-16 text-grey font-500">
                            {role?.name}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey font-400">
                            {role?.total_users}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light font-400">
                            {role?.description}
                          </span>
                        </td>
                        <td>
                          <span
                            className="text-16 text-dark font-500 pointer mr-3"
                            onClick={() => {
                              setState({
                                ...state,
                                activeRole: role,
                                rolesModalType: "Edit",
                                showRolesModal: true,
                              });
                            }}
                          >
                            View
                          </span>

                          <span
                            hidden={!canWrite || role?.name === "Super Admin"}
                            className="text-16 text-danger font-500 pointer"
                            onClick={() => {
                              setConfirm({
                                title: "Delete role",
                                description: `Confirm to delete role [${role?.name}]`,
                                show: true,
                                success: null,
                                error: null,
                                processing: false,
                                action: "deleteRole",
                                data: role,
                              });
                            }}
                          >
                            Delete
                          </span>
                        </td>
                      </tr>
                    ))}
                    {/* <tr>
                    <td colSpan={5}>
                      <PaginationBar
                        page={rolesPaginate.page}
                        pageSize={rolesPaginate.pageSize}
                        totalItems={rolesData?.length}
                        onChangePage={newPage => {
                          setRolesPaginate({ ...rolesPaginate, page: newPage });
                          //mutateRoles()
                        }}
                        onChangeRowsPerPage={size => {
                          //mutateRoles(queryRoles,size, size * rolesPaginate.page,"past")
                          setRolesPaginate({ ...rolesPaginate, pageSize: size, page: 0 });
                        }}
                      />
                    </td>
                  </tr> */}
                  </tbody>
                </Table>
              </div>
            )}
        </div>
      </PageLayout>
    </>
  );
};

export default UsersAndRolesPage;
