import { useState, useGlobal, useEffect } from "reactn";
import { Modal, Row, Col, Table } from "react-bootstrap";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-right-success.svg";
import { ReactComponent as RoundClose } from "../../../assets/icons/round-close.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location.svg";
import HaveAccess from "../../../access-control.jsx";
import converter from "../../converter";

import ToggleButton from "../../../components/form/toggle-button/toggle-button.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import InputField from "../../../components/form/input-field/input-field.component";
import PaginationBar from "../../pagination-bar/pagination-bar.component";
import Select from "../../../components/form/select/select.component";
import LoaderPane from "../../loader-pane/loader-pane.component";
import Button from "../../../components/button/button.component";

import { blockOrUnblockUser, deleteUser } from "../../../api/services/account.service"; // prettier-ignore
import { deleteData as deleteCalendar } from "../../../api/services/calendar.service";
import { approveOrRejectMentor } from "../../../api/services/mentor.service";
import { putData, SWRFetcher, mentorUrl, designerUrl } from "../../../api/api";
import useSWR from "swr";
import { sanitizeLinkedIn } from "utils/helpers";

const MentorSideModal = ({
  mentor,
  token,
  show,
  onHide,
  onUpdated = () => {},
  onDeactivateUser,
  onMarkInReview,
  onClearPhoneNumber,
  phoneNumberCleared,
}) => {
  const [currentUser] = useGlobal("currentUser");
  const [activeTab, setActiveTab] = useState(0);

  const [paginate, setPaginate] = useState({
    page: 0,
    pageSize: 10,
  });

  const [state, setState] = useState({
    hasApproved: false,
    inReview: false,
    rejected: false,
    blocked: false,
    approving: false,
    rejecting: false,
    processing: false,
    deleting: false,
    error: null,
    hasTippingAccess: false,
    hasTippingAccessProcessing: false,
    email: null,
    phoneNumber: null,
    phoneNumberCountryCode: null,
    newEmail: "",
    showChangeEmail: false,
    newPartner: null,
    showChangePartner: false,
    insiderAccess: false,
    insiderAccessProcessing: false,
  });

  const [confirmation, setConfirmation] = useState({
    show: false,
    description: "",
    processing: false,
    success: null,
    error: null,
    type: "delete",
  });

  const [activeMentor, setActiveMentor] = useState(mentor);

  const [menteeFilter] = useState("");

  const {
    data: additionalInfo,
    error: additionalError,
    mutate: mutateInfo,
  } = useSWR(mentor && `mentor/${mentor?.slug}/`);

  const {
    data: menteesData,
    error: menteesError,
    mutate: mutateMentees,
  } = useSWR(
    activeTab === 3 &&
      `mentor/mentees/?offset=${paginate.page * paginate.pageSize}&limit=${
        paginate.pageSize
      }${menteeFilter ? `&filter=${menteeFilter}` : ""}&mentor_identity_id=${
        mentor?.identity_id
      }`
  );

  const { data: partnersData, error: partnersError } = useSWR(
    mentor && "partner/",
    SWRFetcher(currentUser)
  );

  const [allPartners, setAllPartners] = useState([]);

  const {
    data: metrics,
    error: metricsError,
    mutate: mutateMetrics,
  } = useSWR(
    activeTab === 1 &&
      `${process.env.REACT_APP_CALENDAR_API}booking/mentor-metrics/?identity_id=${mentor?.identity_id}`
  );

  if (partnersData && !partnersError && allPartners.length === 0) {
    let p = [];
    partnersData.map((partner) =>
      p.push({ label: partner?.name, value: partner })
    );
    setAllPartners(p);
  }

  useEffect(() => {
    if (!activeMentor && mentor) setActiveMentor(mentor);

    mentor &&
      additionalInfo &&
      setState({
        ...state,
        hasApproved: additionalInfo.date_verified ? true : false,
        inReview: additionalInfo.in_review_marked_at ? true : false,
        blocked: additionalInfo.disabled || !additionalInfo.is_active,
        hasTippingAccess: additionalInfo?.tipping_access,
        email: additionalInfo?.email,
        phoneNumber: phoneNumberCleared ? null : additionalInfo?.phone_number,
        phoneNumberCountryCode: phoneNumberCleared
          ? null
          : additionalInfo?.phone_number_country_code,
        partner: additionalInfo?.partner,
        insiderAccess: additionalInfo?.insider_access,
      });
  }, [
    mentor,
    mentor?.id,
    activeMentor,
    show,
    additionalInfo,
    phoneNumberCleared,
  ]);

  const reset = () => {
    setState({
      hasApproved: false,
      inReview: false,
      rejected: false,
      blocked: false,
      approving: false,
      rejecting: false,
      processing: false,
      deleting: false,
      error: null,
      hasTippingAccess: false,
      hasTippingAccessProcessing: false,
      email: null,
      newEmail: "",
      phoneNumber: null,
      phoneNumberCountryCode: null,
      showChangeEmail: false,
      partner: null,
      newPartner: null,
      showChangePartner: false,
      insiderAccess: false,
      insiderAccessProcessing: false,
    });

    setActiveMentor(null);
    setActiveTab(0);
  };

  const onConfirmDelete = () => {
    setConfirmation({
      show: true,
      processing: true,
      error: null,
      success: null,
      title: "Delete Mentor",
      description: "Do you want to delete this Mentor?",
      type: "delete",
    });
    deleteUser(token, mentor.identity_id).then((response) => {
      console.log(response);
      if (response.success) {
        setConfirmation({
          ...confirmation,
          processing: false,
          success: response?.message || "Successful.",
        });

        let info = { type: "deleted", data: mentor };
        onUpdated(info);
        return;
      }
      setConfirmation({
        ...confirmation,
        processing: false,
        error: response.message,
      });
    });
  };

  const onChangeEmail = () => {
    setConfirmation({
      ...confirmation,
      processing: true,
      error: null,
      success: null,
      type: "change",
    });
    putData(
      `user/update-email/?id=${mentor?.identity_id}`,
      { email: state.newEmail },
      token
    ).then((response) => {
      if (response.success) {
        setConfirmation({
          ...confirmation,
          processing: false,
          success: "Email changed successfully",
        });

        setState({
          ...state,
          email: state.newEmail,
          showChangeEmail: false,
        });
        let info = {
          type: "updated",
          data: { ...mentor, email: state.newEmail },
        };
        onUpdated(info);
        return;
      }
      setConfirmation({
        ...confirmation,
        processing: false,
        error:
          response?.message ||
          response?.data.data?.email?.toString() ||
          "Unable to change email",
      });
    });
  };

  const onChangePartner = () => {
    setConfirmation({
      ...confirmation,
      processing: true,
      error: null,
      success: null,
      type: "change_partner",
    });
    putData(
      `mentor/update/?mentor_id=${mentor?.id}`,
      { partner_id: state?.newPartner?.value.id },
      token
    ).then((response) => {
      if (response.success) {
        setConfirmation({
          ...confirmation,
          processing: false,
          success: "Updated successfully",
        });

        setState({
          ...state,
          partner: state.newPartner.value,
          showChangePartner: false,
        });
        setActiveMentor({ ...activeMentor, partner: state.newPartner.value });
        let info = {
          type: "updated",
          data: { ...mentor, partner: state.newPartner.value },
        };
        onUpdated(info);
        return;
      }
      setConfirmation({
        ...confirmation,
        processing: false,
        error: response?.message || "Unable to update partner organization",
      });
    });
  };

  const onRemovePartner = () => {
    setConfirmation({
      ...confirmation,
      processing: true,
      error: null,
      success: null,
      type: "remove_partner",
    });
    putData(
      `mentor/update/?mentor_id=${mentor?.id}`,
      { partner_id: -1 },
      token
    ).then((response) => {
      if (response.success) {
        setConfirmation({
          ...confirmation,
          processing: false,
          success: "Partner removed successfully",
        });

        setState({
          ...state,
          newPartner: null,
          partner: null,
        });
        setActiveMentor({ ...activeMentor, partner: null });

        let info = { type: "updated", data: { ...mentor, partner: null } };
        onUpdated(info);
        return;
      }
      setConfirmation({
        ...confirmation,
        processing: false,
        error: response?.message || "Unable to remove partner organization",
      });
    });
  };

  return (
    <div>
      {mentor ? (
        <div>
          {confirmation.show && (
            <ConfirmModal
              show={confirmation.show}
              title={confirmation.title}
              description={confirmation.description}
              error={confirmation.error}
              processing={confirmation.processing}
              success={confirmation.success}
              onHide={() => {
                setConfirmation({
                  show: false,
                  error: null,
                  success: null,
                  title: "",
                  description: "",
                  type: "",
                });
                if (confirmation.type === "delete") {
                  reset();
                  onHide();
                }
              }}
              onConfirm={() => {
                if (confirmation.type === "delete") {
                  onConfirmDelete();
                } else if (confirmation.type === "change") {
                  onChangeEmail();
                } else if (confirmation.type === "change_partner") {
                  onChangePartner();
                } else if (confirmation.type === "remove_partner") {
                  onRemovePartner();
                }
              }}
            />
          )}

          <Modal
            className="side-modal"
            show={show && !confirmation.show}
            onHide={() => {
              reset();
              onHide();
            }} //() => setShow(false)
            //dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header>
              <div
                className="close"
                onClick={() => {
                  reset();
                  onHide();
                }}>
                <RoundClose />
              </div>
            </Modal.Header>
            <Modal.Body className="scrollbar">
              <div className="modal-inner">
                {/* profile*/}
                <div hidden={state.editProfile}>
                  <div className="mentor-pic mb-30">
                    <img
                      className="img-f-w-obj-cover"
                      src={mentor.profile_photo_url}
                      alt=""
                    />
                  </div>
                  <div className="text-grey text-20 font-bold">
                    {mentor.name}
                  </div>
                  <div className="text-grey text-16 font-500">
                    {mentor.title}
                    <span className="text-primary pl-2">{mentor.employer}</span>
                  </div>

                  <div className="text-grey text-12 font-bold mt-15 mb-20 d-flex">
                    {" "}
                    <LocationIcon /> {mentor.country.name}
                  </div>

                  {mentor.in_review_marked_at && !state.hasApproved && (
                    <div
                      style={{
                        paddingLeft: "14px",
                        paddingRight: "14px",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        borderRadius: "10px;",
                        background: "#FAFAFA",
                        fontWeight: "bold",
                        marginBottom: "20px",
                      }}>
                      Application In Review: {mentor.in_review_reason}
                    </div>
                  )}

                  <div>
                    <Button
                      classes={`pill success ${activeTab === 0} mr-2`}
                      onClick={() => {
                        setActiveTab(0);
                      }}>
                      Basic data
                    </Button>
                    <Button
                      classes={`pill success ${activeTab === 1} mr-2`}
                      onClick={() => {
                        setActiveTab(1);
                      }}>
                      Calendar
                    </Button>
                    <Button
                      classes={`pill success ${activeTab === 3} mr-2`}
                      onClick={() => {
                        setActiveTab(3);
                      }}>
                      Mentees
                    </Button>
                    <Button
                      classes={`pill success ${activeTab === 2} mr-2`}
                      onClick={() => {
                        setActiveTab(2);
                      }}>
                      Extras
                    </Button>
                  </div>

                  <div className="grey-hr mt-20 mb-30" />

                  <LoaderPane
                    loading={!additionalInfo && !additionalError}
                    error={!additionalInfo && additionalError?.message}
                    noRecord={null}
                    onReload={() => {
                      mutateInfo();
                    }}
                  />

                  {additionalInfo && (
                    <div additionalInfo hidden={activeTab !== 0}>
                      {(additionalInfo?.on_break ||
                        additionalInfo?.on_break_new) && (
                        <div>
                          <span
                            style={{
                              background: "#F88634",
                              fontSize: "12px",
                              color: "#fff",
                              height: "33px",
                              borderRadius: "60px",
                              padding: "7px 12px",
                              width: "74px",
                            }}>
                            On break
                          </span>
                          {additionalInfo?.on_break && (
                            <div>
                              <div className="text-grey-light text-16 mt-20">
                                Reason
                              </div>
                              <div className="text-grey text-16 font-500">
                                {additionalInfo?.on_break.reason}{" "}
                              </div>
                              <div className="text-grey-light text-16 mt-20">
                                Date set on break
                              </div>
                              <div className="text-grey text-16 font-500">
                                {new Date(
                                  additionalInfo?.on_break.date_created
                                ).toDateString()}
                              </div>
                              <div className="text-grey-light text-16 mt-20">
                                Date to return/reactivate
                              </div>
                              <div className="text-grey text-16 font-500">
                                {new Date(
                                  additionalInfo?.on_break.date_to_return
                                ).toDateString()}
                              </div>
                            </div>
                          )}
                          <div className="grey-hr mt-30" />
                        </div>
                      )}

                      <div className="text-grey-light text-16 mt-20">About</div>
                      <div
                        className="text-grey text-16 font-500 mb-20"
                        dangerouslySetInnerHTML={{
                          __html: converter.makeHtml(additionalInfo?.bio),
                        }}
                      />

                      {additionalInfo?.account_type && (
                        <div class="mb-3">
                          <div className="text-grey-light text-16">
                            Account type
                          </div>
                          <div className="w-100 d-flex flex-wrap">
                            <span className="text-grey text-16 font-500">
                              {additionalInfo.account_type}
                            </span>
                          </div>
                        </div>
                      )}

                      {additionalInfo?.topic_packs?.length > 0 && (
                        <div>
                          <div className="text-grey-light text-16">Topics</div>
                          <div className="w-100 d-flex flex-wrap">
                            {additionalInfo.topic_packs.map((topic) => (
                              <span
                                className="text-grey text-16 font-500 pr-2"
                                key={topic.id}>
                                {topic.title},{" "}
                              </span>
                            ))}
                          </div>
                        </div>
                      )}

                      {additionalInfo?.level && (
                        <div>
                          <div className="text-grey-light text-16 mt-20">
                            Level of Experience
                          </div>
                          <div className="w-100 d-flex flex-wrap">
                            {additionalInfo.level && (
                              <span
                                className="text-grey text-16 font-500 pr-2"
                                //key={topic.id}
                              >
                                {additionalInfo.level?.level},{" "}
                              </span>
                            )}
                          </div>
                        </div>
                      )}

                      {/*{mentor.topic_of_interests.length > 0 && (
                      <div className="text-grey-light text-16 mt-20">
                        Interested in
                      </div>
                    )}

                    <div className="w-100 d-flex flex-wrap">
                      {mentor.topic_of_interests &&
                        mentor.topic_of_interests.map((interest) => (
                          <span
                            className="text-grey text-16 font-500 pr-2"
                            key={interest.id}
                          >
                            {interest.description},{" "}
                          </span>
                        ))}
                        </div>*/}

                      <div className="text-grey-light text-16 mt-20">
                        Expertise in
                      </div>
                      <div className="w-100 d-flex flex-wrap">
                        {additionalInfo.ranked_expertise &&
                          additionalInfo.ranked_expertise.map((exp) => (
                            <span
                              className="text-grey text-16 font-500 pr-2"
                              key={exp.expertise.id}>
                              {" "}
                              {exp.expertise.description},{" "}
                            </span>
                          ))}
                      </div>

                      {additionalInfo.languages.length > 0 && (
                        <>
                          <div className="text-grey-light text-16 mt-20">
                            Languages
                          </div>
                          <div className="text-grey text-16 font-500">
                            {additionalInfo.languages.map((lang) => (
                              <span key={lang.id}> {lang.description}, </span>
                            ))}
                          </div>
                        </>
                      )}

                      {state.email && (
                        <div>
                          <div className="text-grey-light text-16 mt-20">
                            Email
                          </div>
                          <span>
                            <a
                              href={`mailto:${state?.email}`}
                              target="_blank"
                              rel="noreferrer">
                              <span className="text-success text-16 font-500 pointer">
                                {state?.email}
                              </span>
                            </a>
                          </span>

                          {HaveAccess("user", "Write", currentUser) && (
                            <span
                              className="text-warning text-16 underline ml-1 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  showChangeEmail: state.showChangeEmail
                                    ? false
                                    : true,
                                });
                              }}>
                              {" "}
                              {state.showChangeEmail
                                ? "Cancel"
                                : "Change email"}
                            </span>
                          )}
                          {HaveAccess("user", "Write", currentUser) &&
                            state.showChangeEmail && (
                              <Row className="d-flex mt-1">
                                <Col xs={9}>
                                  <InputField
                                    placeholder="Enter new email"
                                    classes="small"
                                    value={state.newEmail}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        newEmail: e.target.value,
                                      });
                                    }}
                                  />
                                </Col>
                                <Col xs={3} className="pl-0">
                                  <Button
                                    classes="small secondary"
                                    disabled={state.newEmail.length === 0}
                                    onClick={() => {
                                      setConfirmation({
                                        show: true,
                                        processing: false,
                                        error: null,
                                        success: null,
                                        title: "Change Mentor Email",
                                        description: `Confirm to change mentor email from ${state.email} to ${state.newEmail}`,
                                        type: "change",
                                      });
                                    }}>
                                    Change
                                  </Button>
                                </Col>
                              </Row>
                            )}
                        </div>
                      )}

                      {state.phoneNumber && (
                        <div>
                          <div className="text-grey-light text-16 mt-20">
                            Phone number
                          </div>
                          <span>
                            <a
                              href={`tel:+${state?.phoneNumberCountryCode}${state?.phoneNumber}`}
                              target="_blank"
                              rel="noreferrer">
                              <span className="text-success text-16 font-500 pointer">
                                {`+${state?.phoneNumberCountryCode} ${state?.phoneNumber}`}{" "}
                              </span>
                            </a>
                          </span>

                          {HaveAccess("user", "Write", currentUser) && (
                            <span
                              className="text-warning text-16 underline ml-1 pointer"
                              onClick={() =>
                                onClearPhoneNumber(
                                  `+${state?.phoneNumberCountryCode} ${state?.phoneNumber}`,
                                  mentor.name
                                )
                              }>
                              Clear number
                            </span>
                          )}
                        </div>
                      )}

                      <Row className="mt-20 mb-30">
                        <Col md={6}>
                          <div className="text-grey-light text-16">
                            LinkedIn
                          </div>
                          <a
                            href={sanitizeLinkedIn(mentor?.linkedin_url)}
                            target="_blank"
                            rel="noreferrer">
                            <div className="text-success text-16 font-500 pointer">
                              View Mentor LinkedIn
                              <ArrowUp />
                            </div>
                          </a>
                        </Col>

                        {additionalInfo.twitter_url && (
                          <Col md={6}>
                            <div className="text-grey-light text-16 mt-20">
                              Twitter
                            </div>
                            <a
                              href={additionalInfo?.twitter_url}
                              target="_blank"
                              rel="noreferrer">
                              <div className="text-success text-16 font-500 pointer">
                                View Mentor Twitter
                                <ArrowUp />
                              </div>
                            </a>
                          </Col>
                        )}

                        {additionalInfo.website_url && (
                          <Col md={6}>
                            <div className="text-grey-light text-16 mt-20">
                              Website
                            </div>
                            <a
                              href={additionalInfo?.website_url}
                              target="_blank"
                              rel="noreferrer">
                              <div className="text-success text-16 font-500 pointer">
                                View Mentor Website
                                <ArrowUp />
                              </div>
                            </a>
                          </Col>
                        )}
                      </Row>

                      {additionalInfo?.industries?.length > 1 && (
                        <>
                          <div className="text-grey-light text-16 mt-20">
                            Industry
                          </div>
                          <div className="w-100 d-flex flex-wrap">
                            {additionalInfo.industries &&
                              additionalInfo.industries.map((ind) => (
                                <span className="interest-pill" key={ind.id}>
                                  {" "}
                                  {ind.description}
                                </span>
                              ))}
                          </div>
                        </>
                      )}

                      {additionalInfo?.interests?.length > 1 && (
                        <>
                          <div className="text-grey-light text-16 mt-20">
                            Interests
                          </div>
                          <div className="w-100 d-flex flex-wrap">
                            {additionalInfo.interests &&
                              additionalInfo.interests.map((interest) => (
                                <span
                                  className="interest-pill"
                                  key={interest.id}>
                                  {interest.description}
                                </span>
                              ))}
                          </div>
                        </>
                      )}

                      {state.hasApproved && !state.blocked && (
                        <div>
                          <div className="text-grey-light text-16 mt-20">
                            Partner Organizaton
                          </div>
                          <span className="text-16 font-500 mr-2">
                            {activeMentor?.partner?.name}
                          </span>
                          {HaveAccess("user", "Write", currentUser) && (
                            <span
                              className="text-warning text-16 underline pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  showChangePartner: state.showChangePartner
                                    ? false
                                    : true,
                                });
                              }}>
                              {state.showChangePartner ? "Cancel" : "Update"}
                            </span>
                          )}
                          {HaveAccess("user", "Write", currentUser) &&
                            activeMentor?.partner && (
                              <span
                                className="text-danger text-16 underline pointer  ml-3"
                                onClick={() => {
                                  setConfirmation({
                                    show: true,
                                    processing: false,
                                    error: null,
                                    success: null,
                                    title: "Remove Partner",
                                    description: "Confirm to remove partner ",
                                    type: "remove_partner",
                                  });
                                }}>
                                Remove
                              </span>
                            )}

                          {state.showChangePartner && (
                            <>
                              {partnersData && !partnersError && (
                                <Row className="d-flex mt-1 mb-50">
                                  <Col xs={8}>
                                    <Select
                                      classes="mb-20"
                                      placeholder="Select an organization"
                                      value={state?.newPartner}
                                      options={[
                                        {
                                          label: "Select an organization",
                                          value: null,
                                        },
                                        ...allPartners,
                                      ]}
                                      onChange={(selectedPartner) => {
                                        setState({
                                          ...state,
                                          newPartner: selectedPartner,
                                        });
                                      }}
                                    />
                                  </Col>
                                  <Col xs={4} className="pl-0">
                                    <Button
                                      classes="secondary f-width"
                                      disabled={state.newPartner === null}
                                      onClick={() => {
                                        setConfirmation({
                                          show: true,
                                          processing: false,
                                          error: null,
                                          success: null,
                                          title: "Change Partner",
                                          description:
                                            "Confirm to update partner ",
                                          type: "change_partner",
                                        });
                                      }}>
                                      Update
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                            </>
                          )}
                        </div>
                      )}

                      {state.blocked && (
                        <div>
                          <div>
                            <div className="mt-25">
                              <div className="text-grey-light text-16 font-400">
                                Deactivated at
                              </div>

                              <span className="text-16 text-dark font-bold">
                                {additionalInfo.last_deactivated_info
                                  ? `${new Date(
                                      additionalInfo.last_deactivated_info.last_deactivated_at
                                    ).toUTCString()}`
                                  : "N/A"}
                              </span>
                            </div>
                          </div>

                          <div className="mt-25">
                            <div className="text-grey-light text-16 font-400">
                              Deactivated by
                            </div>

                            <span className="text-16 text-dark font-bold">
                              {additionalInfo.last_deactivated_info
                                ? `${additionalInfo.last_deactivated_info.last_deactivated_by.name}`
                                : "N/A"}
                            </span>
                            <br />
                            <span className="text-16 text-success font-bold">
                              {additionalInfo.last_deactivated_info
                                ? `${additionalInfo.last_deactivated_info.last_deactivated_by.email}`
                                : ""}
                            </span>
                          </div>

                          <div className="mt-25">
                            <div className="text-grey-light text-16 font-400">
                              Reason for deactivation
                            </div>

                            <span className="text-16 text-dark font-bold">
                              {additionalInfo.last_deactivated_info
                                ? additionalInfo.last_deactivated_info
                                    .last_deactivated_reason
                                : "No reason provided"}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div hidden={activeTab !== 1}>
                    <LoaderPane
                      loading={!metricsError && !metrics}
                      //noRecords={metrics}
                      error={metricsError?.message}
                      onReload={() => mutateMetrics()}
                    />

                    {metrics && <CalendarTab metrics={metrics} />}
                  </div>

                  <div hidden={activeTab !== 2}>
                    {HaveAccess("user", "Write", currentUser) &&
                      state.hasApproved &&
                      !state.blocked && (
                        <>
                          <div>
                            <div className="d-flex mb-2 mt-20">
                              <ToggleButton
                                checked={state.hasTippingAccess}
                                loading={state.hasTippingAccessProcessing}
                                blocked={
                                  state.hasTippingAccessProcessing ||
                                  state.processing ||
                                  state.deleting
                                }
                                onChange={() => {
                                  let checked = state.hasTippingAccess;
                                  setState({
                                    ...state,
                                    hasTippingAccessProcessing: true,
                                    hasTippingAccessError: null,
                                    hasTippingAccess: checked ? false : true,
                                  });

                                  putData(
                                    `mentor/update/?mentor_id=${mentor.id}`,
                                    {
                                      tipping_access: checked ? false : true,
                                    },
                                    token
                                  ).then((response) => {
                                    if (response.success) {
                                      setState({
                                        ...state,
                                        hasTippingAccessProcessing: false,
                                        hasTippingAccessError: null,
                                        hasTippingAccess:
                                          response.data.tipping_access,
                                      });
                                      let info = {
                                        type: "updated",
                                        data: {
                                          ...mentor,
                                          tipping_access:
                                            response.data.tipping_access,
                                        },
                                      };
                                      onUpdated(info);
                                      return;
                                    }

                                    setState({
                                      ...state,
                                      hasTippingAccessProcessing: false,
                                      hasTippingAccess: checked,
                                      hasTippingAccessError: response.message,
                                    });
                                  });
                                }}
                              />

                              <div className="text-16 font-500 ml-2">
                                Has tipping access
                              </div>
                            </div>
                            {state.hasTippingAccessError && (
                              <div className="text-danger">
                                {state.hasTippingAccessError}
                              </div>
                            )}
                          </div>

                          <div>
                            <div className="d-flex mb-2 mt-20">
                              <ToggleButton
                                checked={state.insiderAccess}
                                loading={state.insiderAccessProcessing}
                                blocked={
                                  state.insiderAccessProcessing ||
                                  state.processing ||
                                  state.deleting
                                }
                                onChange={() => {
                                  let checked = state.insiderAccess;
                                  setState({
                                    ...state,
                                    insiderAccessProcessing: true,
                                    insiderAccessError: null,
                                    insiderAccess: checked ? false : true,
                                  });

                                  putData(
                                    `user/insider-access/?id=${mentor.identity_id}`,
                                    { insider_access: checked ? false : true },
                                    token
                                  ).then((response) => {
                                    if (response.success) {
                                      setState({
                                        ...state,
                                        insiderAccessProcessing: false,
                                        insiderAccessError: null,
                                        insiderAccess: checked ? false : true,
                                      });
                                      let info = {
                                        type: "updated",
                                        data: {
                                          ...mentor,
                                          insider_access: checked
                                            ? false
                                            : true,
                                        },
                                      };
                                      onUpdated(info);
                                      return;
                                    }

                                    setState({
                                      ...state,
                                      insiderAccessProcessing: false,
                                      insiderAccess: checked,
                                      insiderAccessError: response.message,
                                    });
                                  });
                                }}
                              />

                              <div className="text-16 font-500 ml-2">
                                Has insider access
                              </div>
                            </div>
                            {state?.insiderAccessError && (
                              <div className="text-danger">
                                {state.insiderAccessError}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                  </div>

                  <div hidden={activeTab !== 3}>
                    <LoaderPane
                      loading={!menteesError && !menteesData}
                      noRecords={
                        menteesData && menteesData?.results.length === 0
                      }
                      error={menteesError?.message}
                      onReload={() => mutateMentees()}
                    />
                    {menteesData?.results.length > 0 && (
                      <Table bordered responsive className="table-white">
                        <thead>
                          <tr>
                            <th>Mentee</th>
                            <th>Bookings</th>
                            <th>Last Met</th>
                          </tr>
                        </thead>
                        <tbody>
                          {menteesData &&
                            menteesData?.results.map((item, i) => {
                              const mentee = item?.designer || item?.mentor;
                              const menteeLink = item?.designer
                                ? designerUrl(mentee.slug)
                                : mentorUrl(mentee.slug);

                              return (
                                <tr key={i}>
                                  <td>
                                    <a href={menteeLink} target="_blank">
                                      <div className="d-flex pointer">
                                        <div className="pic-small mr-3">
                                          <img
                                            className="img-f-w-obj-cover"
                                            src={mentee.profile_photo_url}
                                            alt=""
                                          />
                                        </div>
                                        <span className="text-16 text-grey font-500">
                                          {mentee.name}{" "}
                                        </span>
                                      </div>
                                    </a>
                                  </td>
                                  <td>
                                    <span className="text-16 font-500">
                                      {item?.bookings || 0}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-16 font-500">
                                      {new Date(
                                        item?.last_met
                                      ).toLocaleDateString()}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          <tr>
                            <td colSpan={3}>
                              <PaginationBar
                                page={paginate.page}
                                pageSize={paginate.pageSize}
                                totalItems={menteesData["count"]}
                                onChangePage={(newPage) => {
                                  setPaginate({ ...paginate, page: newPage });
                                }}
                                onChangeRowsPerPage={(size) => {
                                  setPaginate({
                                    pageSize: size,
                                    page: 0,
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            {HaveAccess("user", "Write", currentUser) && additionalInfo && (
              <Modal.Footer>
                <div
                  className="modal-footer-inner"
                  style={{ paddingBottom: "40px" }}>
                  {state.error && (
                    <div className="text-danger text-16 mb-10">
                      {state.error}
                    </div>
                  )}

                  {!state.hasApproved && !state.blocked && (
                    <div className="d-flex align-center f-width">
                      <Button
                        loading={state.approving}
                        classes="small secondary mr-3"
                        disabled={state.approving}
                        onClick={() => {
                          setState({
                            ...state,
                            approving: true,
                            error: null,
                          });
                          approveOrRejectMentor(token, {
                            pk: mentor.id,
                            status: "approve",
                          }).then((response) => {
                            if (response.success) {
                              setState({
                                ...state,
                                hasApproved: true,
                                approving: false,
                              });
                              let info = {
                                type: "approved",
                                data: {
                                  ...mentor,
                                  date_verified: new Date().toLocaleDateString,
                                },
                              };
                              onUpdated(info);
                              return;
                            }

                            setState({
                              ...state,
                              hasApproved: false,
                              approving: false,
                              error: response.message,
                            });
                          });
                        }}>
                        Approve mentor
                      </Button>
                      <Button
                        classes="small danger mr-3"
                        loading={state.processing}
                        blocked={state.processing}
                        onClick={() => {
                          setState({
                            ...state,
                            processing: true,
                            error: null,
                          });

                          approveOrRejectMentor(token, {
                            pk: mentor.id,
                            status: "reject",
                          }).then((response) => {
                            if (response.success) {
                              let identity_id = mentor.identity_id;
                              //delete calendar
                              deleteCalendar(
                                `reset-calendar/?identity_id=${identity_id}`,
                                token
                              ).then((resp) => {
                                console.log("calendar \n", resp);
                                setState({
                                  ...state,
                                  processing: false,
                                  hasApproved: false,
                                });
                                let info = { type: "rejected", data: mentor };
                                onUpdated(info);
                                onHide();
                              });

                              return;
                            }
                            setState({
                              ...state,
                              processing: false,
                              error: response.message,
                            });
                          });
                        }}>
                        Reject mentor
                      </Button>

                      {!mentor.in_review_marked_at && (
                        <Button
                          classes="small secondary outline"
                          disabled={state.approving || state.processing}
                          onClick={onMarkInReview}>
                          Mark as In Review
                        </Button>
                      )}
                    </div>
                  )}

                  {state.hasApproved && !state.blocked && (
                    <Button
                      classes="small secondary"
                      loading={state.processing}
                      disabled={state.processing}
                      onClick={onDeactivateUser}>
                      Deactivate
                    </Button>
                  )}

                  {state.hasApproved && state.blocked && (
                    <Button
                      classes="small success"
                      loading={state.processing}
                      disabled={state.processing}
                      onClick={() => {
                        setState({ ...state, processing: true, error: null });
                        blockOrUnblockUser(token, {
                          pk: mentor.identity_id,
                          status: true,
                        }).then((response) => {
                          if (response.success) {
                            setState({
                              ...state,
                              processing: false,
                              blocked: false,
                            });
                            let info = {
                              type: "unblocked",
                              data: { ...mentor, is_active: true },
                            };
                            onUpdated(info);
                            return;
                          }
                          setState({
                            ...state,
                            processing: false,
                            error: response.message,
                          });
                        });
                      }}>
                      Reactivate
                    </Button>
                  )}

                  {state.hasApproved && (
                    <Button
                      classes="small danger ml-2"
                      loading={state.deleting}
                      disabled={state.deleting}
                      onClick={() => {
                        setConfirmation({
                          show: true,
                          processing: false,
                          error: null,
                          success: null,
                          title: "Delete Mentor",
                          description: "Do you want to delete this Mentor?",
                          type: "delete",
                        });
                      }}>
                      Delete
                    </Button>
                  )}
                </div>
              </Modal.Footer>
            )}
          </Modal>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const CalendarTab = ({ metrics }) => {
  const {
    all_bookings,
    cancelled_bookings,
    confirmed_bookings,
    declined_bookings,
    no_show_bookings,
    pending_bookings,
  } = metrics.booking_metrics;

  const {
    booking_duration,
    calendar_type,
    custom_questions,
    daily_booking_limit,
    default_video_integration,
    number_of_calendars,
    override_all_day_events,
  } = metrics.calendar_metrics;

  return (
    <div>
      <div className="row">
        <div className="col-md-5 mb-20">
          <div className="text-grey-light tex-16">Total Bookings</div>
          <div className="text-grey font-500">{all_bookings}</div>
        </div>

        <div className="col-md-6 mb-20">
          <div className="text-grey-light tex-16">Pending Bookings</div>
          <div className="text-grey font-500">{pending_bookings}</div>
        </div>

        <div className="col-md-5 mb-20">
          <div className="text-grey-light tex-16">Confirmed Bookings</div>
          <div className="text-grey font-500">{confirmed_bookings}</div>
        </div>

        <div className="col-md-6 mb-20">
          <div className="text-grey-light tex-16">Declined Bookings</div>
          <div className="text-grey font-500">{declined_bookings}</div>
        </div>

        <div className="col-md-5 mb-20">
          <div className="text-grey-light tex-16">Cancelled Bookings</div>
          <div className="text-grey font-500">{cancelled_bookings}</div>
        </div>

        <div className="col-md-6 mb-20">
          <div className="text-grey-light tex-16">No Shows</div>
          <div className="text-grey font-500">{no_show_bookings}</div>
        </div>

        <div className="col-md-12"></div>
      </div>
      <div className="grey-hr mt-10 mb-20" />

      {calendar_type === "ADPList Calendar" && (
        <div className="row">
          <div className="col-md-5 mb-20">
            <div className="text-grey-light tex-16">Calendar Type</div>
            <div className="text-grey font-500">{calendar_type}</div>
          </div>

          <div className="col-md-6 mb-20">
            <div className="text-grey-light tex-16">Number of Calendars</div>
            <div className="text-grey font-500">{number_of_calendars}</div>
          </div>

          <div className="col-md-5 mb-20">
            <div className="text-grey-light tex-16">
              Default Video Integration
            </div>
            <div className="text-grey font-500">
              {default_video_integration}
            </div>
          </div>

          <div className="grey-hr mt-10 col-md-12 mb-20" />

          <div className="col-md-5 mb-20">
            <div className="text-grey-light tex-16">Custom Questions</div>
            <div className="text-grey font-500">{custom_questions}</div>
          </div>

          <div className="col-md-6 mb-20">
            <div className="text-grey-light tex-16">Booking Duration (min)</div>
            <div className="text-grey font-500">{booking_duration}</div>
          </div>

          <div className="col-md-5 mb-20">
            <div className="text-grey-light tex-16">Daily Booking limit</div>
            <div className="text-grey font-500">{daily_booking_limit}</div>
          </div>
          <div className="col-md-6 mb-20">
            <div className="text-grey-light tex-16">Overide all day events</div>
            <div className="text-grey font-500">
              {override_all_day_events ? "Yes" : "No"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MentorSideModal;
