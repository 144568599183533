import { useState, useGlobal, useEffect } from "reactn";
import SideModal from "../side-modal/side-modal.component";
import InputField from "../../form/input-field/input-field.component";
import FileInput from "../../form/file-input/file-input.component";
import Button from "../../../components/button/button.component";
import { Modal } from "react-bootstrap";
import { postData, putData } from "../../../api/api";

const InterestModal = ({
  show,
  onHide,
  type = "Add",
  interest,
  token,
  onUpdated = () => {},
}) => {
  const [interests, setInterests] = useGlobal("interests");

  const [state, setState] = useState({
    topic: "",
    caption: "",
    color_code: "",
    cover_photo: null,
    processing: false,
    error: null,
  });

  useEffect(() => {
    if (interest !== null) {
      setState({
        topic: interest.description,
        caption: interest.caption,
        color_code: interest.color_code,
        cover_photo: null,
        processing: false,
        error: null,
      });
    }
  }, [interest]);

  const reset = () => {
    setState({
      topic: "",
      caption: "",
      color_code: "",
      cover_photo: null,
      processing: false,
      error: null,
    });
  };

  return (
    <>
      {(interest || type === "Add") && (
        <SideModal
          show={show}
          onHide={() => {
            reset();
            onHide();
          }}
          onBlurHide={false}
        >
          <Modal.Body className="scrollbar">
            <div className="modal-inner">
              <div className="content-medium">
                {/* add interest */}
                <div>
                  <div className="text-dark text-20 font-bold mb-30">
                    {type === "Add" ? "Add new " : "Edit"} interest
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Topic"
                      placeholder={"eg. Career Advice"}
                      value={state.topic}
                      onChange={(e) =>
                        setState({ ...state, topic: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Caption"
                      placeholder={"Add caption "}
                      value={state.caption}
                      onChange={(e) =>
                        setState({ ...state, caption: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Color code"
                      placeholder={"eg. #ffccff "}
                      value={state.color_code}
                      onChange={(e) =>
                        setState({ ...state, color_code: e.target.value })
                      }
                    />
                  </div>

                  <div className="mb-20">
                    <FileInput
                      id="cover-image"
                      label="Upload Cover image"
                      placeholder={"Upload file here"}
                      selectedFile={state.cover_photo}
                      onChange={(e) => {
                        let file = e.target.files[0];
                        setState({ ...state, cover_photo: file });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="modal-footer-inner"
              style={{ paddingBottom: `${window.bottomSpace}` }}
            >
              {state.error && !state.processing && (
                <div className="text-danger text-16 mb-10">{state.error}</div>
              )}

              <div className="d-flex">
                <Button
                  classes="small secondary mr-2"
                  loading={state.processing}
                  disabled={
                    (type === "Add" && state.cover_photo === null) ||
                    state.topic.length < 1 ||
                    state.color_code.length < 1 ||
                    state.processing
                  }
                  onClick={() => {
                    setState({ ...state, processing: true, error: null });
                    const data = new FormData();
                    data.append("description", state.topic);
                    data.append("color_code", state.color_code);
                    data.append("caption", state.caption);
                    if (type === "Add") data.append("disabled", true);
                    if (state.cover_photo)
                      data.append("cover_photo", state.cover_photo);

                    if (type === "Add") {
                      postData("topic-of-interest/", data, token, true).then(
                        (resp) => {
                          if (resp.success) {
                            reset();
                            let newData = [...interests, resp.data];
                            onUpdated(newData);
                            return;
                          }
                          setState({
                            ...state,
                            processing: false,
                            error:
                              resp.message ||
                              "Failed to add interest. Please try again.",
                          });
                        }
                      );
                    } else {
                      let id = interest["id"];
                      putData(
                        "topic-of-interest/" + id + "/",
                        data,
                        token,
                        true
                      ).then((resp) => {
                        if (resp.success) {
                          reset();

                          let current = interests.filter(
                            (interest) => interest.id !== id
                          );
                          let newData = [...current, resp.data];
                          onUpdated(newData);
                          return;
                        }
                        setState({
                          ...state,
                          processing: false,
                          error:
                            resp.message ||
                            "Failed to update interest. Please try again.",
                        });
                      });
                    }
                  }}
                >
                  {type === "Add" ? "Add" : "Save"}
                </Button>
                <Button
                  classes="small info"
                  disabled={state.processing}
                  onClick={() => {
                    reset();
                    onHide();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </SideModal>
      )}
    </>
  );
};

export default InterestModal;
