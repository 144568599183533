import { useState, useGlobal } from "reactn";
import { Modal } from "react-bootstrap";
import { postData, putData } from "../../../api/api";
import InputField from "../../form/input-field/input-field.component";
import Button from "../../../components/button/button.component";
import SideModal from "../side-modal/side-modal.component";

const ExpertiseModal = ({
  show,
  onHide,
  type = "Add",
  currentExpertise,
  token,
  onUpdated = () => {},
}) => {
  const [expertise] = useGlobal("expertise");

  const [state, setState] = useState({
    description: "",
    color_code: "",
    processing: false,
    error: null,
  });

  return (
    <>
      {(expertise || type === "Add") && (
        <SideModal
          show={show}
          onHide={() => {
            setState({
              ...state,
              description: "",
              color_code: "",
              error: null,
            });
            onHide();
          }}
          onBlurHide={false}
        >
          <Modal.Body className="scrollbar">
            <div className="modal-inner">
              <div className="content-medium">
                {/* add expertise */}
                <div>
                  <div className="text-dark text-20 font-bold mb-30">
                    {type === "Add" ? "Add new " : "Edit"} expertise
                  </div>
                  <InputField
                    label="Name"
                    placeholder={"eg. Design"}
                    value={state.description}
                    onChange={(e) =>
                      setState({ ...state, description: e.target.value })
                    }
                  />

                  <InputField
                    label="Color Code (Hex Value only)"
                    placeholder={"eg.#fffccc"}
                    classes="mt-20"
                    value={state.color_code}
                    onChange={(e) =>
                      setState({ ...state, color_code: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="modal-footer-inner"
              style={{ paddingBottom: `${window.bottomSpace}` }}
            >
              {state.error && !state.processing && (
                <div className="text-danger text-16 mb-10">{state.error}</div>
              )}

              <div className="d-flex">
                <Button
                  classes="small secondary mr-2"
                  loading={state.processing}
                  disabled={state.description.length < 1 || state.processing}
                  onClick={() => {
                    setState({ ...state, processing: true, error: null });
                    if (type === "Add") {
                      postData(
                        "expertise/",
                        {
                          description: state.description,
                          color_code: state.color_code,
                        },
                        token
                      ).then((resp) => {
                        if (resp.success) {
                          setState({
                            description: "",
                            color_code: "",
                            processing: false,
                            error: null,
                          });
                          let newData = [...expertise, resp.data];
                          onUpdated(newData);
                          return;
                        }
                        setState({
                          ...state,
                          processing: false,
                          error: resp.message,
                        });
                      });
                    } else {
                      let id = currentExpertise["id"];
                      putData(
                        "expertise/" + id + "/",
                        {
                          disabled: currentExpertise?.disabled,
                          description: state.description,
                          color_code: state.color_code,
                        },
                        token
                      ).then((resp) => {
                        if (resp.success) {
                          setState({
                            description: "",
                            processing: false,
                            error: null,
                          });

                          let current = expertise.filter(
                            (exp) => exp.id !== id
                          );
                          let newData = [...current, resp.data];
                          onUpdated(newData);
                          return;
                        }
                        setState({
                          ...state,
                          processing: false,
                          error: resp.message,
                        });
                      });
                    }
                  }}
                >
                  {type === "Add" ? "Add" : "Save"}
                </Button>
                <Button
                  classes="small info"
                  disabled={state.processing}
                  onClick={() => {
                    setState({
                      ...state,
                      description: "",
                      color_code: "",
                      error: null,
                    });
                    onHide();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </SideModal>
      )}
    </>
  );
};

export default ExpertiseModal;
