import React, { useState, useRef, useGlobal, useEffect } from "reactn";
import { Modal } from "react-bootstrap";
import useSWR from "swr";

import { ReactComponent as Copy } from "../../../assets/icons/copy.svg";
import { convertToMarkdown } from "../../form/editor/editor.component";
import { putData, mentorUrl, designerUrl, SWRFetcher, apiBaseUrl, groupSessionUrl } from "../../../api/api"; // prettier-ignore
import ExportButton from "../../../components/export-button/export-button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import Button from "../../../components/button/button.component";
import SideModal from "../side-modal/side-modal.component";
import HaveAccess from "../../../access-control.jsx";

const GroupSessionModal = ({
  show,
  onHide,
  session = null,
  token,
  onUpdated = () => {},
}) => {
  const [currentUser] = useGlobal("currentUser");

  const linkRef = useRef("link");

  const canWrite = HaveAccess("groupSession", "Write", currentUser);

  const [state, setState] = useState({
    processing: false,
    error: null,
    session: session,
    showAllMentee: false,
  });

  const {
    data: activeSessionsData,
    error: activeSessionsError,
    mutate: mutateActiveSessions,
  } = useSWR(
    session && `${apiBaseUrl}group-session/${session?.slug}/`,
    SWRFetcher(currentUser)
  );

  useEffect(() => {
    if (session !== null) {
      setState({
        ...state,
        session: session,
        showAllMentee: false,
      });
    }
  }, [state.session, session]);

  return (
    <>
      {session && state.session && (
        <SideModal show={show} onHide={onHide} onBlurHide={true}>
          <Modal.Body className="scrollbar">
            <div className="modal-inner">
              <div className="content-medium">
                <div>
                  <div className="text-grey-light text-12 font-400 mb-15">
                    {" "}
                    {state.session?.date_created.split("T")[0]}
                  </div>

                  <div className="text-darker text-16 font-old mb-10">
                    {" "}
                    {state.session?.name}
                  </div>

                  <div className="text-grey-light text-16 font-400 mb-15">
                    <span>{convertToMarkdown(state.session?.description)}</span>
                  </div>

                  <div className="text-grey-light text-12 font-400 mb-15">
                    Organised by:
                  </div>
                  <a
                    href={
                      state.session?.host?.slug
                        ? mentorUrl(state.session?.host?.slug)
                        : state.session?.host?.profile_url
                        ? state.session.host.profile_url
                        : "#"
                    }
                    target="_blank"
                  >
                    <div className="d-flex">
                      <div className="pic-small mr-3">
                        <img
                          className="img-f-w-obj-cover"
                          src={state.session?.host?.profile_photo_url}
                          alt=""
                        />
                      </div>
                      <div>
                        <span className="text-16 text-grey font-500">
                          {state.session?.host?.name} {"\n "}
                        </span>
                        <span className="text-12 text-grey font-500">
                          {state.session?.host?.employer || ""}
                        </span>
                      </div>
                    </div>
                  </a>

                  {session?.total_cohosts && (
                    <div className="text-grey-light text-12 font-400 mb-15 mt-20">
                      Cohosts({`${session?.total_cohosts || "0"}`})
                    </div>
                  )}
                  <LoaderPane
                    loading={!activeSessionsData && !activeSessionsError}
                    error={activeSessionsError?.message}
                    noRecords={
                      !activeSessionsError &&
                      activeSessionsData &&
                      activeSessionsData["count"] === 0
                    }
                    onReload={() => {
                      mutateActiveSessions();
                    }}
                  />
                  <div>
                    {activeSessionsData?.co_hosts?.map(
                      ({ user, ...cohost }) => (
                        <div>
                          <div className="text-grey-light text-12 font-400 mb-15 mt-20">
                            Cohosts(
                            {`${session?.total_co_hosts}`})
                          </div>

                          <span
                            key={user.id}
                            className="mr-1 mb-2"
                            style={{ width: "40px", display: "inline-block" }}
                          >
                            <a
                              href={
                                user.identity_type === "Mentor"
                                  ? mentorUrl(user.slug)
                                  : designerUrl(user.slug)
                              }
                              target="_blank"
                            >
                              <div className="d-flex pointer">
                                <div className="pic-small">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={user.profile_photo_url}
                                    alt={user.name_string}
                                  />
                                </div>
                              </div>
                            </a>
                          </span>
                        </div>
                      )
                    )}
                  </div>

                  <div className="text-grey-light text-12 font-400 mb-15 mt-20">
                    Mentees attending(
                    {`${session?.booked_seats}/${state.session.rsvp_limit}`})
                  </div>
                  <div>
                    {activeSessionsData?.rsvp?.map((user, i) => (
                      <span
                        hidden={i > 19 && !state.showAllMentee}
                        key={user.id}
                        className="mr-1 mb-2"
                        style={{ width: "40px", display: "inline-block" }}
                      >
                        <a
                          href={
                            user.identity_type === "Mentor"
                              ? mentorUrl(user.slug)
                              : designerUrl(user.slug)
                          }
                          target="_blank"
                        >
                          <div className="d-flex pointer">
                            <div className="pic-small">
                              <img
                                className="img-f-w-obj-cover"
                                src={user.profile_photo_url}
                                alt={user.name_string}
                              />
                            </div>
                          </div>
                        </a>
                      </span>
                    ))}

                    {session?.booked_seats > 20 && activeSessionsData && (
                      <span
                        className="pic-small pointer text-12 pt-2 text-ligth text-center"
                        onClick={() =>
                          setState({
                            ...state,
                            showAllMentee: state?.showAllMentee ? false : true,
                          })
                        }
                      >
                        {session?.booked_seats - 20}
                        {state?.showAllMentee ? "-" : "+"}
                      </span>
                    )}
                  </div>

                  <div className="mt-30">
                    <ExportButton
                      label="Export to CVS"
                      url={`group-session/${state.session?.id}/export/`}
                      fileName={state.session?.name}
                    />
                  </div>

                  {activeSessionsData?.interest_areas.length > 0 && (
                    <div>
                      <div className="text-grey text-12 font-400 mb-15 mt-20">
                        Interest area:
                      </div>
                      <div>
                        {activeSessionsData.interest_areas.map((interest) => (
                          <span
                            className="interest-pill br-8"
                            key={interest.id}
                          >
                            {interest?.description}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="text-grey text-12 font-400 mb-15 mt-20">
                    Link to session
                  </div>
                  <InputField
                    ref={linkRef}
                    placeholder="Search  sessions"
                    classes="small mb-20"
                    value={groupSessionUrl(state.session.slug)}
                    disabled
                    rightIcon={
                      <div
                        className="pointer"
                        onClick={(e) => {
                          //e.target?.current.select();
                          //e.target.setSelectionRange(0, 99999);
                          //document.execCommand("copy");
                          //alert(`Copied ${state.session.slug}`);
                        }}
                      >
                        <Copy />
                      </div>
                    }
                  />

                  {activeSessionsData && !activeSessionsError && (
                    <div>
                      <div className="text-grey text-12 font-400 mb-15 mt-20">
                        Video URL
                      </div>
                      <InputField
                        //ref={linkRef}
                        classes="small mb-20"
                        value={activeSessionsData?.video_url}
                        disabled
                        rightIcon={
                          <div
                            className="pointer"
                            onClick={(e) => {
                              //e.target?.current.select();
                              //e.target.setSelectionRange(0, 99999);
                              //document.execCommand("copy");
                              //alert(`Copied ${state.session.slug}`);
                            }}
                          >
                            <Copy />
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="modal-footer-inner d-flex">
              {canWrite && (
                <Button
                  classes={`small ${
                    state.session.disabled ? "success" : "secondary"
                  } mr-2`}
                  disabled={state.processing}
                  loading={state.processing}
                  onClick={() => {
                    setState({ ...state, processing: true, error: null });
                    putData(
                      `group-session/${state.session.id}/`,
                      {
                        ...state.session,
                        disabled: state.session.disabled ? false : true,
                      },
                      token
                    ).then((response) => {
                      console.log(response);
                      if (response.success) {
                        setState({
                          ...state,
                          processing: false,
                          session: response.data,
                        });
                        onHide();
                        onUpdated();
                        return;
                      }

                      setState({
                        ...state,
                        processing: false,
                        error: response.message,
                      });
                    });
                  }}
                >
                  {state.session.disabled ? "Enable" : "Disable"}
                </Button>
              )}
              <Button
                classes="small info"
                disabled={state.processing}
                onClick={() => {
                  setState({ ...state, error: null });
                  onHide();
                }}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </SideModal>
      )}
    </>
  );
};

export default GroupSessionModal;
