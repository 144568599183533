import { useState, useGlobal, useEffect } from "reactn";
import { Table, Row, Col, Spinner } from "react-bootstrap";
import Button from "../../components/button/button.component";
import InputField from "../../components/form/input-field/input-field.component";
import { withRouter } from "react-router-dom";
import { fetchData, postData, apiBaseUrl, SWRFetcher } from "../../api/api";
import PaginationBar from "../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../components/page-layout/page-layout.component";
import SummaryCard from "../../components/card/summary-card/summary-card.component";
import ConfirmModal from "../../components/modal/confirm-modal/confirm-modal.component";
import ExportButton from "../../components/export-button/export-button.component";
import useSWR, { mutate } from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { deleteUser } from "../../api/services/account.service";

const GuestPage = () => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");

  const [guests, setGuests] = useGlobal("guests");

  const [query, setQuery] = useState("");

  const [state, setState] = useState({
    activeTab: 0,
    showGuestModal: false,
    currentGuest: null,
    fetching: false,
    guests: guests,
    canFetch: true,
    error: null,
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    nextURL: null,
    prevURL: null,
  });

  const [confirmation, setConfirmation] = useState({
    show: false,
    processing: false,
    error: null,
    success: null,
    action: "",
    data: null,
  });

  const {
    data: guestMetricsData,
    error: guestMetricsError,
    mutate: mutateGuestMetrics,
  } = useSWR("user/limbo/count/", SWRFetcher(currentUser));

  const {
    data: guestData,
    error: guestError,
    mutate: mutateGuest,
  } = useSWR(
    `user/limbo/?q=${query}&limit=${paginate.pageSize}&offset=${
      paginate.pageSize * paginate.page
    }`,
    SWRFetcher(currentUser)
  );

  const onConfirmSendMails = () => {
    setConfirmation({
      show: true,
      processing: true,
      error: null,
      success: null,
    });
    postData(
      `user/limbo/send-complete-profile-email/`,
      null,
      currentUser.token.actual
    ).then((response) => {
      if (response.success) {
        setConfirmation({
          ...confirmation,
          processing: false,
          success: "Mails sent successful.",
        });
        return;
      }
      setConfirmation({
        ...confirmation,
        processing: false,
        error: response.message,
      });
    });
  };

  const onConfirmDelete = () => {
    setConfirmation({
      ...confirmation,
      show: true,
      processing: true,
      error: null,
      success: null,
      type: "delete",
    });
    deleteUser(currentUser.token.actual, confirmation?.data.id).then(
      (response) => {
        console.log(response);
        if (response.success) {
          setConfirmation({
            ...confirmation,
            processing: false,
            success: response?.message || "Successful.",
          });
          mutate();
          return;
        }
        setConfirmation({
          ...confirmation,
          processing: false,
          error: response.message,
        });
      }
    );
  };

  return (
    <>
      <ConfirmModal
        show={confirmation.show}
        onHide={() =>
          setConfirmation({
            ...confirmation,
            show: false,
            error: null,
            success: false,
            action: "",
            data: null,
          })
        }
        token={currentUser.token.actual}
        title={
          confirmation.action === "delete" ? "Delete User " : "Send emails"
        }
        description={
          confirmation.action === "delete" ? (
            `Confirm to delete this user [${confirmation?.data.username}]`
          ) : (
            <div className="text-center">
              You’re about to send <br />
              “complete profile” <br /> emails to{" "}
              {guestMetricsData?.total_verified || 0} Limbo users
            </div>
          )
        }
        success={confirmation.success}
        error={confirmation.error}
        processing={confirmation.processing}
        onConfirm={() => {
          confirmation.action === "delete"
            ? onConfirmDelete()
            : onConfirmSendMails();
        }}
      />

      <PageLayout activeLink="limbo">
        <div>
          <Row>
            <Col md={6} className="text-grey text-20 font-bold mb-20">
              Limbo Users
            </Col>
            <Col md={6}>
              <div className="d-flex flex-end">
                <div className="mt-10 mr-3">
                  <ExportButton label="Export" url={null} fileName="limbo" />
                </div>
                <div>
                  <Button
                    classes="small secondary"
                    onClick={() =>
                      setConfirmation({ ...confirmation, show: true })
                    }>
                    Send&nbsp;mails
                  </Button>
                </div>
              </div>
            </Col>

            <Col md={2} xs={6}>
              <SummaryCard
                description="Total"
                value={guestMetricsData?.total_count || 0}
              />
            </Col>
            <Col md={2} xs={6}>
              <SummaryCard
                iconType="account"
                description="Verified"
                value={guestMetricsData?.total_verified || 0}
              />
            </Col>
            <Col md={2} xs={6}>
              <SummaryCard
                description="Pending"
                value={guestMetricsData?.total_not_verified || 0}
              />
            </Col>
          </Row>
          <Row className="mt-30">
            <Col md={7} />
            <Col md={5}>
              <InputField
                placeholder="Search"
                classes="small mb-20"
                isSearch
                value={query}
                onChange={(ev) => {
                  setQuery(ev.target.value);
                }}
              />
            </Col>
          </Row>

          <LoaderPane
            loading={!guestData && !guestError}
            error={guestError?.message}
            noRecords={!guestError && guestData && guestData["count"] === 0}
            onReload={() => {
              mutateGuest();
            }}
          />

          {guestData && !guestError && guestData["count"] > 0 && (
            <Table bordered responsive className="table-white">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Verified</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {guestData?.results.map((guest) => (
                  <tr key={guest.id}>
                    <td>
                      <div className="d-flex">
                        {/* <div className="pic-small mr-3"> 
                                        <img className="img-f-w-obj-cover" src={guest.profile_photo_url} alt="" />
                                    </div> */}
                        <span className="text-16 text-grey font-500">
                          {guest.username}{" "}
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href={`mailto:${guest.email}`}
                        target="_blank"
                        className="text-16 text-success font-500 pointer">
                        {guest.email}
                      </a>
                    </td>
                    <td>
                      <span
                        className="text-16 text-success font-500 pointer"
                        onClick={() => {
                          /* setState({
                                        ...state, 
                                        currentGuest: guest,
                                        showGuestModal: true
                                    }) */
                        }}>
                        {guest.email_verified_at ? "Yes" : "No"}
                      </span>
                    </td>
                    <td>
                      <span
                        className="text-danger pointer font-500"
                        onClick={() => {
                          setConfirmation({
                            ...confirmation,
                            action: "delete",
                            data: guest,
                            show: true,
                          });
                        }}>
                        Delete
                      </span>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={5}>
                    <PaginationBar
                      page={paginate.page}
                      pageSize={paginate.pageSize}
                      totalItems={guestData["count"]}
                      onChangePage={(newPage) => {
                        setPaginate({ ...paginate, page: newPage });
                      }}
                      onChangeRowsPerPage={(size) => {
                        setPaginate({ ...paginate, pageSize: size, page: 0 });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default withRouter(GuestPage);
