import React from "react";
import "./button.styles.scss";
import { Spinner } from "react-bootstrap";

const Button = ({ children, loading, classes, ...otherProps }) => (
  <button type="button" className={`custom-button ${classes}`} {...otherProps}>
    {loading ? (
      <Spinner animation="border" size="sm" variant="light" />
    ) : (
      <div className="button-inner">{children}</div>
    )}
  </button>
);

export default Button;
