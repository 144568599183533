import styled from "styled-components";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f0f6f9;
  border-radius: 10px;
  height: ${(props) => (props.isMulti ? "auto" : "60px")};
  padding: 10px 0px 20px 0px;
  border: 1px solid transparent;
  box-sizing: border-box;
  transition: border-color 0.6s linear;

  :hover {
    border-color: #05051b;
  }
`;

export const Inner = styled.div`
  display: flex;
`;

export const CustomSelect = styled(Select)`
  width: 100%;
  border: 0;
`;

export const CustomCreatableSelect = styled(CreatableSelect)`
  width: 100%;
  border: 0;
`;

export const Label = styled.label`
  width: 100%;
  color: #05051b;
  margin-bottom: 13px;
`;

export const IconDiv = styled.label`
  ${(props) => (props.right ? "padding-left: 20px" : "padding-right: 20px")};
  margin: auto 0px;
`;

/* custom styles for select input */
export const Styles = {
  input: (styles, props) => ({
    ...styles,
    width: "100%",
    fontSize: "16px",
    fontFamily: "Circular",
    color: "#303037",
    fontWeight: "bold",
    background: "transparent",
    border: 0,
    outline: "none",
    padding: "0px 20px",
    margin: 0,
  }),
  control: (styles) => ({
    ...styles,
    width: "100%",
    border: "0",
    margin: "0px",
    padding: "0px",
    boxShadow: "none",
    background: "transparent",
  }),
  container: (styles) => ({
    ...styles,
    border: "0",
    margin: "0",
    padding: "0px",
  }),
  placeholder: (styles) => ({
    ...styles,
    margin: "0",
    fontSize: "16px",
    color: "#6B7B8A",
    fontWeight: "normal",
    padding: "0px",
    /*  whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis', */
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "0px 20px",
    margin: "0",
    fontSize: "16px",
    color: "#05051B",
    fontWeight: "normal",
  }),
  multiValue: (styles) => ({
    ...styles,
    bbackground: "#E3E6EA",
    borderRadius: "30px",
  }),
  menu: (styles) => ({
    ...styles,
    background: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 0px 14px 10px rgba(142, 142, 142, 0.08)",
  }),
  menuList: (styles) => ({
    ...styles,
    borderRadius: "0px",
    fontSize: "14px",
    color: "#000000",
  }),
};
