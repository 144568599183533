import { putData } from "./calendar.service";

export const markBookingNoShow = async (authToken, data) => {
  return await putData(
    `booking/${data.pk}/no-show/`,
    { defaulted_user_identity_id: data.defaultedUserIdentityId ?? null },
    authToken
  );
};

export const unMarkBookingNoShow = async (authToken, data) => {
  return await putData(
    `booking/${data.pk}/no-show/unmark/`,
    { reason: data.reason ?? null },
    authToken
  );
};
