import Select from "components/form/select/select.component";
import InputFIeld from "components/form/input-field/input-field.component";
import SideModal from "components/modal/side-modal/side-modal.component";
import { useState, useEffect, useGlobal } from "reactn";
import ToggleButton from "components/form/toggle-button/toggle-button.component";
import Button from "components/button/button.component";
import { Modal } from "react-bootstrap";
import {
  createDiscipline,
  updateDiscipline,
} from "api/services/discipline.service";

const DisciplineModal = ({ show, discipline, expertiseList, onHide }) => {
  const [currentUser] = useGlobal("currentUser");

  const [name, setName] = useState("");
  const [expertise, setExpertise] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (discipline) {
      setName(discipline.name);
      setExpertise(discipline.expertise);
      setDisabled(discipline.disabled);
    } else {
      setName("");
      setExpertise(null);
      setDisabled(false);
    }

    setLoading(false);
    setError(null);
  }, [discipline]);

  const onPublishDiscipline = async () => {
    setError(null);
    setLoading(true);

    if (discipline) {
      // update
      const reponse = await updateDiscipline({
        authToken: currentUser.token.actual,
        id: discipline.id,
        data: {
          name: name,
          expertise_id: expertise.id,
          disabled: disabled,
        },
      });

      setLoading(false);

      if (reponse.success) {
        onHide(true);

        return;
      }

      setError(reponse.message);
    } else {
      // create new one
      const reponse = await createDiscipline({
        authToken: currentUser.token.actual,
        data: {
          name: name,
          expertise_id: expertise.id,
          disabled: disabled,
        },
      });

      setLoading(false);

      if (reponse.success) {
        onHide(true);

        return;
      }

      setError(reponse.message);
    }
  };

  return (
    <SideModal
      show={show}
      onHide={() => {
        // reset();
        onHide();
      }}
      onBlurHide={false}>
      <Modal.Body className="scrollbar">
        <div className="modal-inner">
          <div className="content-medium">
            <div className="text-dark text-20 font-bold mb-30">
              {discipline ? "Edit " : "Add new "} discipline
            </div>

            <div className="mb-20">
              <InputFIeld
                label="Name"
                placeholder={"eg. Honesty"}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mb-20">
              <Select
                label="Expertise"
                placeholder="Select expertise"
                value={[
                  {
                    label: expertise
                      ? expertise.description
                      : "Select expertise",
                    value: expertise ? expertise.id : "",
                  },
                ]}
                options={
                  expertiseList
                    ? expertiseList
                        .filter((m) => !m.disabled)
                        .map((expertiseItem) => {
                          return {
                            label: expertiseItem.description,
                            value: expertiseItem.id,
                          };
                        })
                    : []
                }
                onChange={(selected) => {
                  setExpertise(
                    expertiseList.find((item) => item.id === selected.value)
                  );
                }}
              />
            </div>

            <div>
              <ToggleButton
                label="Disabled"
                checked={disabled}
                // blocked={state.processing}
                onChange={(value) => {
                  setDisabled(value);
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className="modal-footer-inner"
          style={{ paddingBottom: `${window.bottomSpace}` }}>
          {error && !loading && (
            <div className="text-danger text-16 mb-10">{error}</div>
          )}

          <div className="d-flex">
            <Button
              classes="small secondary mr-2"
              loading={loading}
              disabled={name.length === 0 || !expertise}
              onClick={onPublishDiscipline}>
              Publish
            </Button>
            <Button
              classes="small info"
              disabled={loading}
              onClick={() => {
                // reset();
                onHide();
              }}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </SideModal>
  );
};

export default DisciplineModal;
