import Switch from "react-switch";
import { Spinner } from "react-bootstrap";

const SwitchButton = ({
  label = null,
  checked = false,
  onChange = () => {},
  loading = false,
  ...otherProps
}) => {
  return (
    <>
      {label && (
        <div style={{ color: "#05051B", marginBottom: "13px" }}>{label}</div>
      )}
      <Switch
        checked={checked}
        onChange={onChange}
        uncheckedIcon={null}
        checkedIcon={null}
        onHandleColor={"#077F7F"}
        offHandleColor={"#fff"}
        offColor={"#ccc"}
        onColor={"#eee"}
        handleDiameter={24}
        height={26}
        {...otherProps}
      />

      {loading && (
        <div style={{ position: "absolute" }}>
          <Spinner animation="grow" variant="success" />
        </div>
      )}
    </>
  );
};

export default SwitchButton;
