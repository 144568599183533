import React from "react";
import styled from "styled-components";

import * as Type from "../styles/Type";
import Check from "../icons/Check";
import Close from "../icons/Close";

const Notify = ({ body, title = null, type = "default" }) => {
  return (
    <Wrapper type={type}>
      <span className="icon">
        {type === "default" && null}
        {type === "success" && <Check color="#fff" />}
        {type === "error" && <Close color="#fff" />}
      </span>
      <div className="black-text">
        {title && (
          <Type.Regular className="font-weight-600 mb-2">
            {title && title}
          </Type.Regular>
        )}
        <Type.SmallBold className="mb-0">{body}</Type.SmallBold>
      </div>
    </Wrapper>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  gap: 0.5rem;
  display: grid;
  align-items: center;
  font-family: "Circular";
  grid-template-columns: ${({ type }) => type !== "default" && "32px"} minmax(
      0,
      1fr
    );

  .icon {
    width: 32px;
    height: 32px;
    display: flex;
    border-radius: 50%;
    margin-right: 12px;
    align-items: center;
    justify-content: center;

    background-color: ${({ type }) =>
      (() => {
        switch (type) {
          case "success":
            return "var(--green)";
          case "error":
            return "var(--danger)";
          default:
            return "transparent";
        }
      })()};
  }
`;

export default Notify;
