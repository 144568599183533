import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as RoundClose } from "../../../assets/icons/round-close.svg";
import "./side-modal.scss";

const SideModal = ({ children, show, onHide, onBlurHide = true }) => {
  const [state, setState] = useState({
    hasApproved: false,
    deleteProfile: false,
    editProfile: false,
  });

  return (
    <div>
      <Modal
        className="side-modal"
        show={show}
        onHide={() => {
          if (onBlurHide) onHide();
        }} //() => setShow(false)
        //dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="close" onClick={onHide}>
            <RoundClose />
          </div>
        </Modal.Header>
        {children}

        {/* <Modal.Body className="scrollbar">
          <div className="modal-inner">
           {children}
          </div>
        </Modal.Body>
          <Modal.Footer>
            <div className="modal-footer-inner" style={{paddingBottom:"40px"}} >
            
            </div>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default SideModal;
