import { useState, useGlobal } from "reactn";
import Sidebar from "../sidebar/sidebar.component";
import Navbar from "../navbar/navbar.component";
import "./page-layout.styles.scss";
import { propTypes } from "react-bootstrap/esm/Image";

const PageLayout = ({
  children,
  activeLink,
  parentMenu = "users",
  ...otherProps
}) => {
  const [toggle, setToggle] = useState("hide");
  const [currentUser, setCurrentUser] = useGlobal("currentUser");

  if (!currentUser?.superuser_role) {
    setCurrentUser(null);
  }

  return (
    <div className="page-layout">
      <Navbar
        onSideToggle={() => {
          setToggle(toggle === "show" ? "hide" : "show");
        }}
      />
      <Sidebar
        active={activeLink}
        parentMenu={parentMenu}
        toggle={toggle}
        {...otherProps}
      />
      <div className="page-content">{children}</div>
    </div>
  );
};

export default PageLayout;
