import { useState, useGlobal, useEffect } from "reactn";
import SideModal from "../side-modal/side-modal.component";
import InputField from "../../form/input-field/input-field.component";
import FileInput from "../../form/file-input/file-input.component";
import Button from "../../../components/button/button.component";
import { Modal } from "react-bootstrap";
import { postData, putData } from "../../../api/api";

const PartnerModal = ({
  show,
  onHide,
  type = "Add",
  partner,
  token,
  onUpdated = () => {},
}) => {
  const [partners, setPartners] = useGlobal("partners");

  const [state, setState] = useState({
    name: "",
    email: "",
    selectedFile: null,
    color_code: "",
    website_url: "",
    text_color: "",
    processing: false,
    error: null,
  });

  useEffect(() => {
    if (partner !== null) {
      setState({
        name: partner.name,
        email: partner?.email,
        selectedFile: { name: partner?.logo },
        color_code: partner?.color_code,
        text_color: partner?.text_color,
        website_url: partner?.website_url,
        processing: false,
        error: null,
      });
    }
  }, [partner?.id, show]);

  const reset = () => {
    setState({
      name: "",
      email: "",
      website_url: "",
      selectedFile: null,
      color_code: "",
      text_color: "",
      processing: false,
      error: null,
    });
  };

  const updatePartnerData = () => {
    if (partner) {
      let updatedData = new FormData();

      let stateData = {
        name: state.name,
        email: state.email,
        color_code: state.color_code,
        text_color: state.text_color,
        website_url: state.website_url,
      };

      let partnerData = {
        name: partner.name,
        email: partner?.email,
        color_code: partner?.color_code,
        text_color: partner?.text_color,
        website_url: partner?.website_url,
      };

      Object.keys(stateData).forEach((key) => {
        if (partnerData[key] !== stateData[key])
          updatedData.append(key, stateData[key]);
      });

      if (partner?.logo !== state.selectedFile?.name)
        updatedData.append("logo", state.selectedFile);

      return updatedData;
    }
  };

  return (
    <>
      {(partner || type === "Add") && (
        <SideModal
          show={show}
          onHide={() => {
            reset();
            onHide();
          }}
          onBlurHide={false}
        >
          <Modal.Body className="scrollbar">
            <div className="modal-inner">
              <div className="content-medium">
                {/* add partner */}
                <div>
                  <div className="text-dark text-20 font-bold mb-30">
                    {type === "Add" ? "Add new " : "Edit"} partner
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Name"
                      placeholder={"Enter name of partner"}
                      value={state.name}
                      onChange={(e) =>
                        setState({ ...state, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Email"
                      placeholder={"Enter email of partner"}
                      value={state.email}
                      onChange={(e) =>
                        setState({ ...state, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Color Code (Optional: Partners can set this up themselves)"
                      placeholder={"eg. #cfcfcf"}
                      value={state.color_code}
                      onChange={(e) =>
                        setState({ ...state, color_code: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Text Color (Optional: Partners can set this up themselves)"
                      placeholder={"eg. #cfcfcf"}
                      value={state.text_color}
                      onChange={(e) =>
                        setState({ ...state, text_color: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Website (Optional: Partners can set this up themselves)"
                      placeholder={"Partner`s website"}
                      value={state.website_url}
                      onChange={(e) =>
                        setState({ ...state, website_url: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-20">
                    <FileInput
                      accept="image/*"
                      type="file"
                      id="cover-image"
                      label="Upload logo (Optional)"
                      placeholder={"Upload file here"}
                      selectedFile={state.selectedFile}
                      onChange={(e) => {
                        let file = e.target.files[0];
                        let sf = file;
                        setState({ ...state, selectedFile: sf });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="modal-footer-inner"
              style={{ paddingBottom: `${window.bottomSpace}` }}
            >
              {state.error && !state.processing && (
                <div className="text-danger text-16 mb-10">{state.error}</div>
              )}

              <div className="d-flex">
                <Button
                  classes="small secondary mr-2"
                  loading={state.processing}
                  disabled={state?.name?.length < 1 || state.processing}
                  onClick={() => {
                    setState({ ...state, processing: true, error: null });
                    const data = new FormData();
                    data.append("name", state.name);
                    state?.email && data.append("email", state.email);
                    state?.website_url &&
                      data.append("website_url", state?.website_url);
                    state?.color_code &&
                      data.append("color_code", state?.color_code);
                    state?.text_color &&
                      data.append("text_color", state?.text_color);

                    if (type === "Add") {
                      if (state.selectedFile)
                        data.append("logo", state.selectedFile);
                      postData("partner/", data, token, true).then((resp) => {
                        if (resp.success) {
                          reset();
                          let newData = [...partners, resp.data];
                          onUpdated(newData);
                          return;
                        }
                        setState({
                          ...state,
                          processing: false,
                          error:
                            resp.message ||
                            "Failed to add partner. Please try again.",
                        });
                      });
                    } else {
                      if (partner?.logo !== state.selectedFile?.name)
                        data.append("logo", state.selectedFile);
                      let id = partner["id"];
                      putData(
                        "partner/" + id + "/",
                        updatePartnerData(),
                        token,
                        true
                      ).then((resp) => {
                        if (resp.success) {
                          reset();

                          let current = partners.filter(
                            (partner) => partner.id !== id
                          );
                          let newData = [...current, resp.data];
                          onUpdated(newData);
                          return;
                        }
                        setState({
                          ...state,
                          processing: false,
                          error:
                            resp.message ||
                            "Failed to update partner. Please try again.",
                        });
                      });
                    }
                  }}
                >
                  {type === "Add" ? "Add" : "Save"}
                </Button>
                <Button
                  classes="small info"
                  disabled={state.processing}
                  onClick={() => {
                    reset();
                    onHide();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </SideModal>
      )}
    </>
  );
};

export default PartnerModal;
