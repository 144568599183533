import React from "react";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg";
import "./error-boundary.styles.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI -- same error page
      return (
        <div className="error-page">
          <div>
            <ErrorIcon />
          </div>
          <div className="error-div">
            <div className="error">Error</div>
            <div className="textline">
              <span onClick={() => {}}>Reload </span>
              the page or
              <span onClick={() => {}}> try again</span>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
