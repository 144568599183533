import { createConfig, createResponse, handleError } from "../api";
// import axios
const axios = require("axios").default;

const apiBaseUrl = process.env.REACT_APP_CALENDAR_API;
export const http = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// post data
export const postData = (url, data, authToken = null, file = false) =>
  axios
    .post(`${apiBaseUrl}${url}`, data, createConfig(authToken, file))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error, authToken ? false : true));

// update data
export const putData = (url, data, authToken = null, file = false) =>
  axios
    .put(`${apiBaseUrl}${url}`, data, createConfig(authToken, file))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error));

// delete data
export const deleteData = (url, authToken = null, file = false) =>
  axios
    .delete(`${apiBaseUrl}${url}`, createConfig(authToken, (file = false)))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error));
