import { useState, useGlobal, useEffect } from "reactn";
import SideModal from "../side-modal/side-modal.component";
import InputField from "../../form/input-field/input-field.component";
import FileInput from "../../form/file-input/file-input.component";
import Button from "../../button/button.component";
import { Modal } from "react-bootstrap";
import { postData, putData } from "../../../api/api";
import Select from "../../form/select/select.component";
import ToggleButton from "../../../components/form/toggle-button/toggle-button.component";

const TopicPacksModal = ({
  show,
  onHide,
  type = "Add",
  topic,
  motivations,
  expertise,
  token,
  onUpdated = () => {},
}) => {
  const [topics, setInterests] = useGlobal("topics");

  const [state, setState] = useState({
    topic: "",
    selectedFile: null,
    description: "",
    motivation: null,
    expertise: null,
    disabled: true,
    color_code: "",
    topic_image: null,
    processing: false,
    error: null,
  });

  const [expOptions, setExpOptions] = useState([
    { label: "Expertise", value: "" },
  ]);

  const [expValues, setExpValues] = useState([]);

  useEffect(() => {
    if (topic !== null) {
      setState({
        ...state,
        selectedFile: { name: topic?.topic_image },
        processing: false,
        error: null,
        ...topic,
      });
    } else {
      reset();
    }

    if (expertise) {
      let eList = [];
      expertise?.map((exp) => {
        eList.push({ label: exp.description, value: exp });
      });
      setExpOptions(eList);
    }
  }, [topic, show]);

  useEffect(() => {
    let v = [];
    state?.expertise?.map((exp) => {
      v.push({ label: exp.description, value: exp });
    });
    setExpValues(v);
  }, [state?.expertise?.length]);

  const reset = () => {
    setState({
      title: "",
      selectedFile: null,
      description: "",
      disabled: true,
      motivation: "",
      expertise: [],
      topic_image: "",
      processing: false,
      error: null,
    });
  };

  return (
    <>
      {(topic || type === "Add") && (
        <SideModal
          show={show}
          onHide={() => {
            reset();
            onHide();
          }}
          onBlurHide={false}
        >
          <Modal.Body className="scrollbar">
            <div className="modal-inner">
              <div className="content-medium">
                <div hidden={type != "View"}>
                  <div className="mentor-pic mb-30">
                    <img
                      className="img-f-w-obj-cover"
                      src={topic?.topic_image}
                      alt=""
                    />
                  </div>

                  <div className="text-grey text-20 font-bold">
                    {topic?.title}
                  </div>

                  {topic?.motivation && (
                    <div>
                      <div className="text-grey-light text-16 mt-20">
                        Motivation
                      </div>
                      <div className="text-grey text-16 font-500">
                        {topic.motivation.description}
                      </div>
                    </div>
                  )}

                  {topic?.expertise.length > 0 && (
                    <div>
                      <div className="text-grey-light text-16 mt-20">
                        Expertise
                      </div>
                      <div className="w-100 d-flex flex-wrap">
                        {topic.expertise &&
                          topic.expertise.map((exp) => (
                            <span
                              className="text-grey text-16 font-500 pr-2"
                              key={exp.id}
                            >
                              {exp.description},{" "}
                            </span>
                          ))}
                      </div>
                    </div>
                  )}

                  {topic?.description && (
                    <div>
                      <div className="text-grey-light text-16 mt-20">
                        Description
                      </div>
                      <div className="text-grey text-16 font-500">
                        {topic.description}
                      </div>
                    </div>
                  )}
                </div>

                {/* add topic */}
                <div hidden={type === "View" ? true : false}>
                  <div className="text-dark text-20 font-bold mb-30">
                    {type} Topic
                  </div>

                  <div className="mb-20">
                    <Select
                      label="Motivation"
                      placeholder="Select Motivation"
                      value={[
                        {
                          label:
                            state?.motivation?.description ||
                            "Select Motivation",
                          value: state?.motivation || null,
                        },
                      ]}
                      options={
                        motivations?.map((m) => ({
                          label: m.description,
                          value: m,
                        })) || [{ label: "Motivation", value: "" }]
                      }
                      onChange={(selected) => {
                        setState({ ...state, motivation: selected.value });
                      }}
                    />
                  </div>
                  <div className="mb-20">
                    <Select
                      label="Expertise"
                      isMulti
                      placeholder="Select one or more Expertise"
                      value={expValues}
                      onChange={(selections) => {
                        let newExps = [];
                        selections?.map((exp) => {
                          newExps.push(exp.value);
                        });
                        setState({ ...state, expertise: [...newExps] });
                      }}
                      options={expOptions}
                    />
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Title"
                      placeholder={"eg. eg Negotiating salary"}
                      value={state.title}
                      onChange={(e) =>
                        setState({ ...state, title: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-20">
                    <InputField
                      label="Description"
                      placeholder={"Description"}
                      value={state.description}
                      onChange={(e) =>
                        setState({ ...state, description: e.target.value })
                      }
                    />
                  </div>

                  <div className="mb-20">
                    <FileInput
                      id="cover-image"
                      label="Upload topic image"
                      accept="image/*"
                      type="file"
                      placeholder={"Upload file here"}
                      selectedFile={state.selectedFile}
                      onChange={(e) => {
                        let file = e.target.files[0];
                        setState({ ...state, selectedFile: file });
                      }}
                    />
                  </div>

                  <div>
                    <ToggleButton
                      label="Disabled"
                      checked={state.disabled}
                      //loading={state.canCreateSessionProcessing}
                      blocked={state.processing}
                      onChange={() => {
                        let d = state.disabled ? false : true;
                        setState({ ...state, disabled: d });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="modal-footer-inner"
              style={{ paddingBottom: `${window.bottomSpace}` }}
              hidden={type === "View" ? true : false}
            >
              {state.error && !state.processing && (
                <div className="text-danger text-16 mb-10">{state.error}</div>
              )}

              <div className="d-flex">
                <Button
                  classes="small secondary mr-2"
                  loading={state.processing}
                  disabled={
                    (type === "Add" && state.topic_image === null) ||
                    state.title.length < 1 ||
                    state.description.length < 1 ||
                    expValues?.length < 1 ||
                    state.processing
                  }
                  onClick={() => {
                    setState({ ...state, processing: true, error: null });
                    const data = new FormData();
                    data.append("title", state.title);
                    data.append("description", state.description);
                    data.append("motivation", state.motivation.id);
                    data.append("disabled", state.disabled);

                    if (expValues.length === 1) {
                      data.append("expertise", `[${expValues[0]?.value.id},]`);
                    } else {
                      let exp = [];
                      expValues.map((el) => exp.push(el.value.id));
                      data.append("expertise", exp);
                    }

                    if (type === "Add") {
                      if (state.selectedFile)
                        data.append("topic_image", state.selectedFile);
                      postData("topic-pack/", data, token, true).then(
                        (resp) => {
                          if (resp.success) {
                            reset();
                            //let newData = [...topics, resp.data];
                            onHide();
                            onUpdated();
                            return;
                          }
                          setState({
                            ...state,
                            processing: false,
                            error:
                              resp.message ||
                              `Failed to add topic. Topic with the same Title might exist, try changing the title.`,
                          });
                        }
                      );
                    } else {
                      if (topic?.topic_image !== state.selectedFile?.name)
                        data.append("topic_image", state.selectedFile);
                      let id = topic["id"];
                      putData("topic-pack/" + id + "/", data, token, true).then(
                        (resp) => {
                          if (resp.success) {
                            reset();
                            onHide();
                            onUpdated();
                            return;
                          }
                          setState({
                            ...state,
                            processing: false,
                            error:
                              resp.message ||
                              "Failed to update topic. Please try changing the title.",
                          });
                        }
                      );
                    }
                  }}
                >
                  Publish
                </Button>
                <Button
                  classes="small info"
                  disabled={state.processing}
                  onClick={() => {
                    reset();
                    onHide();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </SideModal>
      )}
    </>
  );
};

export default TopicPacksModal;
