import { useState, useGlobal, useEffect } from "reactn";
import { Link } from "react-router-dom";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../components/button/button.component";
import InputField from "../../components/form/input-field/input-field.component";
import AnnouncementModal from "../../components/modal/announcement-modal/announcement-modal.component";
import { fetchFile, apiBaseUrl } from "../../api/api";
import { http, deleteData } from "../../api/services/announcement.service";
import PaginationBar from "../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../components/page-layout/page-layout.component";
import ExportButton from "../../components/export-button/export-button.component";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import useSWR from "swr";
import ConfirmModal from "../../components/modal/confirm-modal/confirm-modal.component";
import HaveAccess from "../../access-control.jsx";

const AnnouncementsPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const canWrite = HaveAccess("announcement", "Write", currentUser);

  const [exportAnnouncement, setExportAnnouncement] = useState({
    exporting: false,
    error: null,
  });

  const [query, setQuery] = useState("");
  const [canFetch, setCanfetch] = useState(false);
  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const SWRFetcher =
    (currentUser) =>
    (
      path,
      config = {
        headers: { Authorization: `Token ${currentUser.token.actual}` },
      }
    ) =>
      http.get(path, config).then((resp) => resp.data);

  const getURL = (
    q = query,
    limit = paginate.pageSize,
    offset = paginate.pageSize * paginate.page
  ) => {
    return `notification/announcement/?q=${q}&limit=${limit}&offset=${offset}`;
  };

  const {
    data: responseData,
    error: fetchError,
    mutate: reload,
    isValidating: loading,
  } = useSWR(canFetch ? getURL() : null, SWRFetcher(currentUser));

  const [state, setState] = useState({
    showModal: false,
    currentAnnouncement: null,
    modalType: "Add",
  });

  const [stateDelete, setStateDelete] = useState({
    showModal: false,
    currentAnnouncement: null,
    title: "Delete Announcement",
    description: "Do you want to delete this announcement?",
    processing: false,
    error: null,
  });

  useEffect(() => {
    if (!canFetch && !responseData) {
      setCanfetch(true);
    }
  }, [responseData, loading, fetchError, paginate.pageSize, paginate.page]);

  //
  function onAnnouncementUpdated(data) {
    reload();
  }

  const onDeleteAnnouncement = () => {
    setStateDelete({ ...stateDelete, processing: true, error: null });
    let id = stateDelete.currentAnnouncement["id"];
    deleteData(
      "notification/announcement/" + id + "/",
      currentUser.token.actual
    ).then((resp) => {
      if (resp.success) {
        setStateDelete({
          ...stateDelete,
          processing: false,
          error: null,
          showModal: false,
        });
        onAnnouncementUpdated(resp.data);

        return;
      }
      setStateDelete({
        ...stateDelete,
        processing: false,
        error: resp.message,
      });
    });
  };

  return (
    <>
      <AnnouncementModal
        announcement={state.currentAnnouncement}
        canWrite={canWrite}
        type={state.modalType}
        token={currentUser.token.actual}
        show={state.showModal}
        onHide={() => {
          setState({ ...state, showModal: false, currentAnnouncement: null });
        }}
        onUpdated={onAnnouncementUpdated}
      />

      <ConfirmModal
        show={stateDelete.showModal}
        title="Delete Announcement"
        description={stateDelete.description}
        error={stateDelete.error}
        processing={stateDelete.processing}
        onHide={() => {
          setStateDelete({ ...stateDelete, showModal: false, error: null });
        }}
        onConfirm={onDeleteAnnouncement}
      />

      <PageLayout activeLink="announcements" parentMenu="activities">
        <div>
          <div className="d-flex space-between  mb-20">
            <div className="text-grey text-20 font-bold">
              Announcements<span className="text-16"></span>
            </div>

            {/* <ExportButton label="Export to CVS"
                        url="account/report/review/export/"
                        fileName="announcements"
                    /> */}
          </div>

          <Row>
            <Col md={3} xs={12}>
              {canWrite && (
                <Button
                  classes={`small success mb-20`}
                  onClick={() => {
                    setState({ ...state, modalType: "Add", showModal: true });
                  }}
                >
                  <PlusIcon />{" "}
                  <span className="pl-2">Add new announcement</span>
                </Button>
              )}
            </Col>
            <Col md={4} />

            <Col md={5}>
              <InputField
                placeholder="Search  announcement"
                classes="small mb-20"
                isSearch
                value={query}
                onChange={(ev) => {
                  reload(getURL(ev.target.value, paginate.pageSize, 0));
                  setQuery(ev.target.value);
                  //fetchActiveReviews(ev.target.value)
                  // if(paginate.page > 0)  setPaginate({...paginate,page: 0})
                }}
              />
            </Col>
          </Row>

          {responseData && responseData["count"] > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>External link</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {responseData &&
                    responseData.results.map((announcement) => (
                      <tr key={announcement.id}>
                        <td>
                          <span className="text-16 text-grey font-500">
                            {announcement.title}{" "}
                          </span>
                        </td>
                        <td>
                          <a
                            href={announcement.url}
                            target="_blank"
                            className="text-16 text-success font-500 pointer"
                          >
                            Visit URL
                          </a>
                        </td>
                        <td>
                          <span className="text-16 text-grey-light  font-400">
                            {announcement.status}
                          </span>
                        </td>

                        <td>
                          {announcement.status === "Draft" ? (
                            <>
                              <span
                                className="text-16 text-success font-400 pointer mr-3"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    currentAnnouncement: announcement,
                                    modalType: "Edit",
                                    showModal: true,
                                  });
                                }}
                              >
                                Edit
                              </span>

                              <span
                                className="text-16 text-danger font-400 pointer mr-2"
                                onClick={() => {
                                  setStateDelete({
                                    ...stateDelete,
                                    currentAnnouncement: announcement,
                                    showModal: true,
                                  });
                                }}
                              >
                                Delete
                              </span>
                            </>
                          ) : (
                            <span
                              className="text-16 text-success font-400 pointer mr-2"
                              onClick={() => {
                                setState({
                                  ...state,
                                  currentAnnouncement: announcement,
                                  modalType: "View",
                                  showModal: true,
                                });
                              }}
                            >
                              View
                            </span>
                          )}

                          {/* <span className="text-16 text-warning font-400 pointer"
                                        onClick={()=>{
                                            setState({...state, currentReview: review, showReviewsModal: true}) 
                                        }}>Disable</span> */}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td colSpan={4}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={responseData["count"]}
                        onChangePage={(newPage) => {
                          setPaginate({ ...paginate, page: newPage });
                          reload(
                            getURL(
                              query,
                              paginate.pageSize,
                              newPage * paginate.pageSize
                            )
                          );
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({ ...paginate, pageSize: size, page: 0 });
                          reload(getURL(query, size, 0));
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {/*no announcement */}
          {responseData && responseData["count"] < 1 && (
            <div className="text-grey text-16 mb-20"> No announcement </div>
          )}

          <div>
            <div className="mt-10 d-flex justify-center">
              <Spinner
                hidden={fetchError || responseData}
                animation="border"
                variant="primary"
              />
            </div>

            {fetchError && !responseData && (
              <div className="mt-30">
                <span className="mt-10 text-danger">
                  Failed to load content.{" "}
                </span>
                &nbsp;
                <span
                  className="text-primary pointer font-bold"
                  onClick={() => reload()}
                >
                  TRY AGAIN
                </span>
              </div>
            )}
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default AnnouncementsPage;
