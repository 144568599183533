import { useState, useGlobal, useEffect } from "reactn";
import { Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import { fetchData, putData, postData } from "../../../api/api";
import PageLayout from "../../../components/page-layout/page-layout.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";

const AccountSettingsPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [activeUser, setActiveUser] = useGlobal("activeUser");

  const [state, setState] = useState({
    name: currentUser.name,
    username: currentUser.username,
    role: currentUser.superuser_role.name,
    password: "",
    showPassword: false,
    //role_id: ,
    active: false,
    private: false,
    fetching: false,
    canFetch: true,
    error: null,
  });

  const [confirm, setConfirm] = useState({
    show: false,
    description: "Confirm to update password",
    processing: false,
    title: "Change Password",
    error: null,
    success: null,
  });

  const onUpdateUser = () => {
    setConfirm({
      ...confirm,
      processing: true,
      error: null,
    });

    putData(
      `dashboard/user/${currentUser.id}/reset-password/`,
      { new_password: state.password },
      currentUser.token.actual
    ).then((response) => {
      if (response.success) {
        setConfirm({
          ...confirm,
          success: "updated successfully.",
          processing: false,
          error: null,
        });
        setState({ ...state, password: "" });
        return;
      }

      setConfirm({
        ...confirm,
        processing: false,
        error: response?.message || "Failed change password.",
      });
    });
  };

  useEffect(() => {});

  return (
    <>
      <ConfirmModal
        show={confirm.show}
        token={currentUser.token.actual}
        title={confirm.title}
        description={confirm.description}
        processing={confirm.processing}
        success={confirm.success}
        error={confirm.error}
        onHide={() => {
          setConfirm({
            ...confirm,
            error: null,
            success: null,
            processing: false,
            show: false,
          });
        }}
        onConfirm={() => {
          onUpdateUser();
        }}
      />

      <PageLayout activeLink="account-settings" parentMenu="settings">
        <div style={{ maxWidth: "530px" }}>
          <div className="text-grey text-20 font-bold mb-20">
            Account Settings
          </div>

          <Row>
            <Col md={12}>
              <InputField
                label="Name"
                placeholder=""
                classes="mb-20"
                disabled={true}
                value={state?.name}
                onChange={(ev) => {
                  //setState({ ...state, name: ev.target.value });
                }}
              />
            </Col>
            <Col md={12}>
              <InputField
                label="Username"
                placeholder=""
                classes="mb-20"
                disabled={true}
                value={state.username}
                onChange={(ev) => {
                  //setState({ ...state, username: ev.target.value });
                }}
              />
            </Col>
            <Col md={12}>
              <InputField
                label="Role"
                placeholder=""
                classes="mb-20"
                disabled={true}
                value={state.role}
                onChange={(ev) => {
                  //setState({ ...state, username: ev.target.value });
                }}
              />
            </Col>
            {/* <Col xs={12}>
              <InputField
                  label="Password"
                  placeholder="*********"
                  classes="mb-20"
                  type={state.showPassword ? "text": "password"}
                  value={state?.password}
                  onChange={ev => {
                    setState({ ...state, password: ev.target.value });
                  }}
                />
                <input type="checkbox" checked={state.showPassword}
                 onChange={(ev)=>{
                   setState({...state, showPassword: state.showPassword ? false : true}) 
                  }}/>
                    <span> Show password</span>
            </Col>
           
            <Col xs={5} className="mb-40">
              <Button
                classes={`secondary w-100 mb-20 mt-30`}
                disabled={state.password.length===0 }
                onClick={() => {
                  setConfirm({
                    ...confirm,
                    show: true
                  })
                }}>
                Update Password
              </Button>
            </Col> */}
          </Row>
        </div>
      </PageLayout>
    </>
  );
};

export default AccountSettingsPage;
