import React from "react";
import "./summary-card.styles.scss";

const SummaryCard = ({
  children,
  description,
  value,
  iconType,
  duration,
  transform = false,
  dangerValue = false,
  difference,
}) => {
  return (
    <div className={`summary-card`}>
      <div>
        {/* <div className={`icon ${iconType}`}>
          {iconType === "account" ? <Account/> : <DefaultIcon/>}
          {children}
        </div> */}
        <h3 className={`value text-truncate ${dangerValue}`}>
          {value ? value : 0}
        </h3>
        <div className="description text-truncate">{description}</div>
        {/* <div className="duration">{duration}</div>
        <div className="difference">{difference}</div> */}
      </div>
    </div>
  );
};

export default SummaryCard;
