import {
  FormControl,
  Select,
  MenuItem as SelectMenuItem,
} from "@material-ui/core";
import { useState } from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import Button from "components/button/button.component";

const ConfirmMarkBookingNoShowModal = ({
  show,
  onHide,
  booking,
  success = null,
  processing = false,
  error = null,
  onConfirm = (userIdentityId) => {},
  onBlurHide = false,
}) => {
  const [userIdentityId, setUserIdentityId] = useState("");

  return (
    <div>
      <Modal
        className="center-modal"
        show={show}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header>
          <div className="font-500">Mark booking as no-show</div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          {booking && (
            <div>
              <div className="text-grey" style={{ marginTop: "-21px" }}>
                Select user below to mark as no-show. The user selected will
                receive a no-show email.
              </div>

              <div className="mt-25">
                <div className="text-grey-light text-16 font-400 mb-10">
                  Select user
                </div>

                <FormControl fullWidth variant="outlined" className="mt-20">
                  <Select
                    value={userIdentityId}
                    onChange={(event) => {
                      setUserIdentityId(event.target.value);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}>
                    <SelectMenuItem value="">Select user</SelectMenuItem>
                    <SelectMenuItem value={booking.mentee.identity_id}>
                      {booking.mentee.name} - Mentee
                    </SelectMenuItem>
                    <SelectMenuItem value={booking.mentor.identity_id}>
                      {booking.mentor.name} - Mentor
                    </SelectMenuItem>
                  </Select>
                </FormControl>
              </div>

              {success && !processing && (
                <div className="mt-10 text-success text-center">{success}</div>
              )}

              {error && (!processing || !success) && (
                <div className="mt-10 text-danger text-center">{error}</div>
              )}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="f-width">
            <Row>
              <Col xs={6}>
                <Button
                  classes="small info f-width"
                  disabled={processing}
                  onClick={onHide}>
                  {success ? "Close" : "Cancel"}
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  classes="small secondary f-width"
                  hidden={success}
                  loading={processing}
                  disabled={processing || !userIdentityId}
                  onClick={() => onConfirm(userIdentityId)}>
                  Confirm
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmMarkBookingNoShowModal;
