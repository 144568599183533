import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./button-dropdown.styles.scss";
import { ReactComponent as Ticked } from "../../assets/icons/ticked.svg";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    id="button-dropdown-toggle"
    className="button-dropdown-toggle"
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

export const MenuItem = ({ children, active, onClick, otherProps }) => {
  return (
    <Dropdown.Item active={active} onClick={() => onClick()} {...otherProps}>
      <div className={`item-text`}>{children}</div>&nbsp;&nbsp;&nbsp;
      <span>{active ? <Ticked /> : ""}</span>
    </Dropdown.Item>
  );
};

const ButtonDropdown = ({ children, title, classes, otherProps }) => {
  return (
    <div className={`button-dropdown ${classes}`}>
      <Dropdown {...otherProps}>
        <Dropdown.Toggle as={CustomToggle}>{title}</Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} className="button-dropdown-menu">
          {children}

          {/* <Dropdown.Item eventKey="3" active>
            Orange
          </Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ButtonDropdown;
