import { apiBaseUrl, handleError, createResponse } from "../api";
// import axios
const axios = require("axios").default;

export const getAnalytics = async (authToken, url, config_) =>
  //await fetchData(url, authToken);
  axios
    .get(`${url}`, {
      baseURL: apiBaseUrl,
      headers: { Authorization: `Token ${authToken}` },
      ...config_,
    })
    .then((response) => createResponse(true, response.data, response?.message))
    .catch((error) => handleError(error));
