import { useState, useGlobal, useEffect } from "reactn";
import { Link } from "react-router-dom";
import { Table, Row, Col, Spinner, Pagination } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import IndustryModal from "../../../components/modal/industry-modal/industry-modal.component";
import { fetchData, putData } from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import HaveAccess from "../../../access-control.jsx";

const IndustriesPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [industries, setIndustries] = useGlobal("industries");
  const canWrite = HaveAccess("industry", "Write", currentUser);

  const [state, setState] = useState({
    activeTab: 0,
    showIndustryModal: false,
    IndustryModalType: null,
    currentIndustry: null,
    showConfirmModal: false,
    confirmDescription: "",
    confirmProcessing: false,
    confirmTitle: "",
    confirmError: null,
    fetching: false,
    canFetch: true,
    error: null,
    query: "",
    queryDisabled: "",
    activeIndustries: [], //industries.filter(industry => industry.disabled === false),
    disabledIndustries: [], // industries.filter(industry => industry.disabled === true),
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [disabledPaginate, setDisabledPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (industries && state.canFetch) {
      setState({
        ...state,
        canFetch: false,
        activeIndustries: industries.filter(
          (industry) => industry.disabled === false
        ),
        disabledIndustries: industries.filter(
          (industry) => industry.disabled === true
        ),
      });
    }

    if (state.canFetch && !state.fetching && !industries) {
      setState({
        ...state,
        fetching: true,
        canFetch: false,
      });

      let authToken = currentUser.token.actual;
      fetchData("industry/", authToken, false).then((response) => {
        if (response.success) {
          setState({
            ...state,
            fetching: false,
            canFetch: false,
            error: null,
            activeIndustries: response.data.filter(
              (industry) => industry.disabled === false
            ),
            disabledIndustries: response.data.filter(
              (industry) => industry.disabled === true
            ),
          });
          setIndustries(response.data);
          return;
        }
        setState({
          ...state,
          fetching: false,
          canFetch: false,
          error: response.message,
        });
      });
    }
  }, [
    industries,
    state.activeIndustries,
    state.disabledIndustries,
    state.canFetch,
    state.limit,
    paginate,
  ]);

  //edit or add --callback
  function onInterestUpdated(data) {
    setState({
      ...state,
      activeIndustries: data.filter((industry) => industry.disabled === false),
      disabledIndustries: data.filter((industry) => industry.disabled === true),
      showIndustryModal: false,
    });

    setIndustries(data);
  }

  //confirm disable or enable industry
  function onInterestDisableOrEnable() {
    setState({ ...state, confirmProcessing: true });
    const formData = new FormData();
    formData.append("description", state.currentIndustry.description);
    formData.append(
      "disabled",
      state.currentIndustry.disabled ? "False" : "True"
    );
    //formData.append("caption", state.currentIndustry.caption);
    //formData.append("color_code", state.currentIndustry.color_code);
    //formdata.append("cover_photo":"")

    putData(
      `industry/${state.currentIndustry.id}/`,
      formData,
      currentUser.token.actual,
      true
    ).then((response) => {
      if (response.success) {
        let current = industries.filter(
          (industry) => industry.id !== state.currentIndustry["id"]
        );
        let newData = [...current, response.data];
        setState({
          ...state,
          confirmProcessing: false,
          confirmError: null,
          showConfirmModal: false,
          activeIndustries: newData.filter(
            (industry) => industry.disabled === false
          ),
          disabledIndustries: newData.filter(
            (industry) => industry.disabled === true
          ),
        });
        setIndustries(newData);
        return;
      }
      setState({
        ...state,
        confirmProcessing: false,
        confirmError: response.message,
      });
    });
  }

  //active filtering
  const activeIndustries = () => {
    let active = state.activeIndustries;
    let totalItems = active.length;

    if (state.query.length > 0) {
      active = active.filter((industry) =>
        industry.description.toLowerCase().includes(state.query)
      );
      //list length
      totalItems = active.length;
    }

    // paginate
    const { page, pageSize } = paginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: active.slice(start, end), totalItems: totalItems };
  };

  //disabled mentors filtering
  const disabledIndustries = () => {
    let disabled = state.disabledIndustries;
    let totalItems = disabled.length;

    if (state.queryDisabled.length > 0) {
      disabled = disabled.filter(
        (industry) =>
          industry["id"].includes(state.queryDisabled) ||
          industry.description.toLowerCase().includes(state.queryDisabled)
      );
      //list length
      totalItems = disabled.length;
    }

    // paginate
    const { page, pageSize } = disabledPaginate;
    const start = page * pageSize;
    const end = start + pageSize;

    return { items: disabled.slice(start, end), totalItems: totalItems };
  };

  return (
    <>
      <IndustryModal
        industry={state.currentIndustry}
        token={currentUser.token.actual}
        type={state.IndustryModalType}
        show={state.showIndustryModal}
        onHide={() => {
          setState({ ...state, showIndustryModal: false });
        }}
        onUpdated={onInterestUpdated}
      />

      <ConfirmModal
        show={state.showConfirmModal}
        token={currentUser.token.actual}
        title={state.confirmTitle}
        description={state.confirmDescription}
        processing={state.confirmProcessing}
        error={state.confirmError}
        onHide={() => {
          setState({ ...state, confirmError: null, showConfirmModal: false });
        }}
        onConfirm={onInterestDisableOrEnable}
      />

      <PageLayout activeLink="industry" parentMenu="demographics">
        <div>
          <div className="text-grey text-20 font-bold mb-20">Industry</div>

          <Row>
            <Col md={7}>
              <div className="d-flex flex-wrap align-center mb-20">
                <Button
                  classes={`pill success ${state.activeTab == 0} mr-2  mb-20`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 0 });
                  }}
                >
                  Active({state.activeIndustries.length})
                </Button>

                <Button
                  classes={`pill success ${state.activeTab == 1}  mb-20 mr-3`}
                  disabled={state.activeTab == null}
                  onClick={() => {
                    setState({ ...state, activeTab: 1 });
                  }}
                >
                  Disabled({state.disabledIndustries.length})
                </Button>
                {canWrite && (
                  <Button
                    classes={`small success w-200  mb-20`}
                    onClick={() => {
                      setState({
                        ...state,
                        IndustryModalType: "Add",
                        showIndustryModal: true,
                      });
                    }}
                  >
                    <PlusIcon /> <span className="pl-2">Add industry</span>
                  </Button>
                )}
              </div>
            </Col>

            <Col md={5}>
              {state.activeTab == 0 && (
                <InputField
                  placeholder="Search  industries"
                  classes="small mb-20"
                  isSearch
                  value={state.query}
                  onChange={(ev) => {
                    setState({ ...state, query: ev.target.value });
                    if (paginate.page > 0)
                      setPaginate({ ...paginate, page: 0 });
                  }}
                />
              )}

              {state.activeTab == 1 && (
                <InputField
                  placeholder="Search disabled  industries"
                  classes="small mb-20"
                  isSearch
                  value={state.queryDisabled}
                  onChange={(ev) => {
                    setState({ ...state, queryDisabled: ev.target.value });
                    if (disabledPaginate.page > 0)
                      setDisabledPaginate({ ...disabledPaginate, page: 0 });
                  }}
                />
              )}
            </Col>
          </Row>

          {state.activeTab == 0 && state.activeIndustries.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Industry</th>
                    <th>Mentors</th>
                    <th>Members</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {activeIndustries().items.map((industry) => (
                    <tr key={industry.id}>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {industry.description}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {industry?.total_mentors}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {industry?.total_members}
                        </span>
                      </td>

                      <td>
                        <span
                          hidden={!canWrite}
                          className="text-16 text-success font-400 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              IndustryModalType: "Edit",
                              currentIndustry: industry,
                              showIndustryModal: true,
                            });
                          }}
                        >
                          Edit
                        </span>

                        <span
                          hidden={!canWrite}
                          className="text-16 text-warning font-400 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              currentIndustry: industry,
                              confirmTitle: "Disable  industry",
                              confirmDescription: `Do you want to disable this  industry [ ${industry.description} ] `,
                              showConfirmModal: true,
                            });
                          }}
                        >
                          Disable
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={activeIndustries().totalItems}
                        onChangePage={(newPage) => {
                          setPaginate({ ...paginate, page: newPage });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({ ...paginate, pageSize: size, page: 0 });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {/*no active industries */}
          {state.activeTab == 0 &&
            state.activeIndustries.length == 0 &&
            !state.fetching && (
              <div className="text-grey text-16 mb-20">
                {" "}
                No active Industry{" "}
              </div>
            )}

          {state.activeTab == 1 && state.disabledIndustries.length > 0 && (
            <div>
              <Table bordered responsive className="table-white">
                <thead>
                  <tr>
                    <th>Industry</th>
                    <th>Mentors</th>
                    <th>Members</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {disabledIndustries().items.map((industry) => (
                    <tr key={industry.id}>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {industry.description}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {industry?.total_mentors}{" "}
                        </span>
                      </td>
                      <td>
                        <span className="text-16 text-grey font-400">
                          {industry?.total_members}
                        </span>
                      </td>
                      <td>
                        <span
                          hidden={!canWrite}
                          className="text-16 text-success font-400 pointer mr-2"
                          onClick={() => {
                            setState({
                              ...state,
                              IndustryModalType: "Edit",
                              currentIndustry: industry,
                              showIndustryModal: true,
                            });
                          }}
                        >
                          Edit
                        </span>

                        <span
                          hidden={!canWrite}
                          className="text-16 text-success font-400 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              currentIndustry: industry,
                              confirmTitle: "Enable  industry",
                              confirmDescription: `Do you want to enable this  industry [ ${industry.description} ] `,
                              showConfirmModal: true,
                            });
                          }}
                        >
                          Enable
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4}>
                      <PaginationBar
                        page={disabledPaginate.page}
                        pageSize={disabledPaginate.pageSize}
                        totalItems={disabledIndustries().totalItems}
                        onChangePage={(newPage) => {
                          setDisabledPaginate({
                            ...disabledPaginate,
                            page: newPage,
                          });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setDisabledPaginate({
                            ...disabledPaginate,
                            pageSize: size,
                            page: 0,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {/*no disabled industry */}
          {state.activeTab == 1 &&
            state.disabledIndustries.length == 0 &&
            !state.fetching && (
              <div className="text-grey text-16 mb-20">
                {" "}
                No disabled industries{" "}
              </div>
            )}

          <div className="mt-10 d-flex justify-center">
            <Spinner
              hidden={!state.fetching || state.query.length > 0}
              animation="border"
              variant="primary"
            />
          </div>

          <div className="mt-30" hidden={!state.error || state.fetching}>
            <span className="mt-10 text-danger">{state.error}.</span>
            &nbsp;
            <span
              className="text-primary pointer font-bold"
              onClick={() => setState({ ...state, canFetch: true })}
            >
              TRY AGAIN
            </span>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default IndustriesPage;
