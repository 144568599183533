import { useState, useGlobal, useEffect } from "reactn";
import { Table, Row, Col } from "react-bootstrap";
import Button from "../../../components/button/button.component";
import InputField from "../../../components/form/input-field/input-field.component";
import BookingsModal from "../../../components/modal/bookings-modal/bookings-modal.component";
import { mentorUrl, designerUrl } from "../../../api/api";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../../components/page-layout/page-layout.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import ExportButton from "../../../components/export-button/export-button.component";
import ConfirmUnMarkBookingNoShowModal from "components/modal/confirm-unmark-booking-noshow-modal/confirm-unmark-booking-noshow-modal.component";
import {
  markBookingNoShow,
  unMarkBookingNoShow,
} from "api/services/booking.service";
import ConfirmModal from "components/modal/confirm-modal/confirm-modal.component";
import ConfirmMarkBookingNoShowModal from "components/modal/confirm-mark-booking-no-show-modal/confirm-mark-booking-no-show-modal.component";

const BookingsPage = (props) => {
  const sortDescending = (array = []) =>
    array?.sort(
      (a, b) => new Date(b.date_and_time) - new Date(a.date_and_time)
    );
  const [currentUser] = useGlobal("currentUser");

  const [query, setQuery] = useState("");
  const [queryPending, setQueryPending] = useState("");
  const [queryConfirmed, setQueryConfirmed] = useState("");
  const [queryDeclined, setQueryDeclined] = useState("");
  const [queryCancelled, setQueryCancelled] = useState("");
  const [queryNoShow, setQueryNoShow] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const filter =
    activeTab === 1
      ? "Pending"
      : activeTab === 2
      ? "Confirmed"
      : activeTab === 3
      ? "Declined"
      : activeTab === 4
      ? "Cancelled"
      : activeTab === 5
      ? "NoShow"
      : "all";

  const [exportFile] = useState({
    exporting: false,
    error: null,
    url: `booking/export/?&limit=10&offset=0&filter=${filter}`,
  });

  const [state, setState] = useState({
    activeTab: 0,
    showSideModal: false,
    currentBooking: null,
    fetching: false,
    canFetch: true,
    error: null,
  });

  const [paginate, setPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [pendingPaginate, setPendingPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [confirmedPaginate, setConfirmedPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [declinedPaginate, setDeclinedPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [cancelledPaginate, setCancelledPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  const [noShowPaginate, setNoShowPaginate] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
  });

  //active,
  const getURL = (q = "", limit, offset, filter = "all") => {
    return `${process.env.REACT_APP_CALENDAR_API}booking/list/tenet/?q=${q}&limit=${limit}&offset=${offset}&filter=${filter}`;
  };

  const getCountURL = (filter = "all") => {
    return `${process.env.REACT_APP_CALENDAR_API}booking/count/?filter=${filter}`;
  };

  const { data: totalAllData } = useSWR(getCountURL());
  const { data: totalPendingData } = useSWR(getCountURL("Pending"));
  const { data: totalConfirmedData } = useSWR(getCountURL("Confirmed"));
  const { data: totalDeclinedData } = useSWR(getCountURL("Declined"));
  const { data: totalCancelledData } = useSWR(getCountURL("Cancelled"));
  const { data: totalNoShowData } = useSWR(getURL("", 1, 0, "NoShow"));

  const [canFetchTab1, setCanFetchTab1] = useState(false);
  const [canFetchTab2, setCanFetchTab2] = useState(false);
  const [canFetchTab3, setCanFetchTab3] = useState(false);
  const [canFetchTab4, setCanFetchTab4] = useState(false);
  const [canFetchTab5, setCanFetchTab5] = useState(false);

  const [
    confirmMarkBookingNoShowModalState,
    setConfirmMarkBookingNoShowModalState,
  ] = useState({
    show: false,
    error: null,
    processing: false,
  });

  const [
    confirmUnmarkBookingNoShowModalState,
    setConfirmUnmarkBookingNoShowModalState,
  ] = useState({
    show: false,
    error: null,
    processing: false,
  });

  const [
    confirmChangeBookingNoShowMarkedByUserState,
    setConfirmChangeBookingNoShowMarkedByUserState,
  ] = useState({
    show: false,
    error: null,
    processing: false,
    success: null,
  });

  const {
    data: allBookingsData,
    error: allBookingsError,
    mutate: mutateAllBookings,
  } = useSWR(
    getURL(query, paginate.pageSize, paginate.pageSize * paginate.page, "all")
  );

  const {
    data: pendingBookingsData,
    error: pendingBookingsError,
    mutate: mutatePendingBookings,
  } = useSWR(
    canFetchTab1 &&
      getURL(
        queryPending,
        pendingPaginate.pageSize,
        pendingPaginate.pageSize * pendingPaginate.page,
        "Pending"
      )
  );

  const {
    data: confirmedBookingsData,
    error: confirmedBookingsError,
    mutate: mutateConfirmedBookings,
  } = useSWR(
    canFetchTab2 &&
      getURL(
        queryConfirmed,
        confirmedPaginate.pageSize,
        confirmedPaginate.pageSize * confirmedPaginate.page,
        "Confirmed"
      )
  );

  const {
    data: declinedBookingsData,
    error: declinedBookingsError,
    mutate: mutateDeclinedBookings,
  } = useSWR(
    canFetchTab3 &&
      getURL(
        queryDeclined,
        declinedPaginate.pageSize,
        declinedPaginate.pageSize * declinedPaginate.page,
        "Declined"
      )
  );

  const {
    data: cancelledBookingsData,
    error: cancelledBookingsError,
    mutate: mutateCancelledBookings,
  } = useSWR(
    canFetchTab4 &&
      getURL(
        queryCancelled,
        cancelledPaginate.pageSize,
        cancelledPaginate.pageSize * cancelledPaginate.page,
        "Cancelled"
      )
  );

  const {
    data: noShowBookingsData,
    error: noShowBookingsError,
    mutate: mutateNoShowBookings,
  } = useSWR(
    activeTab === 5 &&
      getURL(
        queryNoShow,
        noShowPaginate.pageSize,
        noShowPaginate.pageSize * noShowPaginate.page,
        "NoShow"
      )
  );

  useEffect(() => {});

  const formatTime = (dateString, addTime) => {
    const date = new Date(dateString);
    date.setTime(date.getTime() + addTime * 60000);
    return date.toISOString().split("T")[1].slice(0, 5);
  };

  const onConfirmMarkBookingNoShow = (defaultedUserIdentityId) => {
    setConfirmMarkBookingNoShowModalState({
      ...confirmMarkBookingNoShowModalState,
      processing: true,
      error: null,
    });

    markBookingNoShow(currentUser.token.actual, {
      pk: state.currentBooking.id,
      defaultedUserIdentityId: defaultedUserIdentityId,
    }).then((response) => {
      if (response.success) {
        setConfirmMarkBookingNoShowModalState({
          ...confirmMarkBookingNoShowModalState,
          processing: false,
          success: "Booking successfully marked as no show",
        });

        setState({
          ...state,
          currentBooking: response.data,
        });

        return;
      }

      setConfirmMarkBookingNoShowModalState({
        ...confirmMarkBookingNoShowModalState,
        processing: false,
        error: response.message,
      });
    });
  };

  const onConfirmUnMarkBookingNoShow = (reason) => {
    setConfirmUnmarkBookingNoShowModalState({
      ...confirmUnmarkBookingNoShowModalState,
      processing: true,
      error: null,
    });

    unMarkBookingNoShow(currentUser.token.actual, {
      pk: state.currentBooking.id,
      reason: reason ?? null,
    }).then((response) => {
      if (response.success) {
        setConfirmUnmarkBookingNoShowModalState({
          ...confirmUnmarkBookingNoShowModalState,
          processing: false,
          success: "Booking successfully unmarked as no show",
        });

        setState({
          ...state,
          currentBooking: response.data,
        });

        return;
      }

      setConfirmUnmarkBookingNoShowModalState({
        ...confirmUnmarkBookingNoShowModalState,
        processing: false,
        error: response.message,
      });
    });
  };

  const onConfirmOverrideBookingNoShowMarkedByUser = () => {
    setConfirmChangeBookingNoShowMarkedByUserState({
      ...confirmChangeBookingNoShowMarkedByUserState,
      error: null,
      processing: true,
    });

    markBookingNoShow(currentUser.token.actual, {
      pk: state.currentBooking.id,
    }).then((response) => {
      if (response.success) {
        setConfirmChangeBookingNoShowMarkedByUserState({
          ...confirmChangeBookingNoShowMarkedByUserState,
          processing: false,
          success: "Booking no show marked by user override successful.",
        });

        setState({
          ...state,
          currentBooking: response.data,
        });

        return;
      }

      setConfirmChangeBookingNoShowMarkedByUserState({
        ...confirmChangeBookingNoShowMarkedByUserState,
        processing: false,
        error: response.message,
      });
    });
  };

  return (
    <>
      <BookingsModal
        bookingId={state.currentBooking && state.currentBooking.id}
        token={currentUser.token.actual}
        show={state.showSideModal}
        onHide={() => {
          setState({ ...state, showSideModal: false, currentBooking: null });
        }}
        onUnmarkNoShow={() => {
          setState({ ...state, showSideModal: false });
          setConfirmUnmarkBookingNoShowModalState({
            ...confirmUnmarkBookingNoShowModalState,
            show: true,
          });
        }}
        changeBookingNoShowMarkedBy={(booking) => {
          setState({
            ...state,
            showSideModal: false,
            currentBooking: booking,
          });
          setConfirmChangeBookingNoShowMarkedByUserState({
            ...confirmChangeBookingNoShowMarkedByUserState,
            show: true,
          });
        }}
        markBookingAsNoShow={() => {
          setState({ ...state, showSideModal: false });
          setConfirmMarkBookingNoShowModalState({
            ...confirmMarkBookingNoShowModalState,
            show: true,
          });
        }}
      />

      <ConfirmModal
        show={confirmChangeBookingNoShowMarkedByUserState.show}
        title={
          !confirmChangeBookingNoShowMarkedByUserState.success
            ? `Confirm mark ${state.currentBooking?.no_show?.marked_by.name} as no-show`
            : ""
        }
        description={`You are about to override the marked by user of this no-show. This action means ${state.currentBooking?.no_show?.marked_by.name} will now become the defaulted user. An email will be triggered to said user. Click confirm below to proceed`}
        processing={confirmChangeBookingNoShowMarkedByUserState.processing}
        error={confirmChangeBookingNoShowMarkedByUserState.error}
        success={confirmChangeBookingNoShowMarkedByUserState.success}
        onConfirm={onConfirmOverrideBookingNoShowMarkedByUser}
        onHide={() => {
          setConfirmChangeBookingNoShowMarkedByUserState({
            success: null,
            error: null,
            show: false,
          });

          setState({
            ...state,
            showSideModal: true,
          });
        }}
      />

      <ConfirmUnMarkBookingNoShowModal
        show={confirmUnmarkBookingNoShowModalState.show}
        error={confirmUnmarkBookingNoShowModalState.error}
        processing={confirmUnmarkBookingNoShowModalState.processing}
        booking={state.currentBooking}
        success={confirmUnmarkBookingNoShowModalState.success}
        onHide={() => {
          setConfirmUnmarkBookingNoShowModalState({
            ...confirmUnmarkBookingNoShowModalState,
            show: false,
            success: false,
            error: null,
            processing: false,
          });
          setState({ ...state, showSideModal: true });
        }}
        onConfirm={onConfirmUnMarkBookingNoShow}
      />

      <ConfirmMarkBookingNoShowModal
        show={confirmMarkBookingNoShowModalState.show}
        error={confirmMarkBookingNoShowModalState.error}
        processing={confirmMarkBookingNoShowModalState.processing}
        booking={state.currentBooking}
        success={confirmMarkBookingNoShowModalState.success}
        onHide={() => {
          setConfirmMarkBookingNoShowModalState({
            ...confirmMarkBookingNoShowModalState,
            show: false,
            success: false,
            error: null,
            processing: false,
          });
          setState({ ...state, showSideModal: true });
        }}
        onConfirm={onConfirmMarkBookingNoShow}
      />

      <PageLayout activeLink="bookings" parentMenu="activities">
        <div>
          <div className="text-grey text-20 font-bold mb-20"></div>
          <div className="d-flex space-between  mb-20">
            <div className="text-grey text-20 font-bold">Bookings</div>
          </div>
          <div className="d-flex f hlex-end mb-20">
            <ExportButton
              label="Export"
              url={`${exportFile.url}`}
              baseUrl={`${process.env.REACT_APP_CALENDAR_API}`}
              fileName={`${filter}-bookings`}
            />
          </div>
          <Row>
            <Col md={8}>
              <div className="d-flex flex-wrap mb-20">
                <Button
                  classes={`pill success ${activeTab == 0} mb-10 mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(0);
                  }}>
                  All({totalAllData || 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab == 1} mb-10  mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(1);
                    if (!canFetchTab1) setCanFetchTab1(true);
                  }}>
                  Pending({totalPendingData || 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab == 2} mb-10  mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(2);
                    if (!canFetchTab2) setCanFetchTab2(true);
                  }}>
                  Confirmed({totalConfirmedData || 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab === 3}  mb-10 mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(3);
                    if (!canFetchTab3) setCanFetchTab3(true);
                  }}>
                  Declined({totalDeclinedData || 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab === 4} mb-10 mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(4);
                    if (!canFetchTab4) setCanFetchTab4(true);
                  }}>
                  Cancelled({totalCancelledData || 0})
                </Button>

                <Button
                  classes={`pill success ${activeTab === 5} mb-10  mr-2`}
                  disabled={activeTab == null}
                  onClick={() => {
                    setActiveTab(5);
                    if (!canFetchTab5) setCanFetchTab5(true);
                  }}>
                  No-Show({totalNoShowData?.count || 0})
                </Button>
              </div>
            </Col>
            <Col md={4}>
              {activeTab === 0 && (
                <Row>
                  <Col md={8}>
                    <InputField
                      id="all-search-input"
                      placeholder="Search"
                      classes="small mb-20"
                      isSearch
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setQuery(e.target.value);
                        }
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <Button
                      classes="small secondary"
                      disabled={false}
                      onClick={() => {
                        const query =
                          document.querySelector("#all-search-input").value;
                        setQuery(query);
                      }}>
                      Search
                    </Button>
                  </Col>
                </Row>
              )}

              {activeTab === 1 && (
                <Row>
                  <Col md={8}>
                    <InputField
                      id="pending-search-input"
                      placeholder="Search pending bookings"
                      classes="small mb-20"
                      isSearch
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setQueryPending(e.target.value);
                        }
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <Button
                      classes="small secondary"
                      disabled={false}
                      onClick={() => {
                        const query = document.querySelector(
                          "#pending-search-input"
                        ).value;
                        setQueryPending(query);
                      }}>
                      Search
                    </Button>
                  </Col>
                </Row>
              )}

              {activeTab === 2 && (
                <Row>
                  <Col md={8}>
                    <InputField
                      id="confirmed-search-input"
                      placeholder="Search confirmed bookings"
                      classes="small mb-20"
                      isSearch
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setQueryConfirmed(e.target.value);
                        }
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <Button
                      classes="small secondary"
                      disabled={false}
                      onClick={() => {
                        const query = document.querySelector(
                          "#confirmed-search-input"
                        ).value;
                        setQueryConfirmed(query);
                      }}>
                      Search
                    </Button>
                  </Col>
                </Row>
              )}

              {activeTab === 3 && (
                <Row>
                  <Col md={8}>
                    <InputField
                      id="declined-search-input"
                      placeholder="Search declined bookings"
                      classes="small mb-20"
                      isSearch
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setQueryDeclined(e.target.value);
                        }
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <Button
                      classes="small secondary"
                      disabled={false}
                      onClick={() => {
                        const query = document.querySelector(
                          "#declined-search-input"
                        ).value;
                        setQueryDeclined(query);
                      }}>
                      Search
                    </Button>
                  </Col>
                </Row>
              )}

              {activeTab === 4 && (
                <Row>
                  <Col md={8}>
                    <InputField
                      id="cancelled-search-input"
                      placeholder="Search cancelled bookings"
                      classes="small mb-20"
                      isSearch
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setQueryCancelled(e.target.value);
                        }
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <Button
                      classes="small secondary"
                      disabled={false}
                      onClick={() => {
                        const query = document.querySelector(
                          "#cancelled-search-input"
                        ).value;
                        setQueryCancelled(query);
                      }}>
                      Search
                    </Button>
                  </Col>
                </Row>
              )}

              {activeTab === 5 && (
                <Row>
                  <Col md={8}>
                    <InputField
                      id="no-show-search-input"
                      placeholder="Search no show bookings"
                      classes="small mb-20"
                      isSearch
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setQueryNoShow(e.target.value);
                        }
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <Button
                      classes="small secondary"
                      disabled={false}
                      onClick={() => {
                        const query = document.querySelector(
                          "#no-show-search-input"
                        ).value;
                        setQueryNoShow(query);
                      }}>
                      Search
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          {activeTab === 0 && (
            <LoaderPane
              loading={!allBookingsData && !allBookingsError}
              error={allBookingsError?.message}
              noRecords={
                !allBookingsError &&
                allBookingsData &&
                allBookingsData["count"] === 0
              }
              onReload={() => {
                mutateAllBookings();
              }}
            />
          )}

          {activeTab === 0 &&
            allBookingsData &&
            !allBookingsError &&
            allBookingsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Mentee</th>
                      <th>Mentor</th>
                      <th>Status</th>
                      <th>Date and Time</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortDescending(allBookingsData?.results)?.map(
                      (booking) => (
                        <tr key={booking?.id}>
                          <td>
                            <a
                              href={
                                booking.mentee.identity_type === "Mentor"
                                  ? mentorUrl(booking?.mentee.slug)
                                  : designerUrl(booking?.mentee.slug)
                              }
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentee.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentee.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <a
                              href={mentorUrl(booking?.mentor.slug)}
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentor.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentor.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <span className="text-16 text-grey-light font-500">
                              {booking?.status}
                            </span>
                          </td>
                          <td>
                            <span className="text-16 text-grey-light font-500">
                              {new Date(
                                new Date(booking?.date_and_time)
                              ).toUTCString()}
                            </span>
                          </td>
                          {/* <td>
                            <span className="text-16 text-grey-light font-500">
                              {booking?.date_and_time.split("T")[1].slice(0, 5)}{" "}
                              -{" "}
                              {formatTime(
                                booking?.date_and_time,
                                booking?.duration_in_minutes
                              )}
                            </span>
                          </td> */}

                          <td>
                            <span
                              className="text-16 text-success font-500 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  currentBooking: booking,
                                  showSideModal: true,
                                });
                              }}>
                              View
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td colSpan={6}>
                        <PaginationBar
                          page={paginate.page}
                          pageSize={paginate.pageSize}
                          totalItems={allBookingsData["count"]}
                          onChangePage={(newPage) => {
                            setPaginate({ ...paginate, page: newPage });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setPaginate({
                              ...paginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

          {activeTab === 1 && (
            <LoaderPane
              loading={!pendingBookingsData && !pendingBookingsError}
              error={pendingBookingsError?.message}
              noRecords={
                !pendingBookingsError &&
                pendingBookingsData &&
                pendingBookingsData["count"] === 0
              }
              onReload={() => {
                mutatePendingBookings();
              }}
            />
          )}

          {activeTab === 1 &&
            pendingBookingsData &&
            !pendingBookingsError &&
            pendingBookingsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Mentee</th>
                      <th>Mentor</th>
                      <th>Date and Time</th>
                      {/* <th>Time</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortDescending(pendingBookingsData?.results)?.map(
                      (booking) => (
                        <tr key={booking?.id}>
                          <td>
                            <a
                              href={
                                booking.mentee.identity_type === "Mentor"
                                  ? mentorUrl(booking?.mentee.slug)
                                  : designerUrl(booking?.mentee.slug)
                              }
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentee.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentee.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <a
                              href={mentorUrl(booking?.mentor.slug)}
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentor.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentor.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <span className="text-16 text-grey-light font-500">
                              {new Date(
                                new Date(booking?.date_and_time)
                              ).toUTCString()}
                            </span>
                          </td>
                          {/* <td>
                            <span className="text-16 text-grey-light font-500">
                              {booking?.date_and_time.split("T")[1].slice(0, 5)}{" "}
                              -{" "}
                              {formatTime(
                                booking?.date_and_time,
                                booking?.duration_in_minutes
                              )}
                            </span>
                          </td> */}

                          <td>
                            <span
                              className="text-16 text-success font-500 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  currentBooking: booking,
                                  showSideModal: true,
                                });
                              }}>
                              View
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td colSpan={5}>
                        <PaginationBar
                          page={pendingPaginate.page}
                          pageSize={pendingPaginate.pageSize}
                          totalItems={pendingBookingsData["count"]}
                          onChangePage={(newPage) => {
                            setPendingPaginate({
                              ...pendingPaginate,
                              page: newPage,
                            });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setPendingPaginate({
                              ...pendingPaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

          {activeTab === 2 && (
            <LoaderPane
              loading={!confirmedBookingsData && !confirmedBookingsError}
              error={confirmedBookingsError?.message}
              noRecords={
                !confirmedBookingsError &&
                confirmedBookingsData &&
                confirmedBookingsData["count"] === 0
              }
              onReload={() => {
                mutateConfirmedBookings();
              }}
            />
          )}

          {activeTab === 2 &&
            confirmedBookingsData &&
            !confirmedBookingsError &&
            confirmedBookingsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Mentee</th>
                      <th>Mentor</th>
                      <th>Date and Time</th>
                      {/* <th>Time</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortDescending(confirmedBookingsData?.results)?.map(
                      (booking) => (
                        <tr key={booking?.id}>
                          <td>
                            <a
                              href={
                                booking.mentee.identity_type === "Mentor"
                                  ? mentorUrl(booking?.mentee.slug)
                                  : designerUrl(booking?.mentee.slug)
                              }
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentee.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentee.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <a
                              href={mentorUrl(booking?.mentor.slug)}
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentor.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentor.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <span className="text-16 text-grey-light font-500">
                              {new Date(
                                new Date(booking?.date_and_time)
                              ).toUTCString()}
                            </span>
                          </td>
                          {/* <td>
                            <span className="text-16 text-grey-light font-500">
                              {booking?.date_and_time.split("T")[1].slice(0, 5)}{" "}
                              -{" "}
                              {formatTime(
                                booking?.date_and_time,
                                booking?.duration_in_minutes
                              )}
                            </span>
                          </td> */}

                          <td>
                            <span
                              className="text-16 text-success font-500 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  currentBooking: booking,
                                  showSideModal: true,
                                });
                              }}>
                              View
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td colSpan={5}>
                        <PaginationBar
                          page={confirmedPaginate.page}
                          pageSize={confirmedPaginate.pageSize}
                          totalItems={confirmedBookingsData["count"]}
                          onChangePage={(newPage) => {
                            setConfirmedPaginate({
                              ...confirmedPaginate,
                              page: newPage,
                            });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setConfirmedPaginate({
                              ...confirmedPaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

          {activeTab === 3 && (
            <LoaderPane
              loading={!declinedBookingsData && !declinedBookingsError}
              error={declinedBookingsError?.message}
              noRecords={
                !declinedBookingsError &&
                declinedBookingsData &&
                declinedBookingsData["count"] === 0
              }
              onReload={() => {
                mutateDeclinedBookings();
              }}
            />
          )}

          {activeTab === 3 &&
            declinedBookingsData &&
            !declinedBookingsError &&
            declinedBookingsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Mentee</th>
                      <th>Mentor</th>
                      <th>Date and Time</th>
                      {/* <th>Time</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortDescending(declinedBookingsData?.results)?.map(
                      (booking) => (
                        <tr key={booking?.id}>
                          <td>
                            <a
                              href={
                                booking.mentee.identity_type === "Mentor"
                                  ? mentorUrl(booking?.mentee.slug)
                                  : designerUrl(booking?.mentee.slug)
                              }
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentee.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentee.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <a
                              href={mentorUrl(booking?.mentor.slug)}
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentor.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentor.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <span className="text-16 text-grey-light font-500">
                              {new Date(
                                new Date(booking?.date_and_time)
                              ).toUTCString()}
                            </span>
                          </td>
                          {/* <td>
                            <span className="text-16 text-grey-light font-500">
                              {booking?.date_and_time.split("T")[1].slice(0, 5)}{" "}
                              -{" "}
                              {formatTime(
                                booking?.date_and_time,
                                booking?.duration_in_minutes
                              )}
                            </span>
                          </td> */}

                          <td>
                            <span
                              className="text-16 text-success font-500 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  currentBooking: booking,
                                  showSideModal: true,
                                });
                              }}>
                              View
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td colSpan={5}>
                        <PaginationBar
                          page={declinedPaginate.page}
                          pageSize={declinedPaginate.pageSize}
                          totalItems={declinedBookingsData["count"]}
                          onChangePage={(newPage) => {
                            setDeclinedPaginate({
                              ...declinedPaginate,
                              page: newPage,
                            });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setDeclinedPaginate({
                              ...declinedPaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

          {activeTab === 4 && (
            <LoaderPane
              loading={!cancelledBookingsData && !cancelledBookingsError}
              error={cancelledBookingsError?.message}
              noRecords={
                !cancelledBookingsError &&
                cancelledBookingsData &&
                cancelledBookingsData["count"] === 0
              }
              onReload={() => {
                mutateCancelledBookings();
              }}
            />
          )}

          {activeTab === 4 &&
            cancelledBookingsData &&
            !cancelledBookingsError &&
            cancelledBookingsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Mentee</th>
                      <th>Mentor</th>
                      <th>Date and Time</th>
                      {/* <th>Time</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortDescending(cancelledBookingsData?.results)?.map(
                      (booking) => (
                        <tr key={booking?.id}>
                          <td>
                            <a
                              href={
                                booking.mentee.identity_type === "Mentor"
                                  ? mentorUrl(booking?.mentee.slug)
                                  : designerUrl(booking?.mentee.slug)
                              }
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentee.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentee.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <a
                              href={mentorUrl(booking?.mentor.slug)}
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentor.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentor.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <span className="text-16 text-grey-light font-500">
                              {new Date(
                                new Date(booking?.date_and_time)
                              ).toUTCString()}
                            </span>
                          </td>
                          {/* <td>
                            <span className="text-16 text-grey-light font-500">
                              {booking?.date_and_time.split("T")[1].slice(0, 5)}{" "}
                              -{" "}
                              {formatTime(
                                booking?.date_and_time,
                                booking?.duration_in_minutes
                              )}
                            </span>
                          </td> */}

                          <td>
                            <span
                              className="text-16 text-success font-500 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  currentBooking: booking,
                                  showSideModal: true,
                                });
                              }}>
                              View
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td colSpan={5}>
                        <PaginationBar
                          page={cancelledPaginate.page}
                          pageSize={cancelledPaginate.pageSize}
                          totalItems={cancelledBookingsData["count"]}
                          onChangePage={(newPage) => {
                            setCancelledPaginate({
                              ...cancelledPaginate,
                              page: newPage,
                            });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setCancelledPaginate({
                              ...cancelledPaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

          {activeTab === 5 && (
            <LoaderPane
              loading={!noShowBookingsData && !noShowBookingsError}
              error={noShowBookingsError?.message}
              noRecords={
                !noShowBookingsError &&
                noShowBookingsData &&
                noShowBookingsData["count"] === 0
              }
              onReload={() => {
                mutateNoShowBookings();
              }}
            />
          )}

          {activeTab === 5 &&
            noShowBookingsData &&
            !noShowBookingsError &&
            noShowBookingsData["count"] > 0 && (
              <div>
                <Table bordered responsive className="table-white">
                  <thead>
                    <tr>
                      <th>Mentee</th>
                      <th>Mentor</th>
                      <th>Date and Time</th>
                      {/* <th>Time</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortDescending(noShowBookingsData?.results)?.map(
                      (booking) => (
                        <tr key={booking?.id}>
                          <td>
                            <a
                              href={
                                booking.mentee.identity_type === "Mentor"
                                  ? mentorUrl(booking?.mentee.slug)
                                  : designerUrl(booking?.mentee.slug)
                              }
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentee.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentee.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <a
                              href={mentorUrl(booking?.mentor.slug)}
                              target="_blank">
                              <div className="d-flex pointer">
                                <div className="pic-small mr-3">
                                  <img
                                    className="img-f-w-obj-cover"
                                    src={booking?.mentor.profile_photo_url}
                                    alt=""
                                  />
                                </div>
                                <span className="text-16 text-grey font-500">
                                  {booking?.mentor.name}{" "}
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>
                            <span className="text-16 text-grey-light font-500">
                              {new Date(
                                new Date(booking?.date_and_time)
                              ).toUTCString()}
                            </span>
                          </td>
                          {/* <td>
                            <span className="text-16 text-grey-light font-500">
                              {booking?.date_and_time.split("T")[1].slice(0, 5)}{" "}
                              -{" "}
                              {formatTime(
                                booking?.date_and_time,
                                booking?.duration_in_minutes
                              )}
                            </span>
                          </td> */}

                          <td>
                            <span
                              className="text-16 text-success font-500 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  currentBooking: booking,
                                  showSideModal: true,
                                });
                              }}>
                              View
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td colSpan={5}>
                        <PaginationBar
                          page={noShowPaginate.page}
                          pageSize={noShowPaginate.pageSize}
                          totalItems={noShowBookingsData["count"]}
                          onChangePage={(newPage) => {
                            setNoShowPaginate({
                              ...noShowPaginate,
                              page: newPage,
                            });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setNoShowPaginate({
                              ...noShowPaginate,
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
        </div>
      </PageLayout>
    </>
  );
};

export default BookingsPage;
