import React from "react";
import "./text-area.styles.scss";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";

const TextArea = ({
  ref = React.createRef(),
  label,
  error,
  visible,
  classes,
  isSearch,
  rightIcon,
  ...otherProps
}) => (
  <div className={`text-area ${classes}`}>
    {label && <label>{label}</label>}
    <div className={`input-container ${visible}`}>
      <textarea ref={ref} {...otherProps} className=""></textarea>
    </div>
    {error && <div className="text-danger mt-1">{error}</div>}
  </div>
);

export default TextArea;
